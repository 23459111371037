import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider';
import moment from "moment";
import { getOrderAccount } from '../../../Components/Functions/LabelPdf/LabelFunctions';
import Loader from '../../../Components/Global/Loaders';

export const OrderHistory = () => {

    const u_id = localStorage.getItem('u_id')
    const [data, setData] = useState([]);
    const [state, setstate] = useState({
        loading: false,
        error: ''
    });
    
    const handleGetLabel = async () => {
        setstate({...state, loading: true })
        const resp = await getOrderAccount( u_id )
        if ( resp.status ) {
            setData( resp.results )
        } else {
            setData( false )
            setstate({...state, error: resp.msg })
        }
        setstate({...state, loading: false })
    }

    useEffect(() => { handleGetLabel() }, [])

    return (
        <Grid container spacing={2} >
            <Grid item xs={8}  >
                <Typography variant="h4" gutterBottom className="Page_title">
                    Order history
                </Typography>
            </Grid>
            {/* <Grid item xs={2}  >
                <Link to="/customer/history/import" className="No_Decoration px-2" >
                    <Button variant="contained" disableElevation fullWidth className="Go_btn-primary"> Import </Button>
                </Link>
            </Grid> */}
            <Grid item xs={2}  >
                <Link to="/customer/history/export" className="No_Decoration px-2" >
                    <Button id="go_history_buttonExport" variant="contained" disableElevation fullWidth className="Go_btn-primary"> EXPORT </Button>
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Box p={5} className="bg_white" boxShadow={3} >
                    <Grid id="go_historyTable_heders" container className="customer__list-label">
                        <Grid item xs={12} md={1}><small> ORDER NUMBER </small></Grid>
                        <Grid item xs={12} md={2}><small> DATE </small></Grid>
                        <Grid item xs={12} md={3}><small> SERVICE TYPE </small></Grid>
                        <Grid item xs={12} md={2}><small> DELIVER TO </small></Grid>
                        <Grid item xs={12} md={1}><small> STATUS </small></Grid>
                        <Grid item xs={2}> </Grid>
                        <Grid item xs={12}><hr/></Grid>
                    </Grid>
                        
                    { state.loading 
                        ? <Grid container direction="row" justify="center" alignItems="center" > <Loader onlyLoad={true} /> </Grid>
                        : data 
                            ? data.map( (row) => (
                                <Grid container className="my-2 customer__list-text" key={ row.order_id } >
                                    <Grid item xs={12} md={1}>{ row.order_id }</Grid>
                                    <Grid item xs={12} md={2}>{ moment( row.order_date ).format('YYYY/MM/DD') }</Grid>
                                    <Grid item xs={12} md={3}>{ row.service }</Grid>
                                    <Grid item xs={12} md={2}>{ row.delivery_contact }</Grid>
                                    <Grid item xs={12} md={1}>{ row.status }</Grid>
                                    <Grid id="go_historyTable_itemButtons" item xs={12} md={3}>
                                        <Link to={`/customer/pdf/${row.order_id}`} className="No_Decoration">
                                            <Button variant="contained" disableElevation className="btn-grey mr-2" > Print label </Button>
                                        </Link>
                                        <Link to={`/customer/history/details/${row.order_id}`} className="No_Decoration">
                                            <Button variant="contained" disableElevation className="Go_btn-primary" > Details </Button>
                                        </Link>
                                    </Grid>
                                    <Divider />
                                </Grid>
                                 
                                

                                
                            )) 
                            : <Typography variant="subtitle1" gutterBottom > You don't have saved addresses yet </Typography>
                    } 
                </Box>
            </Grid>
        </Grid>
    )
}
