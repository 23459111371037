import React from 'react'
import { Link } from 'react-router-dom';
//media
import _person from '../../Assets/Img/person_go.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ListIcon from '@material-ui/icons/FormatListBulletedOutlined';
import UploadIcon from '@material-ui/icons/Publish';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Box } from '@material-ui/core';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import { ReturnsIcon, ScanInIcon, ScanOutIcon } from '../../Components/AppIcons';
import WarehouseModal from './WarehouseModal';
import SearchIcon from '@material-ui/icons/Search';

const WarehouseDashboard = () => {

    const userName = localStorage.getItem('u_name')

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            
            <Grid id="go_gridContainerXs_dashboardCustomer" item xs={10} >
                <Box boxShadow={3} >
                    <Grid container className="bg_primary " >
                        <Grid item xs={6} md={5} className="center_center">
                            <img src={_person} alt="" className="w-80" />
                        </Grid>
                        <Grid item xs={6} md={7} className="items-cen-cen">
                            <Typography variant="h4" className="customer__tile-home text-center" >Welcome {userName}!</Typography><br />
                            <Typography variant="h5" className="text_white text-center" > What are we doing today?</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="bg_white p-3" >
                        <Grid item xs={6} md={3} className="p-3 items-cen-cen"  >
                            <ScanInIcon size="lg" />
                            <Typography variant="h6" className="text_primary py-3" > Scan In </Typography>
                            <Link to="/wh/scan/in" className="No_Decoration">
                                <Button variant="outlined" className="Go_btn-primary w-150px" > View more </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3} className="p-3 items-cen-cen"  >
                            <ScanOutIcon size="lg" />
                            <Typography variant="h6" className="text_primary py-3" > Scan Out </Typography>
                            <Link to="/wh/scan/out" className="No_Decoration">
                                <Button variant="outlined" className="Go_btn-primary w-150px" > View more </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3} className="p-3 items-cen-cen"  >
                            <ReturnsIcon size="lg" />
                            <Typography variant="h6" className="text_primary py-3" > Returns </Typography>
                            <Link to="/wh/scan/returns" className="No_Decoration" >
                                <Button variant="outlined" className="Go_btn-primary w-150px" > VIEW MORE </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3} className="p-3 items-cen-cen"  >
                            <ListAltIcon color="primary" style={{fontSize:"4em"}} />
                            <Typography variant="h6" className="text_primary py-3" > Inventory Count </Typography>
                            <Link to="/wh/inventory" className="No_Decoration" >
                                <Button variant="outlined" className="Go_btn-primary w-150px" > VIEW MORE </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3} className="p-3 items-cen-cen"  >
                            <SearchIcon color="primary" style={{fontSize:"4em"}} />
                            <Typography variant="h6" className="text_primary py-3" > Find Products </Typography>
                            <Link to="/wh/find/products" className="No_Decoration" >
                                <Button variant="outlined" className="Go_btn-primary w-150px" > VIEW MORE </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3} className="p-3 items-cen-cen"  >
                            <AddLocationIcon color="primary" style={{fontSize:"4em"}} />
                            <Typography variant="h6" className="text_primary py-3" > Set location </Typography>
                            <Link to="/wh/set/location" className="No_Decoration" >
                                <Button variant="outlined" className="Go_btn-primary w-150px" > VIEW MORE </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default WarehouseDashboard

