import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

const SettingsElementItem = ( { isVendor, isStation, isModel, logo, e_id, id, name, link, onDelete } ) => {

    
    if ( isVendor ) {
        return (
            <Box p={1} className="" >
                <Grid container >
                    <Grid item xs={6} md={3}>
                        { logo && <img src={ logo } alt="" className="img_brand" /> }
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <Typography variant="body1" className="text_grey">
                            { name }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        {
                            localStorage.getItem('Type') === '1' &&
                                <Box p={1}>
                                    <form onSubmit={ e => onDelete( e, e_id ) }>
                                        <Button variant="outlined"  type="submit"  className="" fullWidth > DELETE </Button>
                                    </form>
                                </Box>
                        }
                        {
                            localStorage.getItem('Type') === '2' &&
                            <>
                                {
                                    localStorage.getItem('PS_D') === '1' &&
                                        <Box p={1}>
                                            <form onSubmit={ e => onDelete( e, e_id ) }>
                                                <Button variant="outlined"  type="submit"  className="" fullWidth > DELETE </Button>
                                            </form>
                                        </Box>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box p={1}>
                            <Link to={ link } className="No_Decoration">
                                <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >DETAILS</Button>
                            </Link> 
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <hr className="divider_grey" />
                    </Grid>
                </Grid>
            </Box>
        );
    }
    if ( isStation ) {
        return (
            <Box p={1} className="" >
                <Grid container >
                    <Grid item xs={6} md={3}>
                        { id && id }
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <Typography variant="body1" className="text_grey">
                            { name }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        {
                            localStorage.getItem('Type') === '1' &&
                                <Box p={1}>
                                    <form onSubmit={ e => onDelete( e, e_id ) }>
                                        <Button variant="outlined"  type="submit"  className="" fullWidth > DELETE </Button>
                                    </form>
                                </Box>
                        }
                        {
                            localStorage.getItem('Type') === '2' &&
                            <>
                                {
                                    localStorage.getItem('PS_D') === '1' &&
                                        <Box p={1}>
                                            <form onSubmit={ e => onDelete( e, e_id ) }>
                                                <Button variant="outlined"  type="submit"  className="" fullWidth > DELETE </Button>
                                            </form>
                                        </Box>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box p={1}>
                            <Link to={ link } className="No_Decoration">
                                <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >DETAILS</Button>
                            </Link> 
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <hr className="divider_grey" />
                    </Grid>
                </Grid>
            </Box>
        );
    }
    if ( isModel ) {
        return (
            <Box p={1} className="" >
                <Grid container >
                    <Grid item xs={6} md={3}>
                        { id && id }
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <Typography variant="body1" className="text_grey">
                            { name }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        {
                            localStorage.getItem('Type') === '1' &&
                                <Box p={1}>
                                    <form onSubmit={ e => onDelete( e, e_id ) }>
                                        <Button variant="outlined"  type="submit"  className="" fullWidth > DELETE </Button>
                                    </form>
                                </Box>
                        }
                        {
                            localStorage.getItem('Type') === '2' &&
                            <>
                                {
                                    localStorage.getItem('PS_D') === '1' &&
                                        <Box p={1}>
                                            <form onSubmit={ e => onDelete( e, e_id ) }>
                                                <Button variant="outlined"  type="submit"  className="" fullWidth > DELETE </Button>
                                            </form>
                                        </Box>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box p={1}>
                            <Link to={ link } className="No_Decoration">
                                <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >DETAILS</Button>
                            </Link> 
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <hr className="divider_grey" />
                    </Grid>
                </Grid>
            </Box>
        );
    }
};

export default SettingsElementItem;