import React, { useState } from 'react'
import { Box, Button, Grid, makeStyles, Step, StepLabel, Stepper, Typography } from '@material-ui/core'
import Logo from './../../Assets/Img/Logo.png';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const getSteps = () => {
    return ['Account', 'Presonal Information', 'Documentation ( optional )', 'Complete'];
}
const initialState = {
    _rdga: '',
    _name: '',
    _phone: '',
    _email: '',
    _address: '',
    _station: '', 
    _password1: '',
    _password2: '',
    _licenseExp: '',
}

export const OpenRegistry = () => {

    const classes = useStyles();
    const steps = getSteps();
    const [step, setStep] = useState({
        step1: false,
        step2: false,
        step3: false,
        step_: 0,
    })
    const [avatar, setAvatar] = useState('')
    const [values, setValues] = useState( initialState );
    const { step_ } = step
    const { _rdga, _name, _phone, _email, _address, _station, _password1, _password2, _licenseExp } = values

    const nextStep = () => setStep({...step, step_: step_ + 1 })
    const resetStep = () => { setValues( initialState ); setStep({...step, step_: 0 })}
    const backStep = () => {
        setStep({...step, step_: step_ - 1, step3: '' })
    }
    
    const handleImputChange = ({ target }) => {
        setValues({
            ...values,
            [ target.name ]: target.value
        })
    }  

    const getStepContent = ( step ) => {
        switch ( step ) {
            case 0:
            return <Step1 
                _avatar= { avatar }
                _setAvatar= { setAvatar }
                _rdga={ _rdga } 
                _name={ _name }
                _password1={ _password1 }
                _password2={ _password2 }
                handleChange={ handleImputChange } 
                nextStep={ nextStep }
                backStep={ backStep }
            />;
            case 1:
            return <Step2 
                _station={ _station }
                _phone={ _phone } 
                _email={ _email } 
                _address={ _address }
                handleChange={ handleImputChange } 
                nextStep={ nextStep }
                backStep={ backStep }
                />;

            case 2:
            return <Step3  
                _values={ values }
                _avatar={ avatar }
                handleChange={ handleImputChange } 
                nextStep={ nextStep }
                backStep={ backStep }
                />;
            case 3:
            return <Step4 
                resetStep={ resetStep }
                />;
            default:
            return 'Unknown stepIndex';
        }
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" id="app_back" style={{overflow: "scroll"}}>
            <Box className="Box_custom Box_Login-Content" boxShadow={3} p={3} >
                <Grid container direction="row" justify="center" alignItems="center" >
                    <img src={ Logo } alt="" className="Login_Logo" />
                </Grid>
                <Grid  container direction="column" justify="center" alignItems="center">
                    <Typography className="text-primary" variant="h5"> Driver registration process </Typography>
                </Grid>
                <div className={classes.root}>
                    <Stepper activeStep={ step_ } alternativeLabel id="newDriverStep" >
                        { steps.map( label => 
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        )}
                    </Stepper>
                    { step_ === steps.length ? 
                        <div>
                            <Typography className={classes.instructions}>All steps completed</Typography>
                            <Button onClick= { resetStep }>Reset</Button>
                        </div>
                        : getStepContent( step_ ) 
                    }
                </div>
            </Box>
        </Grid>
    )
}
