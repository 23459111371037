import React, { createContext, useState } from 'react';
import { useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ( { children } ) => {

    const [ session, setSession ] = useState(false);
    const [user,setUser] = useState()
    const [token, setToken] = useState('');
    const [type, setType] = useState('');
    const [permissions, setPermissions] = useState('');
    const [isChatActive, setIsChatActive] = useState('');

    useEffect(() => {
        if(localStorage.getItem("go_user")){
            const _user = JSON.parse(localStorage.getItem("go_user"))
            setUser(_user)
        }
        setToken(localStorage.getItem('Token'))
        setType(localStorage.getItem('Type'))
        setPermissions(localStorage.getItem('Permissions'))
    },[session])

    const handlelogout = () =>{
        localStorage.clear()
        setSession(false)
    }

    return(
        <AuthContext.Provider 
            value={{
                user,
                setSession,
                session,
                handlelogout,
                token,
                type,
                permissions,
                isChatActive,
                setIsChatActive,
            }}
        >
            { children }
        </AuthContext.Provider>
    )

}
