import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from '@material-ui/data-grid';

import { useDemoData } from '@material-ui/x-grid-data-generator';
import { APIGO } from '../constants/API';
import { Grid } from '@material-ui/core';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <Grid>
                <GridToolbarExport />
            </Grid>
        </GridToolbarContainer>
    );
}

export default function VehiclesCsv() {

    const [rawData, setrawData] = useState([])

    const [page, setpage] = useState(1)
    const [limit, setlimit] = useState(500)

    const width = 150;

    const [columns, setcolumns] = useState([
        { field: "v_id", headerName: "vehicle_id", width: width, type:"text" },
        { field: "fleet_number", headerName: "fleet_number", width: width ,type:"text"},
        { field: "licence_plate", headerName: "licence_plate", width: width ,type:"text"},
        { field: "status", headerName: "status", width: width ,type:"text"},
        //{ field: "model", headerName: "model", width: width ,type:"text"},
        //{ field: "brand", headerName: "brand", width: width ,type:"text"},
        { field: "station", headerName: "station", width: width ,type:"text"},
        //{ field: "vendor_logo", headerName: "Vendor", width: width ,type:"text"},
        { field: "vendor_name", headerName: "vendor_name", width: width ,type:"text"},
        //garage details
        { field: "Garage_City", headerName: "garage_city", width: width ,type:"text"},
        { field: "Garage_Postal_Code", headerName: "garage_postal_code", width: width ,type:"text"},
        { field: "Garage_Providence", headerName: "garage_province", width: width ,type:"text"},
        { field: "Garage_Street_Address", headerName: "garage_address", width: width ,type:"text"},
        { field: "Pooled_Vehicle", headerName: "garage_pooled", width: width ,type:"text"},
        //Lease details
        { field: "Vendor", headerName: "vendor", width: width ,type:"text"},
        { field: "Ownership", headerName: "ownership", width: width ,type:"text"},
        { field: "Lease_Term", headerName: "lease_term", width: width ,type:"text"},
        { field: "Lease_Type", headerName: "lease_type", width: width ,type:"text"},
        //Specific details
        { field: "insurance", headerName: "insurance", width: width ,type:"text"},
        { field: "vehicle_id", headerName: "rdga", width: width ,type:"text"},
        { field: "vin_number", headerName: "vin_number", width: width ,type:"text"},
        { field: "geo_tab_number", headerName: "geo_tag_number", width: width ,type:"text"},
        //Particular details
        { field: "Year", headerName: "year", width: width ,type:"text"},
        { field: "Brand", headerName: "brand", width: width ,type:"text"},
        { field: "Model", headerName: "model", width: width ,type:"text"},
        { field: "Series", headerName: "series", width: width ,type:"text"},
        { field: "Delivery_Date", headerName: "delivery_date", width: width ,type:"text"},
        { field: "Registration_Exp_Date", headerName: "registration_exp_date", width: width ,type:"text"},
        { field: "Registration_Province", headerName: "registration_province", width: width ,type:"text"},

    ])

    const [rows, setrows] = useState([])

    useEffect(() => {
        var config = {
            method: 'get',
            url: `/vehicles/?page=${page}&limit=${limit}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        }
        APIGO(config)
            .then(response => {
                if (response.data.status === 'error') {
                    // List for all vehicles
                    setrawData(false);
                }
                if (response.data.status === 'success') {
                    // List for all vehicles
                    setrawData(response.data.results);
                }
            })
            .catch(function (error) {
                console.log(error)
                setrawData(false);
            })
        return () => { }
    }, [page, limit])

    useEffect(() => {
        let r = []
        rawData.map((baseItem, index) => {
            baseItem.id = index
            const { Garage_Details, Lease_Details, Particular_Details, Specific_details } = baseItem.details;
            Object.entries(Garage_Details).forEach(([key, val]) => {
                baseItem[key] = val
            })
            Object.entries(Lease_Details).forEach(([key, val]) => {
                baseItem[key] = val
            })
            Object.entries(Particular_Details).forEach(([key, val]) => {
                baseItem[key] = val
            })
            Object.entries(Specific_details).forEach(([key, val]) => {
                baseItem[key] = val
            })

            baseItem.details = "";
            baseItem.pictures = "";
            baseItem.Ownership = "";
            baseItem.ownership = "";
            baseItem.insurance = "";
            baseItem.vendor_logo = "";
            r.push(baseItem)
        })
        setrows(r)
        return () => {
        }
    }, [rawData])

    return (
        <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
                checkboxSelection={true}
                columns={columns}
                rows={rows}
                dataSet="Commodity"
                rowLength={4}
                maxColumns={6}
                disableColumnMenu={true}
                checkboxSelection={true}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}