import React, { useState }  from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Logo from './../../Assets/Img/Logo.png';

const Modal = ({ Operation }) =>{

    const [password, setpassword] = useState('');
    const [user, setuser] = useState('');

    return(
        <Grid container className="modal__style-B"> 
            <Grid item >
                <Box>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <img src={ Logo } alt="modal img" />
                    </Grid>
                </Box >
                <Box pb={0} pt={2} textAlign="center">
                    <Typography variant="h6" gutterBottom className="Page_title">
                        Sign in to continue and delelet 
                    </Typography>
                </Box>
                <Box m={4}>
                    
                    <Grid container > 
                        <Grid item xs={12} >
                            <Grid container direction="row" justify="center" alignItems="center" >
                                <Box my={1} fullWidth textAlign="center" >
                                    <TextField id="user" size="small" fullWidth type="text" variant="outlined" placeholder="User" value={ user } onChange={e => setuser(e.target.value)} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container direction="row" justify="center" alignItems="center" >
                                <Box my={1} fullWidth textAlign="center" >
                                    <TextField id="pass" size="small" fullWidth type="password" variant="outlined" placeholder="Password" value={ password } onChange={e => setpassword(e.target.value)} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} >
                            <Box px={2}>
                                <form onSubmit={ e => Operation(e, false ) }>
                                    <Button className="" variant="outlined" type="submit" fullWidth>Cancel</Button>
                                </form>
                            </Box>
                        </Grid>
                        <Grid item xs={6} >
                            <Box px={2}>
                                <form onSubmit={ e => Operation(e, true, password) }>
                                    <Button variant="outlined" className="Go_btn-primary" fullWidth type="submit" >Confirm</Button>
                                </form>
                            </Box>
                        </Grid> 
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Modal