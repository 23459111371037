import { Box, Button, Card, CardContent, Grid, TextField, Typography, useTheme } from '@material-ui/core'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import { decodeFromBase64DataUri } from 'pdf-lib'
import React, { useContext } from 'react'
import { AuthContext } from '../../Components/Functions/Auth'
import BaseDialog from '../../Components/Global/Modals/BaseDialog'
import { updateRegisterData } from '../../middleware/warehouse/Warehouse'
import { getWarehouseLocationByParams } from '../../middleware/warehouse/WarehouseLocations';

const EditRegisterLocationModal = ({ item, open, onClose, onDataChange }) => {

    const theme = useTheme()

    const { user } = useContext(AuthContext)

    const defaultData = {
        location_name:"",
    }
    const [formData, setFormData] = React.useState(defaultData)

    React.useEffect(() => {
        setFormData({
            ...item,
            username: user.name,
            user_id: user.u_id,
            location_name:item.location_name=== "-" ? "" : item.location_name,
            register_id: item.warehouse_id,
        })
    }, [item])

    const onChange = ({ target }) => {
        const { name, value } = target
        setFormData({
            ...formData,
            [name]: value.toUpperCase()
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        const _isLocation = await getWarehouseLocationByParams(
            `code = "${formData.location_name}"`
        )
        if(!_isLocation.length > 0){
            alert("Invalid location code")
            return
        }
        const result = await updateRegisterData(formData)
        if (result.status) {
            onClose()
            if (onDataChange) {
                onDataChange()
            }
        } else {
            alert("some was wrong")
        }
    }

    return (
        <React.Fragment>
            <BaseDialog open={open} onClose={onClose} >
                <Card>
                    <Box style={{ color: "#fff", background: theme.palette.primary.main }} p={3} >
                        <Typography color="inherit" variant="h5">Set Location</Typography>
                    </Box>
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <Typography>Scann or enter location:</Typography>
                                    <TextField
                                        fullWidth
                                        required
                                        autoFocus
                                        placeholder="A2"
                                        onChange={onChange}
                                        name="location_name"
                                        value={formData.location_name}
                                        variant="outlined" size="small" />
                                </Grid>
                                <Grid item xs={12} >
                                    <Box display="flex" justifyContent="flex-end" flexDirection="row" >
                                        <Button type="submit" color="secondary" variant="contained">
                                            save
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </BaseDialog>
        </React.Fragment>
    )
}

export default EditRegisterLocationModal
