import React, { useContext } from 'react'

import { Container, Grid, Typography, Button, Card, CardContent, Divider, Box } from "@material-ui/core"
import { useHistory, useRouteMatch } from 'react-router-dom'
import { deleteWarehouseList, getWarehouseLists } from '../../middleware/warehouse/Warehouse'
import { AuthContext } from '../../Components/Functions/Auth';
import moment from 'moment';
import DialogMessage from '../../Components/Global/Modals/DialogMessage';
import Pagination  from '@material-ui/lab/Pagination';

const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

const HistoryItem = ({ name, created, id, reloadData }) => {

    const [dialog, setDialog] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    const deleteRegisters = async () => {
        if (id) {
            setLoading(true)
            const result = await deleteWarehouseList(id);
            if (result) {
                if (reloadData) reloadData()
                alert("Deleted")
            }
            console.log(result)
            setDialog(false)
        }
    }

    const history = useHistory()

    return (
        <React.Fragment>

            <DialogMessage
                type="warning"
                open={dialog}
                loading={loading}
                title="Delete all registers?"
                message="This action can not be undone, Are you sure to delete all registers related with this list?"
                positiveText="Proceed"
                positiveAction={deleteRegisters}
                negativeText="Back"
                onClose={() => setDialog(false)}
                negativeAction={() => setDialog(false)}
            />

            <Grid item xs={2}>
                <Typography>{moment(created).format('DD/MM/YYYY HH:MM')}</Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography>{name}</Typography>
            </Grid>
            <Grid item xs={5}  >
                <Grid container spacing={2} justify="space-between">
                    <Grid item>
                        <Box width="100%" textAlign="right" >
                            <Button
                                onClick={() => {
                                    history.push(`/customer_service/list/${id}`)
                                }}
                                color="secondary" variant="contained" >View list</Button>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box width="100%" textAlign="right" >
                            <Button
                                style={{ background: "crimson", color: "white" }}
                                onClick={() => {
                                    setDialog(true)
                                }}
                                color="inherit" variant="contained" >Delete</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const CustomerServiceHistory = () => {

    const history = useHistory()

    const { user: { u_id } } = useContext(AuthContext)

    const [lists, setLists] = React.useState([])
    const [page, setpage] = React.useState(1)
    const [pages, setpages] = React.useState(1)

    const per_page = 8;

    React.useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const response = await getWarehouseLists(u_id,page,per_page)
        if (response.status) {
            setLists(response.result)
            setpage(response.page)
            setpages(response.pages)
        }
    }

    const loadPageData = async (_page) => {
        const response = await getWarehouseLists(u_id,_page,per_page)
        if (response.status) {
            setLists(response.result)
            setpage(response.page)
            setpages(response.pages)
        }
    }

    return (
        <Container>
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Grid container  >
                        <Grid item xs={6}>
                            <Typography variant="h5" color="primary" >CSV History</Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <Box width="100%" textAlign="right" >
                                <Button onClick={() => history.goBack()} color="secondary" variant="contained" >Add new csv file</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography>Date</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>File name</Typography>
                                </Grid>
                                <Grid item xs={6}>

                                </Grid>
                            </Grid>
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                            <Grid container spacing={3} >
                                {
                                    lists.length > 0 && <React.Fragment>
                                        {
                                            lists.map((item, index) => (
                                                <HistoryItem {...item} reloadData={loadData} />
                                            ))
                                        }
                                    </React.Fragment>
                                }
                            </Grid>
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                            <Grid container direction="row" justify="center" alignItems="center" >
                                {lists &&
                                    <Pagination page={page} count={pages} variant="outlined" shape="rounded"
                                        onChange={(e, value) => {
                                            loadPageData(value)
                                        }} />
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CustomerServiceHistory
