import React, { useEffect } from 'react'
import { CHATS_REF, STGREF } from './Contants'
import { APIGO, getProfile } from '../../../constants/API'
import styles from './chat.module.css'
// Material UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { Message } from './Manager'

export const fetchDriver =  (setDriverUser) => {
    let user
    let config = {
        method: 'GET',
        url: getProfile,
        headers: {
            'Access-Control':'multipart/form-data',
            'Authorization': `Bearer ${sessionStorage.Token}`
        }
    }
    APIGO(config)
    .then((res) => {
        user = (res.data)
    })
    .catch(err => console.log(err))
    return setDriverUser(user)
}

export const sendMessage = async (message, user, chatid, link=null) => {
    
    let body = {
        _id     : generate(),
        date    :await new Date().toDateString(),
        message : message,
        link    : link,
        user: {
            _id    : user.rdga,
            avatar : user.avatar,
            name   : user.full_name
        }
    }
    // console.log('chatid', chatid);
    let status = await CHATS_REF.child(`/${chatid}`).update({status:'attend'})
    let pushing = await CHATS_REF.child(`/${chatid}/messages/`).push(body)

    return true
}

export const getChat = async (user, setChatBody, setIdChat) => {

    const listener = CHATS_REF.orderByChild(`${user.rdga}`).equalTo(true).limitToFirst(1)
    if(user.type === 3 ){
        var chat = {
            "status" : "request",
            "members": {
                [`${user.rdga}`]:{
                    "avatar": user.avatar,
                    "isOnline": false,
                    "isTyping": false,
                    "name": user.full_name
                },
            },
            "messages": {},
            [`${user.rdga}`]: true,
        }
        listener.on('value', async (snap) => {
            if(!snap.val()){
                let chatIdRef = await CHATS_REF.push({status:"request"})
                await CHATS_REF.child(chatIdRef.key).update(chat)
                // console.log(chatIdRef.key);
                setIdChat(chatIdRef.key)
            }else{
                // console.log('yes');
                const list1 = await listener.once('value', snap => {
                    let keys = Object.keys(snap.val());
                    setIdChat(keys[0])
                })
                setChatBody(snap.val())
            }
        })
    }
}

export const ChatView = ({chat, user}) => {

    const renderChat = () => {
        
        if(chat) {
            return Object.entries(chat).map(msg => {
                if(msg[1].messages){
                    // console.log(msg[1].messages);
                    return Object.entries(msg[1].messages).map(msg2 => (
                        <Message msg={msg2} myProfile={user} key={msg2[0]} />
                    ))
                }else{
                    return <Box key={msg[0]}>No messages yet</Box>
                }
                
            })
        }else{
            return <p>No messages yet</p>
        }
    }
    
    useEffect(() => {

    },[])

    return(
        <>
            { renderChat() }
        </>
    )
}    

export const uploadFile = (file, user, idChat, setLoading) => {
    setLoading(true)
    try{

        const upload = STGREF.child(`/files/${generate()+(file.name).split('.').pop()}`);
        upload.put(file).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                sendMessage(file.name, user, idChat, url);
                setLoading(false)
            })
        })
    }catch(err){
        console.log(err);
    }
}



// Gen _id

export const  generate = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
