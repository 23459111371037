import React, { useState, useEffect } from 'react'
import SettingsItem from './../Components/Global/SettingsItem';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

const Settings = () => {

    const history = useHistory();
    const [userType, setUserType] = useState(localStorage.getItem('Type'));
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        setPermissions(localStorage.getItem('PS_R'))
    }, []);

    if (localStorage.getItem('Type') === '1') {
        return (
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom className="Page_title">
                        Settings
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box px={2} py={2} className="bg_white" borderRadius={5} boxShadow={3} >
                        <SettingsItem title="General" link="/settings/general" />
                        <SettingsItem title="Vendor" link="/vendor" />
                        <SettingsItem title="Station" link="/station" />
                        <SettingsItem title="Vehicle models" link="/model" />
                        <SettingsItem title="Managers" link="/manager" />
                        <SettingsItem title="Warehouse Locations" link="/settings/locations" />
                    </Box>
                </Grid>
            </Grid>
        );
    }
    if (localStorage.getItem('Type') === '2') {
        return (
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom className="Page_title">
                        Settings
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box px={2} py={2} className="bg_white" borderRadius={5} boxShadow={3} >
                        {
                            localStorage.getItem('PS_R') === '0' ?
                                history.push('/')
                                :
                                <>
                                    <SettingsItem title="Vendor" link="/vendor" />
                                    <SettingsItem title="Station" link="/station" />
                                    <SettingsItem title="Vehicle models" link="/model" />
                                </>
                        }
                    </Box>
                </Grid>
            </Grid>
        );
    }
};

Settings.propTypes = {

};

export default Settings;