import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import moment from "moment";
// Componentes
import ListElements from '../Components/Global/ListElements';
// IMG
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
//API
import { getdrivers, APIGO } from './../constants/API';
import { Paper } from '@material-ui/core';

const Drivers = () => {

    const [data, setData] = useState([]);
    const [info, setInfo] = useState([]);

    const [search, setSearch] = useState("");
    const [refresh, setRefresh] = useState(1);
    const [filter, setFilter] = useState(1);
    const [page, setPage] = useState(1);
    const [isData, setIsData] = useState(true);
    const [isExpirate, setIsExpirate] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    useEffect(() => {
        var config = {
            method: 'get',
            url: `/users/?page=${page}&limit=20&type=3`,
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        }
        APIGO(config)
            .then(response => {
                if (response.data.status === "error") {
                    // List for all vehicles
                    setData(false);
                }
                if (response.data.status === "success") {
                    setData(response.data.results);
                    setInfo(response.data.info)
                }
            })
            .catch(function (error) {
                console.log(error)
                setData(false);
            })
    }, [refresh]);

    useEffect(() => {
        let config;
        if (isData) {
            config = {
                method: 'get',
                url: `/users/?page=${page}&limit=20&type=3`,
                headers: {
                    'Access-Control-Request-Headers': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        if (isExpirate) {
            config = {
                method: 'get',
                url: `/users/?page=${page}&limit=20&type=3&filter_by_expire=true`,
                headers: {
                    'Access-Control-Request-Headers': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        if (isFilter) {
            config = {
                method: 'get',
                url: `/users/search?page=${page}&limit=20&type=3&search=${search}`,
                headers: {
                    'Access-Control-Request-Headers': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        APIGO(config)
            .then(response => {
                if (response.data.status === "error") {
                    // List for all vehicles
                    setData(false);
                }
                if (response.data.status === "success") {
                    setData(response.data.results);
                    setInfo(response.data.info)
                }
            })
            .catch(function (error) {
                console.log(error)
                setData(false);
            })
    }, [page, filter]);

    const filterbyExpirate = () => {
        setIsData(false);
        setIsExpirate(true);
        setIsFilter(false);
        setFilter(filter + 1);
    }
    const getAllData = () => {
        setIsData(true);
        setIsExpirate(false);
        setIsFilter(false);
        setFilter(filter + 1);
    }
    const filterList = (e, search) => {
        e.preventDefault();
        if (search === '') {
            setIsData(true);
            setIsFilter(false);
            setIsExpirate(false);
            setFilter(filter + 1);
        }
        else {
            setIsData(false);
            setIsFilter(true);
            setIsExpirate(false);
            setSearch(search)
            setFilter(filter + 1);

        }
    }
    const ActivePage = (e, value) => {
        setPage(value)
    }

    return (
        <Grid container >
            <Grid item xs={12} md={2}>
                <Typography variant="h4" gutterBottom className="Page_title">
                    Drivers
                </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
                <Box mt={1} px={1}>
                    {(localStorage.getItem('Type') === '1' || localStorage.getItem('Type') === '4') &&
                        <Link to="/drivers/new" className="No_Decoration" >
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >New Driver</Button>
                        </Link>
                    }
                    {localStorage.getItem('Type') === '2' &&
                        localStorage.getItem('PD_C') === '1' ?
                        <Link to="/drivers/new" className="No_Decoration" >
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >New Driver</Button>
                        </Link>
                        : null
                    }
                </Box>
            </Grid>
                {localStorage.getItem('Type') === '4' &&
                    <Grid item md={6} xs={2}>
                        <Box mt={1} px={1}>
                            <Link to="/drivers/approved" className="No_Decoration" >
                                <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" > To be approved </Button>
                            </Link>
                        </Box>
                    </Grid>
                }
            <Grid item xs={12} md={6} >
                <Box p={1} ml={2} className="bg_white" boxShadow={3} >
                    <Grid container className="bg_white" >
                        <Grid item xs={6} md={6}>
                            <Box className="pointer" onClick={getAllData}>
                                <Typography variant="body2" gutterBottom className="text_grey" align="center">
                                    <b> TOTAL DRIVERS </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom className="text_primary" align="center" >
                                    <b> {info.count} </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Box className="pointer" onClick={filterbyExpirate} >
                                <Typography variant="body2" gutterBottom className="text_grey" align="center" >
                                    <b> ABOUT TO EXPIRE </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom className="text_primary" align="center" >
                                    <b> {info.count_exp} </b>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {
                    data.length > 0 ?
                        <>
                            {isData && <ListElements isDriver={true} page_count={info.pages} activePage={ActivePage} list={data} filter={filterList} />}
                            {isExpirate && <ListElements isDriver={true} page_count={info.pages} activePage={ActivePage} list={data} filter={filterList} />}
                            {isFilter && <ListElements isDriver={true} page_count={info.pages} activePage={ActivePage} list={data} filter={filterList} />}
                        </>
                        :
                        <Paper elevation={3}>
                            <Box p={5} >
                                <Typography variant="h6" className="text_primary" >
                                    There is no drivers information &nbsp;
                                    <span
                                        className="try_again"
                                        onClick={() => setRefresh(refresh + 1)}
                                    >
                                        Try again.
                                    </span>
                                </Typography>
                            </Box>
                        </Paper>
                }
            </Grid>
        </Grid>
    )
}
export default Drivers

