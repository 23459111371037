import React, { Component } from 'react'
import Quagga from 'quagga'
import { makeStyles, useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    position:"relative",
    "& canvas": {
      position: "absolute",
      left: 0,
      top: 0
    }
  }
}))

const Scanner = ({ onDetected }) => {

  const theme = useTheme()

  const classes = useStyles()

  const handleDetect = (results) => {
    if (onDetected) {
      onDetected(results)
    }
  }

  const handleProcessed = (results) => {
    
  }

  React.useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: window.innerWidth,
            height: theme.breakpoints.values.sm/2,
            facingMode: 'environment',
          },
          //   area: { // defines rectangle of the detection/localization area
          //     top: "10%",    // top offset
          //     right: "10%",  // right offset
          //     left: "10%",   // left offset
          //     bottom: "10%"  // bottom offset
          //   },
        },
        locator: {
          halfSample: true,
          patchSize: "large", // x-small, small, medium, large, x-large
          debug: {
            showCanvas: true,
            showPatches: true,
            showFoundPatches: true,
            showSkeleton: true,
            showLabels: true,
            showPatchLabels: true,
            showRemainingPatchLabels: true,
            boxFromPatches: {
              showTransformed: true,
              showTransformedBox: true,
              showBB: true
            }
          }
        },
        numOfWorkers: 4,
        decoder: {
          readers: ['code_128_reader'],
          debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
            showPattern: true
          },
        },
        locate: true,
      },
      function (err) {
        if (err) {
          return console.log(err)
        }
        Quagga.start()
      },
    )
    Quagga.onDetected(handleDetect)
    Quagga.onProcessed(handleProcessed)

    return () => {
      Quagga.offDetected(handleDetect)
      Quagga.offProcessed(handleProcessed)
      Quagga.stop()
    }
  }, [])


  return (
    <div id="interactive" className={`${classes.root} viewport`} />
  )
}

export default Scanner