import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, TextField } from '@material-ui/core';
import { APIGO, CustomerRegister } from '../../constants/API';
import PlacesAutocomplete from '../../Components/Global/PlacesAutocomplete';
import { getService } from '../../Components/Functions/LabelPdf/LabelFunctions';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const FormDefault = {
    account: "",
    username: "",
    email: "",
    address: "",
    postal_code: "",
    province: "",
    phone: "",
    password: "",
    apartment_number: ""
}

const CustomersAdd = ({ open, setOpen, customer }) => {

    const [formData, setFormData] = useState({
        account: "",
        username: "",
        name: "",
        email: "",
        address: "",
        apartment_number: "",
        phone: "",
        password: "",
        postal_code: "",
    });
    const [messageInfo, setMessageInfo] = useState({
        severity: "info",
        title: "",
        message: "",
        visible: false,
    })
    const [services, setServices] = useState([])
    const [serviceList, setServiceList] = useState([]);
    const [messageErrors, setmessageErrors] = useState([]);
    const [msgErr, setMsgErr] = useState();
    let servicesActive = [];

    const updateForm = (key, value) => {
        setFormData({ ...formData, [key]: value });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const loadService = async () => {
        const resp = await getService()
        if (resp.status) {
            console.log(resp.status);
            setServiceList(resp.results)
        } else {
            setServiceList(false)
        }
    }

    const noSpace = async (value) => {
        var space = new RegExp(/\s/);
        if (space.test(value))
            return setMsgErr('No spaces allowed');
        else (space.test(value))
        return setMsgErr();
    }

    const SubmitRegister = async (e) => {
        e.preventDefault();

        console.log(servicesActive);

        if (!servicesActive)
            return setMessageInfo({ ...messageInfo, visible: true, message: 'You must to select a service to continue.' })
        else if (msgErr)
            return setMessageInfo({ ...messageInfo, visible: true, message: 'No spaces allowed at name' })
        else
            setMessageInfo({ ...messageInfo, visible: false })

        let config = {
            method: "post",
            url: CustomerRegister,
            data: {
                account: formData.account,
                username: formData.username,
                name: formData.name,
                email: formData.email,
                address: `${formData.address}-${formData.province}-${formData.postal_code}-${formData.apartment_number}`,
                phone: formData.phone,
                password: formData.password,
                postal_code: formData.postal_code,
                services: servicesActive,
                apartment_number: formData.apartment_number
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
        };

        APIGO(config)
            .then((res) => {
                console.log(res);
                const { msg } = res.data;
                if (msg === "success") {
                    if (customer) {
                        customer(formData)
                    }
                    setFormData(FormDefault);
                    setMsgErr();
                    handleClose();
                } else {
                    if (Array.isArray(msg)) {
                        setmessageErrors(msg)
                    } else {
                        setmessageErrors([{ text: msg }])
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const onServiceActive = (e, value) => {

        if (e.target.checked) {
            servicesActive.push(value)
        }
        else {
            var i = servicesActive.indexOf(value);
            if (i !== -1) {
                servicesActive.splice(i, 1);
            }
        }
    }

    useEffect(() => { loadService() }, [])

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Box py={3}>
                        <Typography className="text-primary" variant="h4">
                            Create Customer
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={(e) => SubmitRegister(e)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        id="user_account"
                                        variant="outlined"
                                        placeholder="Account"
                                        value={formData.account}
                                        autoComplete="off"
                                        onChange={(e) => updateForm("account", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        id="user_name"
                                        variant="outlined"
                                        placeholder="User name"
                                        value={formData.username}
                                        autoComplete="off"
                                        onChange={(e) => updateForm("username", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        id="name"
                                        variant="outlined"
                                        placeholder="Name"
                                        value={formData.name}
                                        autoComplete="off"
                                        onChange={(e) => { updateForm("name", e.target.value); noSpace(e.target.value); }}
                                        error={!!msgErr}
                                        helperText={msgErr}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box py={1}>
                                    <PlacesAutocomplete handleSelected={(place, details) => {
                                        console.log(place, details);
                                        setFormData({
                                            ...formData,
                                            "postal_code": details ? (details.postal_code ? details.postal_code.long_name : "") : "",
                                            "province": details ? (details.locality ? details.locality.long_name : "") : "",
                                            "address": place?.description
                                        })
                                    }} >
                                        <TextField
                                            fullWidth
                                            required
                                            type="text"
                                            id="user_address"
                                            variant="outlined"
                                            placeholder="Address ( No, st./av. )"
                                            value={formData.address}
                                            autoComplete="off"
                                        />
                                    </PlacesAutocomplete>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        id="apartment_number"
                                        variant="outlined"
                                        placeholder="Apartment / Number Unit"
                                        value={formData.apartment_number}
                                        autoComplete="off"
                                        onChange={(e) => updateForm("apartment_number", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={5}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        id="postal_code"
                                        variant="outlined"
                                        placeholder="Postal code"
                                        value={formData.postal_code}
                                        autoComplete="off"
                                        onChange={(e) => updateForm("postal_code", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        id="province"
                                        variant="outlined"
                                        placeholder="Province"
                                        value={formData.province}
                                        autoComplete="off"
                                        onChange={(e) => updateForm("province", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="phone"
                                        id="user_phone"
                                        variant="outlined"
                                        placeholder="Telephone number"
                                        value={formData.phone}
                                        autoComplete="off"
                                        onChange={(e) => updateForm("phone", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="email"
                                        id="user_mail"
                                        variant="outlined"
                                        placeholder="E-mail"
                                        value={formData.email}
                                        autoComplete="off"
                                        onChange={(e) => updateForm("email", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="Password"
                                        type="password"
                                        variant="outlined"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={(e) => updateForm("password", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box py={1}>
                                    <Typography className="text_grey" variant="body1"> Select services </Typography>
                                </Box>
                            </Grid>
                            <FormGroup>
                                <Grid container>
                                    {serviceList
                                        ? serviceList.map(service =>
                                            <Grid item xs={6} md={4} key={service.id} >
                                                <FormControlLabel
                                                    label={service.service}
                                                    className="text_grey"
                                                    control={
                                                        <Checkbox
                                                            name={service.id}
                                                            color="default"
                                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                            checked={services.sameDay}
                                                            onChange={e => { onServiceActive(e, service.id); }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        )
                                        : "No services availity"
                                    }
                                </Grid>
                            </FormGroup>

                            <Grid item xs={12}>
                                <Box className="start_box" py={1}>
                                    <Button
                                        type="submit"
                                        className="Go_btn-primary"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        CREATE
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                    {messageInfo.visible &&
                        <Alert severity="error" className="mb-1">{messageInfo.message}</Alert>
                    }
                    {messageErrors.length > 0 &&
                        messageErrors.map(error => {
                            if (error.text === "services must not be null")
                                return (<Alert severity="error" className="mb-1"> You should select one of the services. Example: Same Day, Basic 4-5 HR, etc... </Alert>)
                            else
                                return (<Alert severity="error" className="mb-1"> {error.text} </Alert>)
                        })
                    }
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CustomersAdd

CustomersAdd.propTypes = {
    customer: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func
};