import React, {useContext, useEffect} from 'react'
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from '../../Components/Functions/Auth';
import CustomerServiceLayout from '../../Pages/CustomerService/CustomerServiceLayout';

const PrivateCustomerService = ({ component: RouteComponent, ...rest }) => {
    // let Token  = sessionStorage.Token;
    const { token, user } = useContext(AuthContext)

    return (
        <Route {...rest} render={routeProps =>
            token ?
                <CustomerServiceLayout>
                    <RouteComponent {...routeProps} />
                </CustomerServiceLayout>
                :
                <Redirect to={"/login"} />
        } />
    );
}

export default PrivateCustomerService
