import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
//styles
import '../../Assets/Styles/Layout.css';
// IMG
import Logo from "../../Assets/Img/Logo.png"

import Notification from '../../Assets/Img/Icon_notification.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
//

import { Badge, Hidden, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { AuthContext } from '../../Components/Functions/Auth';
import { APIGO } from '../../constants/API';

const UserMenu = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

const CustomerServiceLayout = ({ children }) => {

    const history = useHistory();
    const userType = localStorage.getItem('Type');
    const { handlelogout, isChatActive, setIsChatActive, type, token } = useContext(AuthContext);
    const [chats, setChats] = useState('');
    const [movil, setMovil] = useState(null);

    const handleClick = (event) => setMovil(event.currentTarget);
    const handleClose = () => setMovil(null);

    useEffect(() => {
        let config = {
            method: 'GET',
            url: '/users/profile',
            headers: {
                'Access-Control': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
        APIGO(config)
            .then(res => {
                getNotifications(res.data.station)
            })
            .catch(err => console.log(err))
    }, [])

    const getNotifications = async (stationID) => {
        let chatsActive = [];

    }

    const LogOut = (e) => {
        e.preventDefault();
        history.push("/login");
        window.location.replace('');
    }

    return (
        <Grid container>
            <Grid item xs={12}  >
                <AppBar position="static" className="navBar" >
                    <Toolbar>
                        <Grid container direction="row" justify="space-between" alignItems="center" className="navBar_movil" >
                            <Box px={3} className="bar_logo">
                                <Link to="/home">
                                    <img src={Logo} alt="Logo Go Logistics" className="navBar_logo" />
                                </Link>
                            </Box>
                            <Box px={3} className="navBar_menu" >
                                <Hidden only={['xs', 'sm']}>

                                    <Link to="/customer_service/upload">
                                        <Button >Upload Csv</Button>
                                    </Link>
                                    <Link to="/customer_service/history">
                                        <Button >Registers List</Button>
                                    </Link>

                                    <Link to="/login">
                                        <Button onClick={e => { handlelogout(); LogOut(e); }} >Logout</Button>
                                    </Link>
                                    <Button aria-controls="notificationsMenu"  >
                                        <Link to="/notifications">
                                            <img src={Notification} alt="Notification" />
                                        </Link>
                                    </Button>
                                </Hidden>
                                {/* Menu movil */}
                                <Hidden mdUp>
                                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                        <MoreIcon />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={movil}
                                        keepMounted
                                        open={Boolean(movil)}
                                        onClose={handleClose}
                                    >

                                        <MenuItem >
                                            <Link to="/notifications" className="navBar_menu-text"> Notifications </Link>
                                        </MenuItem>
                                        <MenuItem onClick={e => { handlelogout(); LogOut(e); }} className="navBar_menu-text"> Logout </MenuItem>
                                    </Menu>
                                </Hidden>
                            </Box>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Container fixed >
                    <Box mt={3} className="App_Content">
                        {children}
                    </Box>
                </Container>
            </Grid>
        </Grid>
    )
}
export default CustomerServiceLayout