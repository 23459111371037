import React, { useState } from 'react'
// Componentes
import HomeSection from './../Components/Global/HomeSection';
// IMG
import Portada1 from './../Assets/Img/Portada_management.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import DashboardCustomer from './DashboardCustomer';
import PrivateCustomerService from '../Routes/components/PrivateCustomerService';
import CustomerServiceRoutes from '../Routes/CustomerServiceRoutes';
import { Redirect } from 'react-router-dom';

// 

const Home = () => {
    const userTypes = localStorage.getItem('Type')

    const loadUser = () => {
        switch (userTypes) {
            case '1':
                return 'Administrator'
            case '2':
                return 'Fleet management'
            case '4':
                return 'Human resources'
            case '5':
                return 'Customer'
            default:
                return 'Go logistics'
        }
    }
    const loadLink = () => {
        switch (userTypes) {
            case '1':
                return '/dashboard'
            case '2':
                return '/dashboard'
            case '4':
                return '/drivers'
            case '5':
                return '/customers/dashboard'
            case '6':
                return '/wh'
            case '7':
                return '/customer_service'
            default:
                return '/dashboard'
        }
    }

    const getComponent = () => {
        switch (userTypes) {
            case "5":
                return (
                    <DashboardCustomer />
                )
            case "7":
                return (
                    < Redirect to="/customer_service" />
                )
            case "6":
                return (
                    < Redirect to="/wh" />
                )
            default: return (
                <Grid item xs={11} md={6}>
                    <HomeSection portada={Portada1} title={loadUser()} link={loadLink()} />
                </Grid>
            )
        }
    }

    return (
        <Grid container spacing={1} >
            {getComponent()}
        </Grid>
    )
}
export default Home
