import React,  { useState, useEffect } from 'react'
import ListItem from './ListItem';
import Loaders from './Loaders';
// IMG
import user from './../../Assets/Img/user_example.png';
//Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import { Hidden } from '@material-ui/core';
// Crypt

const ItemList = ( {  list, page_count, activePage, isDriver, isCustomer, isVehicle, isAccident, isManager, filter  } ) => {

    const [ search, setSearch ] = useState('');

    const PaginationChange = (e, value) => {
        e.preventDefault();
        activePage( e, value );
    };

    if ( list ) {
        return(
            <Paper elevation={3} >
                <Box className="" p={5} >
                    <Grid container >
                        <Grid item xs={12}>
                            <form onSubmit={ e =>  filter( e, search ) } >
                                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={2}>
                                        <Typography variant="h6">
                                            Search
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                    {   ( isDriver || isManager || isCustomer )  &&
                                            <TextField id="Search" variant="outlined" size="small" placeholder="Write an ID or name..." fullWidth value={ search } onChange={ e => { setSearch( e.target.value ) } } />
                                    }
                                    {   ( isVehicle || isAccident) &&
                                            <TextField id="Search" variant="outlined" size="small" placeholder="Write a Fleet number or license plate..." fullWidth value={ search } onChange={ e => { setSearch( e.target.value ) } } />
                                    }                                 
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box py={1}>
                                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >Search</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <hr className="divider_grey" />
                            </form>
                        </Grid>
                        <Grid item xs={12}>
                            <Box py={3} >
                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                    <Grid item xs={12} className="box_contact-content" >
                                        {
                                            Object.entries(list).length === 0 &&
                                                <Loaders onlyLoad={ true } /> 
                                        }
                                        {
                                            isCustomer && list.map( i => 
                                                <ListItem 
                                                    key={i.rdga}
                                                    img={i.avatar} 
                                                    name={i.full_name} 
                                                    info={i.rdga } 
                                                    exp_date={ i.licence_exp }
                                                    link={`/customer/details/${i.rdga}`}
                                                    isCustomer={ true } 
                                                />
                                            ) 
                                        }
                                        {
                                            isDriver &&
                                                list.map( i => 
                                                    <ListItem 
                                                        key={i.rdga}
                                                        img={i.avatar} 
                                                        name={i.full_name} 
                                                        info={i.rdga } 
                                                        exp_date={ i.licence_exp }
                                                        link={`/drivers/details/${i.rdga}`}
                                                        isUser={ true } 
                                                    />
                                                ) 
                                        }
                                        {
                                            isManager &&
                                                list.map( i => 
                                                    <ListItem 
                                                        key={i.rdga}
                                                        img={i.avatar} 
                                                        name={i.full_name} 
                                                        info={i.rdga } 
                                                        link={`/manager/details/${i.rdga}`}
                                                        isManager={ true } 
                                                    />
                                                ) 
                                        }
                                        {
                                            isVehicle &&
                                                <Box px={3}>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                            <Grid item xs={6} md={1}>
                                                                <Box className="List_title" >
                                                                    Type
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1}>
                                                                <Box className="List_title" >
                                                                    Fleet Number 
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={2}>
                                                                <Box className="List_title" >
                                                                    VIN NUMBER
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={2}>
                                                                <Box className="List_title" >
                                                                    LICENSE PLATE
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1}>
                                                                <Box className="List_title" >
                                                                    BRAND
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={2}>
                                                                <Box className="List_title" >
                                                                    STATION
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1}>
                                                                <Box className="List_title" >
                                                                    VENDOR
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Hidden>
                                                    {
                                                        list && 
                                                            list.map( i => 
                                                                <ListItem 
                                                                    key={ i.licence_plate }
                                                                    isVehicle={ true }
                                                                    v_status={i.status}
                                                                    v_fleetNumber={i.fleet_number} 
                                                                    v_vin={i.details.Specific_details.vin_number} 
                                                                    v_licensePlate={i.licence_plate}
                                                                    v_brand={i.brand}
                                                                    v_station={i.station}
                                                                    v_vendor={i.vendor_logo}
                                                                    link={`/vehicles/details/${i.v_id}`}
                                                                />
                                                            ) 
                                                    }
                                                </Box>
                                        }
                                        {
                                            isAccident &&
                                                <Box px={3}>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                            <Grid item xs={6} md={1} >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        Type
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1} >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        DRAFT
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1} >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        DATE
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={2}  >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        INSURANCE<br/>CLAIM NUMBER
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1}  >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        ACCIDENT ID
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1} >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        FLEET<br/>NUMBER
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1} >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        LICENSE PLATE
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1}  >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        STATION
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6} md={1}  >
                                                                <Box className="List_title" textAlign="center" >
                                                                    <Typography variant="button" display="block" gutterBottom>
                                                                        DRIVER
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Hidden>
                                                    {
                                                        list.map( i => 
                                                            <ListItem 
                                                                key={ i.id }
                                                                isAccident={ true }
                                                                a_status={ i.v_status }
                                                                a_draft={ i.draft }
                                                                a_date={ i.details?.form?.A?.fields[0]?.value } 
                                                                a_claim_number={ i.details?.covered }
                                                                a_accident_id={ i.id } 
                                                                a_fleet_number={ i.details?.vehicle_data?.v_id }
                                                                a_license={ i.details?.form?.C?.fields[2]?.sub_form[1]?.value }
                                                                a_station={ i.station_name }
                                                                a_driver={ i.details?.driver_data?.rdga }
                                                                link={ `/accidents/details/${i.details?.driver_data?.rdga}/${i.id}` }
                                                            />
                                                        )
                                                    }
                                                </Box>
                                        }
                                        <Grid container direction="row" justify="center" alignItems="center" >
                                            <Pagination count={ page_count } variant="outlined" shape="rounded" onChange={ PaginationChange } />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        )
    }

    else{
        return(
            <Paper elevation={3} >
                <Box className="" p={5} >
                    <Typography variant="body1" gutterBottom className="Page_title">
                        No data to show
                    </Typography>
                </Box>
            </Paper>
        )
    }

}
export default ItemList
