import React, { useState } from 'react'
import { Box, Button, Grid, TextField, IconButton, InputAdornment, OutlinedInput, } from '@material-ui/core'
import { isEqual, ispass, isText } from './../../Components/Functions/validate';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import user from './../../Assets/Img/picture_placeholder.png';
import { UploadFile } from '../../Components/Functions/UploadFile';

const initialState = {
    rdga: false,
    name: false,
    pass1: false,
    pass2: false,
    avatar: false,
    avatar_c: false,
    avatarMsg: false,
    loading: false,
}

export const Step1 = ({ _avatar, _setAvatar, _rdga, _name, _password1, _password2,  handleChange, nextStep }) => {

    const [value, setValue] = useState({
        rdga: false,
        name: false,
        pass1: false,
        pass2: false,
        avatar: false,
        avatar_c: false,
        avatarMsg: false,
        loading: false,
    })
    const [avatar, setAvatar] = useState(false)
    const [showPass, setShowPass] = useState({
        show_1: true,
        show_2: true,
    });

    const handleUpadteAvatar = () => document.querySelector('#avatarFile').click();

    const uploadAvatar = async( e ) => {
        e.preventDefault();
        const file = e.target.files[0];
        if ( file && ( file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" ) ) {
            setValue({...value, loading: true })
            const res = await UploadFile( file )
            if ( res ) {
                _setAvatar( res )
                document.getElementById("avatarImg").src = res
            }
            else{
                setValue({avatarMsg: 'please try again in a few minutes.' })
            }
        }else{
            setValue({avatarMsg: 'Valid formats: png, jpg and jpeg' })
        }
        setTimeout(() => setValue({...value, loading: false }) , 500);
    }
    

    const handleSubmit = ( e ) => {
        e.preventDefault();

        // Check files         
        if ( !_rdga ) 
            return setValue({rdga: 'RDGA is requiered' })
        if ( !isText( _name ) ) 
            return setValue({name: 'Full name is requiered' })
        if ( !isEqual( _password1, _password2 ) ) 
            return setValue({pass2: 'Passwords have to be equal' })
        if ( !ispass( _password1 ) ) 
            return setValue({pass1: 'The password must have uppercase, lowercase, numbers and min length of 8.' })
        if ( !ispass( _password2 ) ) 
            return setValue({pass2: 'The password must have uppercase, lowercase, numbers and min length of 8.' })
        if ( avatar ) {
            _setAvatar( document.getElementById('avatarImg').src )
        }
        
        setValue( initialState )
        return nextStep()
    }

    return (       
        <form onSubmit={ e => handleSubmit( e ) }>
            <Grid container>
                <Grid container direction="row" justify="center" alignItems="center">
                    <label htmlFor="avatarFile" className="labelText" ><small>Select an avatar ( optional )</small></label>
                </Grid>
                <input type="file" id="avatarFile" name="_avatar" className="fileUpload" onChange={ e => { uploadAvatar( e ); setAvatar( true ); }} onClick={ handleUpadteAvatar } />
                <Grid container direction="row" justify="center" alignItems="center"  >
                    <img src={ user } alt="" className="profile-md rounded pointer" id='avatarImg' onClick={ handleUpadteAvatar } />
                </Grid>
                { value.avatar && <Grid container direction="row" justify="center" alignItems="center" style={{color: 'red'}}> { value.avatarMsg } </Grid> }
                <Grid item xs={12} md={4} >
                    <Box p={1}> 
                        <label htmlFor="rdga" className="labelText" ><small>RDGA</small></label>
                        <TextField id="rdga" name="_rdga" type="text" variant="outlined" fullWidth value={ _rdga } onChange={ handleChange } autoComplete="on" error={ !!value.rdga } helperText={ value.rdga } />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} >
                    <Box p={1}> 
                        <label htmlFor="name" className="labelText" ><small>FULL NAME</small></label>
                        <TextField id="name" name="_name" type="text" variant="outlined" fullWidth value={ _name } onChange={ handleChange } autoComplete="on" error={ !!value.name } helperText={ value.name } />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box p={1}> 
                        <label htmlFor="password" className="labelText" ><small>PASSWORD</small></label>
                        <OutlinedInput 
                            id="password1" 
                            name="_password1" 
                            type={ showPass.show_1 ? "password" : "text" } 
                            variant="outlined" 
                            fullWidth 
                            value={ _password1 } 
                            onChange={ handleChange } 
                            error={ !!value.pass1 } 
                            helperText={ value.pass1 }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={ () => setShowPass({...showPass, show_1: !showPass.show_1 }) }
                                        edge="end"
                                    >
                                        { showPass.show_1 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        { value.pass1 && <span style={{color: 'red'}}> { value.pass1 } </span> }
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box p={1}> 
                        <label htmlFor="password" className="labelText" ><small>CONFIRM PASSWORD</small></label>
                        <OutlinedInput 
                            id="password2" 
                            name="_password2" 
                            type={ showPass.show_2 ? "password" : "text" } 
                            variant="outlined" 
                            fullWidth 
                            value={ _password2 } 
                            onChange={ handleChange } 
                            error={ !!value.pass2 } 
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={ () => setShowPass({...showPass, show_2: !showPass.show_2 }) }
                                        edge="end"
                                    >
                                        { showPass.show_2 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        { value.pass2 && <span style={{color: 'red'}}> { value.pass2 } </span> }
                    </Box>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" >
                    <Box pl={1} mr={3} mt={2}> 
                        <Button 
                            variant="outlined" 
                            disableElevation 
                            disabled
                        >
                            back
                        </Button> 
                    </Box>
                    <Box pl={1} mr={3} mt={2}> 
                        <Button 
                            type={ value.loading ? "button" : "submit" } 
                            variant="outlined" 
                            className="Go_btn-primary"
                        > 
                            { value.loading ? "Loading..." : "Next" }
                        </Button> 
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}
