import React, { useContext } from 'react'

import { makeStyles, Box, Typography, Button, Grid, Divider, useTheme, TextareaAutosize } from "@material-ui/core"

import BaseDialog from '../../Components/Global/Modals/BaseDialog'
import RegisterHistory from './RegisterHistory'
import DialogMessageForm from '../../Components/Global/Modals/DialogMessageForm'
import { postComment, updateRegister } from '../../middleware/warehouse/Warehouse'
import { AuthContext } from '../../Components/Functions/Auth';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    banner: {
        height: "auto",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    bold: {
        fontWeight: "bold",
        color: theme.palette.grey[600]
    },
    actions: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    danger: {
        backgroundColor: theme.palette.error.main,
        color: "#fff"
    }
}))


const WarehouseModal = ({ open, onClose, data, onDataChange }) => {

    const { scan } = useParams()//in out returns

    const history = useHistory()
    const { url } = useRouteMatch()

    const { user } = useContext(AuthContext)
    const context = useContext(AuthContext)

    const classes = useStyles()

    const theme = useTheme()

    const [cancelAlert, setCancelAlert] = React.useState(false)
    const [cancelReason, setCancelReason] = React.useState(false)
    const handleCancelOrder = async () => {
        const _data = {
            register_id: data.warehouse_id,
            status: "canceled",
            username: user.name,
            user_id: user.u_id,
            reason: cancelReason
        }
        const result = await updateRegister(_data)
        if (result.status) {
            if (onDataChange) onDataChange()
            onClose()
            setCancelAlert(false)
        }
    }

    const handleChangeOrder = async (status) => {
        const _data = {
            register_id: data.warehouse_id,
            status: status,
            username: user.name,
            user_id: user.u_id,
            reason: "_"
        }
        const result = await updateRegister(_data)
        if (result.status) {
            if (onDataChange) onDataChange()
            setReloadHistory(reloadHistory + 1)
            return true
        } else {
            alert("Some was wrong")
        }
        return false
    }
    const getStatus = () => {
        let status = "delivered"
        if (scan) {
            switch (scan) {
                case "in":
                    status = "received"
                    break;
                case "out":
                    status = "on_route"
                    break;
                case "returns":
                    status = "returned"
                    break;
                default:
                    status = "delivered"
                    break;
            }
        } else {
            status = "received"
        }
        return status
    }
    /* Action when users search and result found */
    React.useEffect(() => {
        let status = getStatus()
        if (data.found && scan) {
            handleChangeOrder(status)
        }
    }, [data.found])

    const [reloadHistory, setReloadHistory] = React.useState(0)
    const [comment, setComment] = React.useState("")
    const handlePostComment = async () => {

        if (comment !== "") {
            const result = await postComment({
                register_id: data.warehouse_id,
                comments: comment,
                username: user.name
            })
            if (result) {
                setComment("")
                setReloadHistory(reloadHistory + 1)
            }
        }
    }

    return (
        <BaseDialog open={open} onClose={onClose} maxWidth="sm" fullWidth >

            <DialogMessageForm open={cancelAlert} title="Cancel Order" message="Are you sure to cancel this order?" type="error"
                onClose={() => setCancelAlert(false)}
                positiveText="Continue"
                positiveAction={handleCancelOrder}
                negativeText="Back"
                negativeAction={() => setCancelAlert(false)}
                formView={
                    <React.Fragment>
                        <Typography>Reason</Typography>
                        <TextareaAutosize
                            onChange={({ target }) => setCancelReason(target.value)}
                            style={{ width: "100%", height: theme.spacing(10) }}
                            maxRows={15}
                        />
                    </React.Fragment>
                }
            />
            {
                !!data.found &&
                <Box textAlign="left" style={{ color: "#32a23f" }} display="flex" alignItems="center" flexDirection="row" p={1} pt={2} pb={2} >
                    <CheckCircleIcon color="inherit" />
                    <Typography color="primary" variant="h6" >Product found</Typography>
                </Box>
            }
            <Box className={classes.banner} textAlign="center" p={3} >
                <Typography color="inherit" align="center" variant="h3" >{data?.driverkey}</Typography>
                <Typography color="inherit" align="center" variant="h5" >Route Number</Typography>
                <Typography color="inherit" align="center" variant="h4" >{data?.stopnumber}</Typography>
                <Typography color="inherit" align="center" variant="h6" >Stop Number</Typography>
            </Box>
            <Grid container justifyContent="center" alignItems="center" justify="center" >
                <Grid item xs={11} sm={8} >
                    <Box display="flex" flexDirection="row" justifyContent="space-between" mt={3} >
                        <Typography className={classes.bold} align="center" variant="h6" >Location</Typography>
                        <Typography align="center" variant="h6" >{data?.location_name || "-"}</Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="row" justifyContent="space-between" >
                        <Typography className={classes.bold} align="center" variant="h6" >Order number</Typography>
                        <Typography align="center" variant="h6" >{data?.shipment_no}</Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="row" justifyContent="space-between" >
                        <Typography className={classes.bold} align="center" variant="h6" >Client</Typography>
                        <Typography align="center" variant="body1" >{`${data.first_name} ${data.last_name || ""}`}</Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="row" justifyContent="space-between" >
                        <Typography className={classes.bold} align="center" variant="h6" >Product</Typography>
                        <Typography align="center" variant="h6" >{data.description}</Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="row" justifyContent="space-between" >
                        <Typography className={classes.bold} align="center" variant="h6" >Serial number</Typography>
                        <Typography align="center" variant="h6" >{data?.serial_number || "-"}</Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="row" justifyContent="space-between" >
                        <Typography className={classes.bold} align="center" variant="h6" >Aging</Typography>
                        <Typography align="center" variant="h6" >{data.aging}{data.aging == 10 ? " Business days" : " Days"}</Typography>
                    </Box>
                    <Divider />
                    {!data.found &&
                        <React.Fragment>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" >
                                <Typography className={classes.bold} align="center" variant="h6" >History</Typography>
                                <Typography align="center" variant="h6" ></Typography>
                            </Box>
                            <Divider />
                        </React.Fragment>
                    }
                </Grid>
            </Grid>
            {
                !data.found &&
                <Grid container justifyContent="center" alignItems="center" justify="center">
                    <Grid item xs={11} sm={8}>
                        <RegisterHistory id={data.warehouse_id} reload={reloadHistory} />
                    </Grid>
                </Grid>
            }
            <Grid container justifyContent="center" alignItems="center" justify="center">
                <Grid item xs={11} sm={8} style={{ backgroundColor: theme.palette.grey[200] }} >
                    <Box p={1} >
                        <Typography>Comments</Typography>
                        <TextareaAutosize
                            value={comment}
                            onChange={({ target }) => setComment(target.value)}
                            style={{ width: "100%", height: theme.spacing(10) }}
                            maxRows={15}
                        />
                        <Button onClick={handlePostComment} color="secondary" variant="contained" >Add comment</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid spacing={3} className={classes.actions} container justify="center" >
                <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }} >
                    <Button onClick={() => setCancelAlert(true)} className={classes.danger} variant="contained" >Cancel</Button>
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }} >
                    {/*//TODO change status received on print label*/}
                    {
                        scan === "in" &&
                        <Button color="secondary" variant="contained" onClick={
                            async () => {
                                const res = await handleChangeOrder(getStatus())
                                if (res) {
                                    history.push(`/${context.type == "6" ? "wh" : "customer_service"}/print/${data?.shipment_no}/?order_number=${data?.shipment_no}&route=${data?.driverkey}`)
                                } else {
                                    alert("An error occurred")
                                }

                            }
                        } >Print label</Button>
                    }

                    {
                        scan === "out" &&
                        <Button onClick={() => handleChangeOrder("on_route")} color="secondary" variant="contained"
                        >Sent out</Button>
                    }
                    {
                        scan === "returns" &&
                        <Button onClick={() => handleChangeOrder("returned")} color="secondary" variant="contained"
                        >Returns</Button>
                    }
                    {
                        !scan &&
                        <Button color="secondary" variant="contained" onClick={
                            async () => {
                                const res = await handleChangeOrder(getStatus())
                                if (res) {
                                    history.push(`/${context.type == "6" ? "wh" : "customer_service"}/print/${data?.shipment_no}/?order_number=${data?.shipment_no}&route=${data?.driverkey}`)
                                } else {
                                    alert("An error occurred")
                                }

                            }
                        } >Print label</Button>
                    }
                </Grid>
                {
                    !scan &&
                    <Grid item xs="12" style={{ display: "flex", justifyContent: "center" }} >
                        
                        <Button color="primary" variant="contained" onClick={
                            async () => {
                                const res = await handleChangeOrder(getStatus())
                                if (res) {
                                    if (onDataChange) {
                                        onDataChange()
                                    }
                                } else {
                                    alert("An error occurred")
                                }

                            }
                        } >Update as received</Button>
                    </Grid>
                }
            </Grid>




        </BaseDialog>
    )
}

WarehouseModal.defaultProps = {
    open: false
};

export default WarehouseModal
