import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types'

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };
const placesDetailsService = { current: null };
const geocodeService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));


PlacesAutocomplete.propTypes = {
    children: PropTypes.node.isRequired,
    handleSelected: PropTypes.func.isRequired
}

export default function PlacesAutocomplete({ children, handleSelected, inValue}) {
    const classes = useStyles();

    //current place object value
    const [value, setValue] = React.useState(null);
    
    //current text value
    const [inputValue, setInputValue] = React.useState('');

    // places result options
    const [options, setOptions] = React.useState([]);

    //handle script load
    const loaded = React.useRef(false);

    const [placeDetails, setplaceDetails] = React.useState({})

    //Necessary for construct map element required by places derail service
    const map_ref = React.useRef()

    //load google api script
    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyAiQNRR9-qw9w12FSiSmiThryBrBWFZ5Qg&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    //Better mem performance with use memo, its like useCallback
    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    const geo_fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                geocodeService.current.geocode(request, callback);
            }, 200),
        [],
    );

    // Limitation of request, save server charge for google api
    const place_details = React.useMemo(
        () =>
            throttle((request, callback) => {
                //google places api method https://developers.google.com/maps/documentation/javascript/reference/places-service
                placesDetailsService.current.getDetails(request, callback);
            }, 200),
        [],
    );

    // Place details gets all data of place (postal code etc)
    React.useEffect(() => {
        let active = true;

        if (!placesDetailsService.current && window.google) {
            const map = new window.google.maps.Map(map_ref.current);
            placesDetailsService.current = new window.google.maps.places.PlacesService(map);
        }

        if (!placesDetailsService.current) {
            return undefined;
        }

        if (!value) {
            setplaceDetails({})
            return undefined;
        }

        let place_id = value?.place_id;

        place_details({ placeId: place_id }, (results, status) => {
            if (active) {
                let new_details = {}
                if (status === "OK") {
                
                    const address_components = results.address_components;

                    address_components.map(comp => {
                        const { long_name, short_name, types } = comp;
                        new_details = {
                            ...new_details,
                            [types[0]]: { long_name: long_name, short_name: short_name }
                        }
                    })   
                }
                setplaceDetails(new_details)
            }
        })
        return () => {
            active = false;
        }
    }, [value, place_details])

    //Autocomplete service for get place options array
    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue, }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    React.useEffect(() => {
        
        if(Object.keys(placeDetails).length !== 0){
            handleSelected(value, placeDetails)
        }

        return () => {
            
        }
    }, [placeDetails])

    //Geocode Service
    React.useEffect(() => {

        if (!geocodeService.current && window.google) {
            geocodeService.current = new window.google.maps.Geocoder();
        }

        if (!geocodeService.current) {
            return undefined;
        }

        if (!value) {
            return undefined;
        }

        let place_id = value?.place_id;

        geo_fetch({ placeId: place_id }, results => {
            console.log(results)
        })

        return () => {

        }
    }, [value, geo_fetch])

    


    const handleOnChange = (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
    }


    return (
        <React.Fragment>
            <Autocomplete
                id="google-map-demo"
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                inputValue={inputValue === '' ? inValue : inputValue}
                onChange={handleOnChange}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) =>
                    React.cloneElement(children, { ...params })
                }
                renderOption={(option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }}
            />
            <div ref={map_ref} ></div>
        </React.Fragment>

    );
}