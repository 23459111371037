import React,  { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
// Componentes
import ModalShow from '../Components/Global/Modal';
// Custom Hooks
import useStation from './../Hooks/useStation'
// IMG
import img_add from './../Assets/Img/Icon_add.png'
import file_placeholder from './../Assets/Img/file_placeholder.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//API
import * as firebase from 'firebase';
import FormData from 'form-data';
import { createVehicle, getStationModelVendor, APIGO } from './../constants/API';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

const VehiclesDetails = () => {
    
    const history = useHistory();
    let form = new FormData();
    const [ msg, setMsg] = useState('');
    const [ loading, setLoading ] = useState(false);

    const [ v_Licens, setV_Licens] = useState('');
    const [ fleet_number, setFleet_number] = useState('');
    const [ geoTabNumber, setGeoTabNumber ] = useState('');
    const [ stationID, setStationID] = useState('');
    const [ VIN, setVIN ] = useState('');
    const [ ownership, setOwnership] = useState('');
    const [ previwOwnershipDocd, setpreviwOwnershipDocd] = useState('');
    const [ insurance, setInsurance] = useState('');
    const [ previwInsuranceDocd, setPreviwInsuranceDocd] = useState('');
    
    const [ Year, setYear ] = useState('');
    const [ Make, setMake ] = useState('');
    const [ Reg_Province, setReg_Province ] = useState('');
    const [ Reg_Exp_Date, setReg_Exp_Date] = useState('');
    const [ Delivery_Date, setDelivery_Date] = useState('');
    const [ Model, setModel] = useState('');
    const [ Series, setSeries ] = useState('');
    
    const [ ownershipRadio, setOwnershipRadio ] = useState('');
    const [ vendor, setVendor ] = useState('');
    const [ Lease_Type, setLease_Type ] = useState('');
    const [ Lease_Term, setLease_Term] = useState('');
    
    const [ Garage_Address, setGarage_Address ] = useState('');
    const [ Garage_City, setGarage_City ] = useState('');
    const [ Garage_Province, setGarage_Province ] = useState('');
    const [ Garage_Postal_Code, setGarage_Postal_Code] = useState('');
    const [ Pooled_Vehicle, setPooled_Vehicle ] = useState('');
    const [ isError, setIsErrors] = useState([{
        iserror: false, 
        v_ID: false,
        v_Licens: false,
        geo_tab_number: false,
        stationID: false,
        VIN: false,
        ownership: false,
        insurance: false,
        
        Year: false,
        Make: false,
        Registration_Province: false,
        Registration_Exp_Date: false,
        Delivery_Date: false,
        model: false,
        Series: false,
        ownership_type: false,
        Cust_Num: false,
        Lease_Type: false,
        Lease_Term: false,
        vendor: false,
        
        Garage_Street_Address: false,
        Garage_City: false,
        Garage_Province: false,
        Garage_Postal_Code: false,
        Pooled_Vehicle: false,
        Complete: false
    }]);
    const [ stationsData, setStationsData ] = useState([]);
    const [ modelData, setModelData ] = useState([]);
    const [ vendorData, setVendorData ] = useState([]);
    // Modal
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");
    
    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 
    const saveElement = ( e, status ) => {
        e.preventDefault();


        if ( status === true ){
            // Save element 
            handlePost();
        }
        if ( status === false ){
            ModalClose()
        }
    }
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const handlePost = e => { 
        let status = 1;
        const storageRef = firebase.storage().ref();
        switch (ownershipRadio) {
            case 'owned':
                status = 1;
                break;
            case 'rented':
                status = 2;
            break;
            case 'leased':
                status = 3;
                break;
            case 'return':
                status = 4;
                break;
            default:
                break;
        }
        const ownershipimg = ownership ? document.getElementById('ownershipimg').value : "https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25";
        const insuranceimg = insurance ? document.getElementById('insuranceimg').value : "https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25";
        const config = {
            method: 'post',
            url: createVehicle,
            data:{
                vehicle_id: fleet_number,
                fleetnumber: fleet_number,
                licence_plate: v_Licens,
                geo_tab_number: geoTabNumber,
                station_id: stationID,
                vin_number: VIN,
                ownership_doc: ownershipimg,
                insurance_doc: insuranceimg,
                year: Year,
                brand: Make,
                registration_province: Reg_Province,
                registration_exp_date: Reg_Exp_Date,
                delivery_date: Delivery_Date,
                model: Model,
                series: Series,
                ownership: ownershipRadio,
                vendor: vendor,
                lease_type: Lease_Type,
                lease_term: Lease_Term,
                garage_street_address: Garage_Address,
                garage_city: Garage_City,
                garage_province: Garage_Province,
                garage_postal_code: Garage_Postal_Code,
                pooled_vehicle: Pooled_Vehicle,
                status: status,  
                insurance: '.',
            },
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`,
            },
        }
        console.log(config.data)
       
        APIGO(config)
        .then(response =>{
            if(response.data.status === "success"){
                cleanAll();
                ModalClose();
                setIsConfirm( false );
                setModalmsg( 'Vehicle was created successfully.' );
                setModalLink('/vehicles')
                setIsShow( true );
                ModalOpen();
            }
            if(response.data.status === "error"){
                ModalClose();
                setModalmsg( response.data.msg);
                // setModalLink('/vehicles')
                setIsShow( true );
                setIsConfirm( false );
                ModalOpen();
            }
        })
        .catch(function (error){
            if(error.response){
                console.log(error.response)
            }else{
                console.log(error)
            }
            setModalmsg( "Oops! a problem happen, please try again." );
            setIsConfirm( false );
            setIsShow( true );
            ModalOpen()
        })    
    }
    const cleanAll = () => {
        setV_Licens('')
        setV_Licens('')
        setVIN('')
        setYear('')
        setMake('')
        setModel('')
        setSeries('')
        setReg_Province('')
        setReg_Exp_Date('')
        setDelivery_Date('')
        setLease_Type('')
        setLease_Term('')
        setGarage_Address('')
        setGarage_City('')
        setGarage_Province('')
        setGarage_Postal_Code('')
        setPooled_Vehicle('')
    }
    const handleForm = e => { 
        e.preventDefault();
        // if(ownership === ""){
        //     const ownershipImg =  document.getElementById("ownershipimg")
        //     ownershipImg.src = "https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25";
        // }
        // if(insurance === ""){
        //     const insuranceImg =  document.getElementById("insuranceimg")
        //     insuranceImg.src = "https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25";
        // }

        if(Lease_Type === ""){
            setLease_Type('  ')
        }
        if(Lease_Term === ""){
            setLease_Term('  ')
        }
        if(Garage_Address === ""){
            setGarage_Address('  ')
        }
        if(Garage_City === ""){
            setGarage_City('  ')
        }
        if(Garage_Province === ""){
            setGarage_Province('  ')
        }
        if(Garage_Postal_Code === ""){
            setGarage_Postal_Code('  ')
        }
        if(Pooled_Vehicle === ""){
            setPooled_Vehicle('  ')
        }
        

        if(fleet_number === ""){
            setIsErrors({ v_ID: "Enter a VehicleID please", iserror: true });
        }else if(v_Licens === ""){
            setIsErrors({ v_Licens: "Enter a VehicleLicens please", iserror: true });
        }else if(geoTabNumber === ""){
            setMsg("Enter a Geo Tab number please")
            setIsErrors({ geo_tab_number: true, iserror: true });
        }else if(stationID === ""){
            setIsErrors({ stationID: "Enter a Station id please", iserror: true });
        }else if(VIN === ""){
            setIsErrors({ VIN: "Enter some value please", iserror: true });
        // }else if(ownership === ""){
        //     const ownershipImg =  document.getElementById("ownershipimg")
        //     ownershipImg.src = "https://prd-wret.s3.us-west-2.amazonaws.com/assets/palladium/production/s3fs-public/styles/full_width/public/thumbnails/image/file.jpg";
        //     setIsErrors({ ownership: true, iserror: true });
        // }else if(insurance === ""){
        //     const insuranceImg =  document.getElementById("insuranceimg")
        //     insuranceImg.src = "https://prd-wret.s3.us-west-2.amazonaws.com/assets/palladium/production/s3fs-public/styles/full_width/public/thumbnails/image/file.jpg";
        //     setIsErrors({ insurance: true, iserror: true });
        }else if(Year === ""){
            setIsErrors({ Year: "Enter some value please.", iserror: true });
        }else if(Make === ""){
            setIsErrors({ Make: "Enter some value please.", iserror: true });
        }else if(Reg_Province === ""){
            setIsErrors({ Registration_Province: "Enter some value please.", iserror: true });
        }else if(Reg_Exp_Date === ""){
            setIsErrors({ Registration_Exp_Date: "Enter some value please.", iserror: true });
        }/* else if( moment( Reg_Exp_Date ).isValid() ){
            setIsErrors({ Registration_Exp_Date: "Enter a valid date.", iserror: true });
        } */else if(Delivery_Date === ""){
            setIsErrors({ Delivery_Date: "Enter some value please.", iserror: true });
        }/* else if( moment( Delivery_Date ).isValid()){
            setIsErrors({ Delivery_Date: "Enter a valid date.", iserror: true });
        } */else if(Model === ""){
            setIsErrors({ model: "Enter some value please.", iserror: true });
        }else if(Series === ""){
            setIsErrors({ Series: "Enter some value please.", iserror: true });
        }else if(ownershipRadio === ""){
            setIsErrors({ ownership_type: "Enter some value please.", iserror: true });
        }else if(vendor === ""){
            setIsErrors({ vendor: "Enter some value please.", iserror: true });
        // }else if(Lease_Type === ""){
        //     setMsg("Enter some value please")
        //     setIsErrors({ Lease_Type: true, iserror: true });
        // }else if(Lease_Term === ""){
        //     setMsg("Enter some value please")
        //     setIsErrors({ Lease_Term: true, iserror: true });
        // }else if(Garage_Address === ""){
        //     setMsg("Enter some value please")
        //     setIsErrors({ Garage_Street_Address: true, iserror: true });
        // }else if(Garage_City === ""){
        //     setMsg("Enter some value please")
        //     setIsErrors({ Garage_City: true, iserror: true });
        // }else if(Garage_Province === ""){
        //     setMsg("Enter some value please")
        //     setIsErrors({ Garage_Province: true, iserror: true });
        // }else if(Garage_Postal_Code === ""){
        //     setMsg("Enter some value please")
        //     setIsErrors({ Garage_Postal_Code: true, iserror: true });
        // }else if(Pooled_Vehicle === ""){
        //     setMsg("Enter some value please")
        //     setIsErrors({ Pooled_Vehicle: true, iserror: true });
        }else {
            setMsg('')
            setIsErrors({ iserror: false });
            setIsShow( false )
            setIsConfirm( true );
            ModalOpen()
        }
    }
    const previwOwnership = async ( e, img ) =>{
        try {  
            e.preventDefault();
            setLoading( true )
            setpreviwOwnershipDocd( e.target.files[0].name )
            var storage = firebase.storage().ref();
            var upload = storage.child(`vehicles/ownership/Img${Date.now()}`);
            // let docUloaded  = await upload.put(img)
            // console.log( docUloaded );
            // let docUrl      = await upload.getDownloadURL()
            // console.log( docUrl );

            upload.put(img).then(snapshot=>{
                upload.getDownloadURL().then(url=>{
                    const profileImgOwner =  document.getElementById("ownershipimg")
                    profileImgOwner.value = url;
                    setTimeout(() => setLoading( false ) , 500);
                })
            })
        }
        catch (error) { console.log( error ); }    
    }
    const previwinsurance = ( e, img ) =>{
        try { 
            e.preventDefault();
            setLoading( true )
            setPreviwInsuranceDocd( e.target.files[0].name )
            var storage = firebase.storage().ref();
            var upload = storage.child(`drivers/insurance/Img${Date.now()}`);
            upload.put(img).then(snapshot=>{
                upload.getDownloadURL().then(url=>{
                    const profileImgInsurance =  document.getElementById("insuranceimg")
                    profileImgInsurance.value = url;
                    setTimeout(() => setLoading( false ) , 500);
                })
            })
        } 
        catch (error) { console.log( error ); }
    }
    useEffect( () =>{
        var configVendor = {
            method: 'get',
            url: getStationModelVendor,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configVendor)
        .then(response =>{
            setStationsData( response.data.station )
            setModelData( response.data.models )
            setVendorData( response.data.vendor )
        })
        .catch(function (error){
            console.log(error)
        })

    }, [] );

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isConfirm ?
                            <ModalShow 
                            title="Are you sure you want to save a new vehicle? "
                            confirmOperation={ true }
                            Operation={ saveElement }
                            /> : null
                    }    
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            <form onSubmit={e=> handleForm(e)} >
                <Grid container spacing={2} >
                    <Grid item xs={4} md={2} className="center_center">
                        <Link to="/vehicles" className="flex No_Decoration">
                            <ArrowBackIosIcon className="Page_title"/>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Go back
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={8} md={6}>
                        <Typography variant="h4" gutterBottom className="Page_title">
                            New Vehicle
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Link to="/vehicles" className="No_Decoration" >
                            <Button variant="outlined" disableElevation fullWidth className="" >CANCEL</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button 
                            fullWidth 
                            disableElevation 
                            variant="contained"
                            id={'SubmitFormVeh'} 
                            className="Go_btn-primary" 
                            type={ loading ? "button" : "submit" } 
                        >
                            { loading ? "LOADING..." : "SAVE" }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={5} className="box_content" borderRadius={5} boxShadow={3} >
                            <Grid container> 
                                { isError.iserror && 
                                    <Grid item xs={12}>
                                        <Box p={2}> 
                                            <Alert severity="error"> Please complete all the fields required ( * ). </Alert> 
                                        </Box>
                                    </Grid>
                                }       
                                <Grid item xs={12}>
                                    <Box p={2}> 
                                        <Typography variant="h6" gutterBottom className="Page_title">
                                            Specific details <br/>
                                            <label className="labelText"><small>(All fields marked (*) are required)</small></label>
                                            <hr className="divider_blue" />
                                        </Typography>        
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <Box p={2} pt={0}> 
                                        <label htmlFor="VehicleID" className="labelText"><small>FLEET NUMBER / VEHICLE ID *</small></label>
                                        <TextField id="VehicleID" type="text" variant="outlined" fullWidth value={ fleet_number } onChange={e => setFleet_number(e.target.value)} error={ !!isError.v_ID } helperText={ isError.v_ID }  /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <Box p={2} pt={0}> 
                                        <label htmlFor="VehicleLicens" className="labelText"><small>LICENSE PLATE *</small></label>
                                        <TextField id="VehicleLicens" type="text" variant="outlined" fullWidth value={ v_Licens } onChange={e => setV_Licens(e.target.value)} error={ !!isError.v_Licens } helperText={ isError.v_Licens } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <Box p={2} pt={0}> 
                                        <label htmlFor="Master_Cust_Num" className="labelText"><small>GEO TAB NUMBER *</small></label>
                                        <TextField id="Master_Cust_Num" type="text" variant="outlined" fullWidth value={ geoTabNumber } onChange={e => setGeoTabNumber(e.target.value)} error={ !!isError.geo_tab_number } helperText={ isError.geo_tab_number } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <Box p={2} pt={0}> 
                                            <label htmlFor="stationID" className="labelText"><small>STATION ID *</small></label>
                                            <Select labelId="stationID" variant="outlined" value={stationID} onChange={e => setStationID(e.target.value)} displayEmpty className={ isError.stationID && 'file-error' } fullWidth >
                                                <MenuItem value=""><em>Select</em></MenuItem>
                                                {
                                                    stationsData.map( item => 
                                                        <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                                    )
                                                }
                                            </Select>
                                            { !!isError.stationID && <span className="text_error"><small> Enter a station ID please </small></span> }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box p={2} pt={0}>  
                                        <label htmlFor="VIN" className="labelText"><small>VIN NUMBER * </small></label>
                                        <TextField id="VIN" type="text" variant="outlined" fullWidth value={ VIN } onChange={e => setVIN(e.target.value)} error={ !!isError.VIN } helperText={isError.VIN} /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box p={2} pt={0}>  
                                        <input type="file" id="uploadFileOwnership" className="fileUpload" onChange={ e => { setOwnership(e.target.files[0]); previwOwnership(e, e.target.files[0]) } }  />
                                        <label htmlFor="uploadFileOwnership" className="text_primary pointer">
                                            <span htmlFor="VIN" className="labelText"><small>OWNERSHIP</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                {
                                                    ownership && 
                                                    <>
                                                        <img src="https://img.icons8.com/ios/452/file-preview.png" value="" alt="" className="previw_img " id={'ownershipimg'} />
                                                        {/* <img src={ file_placeholder } alt="" className="previw_img"  /> */}
                                                        <span>{ previwOwnershipDocd }</span>
                                                    </>
                                                }
                                                <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                            </Grid>
                                            {
                                                isError.ownership ?
                                                    <span className="text_error"><small> Enter a ownership please </small></span> : null
                                            }
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box p={2} pt={0}>  
                                        <input type="file" id="uploadFileInsurance" className="fileUpload" name="fileUpload" onChange={ e => { setInsurance(e.target.files[0]); previwinsurance(e, e.target.files[0]) } }  />
                                        <label htmlFor="uploadFileInsurance" className="text_primary pointer">
                                            <span htmlFor="VIN" className="labelText"><small>INSURANCE</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                {
                                                    insurance && 
                                                    <>
                                                        <img src="https://img.icons8.com/ios/452/file-preview.png" value="" alt="" className="previw_img" id={'insuranceimg'} />
                                                        {/* <img src={ file_placeholder } alt="" className="previw_img"  /> */}
                                                        <span>{ previwInsuranceDocd }</span>
                                                    </>
                                                }
                                                <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                            </Grid>
                                            {
                                                isError.insurance ?
                                                    <span className="text_error"><small> Enter a insurance please </small></span> : null
                                            }
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box p={2}  pt={0}> 
                                        <Typography variant="h6" gutterBottom className="Page_title">
                                            Particular details
                                            <hr className="divider_blue" />
                                        </Typography>        
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Year" className="labelText"><small>YEAR *</small></label>
                                        <TextField id="Year" type="number" variant="outlined" fullWidth value={ Year } onChange={e => setYear(e.target.value)} error={ !!isError.Year } helperyear={ isError.Year } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Make" className="labelText"><small>BRAND *</small></label>
                                        <TextField id="Make" type="text" variant="outlined" fullWidth value={ Make } onChange={e => setMake(e.target.value)} error={ !!isError.Make } helperText={ isError.Make } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Reg_Province" className="labelText"><small>REGISTRATION PROVINCE *</small></label>
                                        <TextField id="Reg_Province" type="text" variant="outlined" fullWidth value={ Reg_Province } onChange={e => setReg_Province(e.target.value)} error={ !!isError.Registration_Province } helperText={ isError.Registration_Province } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Reg_Exp_Date" className="labelText"><small>REGISTRATION EXP DATE *</small></label>
                                        <TextField id="Reg_Exp_Date" type="date" variant="outlined" fullWidth value={ Reg_Exp_Date } onChange={e => {
                                            console.log(e.target.value)
                                            setReg_Exp_Date(e.target.value)
                                        }} error={ !!isError.Registration_Exp_Date } helperText={ isError.Registration_Exp_Date } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Delivery_Date" className="labelText"><small>DELIVERY DATE *</small></label>
                                        <TextField id="Delivery_Date" type="date" variant="outlined" fullWidth value={ Delivery_Date } onChange={e => setDelivery_Date(e.target.value)} error={ !!isError.Delivery_Date } helperText={ isError.Delivery_Date } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Model" className="labelText"><small>MODEL *</small></label>
                                        <Select labelId="Model" variant="outlined" value={Model} onChange={e => setModel(e.target.value)} displayEmpty className={ isError.model && 'file-error' } fullWidth >
                                            <MenuItem value=""><em>Select</em></MenuItem>
                                            { modelData.map( item => ( <MenuItem key={ item.id } value={ item.id } >{ item.model }</MenuItem> )) }
                                        </Select>
                                        { !!isError.model && <span className="text_error"><small> Enter a model please </small></span> }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Series" className="labelText"><small>SERIES *</small></label>
                                        <TextField id="Series" type="text" variant="outlined" fullWidth value={ Series } onChange={e => setSeries(e.target.value)} error={ !!isError.Series } helperText={ isError.Series } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box p={2}  pt={0}> 
                                        <Typography variant="h6" gutterBottom className="Page_title">
                                            Type of ownership
                                            <hr className="divider_blue" />
                                        </Typography>        
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Fleet_V_Status" className="labelText"><small>OWNERSHIP *</small></label>
                                        <RadioGroup aria-label="gender" row value={ ownershipRadio } onChange={ e => setOwnershipRadio( e.target.value ) }>
                                            <FormControlLabel value="owned" control={<Radio color="primary" />} label="Owned" />
                                            <FormControlLabel value="rented" control={<Radio color="primary" />} label="Rented" />
                                            <FormControlLabel value="leased" control={<Radio color="primary" />} label="Leased" />
                                            <FormControlLabel value="return" control={<Radio color="primary" />} label="Return" />
                                        </RadioGroup>
                                        { !!isError.ownership_type && <span className="text_error"><small> Enter a value please </small></span> }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="vendor" className="labelText"><small>VENDOR *</small></label>
                                        <Select labelId="vendor" variant="outlined" value={vendor} onChange={e => setVendor(e.target.value)} displayEmpty className={ isError.vendor && 'file-error' } fullWidth >
                                            <MenuItem value=""><em>Select</em></MenuItem>
                                            { vendorData.map( item => (
                                                    <MenuItem key={ item.v_id }  value={ item.v_id } >{ item.v_name }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        { isError.vendor && <span className="text_error"><small> Enter a Vendor please </small></span> }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Lease_Type" className="labelText"><small>LEASE TYPE</small></label>
                                        <TextField id="Lease_Type" type="text" variant="outlined" fullWidth value={ Lease_Type } onChange={e => setLease_Type(e.target.value)} error={ !!isError.Lease_Type } helperText={ isError.Lease_Type } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Lease_Term" className="labelText"><small>LEASE TERM</small></label>
                                        <TextField id="Lease_Term" type="text" variant="outlined" fullWidth value={ Lease_Term } onChange={e => setLease_Term(e.target.value)} error={ !!isError.Lease_Term } helperText={ isError.Lease_Term } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box p={2}  pt={0}> 
                                        <Typography variant="h6" gutterBottom className="Page_title">
                                            Garage
                                            <hr className="divider_blue" />
                                        </Typography>        
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Garage_Address" className="labelText"><small>GARAGE STREET ADDRESS</small></label>
                                        <TextField id="Garage_Address" type="text" variant="outlined" fullWidth value={ Garage_Address } onChange={e => setGarage_Address(e.target.value)} error={ !!isError.Garage_Street_Address } helperText={ isError.Garage_Street_Address } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Garage_City" className="labelText"><small>GARAGE CITY</small></label>
                                        <TextField id="Garage_City" type="text" variant="outlined" fullWidth value={ Garage_City } onChange={e => setGarage_City(e.target.value)} error={ !!isError.Garage_City } helperText={ isError.Garage_City } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Garage_Province" className="labelText"><small>GARAGE PROVIDENCE</small></label>
                                        <TextField id="Garage_Province" type="text" variant="outlined" fullWidth value={ Garage_Province } onChange={e => setGarage_Province(e.target.value)} error={ !!isError.Garage_Province } helperText={ isError.Garage_Province } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Garage_Postal_Code" className="labelText"><small>GARAGE POSTAL CODE</small></label>
                                        <TextField id="Garage_Postal_Code" type="text" variant="outlined" fullWidth value={ Garage_Postal_Code } onChange={e => setGarage_Postal_Code(e.target.value)} error={ !!isError.Garage_Postal_Code } helperText={ isError.Garage_Postal_Code } /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <Box p={2}  pt={0}> 
                                        <label htmlFor="Pooled_Vehicle" className="labelText"><small>POOLED VEHICLE</small></label>
                                        <TextField id="Pooled_Vehicle" type="text" variant="outlined" fullWidth value={ Pooled_Vehicle } onChange={e => setPooled_Vehicle(e.target.value)} error={ !!isError.Pooled_Vehicle } helperText={ isError.Pooled_Vehicle } /> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
export default VehiclesDetails
    