import React, { useState } from 'react'
import moment from "moment";
import { Avatar, Box, Button, Dialog, Grid, Typography } from '@material-ui/core'
import _user from './../../Assets/Img/Profile_avatar.png';
import _file from './../../Assets/Img/not-available.png';
import { APIGO, postRegisterDriver } from '../../constants/API';
import { Alert } from '@material-ui/lab';
import ImageDialog from './ImageDialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const noUser = 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2FProfile_avatar.png?alt=media&token=0c02f76e-981b-42a2-9395-74e979f02520'
const noData = 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25'


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const DialogApprove = ({ driver, open, setOpen, refresh, setRefresh }) => {

    const handleClose = () => {
        setOpen(false);
        setMsg({ type: '', msg: '' })
    }
    const [msg, setMsg] = useState({
        type: '',
        msg: ''
    })

    const handleSave = async (status) => {
        let config = {
            method: 'PUT',
            url: `https://golosuite.com:8443/users/to-be-approved?id=${driver.id}&rdga=${driver.rdga}&status=${status}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
        }
        APIGO(config)
            .then(response => {
                if (response.data.status === "error") {
                    setMsg({ open: true, type: 'error', msg: 'A problem happened, please try again in a few minutes.' })
                }
                if (response.data.status === "success") {
                    if (status === 5) {
                        setMsg({ open: true, type: 'success', msg: 'Driver was rejected.' })
                    }
                    if (status === 4) {
                        saveApprove()
                        setMsg({ open: true, type: 'success', msg: 'Driver was acepted.' })
                    }
                }
                setRefresh(refresh + 1)
                setTimeout(() => handleClose(), 2500);
            })
            .catch(error => {
                setMsg({ open: true, type: 'error', msg: 'A problem happened, please try again in a few minutes.' })
            })
    }

    const saveApprove = async () => {

        const _avatar = (driver.avatar === "undefined" || driver.avatar === "") ? noUser : driver.avatar;
        const _licenceBack = (driver.licence_back === "undefined" || driver.licence_back === "") ? noData : driver.licence_back;
        const _licenceFront = (driver.licence_front === "undefined" || driver.licence_front === "") ? noData : driver.licence_front;
        const _deduction = (driver.deduction === "undefined" || driver.deduction === "") ? noData : driver.deduction;
        const _abstract = (driver.abstract === "undefined" || driver.abstract === "") ? noData : driver.abstract;
        const _licenceExp = (driver.licence_exp === "undefined" || driver.licence_exp === "") ? moment().format("YYYY-MM-DD") : moment(driver.licence_exp).format("YYYY-MM-DD");

        let config = {
            method: 'post',
            url: postRegisterDriver,
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
            data: {
                rdga: driver.rdga,
                user_name: driver.rdga,
                email: driver.email,
                licence_exp: _licenceExp,
                password: driver.password,
                phone: driver.phone,
                full_name: driver.full_name,
                address: driver.address,
                deduction: _deduction,
                licence_front: _licenceFront,
                licence_back: _licenceBack,
                avatar: _avatar,
                drivers_abstract: _abstract,
                station: driver.station
            }
        }
        APIGO(config)
            .then(response => {
                if (response.data.status === "error") {
                    console.log(response);
                }
                if (response.data.message) {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog" open={open} maxWidth="md" fullWidth >
            <DialogTitle id="customized-dialog" onClose={handleClose}>
                <Typography className="text-primary" variant="h5">
                    Driver information
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    {msg.open && <Grid item xs={12} className="approve"><Alert severity={msg.type}> {msg.msg} </Alert></Grid>}
                    <Grid item xs={12} className="approve" >
                        <Grid container direction="column" justify="center" alignItems="center" >
                            <Avatar alt="Remy Sharp"
                                className="avatar_x-large"
                                src={(driver?.avatar === "undefined" || driver?.avatar === "") ? _user : driver?.avatar} />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} className="approve" >
                        <span >{driver?.rdga} </span><br />
                        <span className="Page_title"><b> RDGA </b> </span>
                    </Grid>
                    <Grid item xs={3} className="approve" >
                        <span >{driver?.full_name}</span><br />
                        <span className="Page_title"><b> Full name </b></span>
                    </Grid>
                    <Grid item xs={3} className="approve" >
                        <span >{driver?.phone}</span><br />
                        <span className="Page_title"><b> Phone </b></span>
                    </Grid>
                    <Grid item xs={3} className="approve" >
                        <span >{driver?.email}</span><br />
                        <span className="Page_title"><b> Email </b></span>
                    </Grid>
                    <Grid item xs={3} className="approve" >
                        <span >{driver?.address}</span><br />
                        <span className="Page_title"><b> Address </b></span>
                    </Grid>
                    <Grid item xs={3} className="approve" >
                        <span >{driver?.station_id}</span><br />
                        <span className="Page_title"><b> Station </b></span>
                    </Grid>
                    <Grid item xs={3} className="approve" >
                        <span >{ moment(driver?.licence_exp).format("MMM Do YY") }</span><br />
                        <span className="Page_title"><b> License expiration </b></span>
                    </Grid>
                    <Grid item xs={6} className="approve" >
                        <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                            <ImageDialog
                                src={
                                    (driver?.licence_front === "undefined" ||
                                        driver?.licence_front === "")
                                        ? _file : driver?.licence_front}
                                alt=""
                                className="previw_img">
                            </ImageDialog>
                        </Grid>
                        <span className="Page_title"><b>License Front </b></span>
                    </Grid>
                    <Grid item xs={6} className="approve" >
                        <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                            <ImageDialog
                                src={(driver?.licence_back === "undefined" || driver?.licence_back === "") ? _file : driver?.licence_back}
                                alt=""
                                className="previw_img">
                            </ImageDialog>
                        </Grid>
                        <span className="Page_title"><b> License Back </b></span>
                    </Grid>
                    <Grid item xs={6} className="approve" >
                        <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >

                            <ImageDialog
                                src={(driver?.abstract === "undefined" || driver?.abstract === "") ? _file : driver?.abstract}
                                alt=""
                                className="previw_img">
                            </ImageDialog>
                        </Grid>
                        <span className="Page_title"><b> Driver abstract </b></span>
                    </Grid>
                    <Grid item xs={6} className="approve" >
                        <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                            <ImageDialog
                                src={(driver?.deduction === "undefined" || driver?.deduction === "") ? _file : driver?.deduction}
                                alt=""
                                className="previw_img">
                            </ImageDialog>
                        </Grid>
                        <span className="Page_title"><b> Driver form deduction </b></span>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" variant="outlined" disableElevation onClick={() => handleSave(5)}>
                    Reject
                </Button>
                <Button type="button" variant="contained" disableElevation className="Go_btn-primary" onClick={() => handleSave(4)}>
                    Approve
                </Button>
            </DialogActions>
        </Dialog>
    )
}
