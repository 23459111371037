import React, { useState, useEffect } from 'react'
// Componentes
import HistoryItem from '../Components/Global/HistoryItem';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import axios from 'axios'
//API
import { APIGO, getCicleCheck } from './../constants/API'; 
import { Button, TextField } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const History = () => {

    const [ data, setData ]         = useState( [] );
    const [ search, setSearch]      = useState('')
    const [ refresh, setRefresh]    = useState(0)
    const [pagination, setPagination] = useState({
        pages: '',
        count: '',
        activePage: 1,
    });
    const [ modalStaus, setModalStaus ] = useState( false );

    const getData = async() => {
        var config = {
            method: 'get',
            url: '/cicleCheck/getList/complete',
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then (response => {
            if (response.data.status === "error") {
                setData( false )
            }
            else{
                setPagination({
                    ...pagination,
                    'pages': Math.ceil( response.data.length / 10 ),
                    'count': response.data.length
                })
                console.log(response.data);
                setData( response.data.slice( ( pagination.activePage - 1 ) * 10, pagination.activePage * 10 ) );
            }
        })
        .catch(function (error){
            console.log(error)
            setData( false )
        })
    }

    useEffect( () =>{



        getHistory();
        // getData();

    }, [refresh] );
 


    const getHistory = async () =>{
        var config = {
            method: 'get',
            url: 'https://golosuite.com:8443/cicleCheck/getList/complete',
            headers: { 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`,
                'Cookie': '__cfduid=d621102b6dd004e2116bd345ee1a64e941604526391'
            }
        };
        try {
            const ress = await axios(config)
            console.log(ress);
            if (ress.data.status === "error") 
                setData( false )
            else{
                setPagination({
                    ...pagination,
                    'pages': Math.ceil( ress.data.length / 10 ),
                    'count': ress.data.length
                })
                setData( ress.data.slice( ( pagination.activePage - 1 ) * 10, pagination.activePage * 10 ) );
            }
        } catch (error) {
            console.log(error);
            setData( false )
        } 
    }
    
    const activePage = ( e, value ) =>{
        setPagination({
            ...pagination,
            'activePage': value
        })
        setRefresh( refresh + 1 )
    }

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const handleSearch = ( e ) => {
        e.preventDefault();
        if ( search ) {
            var config = {
                method: 'get',
                url: `cicleCheck/getList/complete?search=${search}`,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
            APIGO(config)
            .then (response => {
                if (response.data.status === "error") {
                    setData( false )
                }
                else{
                    setData( response.data );
                }
            })
            .catch(function (error){
                console.log(error)
                setData( false )
            })
        }
        else{
            setRefresh( refresh + 1 )
        }
    };


    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
            </Modal>

            <Grid container spacing={2} >
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" gutterBottom className="Page_title">
                        History  
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                        <Grid container>
                            <Grid item xs={12}>
                                <form onSubmit={ e =>  handleSearch( e ) } >
                                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                        <Grid item xs={12} md={2}>
                                            <Typography variant="h6">
                                                Search
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField id="Search" variant="outlined" size="small" placeholder="Write an circle check ID, vehicle ID, Driver ID, or name..." fullWidth value={ search } onChange={ e => { setSearch( e.target.value ) } } />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Box py={1} >
                                                <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >Search</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <hr className="divider_grey" />
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    data ? 
                                        data.map( item => (
                                            <HistoryItem
                                                key={item.check_id}
                                                id={item.driver_id}
                                                img={item.avatar}
                                                name={item.name}
                                                start_date={item.start_date}
                                                end_date={item.end_date}
                                                cicleID={item.check_id}
                                                link={`/history/details/${item.check_id}`}
                                                IsIssue={item.check_status}
                                            />
                                        ))
                                    :
                                    <Typography variant="h6" className="text_primary" >
                                        There is no Information &nbsp;
                                        <span
                                            className="try_again"
                                            onClick={ () => setRefresh( refresh + 1 ) } 
                                        >
                                            Try again.
                                        </span>
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <Pagination count={ pagination.pages } variant="outlined" shape="rounded" onChange={ activePage } />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
export default History
    