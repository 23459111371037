import React, { useEffect, useState } from 'react'
import { Box, InputAdornment, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import {  APIGO } from './../../../constants/API'; 

export const SearchUser = ({ setUsersList }) => {
    
    const [search, setSearch] = useState('')

    const handleSearch = ( e ) => {
        e.preventDefault();
        if ( search.length > 0 ) {
            var configDriver = {
                method: 'get',
                url: `/users/search?page=1&limit=10&type=3&search=${search}`,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
            APIGO(configDriver)
            .then(response =>{
                if ( response.data.status === "success" ) {
                    if ( response.data.info.count > 0 ) {
                        setUsersList(response.data.results)
                        console.log(response.data.results);
                    }
                    else{
                        setUsersList( false )
                    }
                }
                if ( response.data.status === "error" ) {
                    setUsersList(false)
                }
                setSearch('')
            })
            .catch(function (error){
                console.log(error)
            })
        }
    }

    return (
        <Box 
            style={{
                padding: '10px',
                background: '#f6f6f6',
                marginBottom: '10px',
                width: '100%',
            }}
        >
            <form onSubmit={ e => handleSearch(e) }>
                <TextField
                    placeholder="Search for a driver"
                    fullWidth
                    onChange={ e => setSearch( e.target.value ) }
                    value={ search }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </form>
        </Box>
    )
}
