import React, { useState, useEffect, useContext } from 'react'
// import { Link } from 'react-router-dom';
// IMG

//MaterialUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CircularProgress from '@material-ui/core/CircularProgress';
// Local
import { APIGO, getProfile } from '../../constants/API' 
import { sendMessage, getChat, ChatView, uploadFile } from './Functions/Driver'
import styles from './Functions/chat.module.css'


const UserChat = () => {
    
    const [ driverUser, setDriverUser ] = useState([])

    const [ message, setMessage ]   = useState('')
    const [ chatBody, setChatBody ] = useState([])
    const [ idChat, setIdChat ]     = useState(null)
    const [ loading, setLoading ]   = useState(false)

    const fetchDriver = async () => {
        let user;
        // let userType = 
        let config = {
            method: 'GET',
            url: getProfile,
            headers: {
                'Access-Control':'multipart/form-data',
                'Authorization' : `Bearer ${localStorage.Token}`
            }
        }
        await APIGO(config)
        .then((res) => {
            user = (res.data)
            setDriverUser(res.data)
        })
        .catch(err => console.log(err))
        return user
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        sendMessage(message, driverUser, idChat)
        setMessage("")
    }

    const handleUpload = () => {
        document.getElementById('upload_file').click()
    }

    const handleChangeUpload = (e) => {
        uploadFile(e.target.files[0], driverUser, idChat, setLoading)
        
    }
    let content;
    useEffect(() => {
        // if(localStorage.Type !== 1){
            fetchDriver().then(res => {
                getChat(res, setChatBody, setIdChat)
            })
        // }
    },[])


    return(
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom className="Page_title">
                            Chat x
                        </Typography>                
                </Grid>
                <Grid item xs={12}>
                    <Box className="box_content" p={3} boxShadow={3} >
                        <Grid container spacing={3} >
                            
                            <Grid item xs={12} md={12} className="bg-grey">
                                <Grid container className="bg_history" spacing={2}>
                                    <Grid item xs={12} >
                                        <Grid container direction="row" justify="center" alignItems="center" >
                                            <img src={ driverUser.avatar } alt="" className="historyImg" />
                                            <Box>
                                                <span className="History_title" >
                                                    { driverUser.full_name }
                                                </span>
                                                <br/>
                                                <span className="Text_blod-grey">
                                                    ID { driverUser.go_id }
                                                </span>        
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="chat_content" >
                                        <Grid item xs={12}>
                                            <ChatView chat={chatBody} user={driverUser}/>
                                        </Grid>
                                    </Grid>
                                    
                                    <form className="form_chat_user" onSubmit={e =>  handleSubmit(e)}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={7} className="" >
                                            <TextField 
                                                id="message" 
                                                type="text" 
                                                variant="outlined" 
                                                size="small" 
                                                placeholder="Type a message..." 
                                                value={message} fullWidth 
                                                onChange={e => setMessage(e.target.value)} 
                                            />
                                        </Grid>
                                            <Grid item xs={2} className="" >
                                            <Button variant="contained" disableElevation fullWidth disabled={ !driverUser } onClick={handleUpload} className={ styles.attach_btn }> 
                                            
                                                {loading?<CircularProgress className={ styles.attach_loader } />: <AttachFileIcon />} 
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3} className="" >
                                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" disabled = {!message} type="submit">send </Button>
                                        </Grid>  
                                        
                                    </Grid>
                                    </form>                  
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <div>
                <input type="file" hidden id="upload_file" onChange={e => handleChangeUpload(e)}/>
            </div>
        </>
    )
}
export default UserChat
