import React, { useEffect, useState } from 'react'
import { Box, Button, Grid,  MenuItem, Select, TextField } from '@material-ui/core'
import { isEmail, isText, isPhone, isNumber } from './../../Components/Functions/validate';
import { APIGO } from '../../constants/API';

const initialState = {
    phone: false,
    email: false,
    address: false,
    station: false,
}

export const Step2 = ({ _phone, _email, _address, _station, handleChange, nextStep, backStep }) => {

    const [value, setValue] = useState( initialState )
    const [ stationsData, setStationsData ] = useState([]);

    const handleSubmit = ( e ) => {
        e.preventDefault();
        // Check files 
        console.log( _phone.length > 8 );
        
        if ( !isPhone( _phone ) ) {
            return setValue({phone: 'Phone number is required with min length of 8 characters' })
        }
        if ( !isEmail( _email ) ) {
            return setValue({email: 'Email is requiered' })
        }
        if ( !_address ) {
            return setValue({address: 'Address is requiered' })
        }
        if ( !_address.length > 3 ) {
            return setValue({address: 'Address requiered min length of 5 characters' })
        }
        if ( !_station ) {
            return setValue({station: 'Station is requiered' })
        }

        setValue( initialState )
        return nextStep()
    }

    const getStation = async () => {
        let configDrop = {
            method: 'get',
            url: 'https://golosuite.com:8443/stations/',
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data'
            }
        }
        const res = await APIGO(configDrop)
        if ( res.data ) {
            setStationsData( res.data )
        } else {
            setStationsData( false )
        }
    }

    useEffect(() => { getStation() }, [])

    return (      
        <form onSubmit={ e => handleSubmit( e ) }> 
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box p={1}> 
                        <label htmlFor="phone" className="labelText" ><small>PHONE</small></label>
                        <TextField id="phone" name="_phone" type="text" variant="outlined" fullWidth value={ _phone } onChange={ handleChange } autoComplete="on" error={ !!value.phone } helperText={ value.phone }/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box p={1}> 
                        <label htmlFor="email" className="labelText" ><small>EMAIL</small></label>
                        <TextField id="email" name="_email" type="text" variant="outlined" fullWidth value={ _email } onChange={ handleChange } autoComplete="on" error={ !!value.email } helperText={ value.email }/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box p={1}> 
                        <label htmlFor="address" className="labelText" ><small>ADDRESS</small></label>
                        <TextField id="address" name="_address" type="text" variant="outlined" fullWidth value={ _address } onChange={ handleChange } autoComplete="on" error={ !!value.address } helperText={ value.address }/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Box p={1}> 
                        <label htmlFor="Station" className="labelText"><small>Station</small></label>
                        <Select labelId="Station" variant="outlined" name="_station" value={ _station } onChange={ handleChange } displayEmpty fullWidth error={ !!value.station } >
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { stationsData?.map( item => (
                                    <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                ))
                            }
                        </Select>
                        { !!value.station && <div style={{color: 'red'}}> { value.station } </div> }
                    </Box>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" >
                    <Box pl={1} mr={3} mt={2}> 
                        <Button 
                            variant="outlined" 
                            disableElevation 
                            onClick={ backStep }
                        >
                            back
                        </Button> 
                    </Box>
                    <Box pl={1} mr={3} mt={2}> 
                        <Button 
                            type="submit" 
                            variant="outlined" 
                            className="Go_btn-primary"
                        > 
                            Next
                        </Button> 
                    </Box>
                </Grid>
            </Grid>
        </form> 
    )
}
