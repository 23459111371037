import React, { useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid';


import InputFile from '../../../Components/Global/InputFile';

import empty_image from '../../../Assets/Img/empty_data.png'
import { APIGO } from '../../../constants/API';
import { saveOrder } from '../../../Components/Functions/LabelPdf/LabelFunctions';

const OrdersImport = () => {

    const [columns, set_columns] = useState([])
    const [rows, set_rows] = useState([])

    const handleJsonResult = ( json_array ) => {

        const map = json_array[0]
        let column_map = mapColumns(map)
        let row_map = mapRows(json_array)
        set_columns(column_map)
        set_rows(row_map)
    }

    const mapColumns = (json) =>{
        let column_map = []
        Object.entries(json).forEach(([key, val]) => {
            column_map.push({
                field: key,
                width: 150,
                type: "text"
            })
        })
        return column_map;
    }

    const mapRows = (json_array) => {
        let row_map = []
        json_array.map((json, index) => {
            json.id = index
            row_map.push(json)
        })
        return row_map;
    }

    const handleSubmit = () => { 
        
        Object.entries( rows ).forEach( async(row) => {
            let _formData = {
                pickUpId: row.pickUpId,
                deliveryId: row.deliveryId,
                pickUpDate: row.pickUpDate,
                readyTime: row.readyTime,
                service: row.service_id,
                package: row.package,
                packageNumber: row.packageNumber,
                packageWeight: row.packageWeight,
                comments: row.comments,
                insuranceValue: row.insuranceValue
            }
            const resp = await saveOrder( _formData )
            if ( resp.status ) {
                
            } else {
            
            }
        })
    }


    return (
        <>
            <Grid className="header__details" container direction="row" justify="center" alignItems="center">
                <Grid item><Typography align="center" >Upload your csv file to parse...</Typography></Grid>
                <Grid item>
                    <InputFile
                        accept=".csv"
                        label="Click to upload your csv"
                        handleJsonResult={handleJsonResult}
                    />
                </Grid>
            </Grid>
            <Grid container direction="column"   >
                <Grid item><Typography align="center" >Preview</Typography></Grid>
                <Grid item><DataGrid
                    columns={columns}
                    rows={rows}
                    dataSet="Commodity"
                    rowLength={4}
                    maxColumns={6}
                    autoHeight={true}
                    disableColumnMenu={true}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                /></Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" >
                <Grid item style={{ paddingTop: 20 }} >
                    <Button className="Go_btn-primary" variant="contained" onClick={handleSubmit} >SUBMIT</Button>
                </Grid>
            </Grid>
        </>
    )
}

function CustomNoRowsOverlay() {

    return (
        <GridOverlay>
            <Grid container justify="center" alignItems="center" direction="column">
                <Grid item xs={3}>
                    <img src={empty_image} alt="No data imported yet..." width={64} height={64} />
                </Grid>
                <Grid item xs={3}>
                <Typography>No data imported yet...</Typography>
                </Grid>
            </Grid>
        </GridOverlay>
    )

}

export default OrdersImport
