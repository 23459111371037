import React, { useState, useContext, createContext } from "react";
import { Link, useHistory } from "react-router-dom";
// Auth
import { AuthContext } from "./../Components/Functions/Auth";
// Ma6terial UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MLink from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
//API
import { LoginGo, CustomerRegister, APIGO } from "./../constants/API";
import { postLogin } from "../middleware/warehouse/Warehouse";

const Login = () => {
  const [isError, setIsErrors] = useState(false);
  const [userName, setUserName] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsj, setErrorMsj] = useState("");
  const [loginAction, setLoginAction] = useState(0); //0 login, 1 register
  const [snackOpen, setOpen] = useState(false);
  const { setSession } = useContext(AuthContext);
  let history = useHistory();

  const Submit = async (e) => {
    e.preventDefault();

    try {
      const res = await postLogin(userName, password)
    
      if (res.token) {    
        if (res.token && (res.message === "Login successful")) {
          const { token, type, permissions } = res;
          if (type === "Admin") {
            localStorage.setItem("Type", 1);
            localStorage.setItem("Token", token);
            setSession(true);
            history.push("/");
          }
          else if (type === "Fleet Manager") {
            localStorage.setItem("Type", 2);
            localStorage.setItem("Token", token);
            localStorage.setItem("PV_C", permissions[0].Vehicle_C);
            localStorage.setItem("PV_R", permissions[0].Vehicle_R);
            localStorage.setItem("PV_U", permissions[0].Vehicle_U);
            localStorage.setItem("PV_D", permissions[0].Vehicle_D);
            localStorage.setItem("PD_C", permissions[0].Driver_C);
            localStorage.setItem("PD_R", permissions[0].Driver_R);
            localStorage.setItem("PD_U", permissions[0].Driver_U);
            localStorage.setItem("PD_D", permissions[0].Driver_D);
            localStorage.setItem("PS_C", permissions[0].Settings_C);
            localStorage.setItem("PS_R", permissions[0].Settings_R);
            localStorage.setItem("PS_U", permissions[0].Settings_U);
            localStorage.setItem("PS_D", permissions[0].Settings_D);
            localStorage.setItem("PA_R", permissions[0].Accident_R);
            localStorage.setItem("PA_U", permissions[0].Accident_U);
            localStorage.setItem("PR_R", permissions[0].Reports_R);
            localStorage.setItem("PH_R", permissions[0].History_R);
            setSession(true);
            history.push("/");
          }
          else if (type === "Human resources") {
            localStorage.setItem("Type", 4);
            localStorage.setItem("Token", token);
            setSession(true);
            history.push("/");
          }
          else if (type === "Customer") {
            localStorage.setItem("Type", 5);
            localStorage.setItem("Token", token);
            localStorage.setItem("u_name", res.user.name);
            localStorage.setItem("u_id", res.user.u_id);
            setSession(true);
            history.push("/");
          } 
          else if (type === "Warehouse") {
            localStorage.setItem("Type", 6);
            localStorage.setItem("Token", token);
            localStorage.setItem("u_name", res.user.name);
            localStorage.setItem("go_user", JSON.stringify(res.user))
            localStorage.setItem("u_id", res.user.u_id);
            setSession(true);
            history.push("/");
          } 
          else if (type === "CustomerService") {
            localStorage.setItem("Type", 7);
            localStorage.setItem("Token", token);
            localStorage.setItem("u_name", res.user.name);
            localStorage.setItem("go_user", JSON.stringify(res.user))
            localStorage.setItem("u_id", res.user.u_id);
            setSession(true);
            history.push("/");
          } 
          else {
            setPassword("");
            setIsErrors(true);
            setErrorMsj("Sorry you don't have the required access.");
          }
        }
        if (res.message === "Username or password incorrect") {
          setPassword("");
          setIsErrors(true);
          setErrorMsj("Pease check password or email and try again.");
        }
      } else {
        console.log(res);
        setIsErrors(true);
        setErrorMsj("Email or password incorrect, Please try again.");
      }
    } catch (error) {
      console.log(error);
      setIsErrors(true);
      setErrorMsj("Email or password incorrect, Please try again.");
    }
  };

  const SubmitRegister = (e) => {
    e.preventDefault();
    let config = {
      method: "post",
      url: CustomerRegister,
      data: {
        username: userName,
        email: userName + "@golosuite.com",
        address: userAddress,
        phone: userPhone,
        password: password,
      },
      headers: { "Access-Control-Request-Headers": "multipart/form-data" },
    };
    APIGO(config)
      .then((res) => {
        const { msg } = res.data;

        if (msg == "success") {
          setLoginAction(0);
          setIsErrors(false);
          setOpen(true);
        } else {
          setIsErrors(true);
          setErrorMsj("Please try again in a few minutes.");
          console.log(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsErrors(true);
        setErrorMsj("Please try again in a few minutes.");
      });
  };

  const changeLoginAction = (a) => {
    setLoginAction(0);
  };

  return (
    <>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity="success">
          Congratulations!, now you can log in
        </Alert>
      </Snackbar>
      {loginAction === 0 && (
        <form onSubmit={(e) => Submit(e)}>
          <Grid container>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Box py={3}>
                <Typography className="text-primary" variant="h4">
                  {" "}
                  Welcome!{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={1}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="user_name"
                  variant="outlined"
                  placeholder="User name"
                  value={userName}
                  autoComplete="off"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={1}>
                <TextField
                  fullWidth
                  required
                  id="Password"
                  type="password"
                  variant="outlined"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Box py={1}>
                  {/* <Typography variant="body2" > 
                                <Link to="/forgotPassword" className="text-grey" > 
                                    I forgot my password 
                                </Link> 
                            </Typography> */}
                </Box>
              </Grid>
              <Box className="start_box" py={1}>
                <Button
                  type="submit"
                  className="Go_btn-primary"
                  variant="outlined"
                  fullWidth
                >
                  LOG IN
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
      {loginAction === 1 && (
        <form onSubmit={(e) => SubmitRegister(e)}>
          <Grid container>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Box py={3}>
                <Typography className="text-primary" variant="h4">
                  {" "}
                  Welcome!{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={1}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="user_name"
                  variant="outlined"
                  placeholder="User name"
                  value={userName}
                  autoComplete="off"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={1}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="user_address"
                  variant="outlined"
                  placeholder="Address"
                  value={userAddress}
                  autoComplete="off"
                  onChange={(e) => setUserAddress(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={1}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="user_phone"
                  variant="outlined"
                  placeholder="Telephone number"
                  value={userPhone}
                  autoComplete="off"
                  onChange={(e) => setUserPhone(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={1}>
                <TextField
                  fullWidth
                  required
                  id="Password"
                  type="password"
                  variant="outlined"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className="start_box" py={1}>
                <Button
                  type="submit"
                  className="Go_btn-primary"
                  variant="outlined"
                  fullWidth
                >
                  REGISTER
                </Button>
              </Box>
              <Box textAlign="center">
                <MLink
                  onClick={() => changeLoginAction(0)}
                  href="#"
                  color="inherit"
                >
                  {" "}
                  Already have an account? <b>Login here</b>{" "}
                </MLink>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
      {isError && <Alert severity="error"> {errorMsj} </Alert>}
    </>
  );
};

export default Login;
