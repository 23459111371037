import React from 'react';
import { withRouter, Link } from 'react-router-dom';
// IMG
import Logo from './../Assets/Img/Logo.png';
// functions

//materialui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';


function LayoutOutSide({ children }) {
    // const { currentUser } = ;
    //     if (currentUser) {
    //       return <Redirect to="/" />;
    //     }

    return (
        <Grid container >
            <Grid container className="Login_Header" alignItems="center" xs={12} xl={12} justify="center" >
                <Link to="/login">
                    <img src={Logo} alt="" className="Login_Logo" />
                </Link>
            </Grid>

            <Grid container  direction="row" justify="center" alignItems="center">
                <Grid item xs={10} sm={4} md={4} lg={3} xl={3} >
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );

}

export default withRouter(LayoutOutSide)