import React, { useState, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import moment from "moment";
// Components
import ModalImg from '../../Components/Global/ModalImg';
// IMG
import Portada from './../../Assets/Img/Portada_management.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
//API
import { 
    APIGO, 
    getNotesCFMbyV_Id, 
    getNotesDRMbyV_Id,
    getAccidentsByV_id
} from './../../constants/API'; 


const HistoryDetailsInfo = ({ checkList, checkPictures, prevDamage, finalDetails, v_id, signature,  }) => {
    
    // Notes
    const [ dataFinalDetails, setDataFinalDetails ] = useState([]);
    const [ dataPrevDamage, setPrevDamage ] = useState([]);
    const [ showImg, setShowImg ] = useState('');
    const [ modalStaus, setModalStaus ] = useState( false );
    
    useEffect( () =>{
        setDataFinalDetails( finalDetails )
        setPrevDamage( prevDamage )
        console.log( dataFinalDetails );
        console.log( prevDamage );
    }, [] );

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const modalForIMG = ( e, img ) => {
        e.preventDefault();
        setShowImg( img )
        ModalOpen()
    }

    console.log( signature );
    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global bg_white">
                    <ModalImg img={ showImg } />
                </Grid>
            </Modal>

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className="Page_title">
                    Check list 
                </Typography>
                <hr className="divider_blue" />
                <ExpansionPanel  className="bg_grey">
                    <ExpansionPanelSummary expandIcon={ <ArrowDropDownIcon /> } aria-controls="panel-GENERAL" id="panel1a-header" >
                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Box display="flex" alignItems="center">
                                <span className="Page_title"> General </span>
                            </Box>
                        </Grid>
                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {
                                checkList ?
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Clean inside </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.g1_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Clean outside </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.g2_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> License tags / Reg. </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.g3_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Owner / Insurance </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.g4_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Spare tire / Tools </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.g5_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                    </Grid>
                                :
                                    <Typography variant="body1" gutterBottom className="Page_title">
                                        No information at this moment.
                                    </Typography>
                            }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel  className="bg_grey">
                    <ExpansionPanelSummary expandIcon={ <ArrowDropDownIcon /> } aria-controls="panel-GENERAL" id="panel1a-header" >
                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Box display="flex" alignItems="center">
                                <span className="Page_title"> Exterior </span>
                            </Box>
                        </Grid>
                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {
                                checkList ?
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Driver side body </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e1_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Driver side mirror </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e2_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Front end body </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e3_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Front wheels </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e4_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Gas cap </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e5_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Pass side body </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e6_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Pass side mirror </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e7_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid><Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Rear end body </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e8_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Rear wheels </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e9_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Roof </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e10_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Windows / Doors </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e11_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Windshield / Wipers </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.e12_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                    </Grid>
                                :
                                    <Typography variant="body1" gutterBottom className="Page_title">
                                        No information at this moment.
                                    </Typography>
                            }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel  className="bg_grey">
                    <ExpansionPanelSummary expandIcon={ <ArrowDropDownIcon /> } aria-controls="panel-GENERAL" id="panel1a-header" >
                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Box display="flex" alignItems="center">
                                <span className="Page_title"> Interior </span>
                            </Box>
                        </Grid>
                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {
                                checkList ?
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Driver seat </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.i1_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Passenger seat </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.i2_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Floors / Mats </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.i3_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Headliner </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.i4_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Horn / Radio </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.i5_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Rearview mirror </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.i6_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Heater / AC </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.i7_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid><Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Phone mount </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.i8_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                    </Grid>
                                :
                                    <Typography variant="body1" gutterBottom className="Page_title">
                                        No information at this moment.
                                    </Typography>
                            }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel  className="bg_grey">
                    <ExpansionPanelSummary expandIcon={ <ArrowDropDownIcon /> } aria-controls="panel-GENERAL" id="panel1a-header" >
                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Box display="flex" alignItems="center">
                                <span className="Page_title"> Mechanical - Fluid add if need </span>
                            </Box>
                        </Grid>
                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {
                                checkList ?
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Trans fluid </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.m1_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Coolant </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.m2_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Engine belts </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.m3_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Battery  </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.m4_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Hazard light </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.m5_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Turn signal </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.m6_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Lights / All </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.m7_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid><Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-start" >
                                                <span className="notificacion_title-body"> Geo tab </span> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <span className="notificacion_title-body"> { checkList.m8_v === 1 ? 'OK' : 'NO'  } </span> 
                                            </Box>
                                        </Grid>
                                    </Grid>
                                :
                                    <Typography variant="body1" gutterBottom className="Page_title">
                                        No information at this moment.
                                    </Typography>
                            }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className="Page_title">
                    General pictures of vehicle
                </Typography>
                <hr className="divider_blue" />
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6} >
                        <Box p={3}>
                                    {
                                        checkPictures ?
                                            <GridList cellHeight={160} className="" cols={3}>
                                                <GridListTile key="back" cols={1}>
                                                    <img src={ checkPictures.back } alt="back" onClick={ e => modalForIMG( e, checkPictures.back ) }/>
                                                </GridListTile>
                                                <GridListTile key="left" cols={1}>
                                                    <img src={ checkPictures.left } alt="left" onClick={ e => modalForIMG( e, checkPictures.left ) } />
                                                </GridListTile>
                                                <GridListTile key="roof" cols={1}>
                                                    <img src={ checkPictures.roof } alt="roof" onClick={ e => modalForIMG( e, checkPictures.roof ) } />
                                                </GridListTile>
                                                <GridListTile key="front" cols={1}>
                                                    <img src={ checkPictures.front } alt="front" onClick={ e => modalForIMG( e, checkPictures.front ) } />
                                                </GridListTile>
                                                <GridListTile key="right" cols={1}>
                                                    <img src={ checkPictures.right } alt="right" onClick={ e => modalForIMG( e, checkPictures.right ) } />
                                                </GridListTile>
                                            </GridList>
                                        :
                                            <GridList cellHeight={160} className="" cols={3}>
                                                <GridListTile key="back" cols={1}>
                                                    <img src={ Portada } alt="back" onClick={ e => modalForIMG( e, Portada ) } />
                                                </GridListTile>
                                                <GridListTile key="left" cols={1}>
                                                    <img src={ Portada } alt="left" onClick={ e => modalForIMG( e, Portada ) } />
                                                </GridListTile>
                                                <GridListTile key="roof" cols={1}>
                                                    <img src={ Portada } alt="roof" onClick={ e => modalForIMG( e, Portada ) } />
                                                </GridListTile>
                                                <GridListTile key="front" cols={1}>
                                                    <img src={ Portada } alt="front" onClick={ e => modalForIMG( e, Portada ) } />
                                                </GridListTile>
                                                <GridListTile key="right" cols={1}>
                                                    <img src={ Portada } alt="right" onClick={ e => modalForIMG( e, Portada ) } />
                                                </GridListTile>
                                            </GridList>
                                        
                                    }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box p={3}>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Observations
                            </Typography>
                            <Typography variant="body1" gutterBottom className="Text_grey">
                            {   
                                checkPictures.observations ?
                                    checkPictures.observations : "No observations yet"
                            }
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className="Page_title">
                    Previous damages
                </Typography>
                <hr className="divider_blue" /> 
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                            Notes
                        </Typography>
                    </Grid>
                    {
                        prevDamage ? 
                            prevDamage.map( index =>   
                                <Grid item xs={12} md={6} key={ index.key }>
                                    <Box p={1} mt={1} className="bg_white">
                                        <Typography variant="subtitle1" gutterBottom className="text_primary">                                    
                                            Damage type:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom className="text_grey">                                    
                                            { index.damage_type }
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom className="text_primary">                                    
                                            Damage part:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom className="text_grey">                                    
                                            { index.damage_type }
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom className="text_primary">                                    
                                            Damage notes:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom className="text_grey">                                    
                                            { index.further_notes }
                                        </Typography>
                                        <Grid container direction="row" justify="center" alignItems="center" >
                                            <img src={ index.uri } alt="" className="CC_notes-img"/>
                                        </Grid>
                                    </Box>
                                </Grid>
                            )
                        :
                            <Typography variant="body1" gutterBottom className="Text_grey">
                                No data
                            </Typography>  
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className="Page_title">
                    Final details
                </Typography>
                <hr className="divider_blue" /> 
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                        <Box p={3} pb={1} >
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Note defects while driving - any - Accidents/incidents
                            </Typography>
                            {
                                finalDetails ? 
                                    <Box p={1} mt={1} className="bg_white" >
                                        { finalDetails.age } <br/>
                                        { finalDetails.defects } <br/>
                                    </Box> 
                                :
                                    <Typography variant="body1" gutterBottom className="Text_grey">
                                        No data
                                    </Typography> 
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box p={3} pb={1}>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Mileage in km
                            </Typography> 
                            <Typography variant="body1" gutterBottom className="Text_grey">
                                { 
                                    finalDetails.distance ? 
                                        finalDetails.distance : 'No data for dinstance'
                                }
                            </Typography>
                        </Box>
                    </Grid>    
                    <Grid item xs={12} md={6}>
                        <Box p={3}>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Fleet Manager’s Signature 
                            </Typography> 
                            <Typography variant="body1" gutterBottom className="Text_grey">
                                { 
                                    signature.driver 
                                    ? <img src={ signature.driver } alt="Fleet Manager’s Signature" style={{maxWidth: '100%', maxHeight: '200px'}} onClick={ e => modalForIMG( e, signature.driver )}/>      
                                    : 'No signature information.'
                                }
                            </Typography>
                        </Box>
                    </Grid>    
                    <Grid item xs={12} md={6}>
                        <Box p={3}>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Driver’s Signature
                            </Typography> 
                            <Typography variant="body1" gutterBottom className="Text_grey">
                                { 
                                    signature.fleetManager 
                                    ? <img src={ signature.fleetManager } alt="Driver’s Signature" style={{maxWidth: '100%', maxHeight: '200px'}} onClick={ e => modalForIMG( e, signature.fleetManager )} />  
                                    : 'No signature information.'
                                }
                            </Typography>
                        </Box>
                    </Grid>    
                </Grid>
            </Grid>
        </>
    )
}
export default HistoryDetailsInfo