import React, { useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom';
import { getOrderById } from '../../../Components/Functions/LabelPdf/LabelFunctions';
import { Button, Grid, Typography } from '@material-ui/core';
import moment from 'moment'

export const OrderHistoryDetails = () => {

    const u_id      = localStorage.getItem('u_id')
    const match     = useRouteMatch('/customer/history/details/:id');
    const order_id  = match.params.id;
    const [data, setData] = useState([]);
    const [packages, setPackages] = useState([]);
    const [state, setstate] = useState({
        loading: false,
        error: ''
    });

    const handleGetLabel = async () => {
        
        const convert = 2.205;
        setstate({...state, loading: true })
        const resp = await getOrderById( u_id, order_id )
        if ( resp.status ) {
            console.log("AQUI ESTAN LOS DATOS DEL PAQUETE:")
            console.log( resp.results );
            setData( resp.results )
            setPackages(JSON.parse( resp.results.packages ))
        } else {
            setData( false )
            setstate({...state, error: resp.msg })
        }
        setstate({...state, loading: false })
    }

    useEffect(() => { handleGetLabel() }, [])

    return (
        <Grid container id="go_gridContainer_orderHostoryDetails">
            <Grid item xs={12} className="header__details" >
                <Typography variant='h4' > Order details </Typography>
                <Grid container direction="row" justify="space-between" alignItems="center" >
                    <Typography variant='h5' className="text_lite-white" > # 255{ order_id } </Typography>
                    <Typography variant='h5' className="text_lite-white" > { moment( data.order_date ).format('MMMM Do YYYY') } </Typography>
                </Grid>
            </Grid>
            <Grid container >
                <Grid container direction="row" justify="center" alignItems="center" >
                    <Typography variant='h5' className="text_primary py-3" ><b>Summary</b></Typography>
                </Grid>
                <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                    <Typography variant='subtitle1' className="customer__list-label"><b>Deliver to</b></Typography>
                    <Typography variant='subtitle1' className="customer__list-text" > { data.delivery_contact } </Typography>
                </Grid>
                <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                    <Typography variant='subtitle1' className="customer__list-label"><b>Address</b></Typography>
                    <Typography variant='subtitle1' className="customer__list-text"> 
                        { (data.delivery_address !== 'null' || data.delivery_address !== 'undefined') && data.delivery_address },&nbsp;
                        { (data.delivery_apartment !== 'null' || data.delivery_apartment !== 'undefined') && data.delivery_apartment },&nbsp;
                        { (data.delivery_postal !== 'null' || data.delivery_postal !== 'undefined') && data.delivery_postal },&nbsp;
                        { (data.delivery_city !== 'null' || data.delivery_city !== 'undefined') && data.delivery_city },&nbsp;
                        { (data.delivery_province !== 'null' || data.delivery_province !== 'undefined') && data.delivery_province },&nbsp;
                        { (data.delivery_country !== 'null' || data.delivery_country !== 'undefined') && data.delivery_country }
                    </Typography>
                </Grid>
                <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                    <Typography variant='subtitle1' className="customer__list-label"><b>Phone number</b></Typography>
                    <Typography variant='subtitle1' className="customer__list-text"> { data.delivery_phone } </Typography>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="space-between" className="py-3" >
                    <table style={{width: '100%', border: '1px solid #CECECE', borderCollapse: 'collapse', margin: '15px, 0'}}>
                        <tr className="table__header" style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                            <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%'}} >
                                Number of Packages
                            </th>
                            <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%'}} >
                                Weight Per Package
                            </th>
                            <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '60%', minWidth: '60%'}} >
                                Dimensions Per Package (L * W * H)
                            </th>
                        </tr>
                        { packages?.map( row => 
                            <tr style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', padding: '5px', textAlign: 'center' }} >
                                    { row.package_num }
                                </td>
                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', padding: '5px', textAlign: 'center' }} >   
                                    { row.weight }
                                    { data.weight_unit }
                                </td>
                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '60%', minWidth: '60%', padding: '5px', textAlign: 'center' }} >
                                    { row.dimensionsL } * { row.dimensionsW } * { row.dimensionsH } { data.dimensions_unit } 
                                </td>
                            </tr>
                        )}
                    </table>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Package</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> { data.total_packages } </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Total weight</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> { data.total_weight } { data.weight_unit } </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Pick up date </b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text">  { moment( data.order_date ).format('L') } </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Ready time</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> { data.ready_time } </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Service</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> { data.service } </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Vehicle</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Waybill</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>COD</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Insurance declared value</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> $ { data.insurance_value ? data.insurance_value: '0' } </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="px-2" >
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Department</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="pt-3">
                    <Typography variant='subtitle1' className="customer__list-label"><b>Comments/Instructions</b></Typography>
                    <div className="customers_comment" >
                        { data.instructions === 'null' || data.instructions === "" ? 'N / A'  : data.instructions } 
                    </div>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" className="py-3">
                    {/* <table style={{width: '40%', border: '1px solid #CECECE', borderCollapse: 'collapse', margin: '15px, 0'}}>
                        <tr className="table__header" style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                            <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', textAlign: 'center'}} >
                                Charges
                            </th>
                        </tr>
                        <tr style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                            <td 
                                style={{ 
                                    border: '1px solid #CECECE', 
                                    borderCollapse: 'collapse', 
                                    padding: '5px'
                                }}
                            >
                                <Grid container direction="row" justify="space-between" alignItems="center" >
                                    <Typography variant='subtitle1' className="customer__list-label" > Subtotal </Typography>
                                    <Typography variant='subtitle1'  className="customer__list-text"> $ 0 </Typography>
                                </Grid>
                                <Typography variant='subtitle1'  className="customer__list-text"> HST (13.00%) </Typography>
                                <Grid container direction="row" justify="space-between" alignItems="center" >
                                    <Typography variant='subtitle1' className="customer__list-label" > Total </Typography>
                                    <Typography variant='subtitle1' className="customer__list-label"> $ 0 </Typography>
                                </Grid>
                            </td>
                        </tr>
                    </table> */}
                </Grid>
                <Grid id="go_orderDetails_gridContainerButtons" container direction="row" justify="center" alignItems="center" className="my-2" >
                    <Link to={`/customer/history`} className="flex No_Decoration">
                        <Button variant="outlined" disableElevation className="w-250px mr-2" > Back </Button>
                    </Link>
                    <Link to={`/customer/pdf/${order_id}`} className="flex No_Decoration">
                        <Button variant="contained" disableElevation className="Go_btn-primary w-250px" > Print Label </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    )
}
