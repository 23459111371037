import React, { useContext, useRef } from 'react'

import { makeStyles, Box, Typography, Button, Grid, Divider, useTheme, TextareaAutosize, TextField } from "@material-ui/core"

import BaseDialog from '../../Components/Global/Modals/BaseDialog'
import { postRegister } from '../../middleware/warehouse/Warehouse'
import { AuthContext } from '../../Components/Functions/Auth';
import { postWarehouseProduct } from '../../middleware/warehouse/WarehouseProducts';

const useStyles = makeStyles((theme) => ({
    banner: {
        height: "auto",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    bold: {
        fontWeight: "bold",
        color: theme.palette.grey[600]
    },
    actions: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    danger: {
        backgroundColor: theme.palette.error.main,
        color: "#fff"
    }
}))


const RegisterProduct = ({ location, open, onClose, onResult }) => {

    const { user } = useContext(AuthContext)

    const classes = useStyles()

    const theme = useTheme()

    const defaultForm = {
        name: "",
        client: "",
        order_number: "",
        model_number: "",
        serial_number: "",
        location_name: location.code,
        location_id: location.id
    }
    const [formData, setFormData] = React.useState(defaultForm)
    const handleChange = ({ target: { value, name } }) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }
    const handleSave = async () => {
        if (formData.model_number === "" || formData.serial_no === "") {
            alert("Complete required fields")
            return
        }

        const data = {
            street_name: formData.name,
            shipment_no: formData.order_number,
            sales_order_no: formData.order_number,
            serial_number: formData.serial_number,
            first_name: formData.client,
            description: formData.model_number,
            username: user.name,
            user_id: user.u_id,
            location_name: location.code,
            comments: "",
            status: "received"
        }

        const response = await postRegister(data)
        if (!response.status) {
            return
        }

        onResult(response.result)
        setFormData(defaultForm)
    }

    const [focus, setFocus] = React.useState(0)
    const field_0 = useRef()
    const field_1 = useRef()
    const field_2 = useRef()
    const field_3 = useRef()
    const field_4 = useRef()
    const field_5 = useRef()

    React.useEffect(() => {
        if (field_0.current) {
            switch (focus) {
                case 0:
                    field_0.current.focus()
                    break;
                case 1:
                    field_1.current.focus()
                    break;
                case 2:
                    field_2.current.focus()
                    break;
                case 3:
                    field_3.current.focus()
                    break;
                case 4:
                    field_4.current.focus()
                    break;
                case 5:
                    field_5.current.focus()
                    break;
                default:
                    break;
            }
        }
    }, [focus])


    const handleFocus = (e) => {
        if (e.key === "Enter") {
            let _focus = focus + 1
            if (_focus > 5) {
                _focus = 0
            }
            console.log(focus)
            setFocus(_focus)
        }
    }

    return (
        <BaseDialog open={open} onClose={onClose} maxWidth="xs"  >

            <Box mb={2} style={{ backgroundColor: theme.palette.primary.main }} color={theme.palette.primary.contrastText} textAlign="left" display="flex" alignItems="center" flexDirection="row" p={1} pt={2} pb={2} >
                <Typography color="inherit" variant="h6" >Add product</Typography>
            </Box>

            <Grid container justifyContent="center" alignItems="center" justify="center" >
                <Grid item xs={11} >
                    <form onKeyPress={handleFocus} >
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography>Address</Typography>
                                <TextField onFocus={()=>{
                                    if(focus!=0){
                                        setFocus(0)
                                    }
                                }} inputRef={field_0} autoFocus onChange={handleChange} name="name" value={formData.name} variant="outlined" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Client</Typography>
                                <TextField onFocus={()=>{
                                    if(focus!=1){
                                        setFocus(1)
                                    }
                                }} inputRef={field_1} onChange={handleChange} name="client" value={formData.client} variant="outlined" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Order number</Typography>
                                <TextField onFocus={()=>{
                                    if(focus!=2){
                                        setFocus(2)
                                    }
                                }} inputRef={field_2} onChange={handleChange} name="order_number" value={formData.order_number} variant="outlined" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Model number</Typography>
                                <TextField onFocus={()=>{
                                    if(focus!=3){
                                        setFocus(3)
                                    }
                                }} inputRef={field_3} onChange={handleChange} name="model_number" value={formData.model_number} variant="outlined" fullWidth size="small" />
                                <Typography variant="caption" >This field is required*</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Serial number</Typography>
                                <TextField onFocus={()=>{
                                    if(focus!=4){
                                        setFocus(4)
                                    }
                                }} inputRef={field_4} onChange={handleChange} name="serial_number" value={formData.serial_number} variant="outlined" fullWidth size="small" />
                                <Typography variant="caption" >This field is required*</Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>

            <Grid className={classes.actions} container justify="center" >
                <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }} >
                    <Button ref={field_5} onClick={() => handleSave(false)} color="secondary" variant="contained" >Save</Button>
                </Grid>
            </Grid>

        </BaseDialog>
    )
}

RegisterProduct.defaultProps = {
    open: false
};

export default RegisterProduct