import axios from 'axios';

//Axios instance
export var APIGO = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60 * 60 * 1000,

});

APIGO.interceptors.request.use( config => {
    const token = localStorage.getItem("Token")
    if(token){
        config.headers = {
            "Authorization":`Bearer ${token}`,
            "Accept":'application/json',
            "Content-Type":'application/json',
            "x-api-secret":'AIzaSyDUFGqptrJ5bJTeZ-0j4HbBz6OL6kv3JZ4'
        }
    }
    return config
}
,error => {
    Promise.reject(error)
}
)

// Login
export const LoginGo = `/login`;
//Customers Register
export const CustomerRegister = `/register/customer`;
// http requeest for VEHICLES
export const getVehicles = `/vehicles/?page=1&limit=20`;
export const getVehiclesFilterByStatus = `/vehicles/?page=1&limit=10&filter_by_status=3`;
export const getVehiclesFilterByStation = `/vehicles/?page=1&limit=20`;
export const getVehiclesById = `/vehicles/getById/`;
export const getVehiclesByLicence = `/vehicles/getByLicence/`;
export const getVehiclesByRdga = `/vehicles/getByRdga/`;
export const getissues = `/vehicles/GetIssues?v_id=`;
export const createVehicle = `/vehicles/create`;
export const updateVehicle = `/vehicles/update/`;
export const deletedVehicle = `/vehicles/delete/`;
export const updatevehicle = '/vehicles/update/?id=';
export const updateStation = '/vehicles/update/station?id=';
export const updateDocumentsVehicles = '/vehicles/update/documents/?id=';
export const createVehicleNote = '/vehicles/notes/create';
export const GetVehicleNote = '/vehicles/notes?id_vehicle=';
export const EditVehicle = '/vehicles/edit';

// http requeest for permission
export const permissionVehicle = `/permission/Vehicle`;
export const permissionDrivers = `/permission/Drivers`;
export const permissionAccidents = `/permission/Accidents`;
export const permissionReports = `/permission/Reports`;
export const permissionHistory = `/permission/History`;
export const permissionSettings = `/permission/Settings`;

// http requeest for Admin
export const CreateManager = `/register/manager`;
export const GetManagerByID = `/users/admin/?type=2&manager=1&id=`;
export const getManager = '/users/admin/?type=2';
export const GetCustomers = '/users/admin/?type=5';
export const UpdateStationManager = '/stations/update/assign';
export const DeleteManager = '/users/admin/delete_manager';


// http requeest for CIRCLECHECK
export const getCicleCheck = `/cicleCheck/getList/complete`;
export const getCicleCheckById = `/cicleCheck/getById`;
export const getCicleCheckByRDGA = `/cicleCheck/getByRdga/`;
export const createCicleCheck = `/vehicles/getByLicence/`;
export const startCicleCheck = `/vehicles/getByRdga/`;
export const finishCicleCheck = `/vehicles/create/`;
export const changeStatusCicleCheck = `/vehicles/update/`;
export const changeCheckInCicleCheck = `/vehicles/delete/`;
export const changeCheckOutCicleCheck = `/vehicles/getByRdga/`;
export const changePictureInCicleCheck = `/vehicles/create/`;
export const changePictureOutCicleCheck = `/vehicles/update/`;
export const changeDriverCicleCheck = `/vehicles/delete/`;
export const changeVehicleCicleCheck = `/vehicles/update/`;
export const updateCicleCheck = `/vehicles/delete/`;
export const deleteCicleCheck = `/vehicles/delete/`;
// http request for Drivers
export const getProfile = '/users/profile';
//Chat
export const getDriverById = `/users/?id=`;
// export const getdrivers = '/users'
export const postRegisterDriver = `/register/driver`;
export const getdrivers = '/users/admin/?page=1&limit=20&type=3';
export const usersbyid = '/users/admin/?id=';
export const getDriversById = `/users/?type=3&id=`;
export const updateuser = 'users/update/?id=';
export const updateDriverDocument = '/users/update/documents/?rdga=';
export const UpdateLicense = '/users/update/licence/?rdga=';
export const UpdateAvatar = '/users/update/avatar/?rdga=';
export const deleteuser = 'users/delete/?id=';
export const EditDriverAsAdmin = '/users/edit';
export const deleteVehicle = '/vehicles/delete/?id=';

// http requeest for settings
export const getVendor = `/vendors`;
export const getVendorById = `/vendors/?id=`;
export const createVendor = `/vendors/register`;
export const deleteVendor = `/vendors/delete?id=`;
export const UpdateVendor = `/vendors/update/?vendor_id=`;

export const getModel = `/models/`;
export const getModelById = `/models/?id=`;
export const createModel = `/models/register`;
export const updateModel = `/models/update/?model_id=`;
export const deleteModel = `/models/delete?id=`;

export const getStation = `/stations/`;
export const getStationById = `/stations/?id=`;
export const createStations = `/stations/register`;
export const deleteStations = `/stations/delete?id=`;
export const updateStationbyId = `/stations/update/?st_id=`;

export const getStationModelVendor = `/modelsxvendorxstation`;
// http requeest for accidernts
export const getAccidents = `/accident`;
export const getAccidentsByV_id = `/accident?V_id=`;
export const getAccidentsbyid = `/accident?id=`;
export const getNotesCFMbyId = `/accident/GetCFM?id_accident=`;
export const getNotesDRMbyId = `/accident/GetDRM?id_accident=`;
export const getNotesCFMbyV_Id = `/accident/GetCFM?v_id=`;
export const getNotesDRMbyV_Id = `/accident/GetDRM?v_id=`;
export const getAditionalFiles = `/accident/AdditionalFiles`;
export const createNotesCFM = `/accident/CreateCFM`;
export const createNotesDRM = `/accident/CreateDRM`;
export const getEstimates = `/accident/GetEstimates?id_accident=`;
export const getAllEstimates = `/accident/GetEstimates`;
export const createEstimates = `/accident/AddEstimate`;
export const changeEstimates = `/accident/activeEstimate`;
export const getAditionalFile = `/accident/AdditionalFiles?id_accident=`;
export const CreateAditionalFile = `/accident/AdditionalFiles`;
export const CoveredAccidentBy  = `/accident/Covered_by/`;

