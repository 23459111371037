import React from 'react'

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,

} from '@material-ui/data-grid';

import { CircularProgress, Container, Grid, LinearProgress, Typography } from "@material-ui/core"
import { filter, getRegisters, searchInRegisters, searchWithQuery } from '../../middleware/warehouse/Warehouse';
import moment from 'moment';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <Grid>
                <GridToolbarExport />
            </Grid>
        </GridToolbarContainer>
    );
}

const RegistersExport = ({ location }) => {

    const bundle = location.state

    console.log(bundle);

    const width = 128
    const [columns, setColumns] = React.useState([
        { field: "id", headerName: "ID", width: width, type: "text" },
        { field: "description", headerName: "Description", width: width, type: "text" },
        { field: "shipment_no", headerName: "Shipment no", width: width, type: "text" },
        { field: "serial_number", headerName: "Serial no", width: width, type: "text" },
        { field: "sales_order_no", headerName: "Sales Order No", width: width, type: "text" },
        { field: "first_name", headerName: "First Name", width: width, type: "text" },
        { field: "last_name", headerName: "Last Name", width: width, type: "text" },
        { field: "driverkey", headerName: "DriverKey", width: width, type: "text" },
        { field: "location_name", headerName: "Location", width: width, type: "text" },
        /* { field: "drivername", headerName: "DriverName", width: width, type: "text" }, */
        { field: "orderqty", headerName: "OrderQty", width: width, type: "text" },
        { field: "status", headerName: "Status", width: width, type: "text" },
        { field: "stopnumber", headerName: "StopNumber", width: width, type: "text" },
        { field: "created", headerName: "Created", width: width, type: "text" },
        { field: "aging", headerName: "Aging", width: width, type: "text" },
        { field: "reason", headerName: "Return reason", width: width, type: "text" },
    ]
    )
    const [rows, setRows] = React.useState()
    React.useEffect(() => {
        (async () => {
            try {
                let response;
                const from = bundle.filterOptions.from ? moment(bundle.filterOptions.from).format("YYYY-MM-DD") : moment("2020-01-01").format("YYYY-MM-DD")
                const to = bundle.filterOptions.to ? moment(bundle.filterOptions.to).format("YYYY-MM-DD") : moment("2050-01-01").format("YYYY-MM-DD")

                const order_status = bundle.scan == "returns" ? "returned" : bundle.filterOptions.status;

                response = await filter(
                    bundle.id,
                    order_status,
                    from,
                    to,
                    1,
                    -1
                )
                /* if (bundle.filterOptions.status != "all") {
                    console.log("!=all");

                    //response = await searchInRegisters(bundle.id, bundle.filterOptions.status, 1, -1, bundle.u_id)
                    response = await searchWithQuery(
                        bundle.filterOptions.status, 1, -1,
                        bundle.id ? `list_id=${bundle.id} and (created BETWEEN '${from}' AND '${to}')` : ` (created BETWEEN '${from}' AND '${to}')`
                    )

                } else {
                    console.log("None all")

                    response = await searchWithQuery(
                        bundle.filterOptions.status, 1, -1,
                        bundle.id ? `list_id=${bundle.id} and (created BETWEEN '${from}' AND '${to}')` : ` (created BETWEEN '${from}' AND '${to}')`
                    )

                    //response = await getRegisters(bundle.id, bundle.u_id, 1, -1)
                } */
                console.log(response)
                if (response.status) {
                    console.log("object", response)
                    let _rows = []
                    await Promise.all(response.result.map((register, index) => {
                        register.id = `${register.warehouse_id}`
                        _rows.unshift(register)
                    }))
                    setRows(_rows)
                } else {
                    setRows([])
                }
            } catch (error) {
                console.warn(error);
            }
        })()
    }, [])

    return (
        <React.Fragment>
            {!rows && <Container>
                <LinearProgress />
            </Container>}
            <Container>
                {
                    rows &&
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            checkboxSelection={true}
                            columns={columns}
                            rows={rows}
                            dataSet="Commodity"
                            rowLength={4}
                            maxColumns={6}
                            disableColumnMenu={true}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </div>
                }
            </Container>
        </React.Fragment>
    )
}

export default RegistersExport
