import React from 'react'

import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';


const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      dark: "#06396B",
      main: "#06396B",
      contrastText: "#fff"
    },
    secondary: {
      main: "#E67632",
      contrastText: "#fff"
    },
    accent: {
      light: "#ffc936",
      main: "#f9b233",
      dark: "#eaa52e",
      contrastText: "#fff"
    },
    text: {
      primary: "#3d3d3d"
    },
    error: {
      main: "#ed143d"
    },
    background: {
      default: "#f6f6f6"
    }

  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
    subtitle1: {
      color: "#033a47"
    }
  },
  shape: {
    borderRadius: 2,
    button_radius: 2,
    input_radius: 2,
  }
});


const ApplicationTheme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ApplicationTheme
