import React from 'react'
import _logo from '../../../Assets/Img/Logo.png'
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core'

export const DialogConfirm = ({ open, setOpen, body, onAction }) => {

    return (
        <Dialog 
            open={open}
            onClose={ () => setOpen(false) }
            fullWidth={true}
            maxWidth='xs'
        >
            <DialogTitle className="items-cen-cen"> 
                <img src={ _logo } alt=""/>
            </DialogTitle>
            <DialogContent className="text_primary items-cen-cen">
                <Typography variant="h5">{ body }</Typography>
            </DialogContent>
            <Grid container direction="row" justify="center" alignItems="center" className="my-2">
                <Button 
                    onClick={ () => setOpen(false) } 
                    variant="outlined"
                    className="w-150px mr-2"
                >
                    Disagree
                </Button>
                <Button 
                    onClick={ () => onAction() }
                    autoFocus
                    variant="outlined" 
                    className="Go_btn-primary w-150px"
                >
                    Agree
                </Button>
            </Grid>
        </Dialog>
    )
}
