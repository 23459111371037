import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ChatIcon from '@material-ui/icons/Chat'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import RootRef from '@material-ui/core/RootRef'
import { ChatContext } from './ChatContext'

import { CHATS_REF, STGREF } from '../Functions/Contants'
import { SearchUser } from './SearchUser'
import { getChat } from '../Functions/Driver'
import {  APIGO } from './../../../constants/API'; 
import { Badge } from '@material-ui/core'
import { AuthContext } from '../../../Components/Functions/Auth'

const ItemChat = ({ chat }) => {
    
    const { setIdChatActive, setDriverUser, idChatActive } = useContext(ChatContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const domRef = React.useRef();

    let driver ={
        avatar   : Object.values(chat[1].members)[0].avatar,
        name     : Object.values(chat[1].members)[0].name,
        isTyping : Object.values(chat[1].members)[0].isTyping,
        isOnline : Object.values(chat[1].members)[0].isOnline,
        id       : Object.keys(chat[1].members)[0]
    }
    const handleClick = () => {
        setIdChatActive(chat[0])
        if(driver.avatar){
            setDriverUser(driver)
        }
    }

    const handleClickItem = () => {
        CHATS_REF.child(`/${idChatActive}/`).update({status:"attend"})
        setIdChatActive(null)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return(
        <div className="driver_item" onClick={ handleClick  }>
            <Grid container direction="row" jusitfy="center" alignItems="center" className="bg_history driver_chat_item">
                <Grid item xs={ 11 } md={4}>
                    <img src={ driver.avatar } alt="avatar" className="historyImg driver_chat_item_avatar"/>
                </Grid>
                <Grid item xs={ 11 } md={ 7 }>
                    <Box>
                        <span className="History_title driver_name">
                            { driver.name }
                        </span><br/>
                        <span className="Text_blod-grey">
                            ID { driver.id }
                        </span>
                    </Box>
                </Grid> 
                <Grid item xs={ 1 } md={1}>
                    <Badge color="secondary" variant="dot" >
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            style={{padding:'0px'}}
                            onClick ={(e) => setAnchorEl(e.currentTarget)}
                        
                        >   
                            <MoreVertIcon />
                        </IconButton>
                    </Badge>
                    <RootRef rootRef= { domRef }>
                        <Menu
                        id="long-menu"
                        PaperProps={{
                            style: {
                                maxHeight: '55px',
                                width: '20ch',
                            },
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        keepMounted
                        onClose={handleClose}
                        >
                            <MenuItem onClick={handleClickItem}>
                                Set as Attended
                            </MenuItem>
                        </Menu>
                    </RootRef>
                </Grid>
            </Grid>
        </div>
    )
}
const ItemUser = ({ info:{ rdga: id, avatar, full_name, station } }) => {
    
    const { setIdChatActive, setDriverUser, idChatActive } = useContext(ChatContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const domRef = React.useRef();
    
    let driver = {
        avatar   : avatar,
        name     : full_name,
        isTyping : false,
        isOnline : true,
        id       : id
    }

    let driverRegister = {
        "status" : "request",
        "members": {
            [`${id}`]:{
                "avatar": avatar,
                "name": full_name,
                "isOnline": true,
                "isTyping": false,
            },
        },
        "messages": {},
        [`${id}`]: true,
        "station": station
    }
    
    const handleClick = () => {
        
        let isChat = true;
        CHATS_REF.on("value", snap => {
            snap.forEach( chats => {
                if ( chats.key === id ) {
                    isChat = false;
                }
            })
        })

        if ( isChat ) {
            CHATS_REF.child(`/${id}`).update(driverRegister)
        }

        setIdChatActive(id)
        if ( driver.avatar ) {
            setDriverUser(driver)
        }
    }

    const handleClickItem = () => {
        CHATS_REF.child(`/${idChatActive}/`).update({status:"attend"})
        setIdChatActive(null)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return(
        <div className="driver_item" onClick={ handleClick  }>
            <Grid container direction="row" jusitfy="center" alignItems="center" className="bg_history driver_chat_item">
                <Grid item xs={ 2 } md={ 4 }>
                    <img src={ avatar } alt="avatar" className="historyImg driver_chat_item_avatar"/>
                </Grid>
                <Grid item xs={ 8 } md={ 7 }>
                    <Box>
                        <span className="History_title driver_name">
                            { full_name }
                        </span><br/>
                        <span className="Text_blod-grey">
                            ID { id }
                        </span>
                    </Box>
                </Grid> 
                <Grid item xs={2} md={ 1 }>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        style={{padding:'0px'}}
                        onClick ={(e) => setAnchorEl(e.currentTarget)}
                    >   
                        <MoreVertIcon />
                    </IconButton>
                    <RootRef rootRef= { domRef }>
                    <Menu
                    id="long-menu"
                    PaperProps={{
                        style: {
                        maxHeight: '55px',
                        width: '20ch',
                    },
                }}
                    open={open}
                    anchorEl={anchorEl}
                    keepMounted
                    onClose={handleClose}
                    >
                        <MenuItem onClick={handleClickItem}>
                            Set as Attended
                        </MenuItem>
                    </Menu>
                </RootRef>
                </Grid>
            </Grid>
        </div>
    )
}

const infoUser = () => {
    
}

const SideChats = () => {
    const { availableChats, setAvailableChats } = useContext(ChatContext)
    const { setIsChatActive, type, token } = useContext(AuthContext);
    const [reload, setReload] = useState(1)
    const [userList, setUserList] = useState([])
    const [userStation, setUserStation] = useState('')
    
    useEffect(() => {
        let configUser = {
            method: 'GET',
            url: '/users/profile',
            headers: {
                'Access-Control':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
        APIGO(configUser)
        .then( res => {
            setUserStation( res.data.station )
        })
        .catch(err => console.log(err))
    }, []);

    useEffect(() => {
        CHATS_REF.orderByChild("status").equalTo("request").on("value", snap => {
            // console.log('snap ', snap.val());
            setAvailableChats(snap.val())
        })
        var configDriver = {
            method: 'get',
            url: `/users/?page=1&limit=10&type=3`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configDriver)
        .then(response =>{
            if ( response.data.status === "success" ) {
                if ( response.data.info.count > 0 ) {
                    setUserList(response.data.results)
                }
                else{
                    setUserList( false )
                }
            }
            if ( response.data.status === "error" ) {
                setUserList(false)
            }
        })
        .catch(function (error){
            console.log(error)
        })
    },[ reload ])

    const renderActiveList = () => {
        if(availableChats) { 
            return Object.entries(availableChats).map(user =>{
                if ( type == 1 ) {
                    return user[1].members &&  <ItemChat chat={user} key={user[0]}/>
                }
                if ( type == 2 ) {
                    if ( user.station === userStation ) {
                        return user[1].members &&  <ItemChat chat={user} key={user[0]}/>
                    }
                }
            })
        }
    }
    const renderStationList = () => {
        
        if ( userList.length > 0 ) {
            return userList.map( user =>{
                return <ItemUser info={ user } key={user.rdga}/>
            })
        }
        else{
            return <Box>
                No drivers available. 
                <span
                    onClick={ () => setReload( reload + 1) } 
                    style={{textDecoration: 'underline', cursor: "pointer", color: '#e67632' }}
                > 
                    Try again
                </span>
            </Box>
        }
    }

    return(
        <Grid container style={{maxHeight: '75vh'}}>
            <SearchUser setUsersList={ setUserList } />
            { availableChats && renderActiveList() }
            { availableChats && renderStationList() }
        </Grid>
    )
}


export default SideChats;