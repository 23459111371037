import React from 'react'

import PropTypes from "prop-types"

import BaseDialog from './BaseDialog'

import { makeStyles, Box, Typography, Button } from "@material-ui/core"

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        padding: 0
    },
    banner: {
        height: theme.spacing(15),
        width: "100%",
        backgroundColor: theme.palette.grey[200]
    },
    message: {
        maxHeight: 300,
        width: "100%",
    },
    icon: {
        fontSize: "5rem"
    },
    action:{
        minWidth:100
    }
}))

const DialogMessage = ({ type, open, loading, onClose, title, message, positiveText, negativeText, positiveAction, negativeAction }) => {

    const classes = useStyles()

    return (
        <BaseDialog open={open} onClose={onClose} >
            <Box className={classes.banner} textAlign="center" p={3} >
                {
                    type === "error" ?
                        <ErrorIcon color="error" className={classes.icon} />
                        : type === "success" ?
                            <CheckCircleIcon color="primary" className={classes.icon} />
                            : type === "warning" ?
                                <WarningIcon color="primary" className={classes.icon} />
                                : type === "info" ? <InfoIcon color="primary" className={classes.icon} /> : <></>
                }
            </Box>
            <Box className={classes.message} textAlign="center" p={2} >
                <Typography variant="h6" color="primary" >{title}</Typography>
                <Typography variant="body1" >{message}</Typography>
            </Box>
            <Box className={classes.actions} display="flex" justifyContent="space-around" flexDirection="row" p={1} >
                <Button className={classes.action} variant="outlined" disabled={loading} onClick={negativeAction} >{negativeText}</Button>
                <Button className={classes.action} variant="contained" disabled={loading} onClick={positiveAction} color="secondary" >{positiveText}</Button>
            </Box>
        </BaseDialog>
    )
}

DialogMessage.propTypes = {
    /**
     * Icon type ["info","error","success","warning"]
     */
    type: PropTypes.oneOf(["info", "error", "success", "warning"])
};

DialogMessage.defaultProps = {
    type: "info"
};

export default DialogMessage
