import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { ChatContext } from './ChatContext'

const HeaderChat = () => {

    const { driverUser } = useContext(ChatContext)
    let user = driverUser ? driverUser : {
        avatar: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
        name: "Select a chat",
        id: ""
    }
    return(
        <Grid item xs={12} >
            <Grid container direction="row" justify="center" alignItems="center" >
                <img src={ user.avatar } alt="" className="historyImg" />
                <Box>
                    <span className="History_title" >
                        { user.name }
                    </span>
                    <br/>
                    <span className="Text_blod-grey">
                        { user.id && `ID ${user.id}` }
                    </span>        
                </Box>
            </Grid>
        </Grid>
    )
}

export default HeaderChat;