import { Box, Button, CircularProgress, FormControl, Grid, Hidden, LinearProgress, ListItem, makeStyles, Menu, MenuItem, Select, TableCell, TableRow, Typography } from '@material-ui/core'
import moment from 'moment'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { AuthContext } from '../../Components/Functions/Auth'
import MenuOptions from '../../Components/Global/MenuOptions'
import DialogMessage from '../../Components/Global/Modals/DialogMessage'
import { deleteWarehouseRegister, updateRegisterData } from '../../middleware/warehouse/Warehouse'

export const trigger_types = {
    exemption: 0,
    history: 1,
    not_found: 2,
    edit: 3,
    edit_location: 4
}

const useItemStyles = makeStyles(theme => (
    {
        root: props => ({
            minWidth: 150,
            borderRadius: 100,
            backgroundColor: props.status === "pending" ? "#6594C1"
                : props.status === "delivered" ? theme.palette.success.main
                    : props.status === "canceled" ? theme.palette.error.main
                        : props.status === "exemption" ? theme.palette.warning.main
                            : "#6594C1"
        }),

    }
));


const RegisterStatus = (props) => {


    const { itemStatus } = props

    const [status, setStatus] = React.useState()

    const classes = useItemStyles({ status: itemStatus })

    React.useEffect(() => {
        setStatus(getReadableStatus(itemStatus))
    }, [itemStatus])

    const getReadableStatus = (status) => {
        switch (status) {
            case "pending":
                return "Pending receipt"
            case "canceled":
                return "Canceled"
            case "returned":
                return "Returned"
            case "delivered":
                return "Delivered"
            case "received":
                return "Received"
            case "on_route":
                return "Audit/on route"
            case "exemption":
                return "Received-as-X"
            default:
                return ""
        }
    }

    return (
        <Typography color="inherit" className={classes.root} align="center" >
            {
                status
            }
        </Typography>
    )
}

const RegisterProductStatus = ({ itemStatus, itemData }) => {

    const [status, setStatus] = React.useState();
    const [data, setData] = React.useState()

    React.useEffect(() => {
        setStatus(null)
    }, [])

    React.useEffect(() => {
        setStatus(getStatus(itemStatus))
    }, [itemStatus])

    React.useEffect(() => {
        setData(itemData)
    }, [itemData])

    const getStatus = (status) => {
        if (status == null || status === 'undefined') {
            return "undefined"
        }
        return status
    }

    const onStatusChange = async ({ target }) => {
        const { value } = target
        setStatus(null)
        const updateResult = await updateRegisterData({ register_id: data.warehouse_id, product_status: value })

        if (updateResult.status) {
            setStatus(value)
        } else {
            setTimeout(() => {
                setStatus(data.product_status)
            }, 500);
        }
    }

    if (!status || !data) {
        return (
            <CircularProgress />
        )
    }

    const returnOptions = [
        { text: "Undefined", value: "undefined" },
        { text: "Factory Fresh", value: "factory_fresh" },
        { text: "Open Never Used", value: "open_never_used" },
        { text: "Open Used", value: "open_used" },
        { text: "Damaged", value: "damaged" },
        { text: "Box Damage", value: "box_damage" },
    ]

    return (
        <FormControl fullWidth variant="outlined" size="small">
            <Select native name="status" onChange={onStatusChange}  >
                {
                    returnOptions.map((item,index) => (
                        <option
                            key={index}
                            style={status === item.value ? { color: "black" } : { color: "grey" }}
                            selected={status === item.value} value={item.value}>{item.text}</option>
                    ))
                }
            </Select>
        </FormControl>
    )

}

const RegisterItem = ({ itemData, handleTrigger, onDelete }) => {

    const { location } = useParams()

    const context = useContext(AuthContext)
    const [dialog, setDialog] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    /**Menu control */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const [_item, setItem] = React.useState()
    React.useEffect(() => {
        setItem(itemData)
    }, [itemData])

    /* const deleteRegister = async () => {
        if (_item) {
            setLoading(true)
            const result = await deleteWarehouseRegister(_item.warehouse_id);
            if (!result) {
                alert("Some was wrong")
            } else {
                if (onDelete) onDelete()
            }
            setLoading(false)
            setDialog(false)
        }
    } */
    if (!_item) {
        return <LinearProgress />
    }

    return (
        <React.Fragment>

            <DialogMessage
                type="warning"
                open={dialog}
                loading={loading}
                title="Delete selected register?"
                message="This action can not be undone, Are you sure to delete selected register?"
                positiveText="Proceed"
                //positiveAction={deleteRegister}
                negativeText="Back"
                onClose={() => setDialog(false)}
                negativeAction={() => setDialog(false)}
            />

            <Hidden only={['xs', 'sm', 'md']} >
                <TableRow>
                    <TableCell padding="none" ><Typography align="center" variant="body1" >{_item?.shipment_no}</Typography></TableCell>
                    <TableCell  ><Typography align="center" variant="body1" >{`${_item?.first_name} ${_item?.last_name || ""} `}</Typography></TableCell>
                    <TableCell padding="none" ><Typography align="center" variant="body1" >{_item?.description}</Typography></TableCell>
                    <TableCell  ><Typography align="center" variant="body1" >{_item?.serial_number || "Not Set"}</Typography></TableCell>
                    <TableCell padding="none" ><Typography align="center" variant="body1" >{_item?.location_name || "Not set"}</Typography></TableCell>
                    <TableCell style={{ color: "#fff" }} ><RegisterStatus itemStatus={_item?.status} /></TableCell>
                    <TableCell padding="none" ><RegisterProductStatus itemData={_item} itemStatus={_item?.product_status} /></TableCell>
                    <TableCell  ><Typography align="center" variant="body1" >{moment(_item?.created).format('DD/MM/YYYY HH:MM')}</Typography></TableCell>
                    <TableCell padding="none" ><Typography align="center" variant="body1" >{_item?.aging}</Typography></TableCell>
                    <TableCell >

                        <div>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                Options
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => handleTrigger({ data: _item, trigger: trigger_types.history })}>History</MenuItem>
                                {
                                    context.type == 7 &&
                                    <MenuItem onClick={() => handleTrigger({ data: _item, trigger: trigger_types.edit })}>Edit</MenuItem>
                                }
                                {
                                    context.type == 7 &&
                                    <MenuItem style={{ color: "red" }} onClick={() => setDialog(true)}>Delete</MenuItem>
                                }
                                {
                                    (context.type == 6 && location) &&
                                    <MenuItem onClick={() => handleTrigger({ data: _item, trigger: trigger_types.edit_location })}>Add location</MenuItem>
                                }
                            </Menu>
                        </div>
                    </TableCell>
                </TableRow>
            </Hidden>

        </React.Fragment>
    )


    /*     */


    /* 

    return (
        <React.Fragment>
            <DialogMessage
                type="warning"
                open={dialog}
                loading={loading}
                title="Delete selected register?"
                message="This action can not be undone, Are you sure to delete selected register?"
                positiveText="Proceed"
                positiveAction={deleteRegister}
                negativeText="Back"
                onClose={() => setDialog(false)}
                negativeAction={() => setDialog(false)}
            />
            <Hidden only={['xl', 'lg']} >
                <ListItem disableGutters divider >
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end" >
                                <MenuOptions selected="1" options={[
                                    { key: "history", label: "History", onClick: () => handleTrigger({ data: _item, trigger: trigger_types.history }) }
                                ]} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}  ><Typography variant="body1" >{`Order no ${_item?.shipment_no}`}</Typography></Grid>
                        <Grid item xs={12} md={6} ><Typography noWrap variant="body1" >{`Client: ${_item?.first_name} ${_item?.last_name || ""} `}</Typography></Grid>
                        <Grid item xs={12} md={6}  ><Typography variant="body1" >{`Product: ${_item?.description}`}</Typography></Grid>
                        <Grid item xs={12} md={6}  ><Typography variant="body1" >{`Serial no: ${_item?.serial_number || "Not Set"}`}</Typography></Grid>
                        <Grid item xs={12} md={6}  ><Typography variant="body1" >{`Location: ${_item?.location_name || "Not set"}`}</Typography></Grid>
                        <Grid item xs={12} md={6}  ><Typography variant="body1" >{`First scan ${moment(_item?.created).format('DD/MM/YYYY HH:MM')}`}</Typography></Grid>
                        <Grid item xs={12} md={6} ><Typography variant="body1" >{`Aging: ${_item?.aging}`}</Typography></Grid>
                        <Grid item xs={12} md={6} style={{ color: "#fff" }} ><RegisterStatus status={_item?.status} /></Grid>

                    </Grid>
                </ListItem>
            </Hidden>
            
        </React.Fragment>
    ) */
}

export default RegisterItem