import { Box, Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

export const Billing = () => {
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} >
                <Box boxShadow={3} py={3} className="bg_primary">
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Typography variant="h4" className="text_white py-3" > Outstanding balance </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-around" alignItems="center" className="txt_blue-lite">
                        <div className="items-cen-cen">
                            <Typography variant="subtitle1" > Previous balance </Typography>
                            <Typography variant="subtitle1" > $ 0 </Typography>
                        </div>
                        <div className="items-cen-cen">
                            <Typography variant="subtitle1" > Month balance </Typography>
                            <Typography variant="subtitle1" > $ 0 </Typography>
                        </div>
                        <div className="items-cen-cen">
                            <Typography variant="subtitle1" > Total to pay </Typography>
                            <Typography variant="subtitle1" > $ 0 </Typography>
                        </div>
                    </Grid>
                </Box>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="h4" className="text_primary" > Sumaries </Typography>
                <div className="flex">
                    <Typography variant="body1" > Filter by &nbsp;&nbsp;</Typography>
                    <select>
                        <option value="day"> Day </option>
                        <option value="amount"> Amount </option>
                    </select>
                </div>
            </Grid>
            <Grid item xs={12} md={4} >
                <Box boxShadow={3} className="bg_white p-3">
                    <Typography variant="h5" className="text_primary" > Orders </Typography>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">Number of order</Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">-</Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">-</Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} md={4} >
                <Box boxShadow={3} className="bg_white p-3">
                    <Typography variant="h5" className="text_primary" > Services </Typography>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">Same day</Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">Basic</Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">Rush</Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} md={4} >
                <Box boxShadow={3} className="bg_white p-3">
                    <Typography variant="h5" className="text_primary" > Charges </Typography>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">From $0 to $250</Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">From $251 to $500</Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label">From $500 </Typography>
                        <Typography variant='subtitle1' className="customer__list-text"> - </Typography>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} md={4} >
                <Typography variant="h4" className="text_primary" > Order charges </Typography>
            </Grid>
            <Grid item xs={12}  >
                <Box boxShadow={3} className="bg_white p-3" >
                    <Grid container className="customer__hr customer__list-label">
                        <Grid item xs={2} > 
                            <span> Order number </span>
                        </Grid>
                        <Grid item xs={2} > 
                            <span> Charge </span>
                        </Grid>
                    </Grid>

                    <Grid container className="customer__hr customer__list-text my-2">
                        <Grid item xs={2} > 
                            <span> Order number </span>
                        </Grid>
                        <Grid item xs={2} > 
                            <span> Charge </span>
                        </Grid>
                        <Grid item xs={8} className="text-right" > 
                            <Link to="/customer/history" className="No_Decoration" >
                                <Button  variant="outlined" className="Go_btn-primary w-150px" > DETAILS </Button> 
                            </Link>
                        </Grid>
                    </Grid>
                </Box> 
            </Grid>
        </Grid>    
    )
}
