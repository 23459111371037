import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import video_1 from './../../Assets/video/Golo_Login.mp4';
import video_2 from './../../Assets/video/Golo_Circlecheck.mp4';

export const VideoDialog = ({ open, setOpen, active }) => {
    
    const handleClose = () => setOpen(false);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth='md'
            onClose={ () => setOpen(false) }
        >
            <DialogTitle className="text-primary" > { active.title } </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <video src={ active.active === 1 ? video_1 : video_2 } controls width="600px" />
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="outlined" 
                    disableElevation 
                    onClick={ () => setOpen(false) }
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    )
}
