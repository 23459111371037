import { APIGO } from '../../constants/API';


export const getReceipts = (page, per_page,query) => new Promise(async (resolve, reject) => {
    try {

        const path = query 
        ? `/driver/gas/?page=${page}&per_page=${per_page}&query=${query}`
        : `/driver/gas/?page=${page}&per_page=${per_page}`

        const result = await APIGO.get(path)

        if(result.data.status){
            resolve(result.data)
        }else{
            resolve(false)
        }

    } catch (error) {
        console.warn(error.response)
        resolve(false)
    }
})

export const getReceiptsReport = (page, per_page,query) => new Promise(async (resolve, reject) => {
    try {

        const path = query 
        ? `/driver/gas/report?&query=${query}`
        : `/driver/gas/report?`

        const result = await APIGO.get(path)

        if(result.data.status){
            resolve(result.data)
        }else{
            resolve(false)
        }

    } catch (error) {
        console.warn(error.response)
        resolve(false)
    }
})