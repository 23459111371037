import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { getCustomerService } from '../../../Components/Functions/LabelPdf/LabelFunctions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';        
import Modal from '@material-ui/core/Modal';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
    table: {
        minWidth: "100%",
    },
    tableHead:{
        padding:"10px"
    }
});

export const Step5 = ({ dataDelivery, dataPackage, formDataBulk, setFormDataBulk, backStep, saveOrder, saveOrderBulk }) => {

    const classes = useStyles();
    const u_id = localStorage.getItem('u_id')
    const [service, setService] = useState([]);
    const [isBulk, setIsBulk] = useState();
    const [bulkData, setBulkData] = useState([]);
    const [rows, setRows] = useState([])
    const [open, setOpen] = useState(false);
    const [rowsPosition, setRowsposition] = useState(1)

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "rgb(206, 206, 206)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            padding:"10px"
        },
        body: {
            fontSize: 14,
            
        },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    }))(TableRow);

    function createData(pickUpName, pickUpAddress, deliveryName, deliveryAddress, pickUpDate, packageNumber, packageType, totalWeight) {
        return { pickUpName, pickUpAddress, deliveryName, deliveryAddress, pickUpDate, packageNumber, packageType, totalWeight};
    }
    const rowsMap = () =>{
        let rowsLocal = []
        formDataBulk.data.forEach(it => 
            rowsLocal.push( createData(it.pickUpName, it.pickUpLocation, it.deliveryName, it.deliveryLocation, it.pickUpDate, it.packageNumber, it.dimensionsType, it.packageWeight) )
        )
        setRows(rowsLocal)
    }
    const handleOpen = (key, isSaved) => {
        if ( isSaved ) {
            // other page
        }
        else{
            setOpen(true);
            setRowsposition(key);
        }
    };
    const handleClose = () => setOpen(false);

    const GetServices = async () => {
        setIsBulk(formDataBulk.isBulk)
        setBulkData(formDataBulk.data)
        const resp = await getCustomerService(u_id)
        if (resp.status) {
            setService(resp.results)
        } else {
            setService(false)
        }
    }

    useEffect(() => { 
        GetServices() 
        rowsMap();
        
    }, [])

    return ( 
        <Grid id="go_gridContainer_step5" container className="text_grey">
            { isBulk  ?
                <>
                    <Grid id="go_summaryBulkTitle" item md={12} >
                        <Box my={2} >
                            <Typography variant="h5" className="text-center"> Summary </Typography> 
                            <Typography className="text-center">Please verify that the information of your packages is correct</Typography>
                        </Box>
                    </Grid>
                    
                    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Pick Up Name</TableCell>
            <TableCell align="right">Pick Up Address</TableCell>
            <TableCell align="right">Delivery Name</TableCell>
            <TableCell align="right">Delivery Address</TableCell>
            <TableCell align="right">Pick Up Date</TableCell>
            <TableCell align="right">Package Number</TableCell>
            
            <TableCell align="right">Total Weight</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row, key) => (
                                <StyledTableRow id="go_bulkTableRow" onClick={e => handleOpen(key, row.isSaved )} key={key}>
                                    <StyledTableCell component="th" scope="row"> {row.pickUpName} </StyledTableCell>
                                    <StyledTableCell align="right">{row.pickUpAddress}</StyledTableCell>
                                    <StyledTableCell align="right">{row.deliveryName}</StyledTableCell>
                                    <StyledTableCell align="right">{row.deliveryAddress}</StyledTableCell>
                                    <StyledTableCell align="right">{row.pickUpDate}</StyledTableCell>
                                    <StyledTableCell align="right">{row.packageNumber}</StyledTableCell>
                                    <StyledTableCell align="right">{row.totalWeight}</StyledTableCell>
                                </StyledTableRow>
                            ))}
        </TableBody>
      </Table>
    </TableContainer>
                    {/*<TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead className={classes.tableHead}>
                            <TableRow>
                                <StyledTableCell>Pick Up Name</StyledTableCell>
                                <StyledTableCell align="right">Pick Up Address</StyledTableCell>
                                <StyledTableCell align="right">Delivery Name</StyledTableCell>
                                <StyledTableCell align="right">Delivery Address</StyledTableCell>
                                <StyledTableCell align="right">Pick Up Date</StyledTableCell>
                                <StyledTableCell align="right">Package Number</StyledTableCell>
                                <StyledTableCell align="right">Package Type</StyledTableCell>
                                <StyledTableCell align="right">Total Weight</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row, key) => (
                                <StyledTableRow id="go_bulkTableRow" onClick={e => handleOpen(key, row.isSaved )} key={key}>
                                    <StyledTableCell component="th" scope="row"> {row.pickUpName} </StyledTableCell>
                                    <StyledTableCell align="right">{row.pickUpAddress}</StyledTableCell>
                                    <StyledTableCell align="right">{row.deliveryName}</StyledTableCell>
                                    <StyledTableCell align="right">{row.deliveryAddress}</StyledTableCell>
                                    <StyledTableCell align="right">{row.pickUpDate}</StyledTableCell>
                                    <StyledTableCell align="right">{row.packageNumber}</StyledTableCell>
                                    <StyledTableCell align="right">{row.packageType}</StyledTableCell>
                                    <StyledTableCell align="right">{row.totalWeight}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>*/}
                    
                    <Grid id="go_gridContainer_stepper_sumary_buttons" container direction="row" justify="center" alignItems="center" className="my-2" >
                        <Button 
                            variant="outlined" 
                            className="Go_btn-primary w-150px"
                            onClick={ saveOrderBulk }
                        > 
                            Confirm 
                        </Button> 
                    </Grid>
                    
                    <Modal open={open}onClose={handleClose} >
                        <div className="go_bulkModal">                            
                            <Grid container >
                                <Grid item xs={12} md={6} className="go_bulkModal_pickUpData">
                                    <h1>Pick Up</h1>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Pick up name</b></Typography>
                                        <Typography variant='subtitle1' className="customer__list-text"> {rows[rowsPosition].pickUpName} </Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Pick up address</b></Typography>
                                        <Typography variant='subtitle1' className="customer__list-text"> {rows[rowsPosition].pickUpAddress} </Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Postal</b></Typography>
                                        <Typography variant='subtitle1' className="customer__list-text"> {formDataBulk.data[rowsPosition].pickUpPostal } </Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Province</b></Typography>
                                        <Typography variant='subtitle1' className="customer__list-text"> {formDataBulk.data[rowsPosition].pickUpProvince } </Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-label"><b>City</b></Typography>
                                        <Typography variant='subtitle1' className="customer__list-text"> {formDataBulk.data[rowsPosition].pickUpCity } </Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Country</b></Typography>
                                        <Typography variant='subtitle1' className="customer__list-text"> {formDataBulk.data[rowsPosition].pickUpCountry } </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} className="go_bulkModal_deliveryData">
                                    <h1>Delivery</h1>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-text"> {rows[rowsPosition].deliveryName} </Typography>
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Delivery name</b></Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-text"> {rows[rowsPosition].deliveryAddress} </Typography>
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Delivery address</b></Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-text"> {formDataBulk.data[rowsPosition].deliveryPostal } </Typography>
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Postal</b></Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-text"> {formDataBulk.data[rowsPosition].deliveryProvince } </Typography>
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Province</b></Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-text"> {formDataBulk.data[rowsPosition].deliveryCity } </Typography>
                                        <Typography variant='subtitle1' className="customer__list-label"><b>City</b></Typography>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                                        <Typography variant='subtitle1' className="customer__list-text"> {formDataBulk.data[rowsPosition].deliveryCountry } </Typography>
                                        <Typography variant='subtitle1' className="customer__list-label"><b>Country</b></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="go_bulkModal_packageData">
                                    <TableContainer  component={Paper}>
                                        <Table  className={classes.table} aria-label="customized table">
                                            <TableHead  className={classes.tableHead}>
                                                <TableRow >
                                                    <StyledTableCell id="headPrueba">Package number</StyledTableCell>
                                                    <StyledTableCell id="headPrueba" align="right">Pick Up Date</StyledTableCell>
                                                    <StyledTableCell id="headPrueba" align="right">Package Type</StyledTableCell>
                                                    <StyledTableCell id="headPrueba" align="right">Total Weight</StyledTableCell>
                                                    <StyledTableCell id="headPrueba" align="right">Comments</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <StyledTableRow  key={rows[rowsPosition].packageNumber}>
                                                    <StyledTableCell component="th" >
                                                        {rows[rowsPosition].packageNumber}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">{rows[rowsPosition].pickUpDate}</StyledTableCell>
                                                    <StyledTableCell align="right">{rows[rowsPosition].packageType}</StyledTableCell>
                                                    <StyledTableCell align="right">{rows[rowsPosition].totalWeight}</StyledTableCell>
                                                    <StyledTableCell align="right">{formDataBulk.data[rowsPosition].comments }</StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </div>
                    </Modal>
                </>
                :
                <>
                    <Grid item md={12} >
                        <Box my={2} >
                            <Typography variant="h5" className="text-center"> Summary </Typography> 
                        </Box>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Deliver to</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text" > { dataDelivery.deliveryData.name } </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Address</b></Typography>
                        <Typography id="go_sumary_address" variant='subtitle1' className="customer__list-text" > 
                            { dataDelivery.deliveryData.address },
                            { dataDelivery.deliveryData.num },
                            { dataDelivery.deliveryData.postal },
                            { dataDelivery.deliveryData.city },
                            { dataDelivery.deliveryData.province },
                            { dataDelivery.deliveryData.country }
                        </Typography>
                        <Typography id="go_sumary_address_movil" variant='subtitle1' className="customer__list-text" > 
                            <p>{ dataDelivery.deliveryData.address }</p>
                            <p> { dataDelivery.deliveryData.num },</p>
                            <p>{ dataDelivery.deliveryData.postal },</p>
                            <p>{ dataDelivery.deliveryData.city },</p>
                            <p>{ dataDelivery.deliveryData.province },</p>
                            <p>{ dataDelivery.deliveryData.country }</p>
                        </Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                        <Typography variant='subtitle1' className="customer__list-label"><b>Phone number</b></Typography>
                        <Typography variant='subtitle1' className="customer__list-text" > { dataDelivery.deliveryData.phone } </Typography>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="space-between"className="py-3" >
                        <table style={{width: '100%', border: '1px solid #CECECE', borderCollapse: 'collapse', margin: '15px, 0'}}>
                            <thead>
                                <tr className="table__header" style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                                    <th id="go_tablePackages_th" style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%'}} >
                                        Number of packages
                                    </th>
                                    <th id="go_tablePackages_th"  style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%'}} >
                                        Weight per package
                                    </th>
                                    <th id="go_tablePackages_th"  style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '60%', minWidth: '60%'}} >
                                        Dimensions per package (L * W * H)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { dataPackage.package.map( row => 
                                    <tr style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                                        { console.log( row ) }
                                        <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse',  maxWidth: '20%',  minWidth: '20%', padding: '5px', textAlign: 'center' }} >
                                            { row.package_num }
                                        </td>
                                        <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', padding: '5px', textAlign: 'center' }} >
                                            { row.endWeight } { dataPackage.weightType ? 'KG' : 'LB' }
                                        </td>
                                        <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '60%', minWidth: '60%', padding: '5px', textAlign: 'center' }} >   
                                            { row.dimensionsL } * { row.dimensionsW } * { row.dimensionsH } { dataPackage.dimensionsType ? 'cm' : 'in' }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={6} className="px-2" >
                        <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                            <Typography variant='subtitle1' className="customer__list-label"><b>Package</b></Typography>
                            <Typography variant='subtitle1' className="customer__list-text"> { dataPackage.packageNumber } </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className="px-2" >
                        <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                            <Typography variant='subtitle1' className="customer__list-label"><b>Total Weight</b></Typography>
                            <Typography variant='subtitle1' className="customer__list-text"> { dataPackage.packageWeight } { dataPackage.weightType ? 'KG' : 'LB' } </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className="px-2" >
                        <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                            <Typography variant='subtitle1' className="customer__list-label"><b>Pick up date </b></Typography>
                            <Typography variant='subtitle1' className="customer__list-text"> { dataPackage.pickUpDate } </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className="px-2" >
                        <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                            <Typography variant='subtitle1' className="customer__list-label"><b>Ready time</b></Typography>
                            <Typography variant='subtitle1' className="customer__list-text"> { dataPackage.readyTime } </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className="px-2" >
                        <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                            <Typography variant='subtitle1' className="customer__list-label"><b>Service</b></Typography>
                            <Typography variant='subtitle1' className="customer__list-text"> 
                                {  service.map( i => {
                                    if ( dataPackage.service_id === i.service_id ) 
                                        return i.service
                                })} 
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className="px-2" >
                        <Grid container direction="row" justify="space-between" alignItems="center" className="customer__hr" >
                            <Typography variant='subtitle1' className="customer__list-label"><b>Insurance declared value</b></Typography>
                            <Typography variant='subtitle1' className="customer__list-text"> $ { !!dataPackage.isInsurance && !!dataPackage.insuranceValue ? dataPackage.insuranceValue : '-' } </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="my-2">
                        <Typography variant='subtitle1' className="customer__list-label"><b>Comments/Instructions</b></Typography>
                        <div className="customers_comment" >
                        { !!dataPackage.comments ? dataPackage.comments : 'N / A' } 
                        </div>
                    </Grid>
                <Grid id="go_gridContainer_stepper_sumary_buttons" container direction="row" justify="center" alignItems="center" className="my-2" >
                    <Button 
                        variant="outlined" 
                        disableElevation 
                        className="w-150px mr-2-r mb-2-r" 
                        onClick={ backStep }
                    > 
                        Back 
                    </Button>
                    <Button 
                        variant="outlined" 
                        className="Go_btn-primary w-150px"
                        onClick={ saveOrder }
                    > 
                        CONTINUE 
                    </Button> 
                </Grid>
                </>
            }
        </Grid>
    )
}
