import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
// Componentes
import ListElements from '../Components/Global/ListElements';
// IMG
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//API
import {
    APIGO,
    getStation
} from './../constants/API';
import { Paper } from '@material-ui/core';
import Dialog from '../Components/Global/Dialog';


const Vehicles = () => {

    const [data, setData] = useState([]);
    const [info, setInfo] = useState([]);

    const [stationsData, setStationsData] = useState([]);
    const [stations, setStations] = useState('');
    const [search, setSearch] = useState('');

    const [refresh, setRefresh] = useState(1);
    const [filter, setfilter] = useState(1);
    const [page, setPage] = useState(1);

    const [isData, setIsData] = useState(true);
    const [isOwned, setIsOwned] = useState(false);
    const [isRented, setIsRented] = useState(false);
    const [isLeased, setIsLeased] = useState(false);
    const [isReturn, setIsReturn] = useState(false);
    const [isStation, setIsStation] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [isStationAndStatus, setIsStationAndStatus] = useState(false);
    const [isSearchAndStatus, setIsSearchAndStatus] = useState(false);

    useEffect(() => {

        var config = {
            method: 'get',
            url: `/vehicles/?page=1&limit=20`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        }
        APIGO(config)
            .then(response => {
                if (response.data.status === 'error') {
                    // List for all vehicles
                    setData(false);
                }
                if (response.data.status === 'success') {
                    // List for all vehicles
                    setData(response.data.results);
                    setInfo(response.data.info)
                }
            })
            .catch(function (error) {
                console.log(error)
                setData(false);
            })

        var configStation = {
            method: 'get',
            url: getStation,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        }
        APIGO(configStation)
            .then(response => {
                setStationsData(response.data)
            })
            .catch(function (error) {
                console.log(error)
            })

    }, [refresh]);

    useEffect(() => {
        let config;
        if (isData) {
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        else if (isStationAndStatus) {
            // station and status
            let status;
            if (isOwned) {
                status = 1;
            }
            else if (isRented) {
                status = 2;
            }
            else if (isLeased) {
                status = 3;
            }
            else if (isReturn) {
                status = 4;
            }
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20&filter_by_status=${status}&filter_by_station=${stations}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        else if (isSearchAndStatus) {
            // Search and status
            let status;
            if (isOwned) {
                status = 1;
            }
            else if (isRented) {
                status = 2;
            }
            else if (isLeased) {
                status = 3;
            }
            else if (isReturn) {
                status = 4;
            }
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20&filter_by_status=${status}&search=${search}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        else if (isStation) {
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20&filter_by_station=${stations}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        else if (isOwned) {
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20&filter_by_status=1`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        else if (isRented) {
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20&filter_by_status=2`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        else if (isLeased) {
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20&filter_by_status=3`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        else if (isReturn) {
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20&filter_by_status=4`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        else if (isFilter) {
            config = {
                method: 'get',
                url: `/vehicles/?page=${page}&limit=20&search=${search}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
        }
        APIGO(config)
            .then(response => {
                if (response.data.status === 'error') {
                    // List for all vehicles
                    setData(false);
                }
                if (response.data.status === 'success') {
                    // List for all vehicles
                    setData(response.data.results);
                    setInfo(response.data.info)
                }
            })
            .catch(function (error) {
                console.log(error)
                setData(false);
            })
    }, [page, filter]);

    const filterbyOwned = () => {
        if (isFilter) {
            setIsOwned(true);
            setIsSearchAndStatus(true)
        }
        else if (isStation) {
            setIsOwned(true);
            setIsStationAndStatus(true)
        }
        else {
            setIsRented(false);
            setIsLeased(false);
            setIsData(false);
            setIsReturn(false);
            setIsOwned(true);
        }
        setfilter(filter + 1)
    }
    const filterbyRented = () => {
        if (isFilter) {
            setIsRented(true);
            setIsSearchAndStatus(true)
        }
        else if (isStation) {
            setIsRented(true);
            setIsStationAndStatus(true)
        }
        else {
            setIsOwned(false);
            setIsLeased(false);
            setIsData(false);
            setIsReturn(false);
            setIsRented(true);
        }
        setfilter(filter + 1)
    }
    const filterbyLeased = () => {
        if (isFilter) {
            setIsLeased(true);
            setIsSearchAndStatus(true)
        }
        else if (isStation) {
            setIsLeased(true);
            setIsStationAndStatus(true)
        }
        else {
            setIsOwned(false);
            setIsRented(false);
            setIsData(false);
            setIsReturn(false);
            setIsLeased(true);
        }
        setfilter(filter + 1)
    }
    const filterbyReturn = () => {
        if (isFilter) {
            setIsReturn(true);
            setIsSearchAndStatus(true)
        }
        else if (isStation) {
            setIsReturn(true);
            setIsStationAndStatus(true)
        }
        else {
            setIsOwned(false);
            setIsRented(false);
            setIsData(false);
            setIsLeased(false);
            setIsReturn(true);
        }
        setfilter(filter + 1)
    }
    const getAllData = () => {
        setIsData(true);
        setIsOwned(false);
        setIsRented(false);
        setIsLeased(false);
        setIsReturn(false);
        setIsStation(false);
        setIsSearchAndStatus(false);
        setIsStationAndStatus(false);
        setSearch('');
        setStations('');
        setfilter(filter + 1);
    }
    const filterByStation = (e) => {
        setStations(e);
        setIsStation(true);
        setIsData(false);
        setfilter(filter + 1);
    }
    const filterList = (e, search) => {
        e.preventDefault();
        if (search === '') {
            setIsData(true);
            setIsOwned(false);
            setIsRented(false);
            setIsLeased(false);
            setIsStation(false);
            setIsFilter(false);
            setStations('')
            setfilter(filter + 1);
        }
        else {
            setIsFilter(true);
            setIsData(false);
            setIsOwned(false);
            setIsRented(false);
            setIsLeased(false);
            setSearch(search)
            setfilter(filter + 1);
        }
    }
    const ActivePage = (e, value) => {
        setPage(value)
    }

    return (
        <Grid container >
            <Grid item xs={12} md={2}>
                <Typography variant="h4" gutterBottom className="Page_title">
                    Vehicles
                </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
                <Box mt={1} p={1}>
                    {localStorage.getItem('Type') === '1' &&
                        <Link to="/vehicles/new" className="No_Decoration" >
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >New Vehicle</Button>
                        </Link>
                    }
                    {localStorage.getItem('Type') === '2' &&
                        localStorage.getItem('PV_C') === '1' ?
                        <Link to="/vehicles/new" className="No_Decoration" >
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >New Vehicle</Button>
                        </Link>
                        : null
                    }
                </Box>
            </Grid>
            <Grid item xs={6} md={2}>
                {localStorage.getItem('Type') === '1' &&
                    <Box mt={1}>
                        <Select labelId="stationID" variant="outlined" displayEmpty className="" size="small" fullWidth value={stations} onChange={e => { filterByStation(e.target.value); }} >
                            <MenuItem value=""><em>All stations</em></MenuItem>
                            {
                                stationsData.map(item =>
                                    <MenuItem key={item.st_id} value={item.st_id} >{item.station_id}</MenuItem>
                                )
                            }
                        </Select>
                    </Box>
                }
            </Grid>
            <Grid item xs={12} md={6} >
                <Box p={1} ml={2} className="bg_white" boxShadow={3} >
                    <Grid container className="bg_white" >
                        <Grid item xs={4} >
                            <Box onClick={getAllData} className="pointer" >
                                <Typography variant="body2" gutterBottom className="text_grey" align="center">
                                    <b> TOTAL VEHICLES </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom className="text_primary" align="center" >
                                    <b> {info.count ? info.count : '0'} </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}  >
                            <Box onClick={filterbyOwned} className="pointer" >
                                <Typography variant="body2" gutterBottom className="text_grey" align="center" >
                                    <b> OWNED </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom className="text_primary" align="center" >
                                    <b> {info.count_owned ? info.count_owned : '0'} </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} >
                            <Box onClick={filterbyRented} className="pointer" >
                                <Typography variant="body2" gutterBottom className="text_grey" align="center" >
                                    <b> RENTED </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom className="text_primary" align="center" >
                                    <b>  {info.count_rented ? info.count_rented : '0'} </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} >
                            <Box onClick={filterbyLeased} className="pointer" >
                                <Typography variant="body2" gutterBottom className="text_grey" align="center" >
                                    <b> LEASED </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom className="text_primary" align="center" >
                                    <b>  {info.count_leased ? info.count_leased : '0'}   </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} >
                            <Box onClick={filterbyReturn} className="pointer" >
                                <Typography variant="body2" gutterBottom className="text_grey" align="center" >
                                    <b> RETURN </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom className="text_primary" align="center" >
                                    <b>  {info.count_return ? info.count_return : '0'}   </b>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {
                    data ?
                        <ListElements isVehicle={true} list={data} page_count={info.pages} activePage={ActivePage} filter={filterList} />
                        :
                        <Paper elevation={3}>
                            <Box p={5} >
                                <Typography variant="h6" className="text_primary" >
                                    There is no vehicles at station &nbsp;
                                    <span
                                        className="try_again"
                                        onClick={() => setRefresh(refresh + 1)}
                                    >
                                        Try again.
                                    </span>
                                </Typography>
                            </Box>
                        </Paper>
                }
            </Grid>

            {localStorage.getItem('Type') === '1' &&
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <Link to="/vehicles/csv">
                            <Button>EXPORT</Button>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/vehicles/import">
                            <Button>IMPORT</Button>
                        </Link>
                    </Grid>
                </Grid>
            }

        </Grid>
    )
}
export default Vehicles