import React, { useState } from 'react'
import { Button, Grid, Switch, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
const csvTemplate = 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2FGo%20Logistics%20Orders%20Template.csv?alt=media&token=1cbcf282-902c-41e1-91ff-1efaf01c8895'


export const Step1 = ({ formData, setFormData, formDataBulk, setFormDataBulk, nextStep, toBulk}) => {

    const [message, setMessage] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage(false)
        return nextStep()
    }

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [fileBulk, setfileBulk] = useState()

    const fillBulkMode=(list)=>{
        let bulkArrayLocal = [];
        let bulkObject={
            pickUpTitle: '',
            pickUpName: '',
            pickUpPhone: '',
            pickUpCity: '',
            pickUpCountry: '',
            pickUpLocation: '',
            pickUpPostal: '',
            pickUpProvince: '',

            deliveryTitle: '',
            deliveryName: '',
            deliveryPhone: '',
            deliveryCity: '',
            deliveryCountry: '',
            deliveryLocation: '',
            deliveryPostal: '',
            deliveryProvince: '',

            pickUpDate: '',
            service: '',
            comments: '',
            packageNumber: '',
            packageWeight: '',
            dimensionsType: '',
            package: [
                {
                    act: true,
                    package_num: '1',
                    weight: 0,
                    endWeight: 0,
                    dimensionsL: '',
                    dimensionsW: '',
                    dimensionsH: ''
                }
            ],
        }
        list.forEach(element => {
            bulkObject={
                pickUpTitle: element.pickupcontact,
                pickUpName: element.pickupcontact,
                pickUpPhone: element.pickupphone,
                pickUpCity: element.PickUpCity,
                pickUpCountry: element.PickUpCountry,
                pickUpLocation: element.PickUpAddress,
                pickUpPostal: element.PickUpPostal,
                pickUpProvince: element.PickUpProvince,
    
                deliveryTitle: element.deliverycontact,
                deliveryName: element.deliverycontact,
                deliveryPhone: element.deliveryphone,
                deliveryCity: element.deliverycity,
                deliveryCountry: element.deliverycountry,
                deliveryLocation: element.deliveryaddress,
                deliveryPostal: element.deliverypostal,
                deliveryProvince: element.deliveryprovince,
    
                pickUpDate: element.OrderDate,
                service: element.Service,
                comments: element.instructions,
                packageNumber: element.totalpackages,
                packageWeight: element.totalweight,
                dimensionsType: element.packagetype,
                package: [
                    {
                        act: true,
                        package_num: '1',
                        weight: 0,
                        endWeight: 0,
                        dimensionsL: '',
                        dimensionsW: '',
                        dimensionsH: ''
                    }
                ],
            }
            bulkArrayLocal.push(bulkObject)
            setFormDataBulk({...formDataBulk,
                isBulk:true,
                data:bulkArrayLocal
            
            }) 
        });
        return toBulk()
    }

    // process CSV data
    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const list = [];

        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                } 
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));
        setData(list);
        setColumns(columns);
        fillBulkMode(list);
    }
    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();

        let fileBulk = e.target.files[0];
        setfileBulk(fileBulk)
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    return (
        <form
            onSubmit={handleSubmit}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}
        >
            <Grid container className="text_grey">
                <Grid id="go_gridContainer_orderType" item md={12} >
                    <Grid  container direction="row" justify="center" alignItems="center" >
                        <Typography variant="h6" className="text_primary" >
                            Order type
                        </Typography>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" className="mb-3">
                        <label htmlFor="_orderType" className="customer__list-label" > Individual order <Switch checked={ formData.orderType } onChange={ e => setFormData({...formData, orderType: e.target.checked })} color="primary" id="_orderType"/>  Bulk order </label><br/>
                    </Grid>
                </Grid>
                {!!message && <Grid item md={12} className="mb-1" > <Alert severity={message.msgType} > {message.msg} </Alert> </Grid>}

                { formData.orderType ? 
                    <Grid className="header__details" container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} >
                            <Typography align="center">
                                <a href={ csvTemplate } target="_blank" rel="noopener noreferrer" className="customer_newPackage">
                                    Download package batch template
                                </a>
                            </Typography>
                            <Typography align="center" >Upload your csv file to parse...</Typography>
                        </Grid>
                        <Grid item xs={12} className="pointer"  >
                            <div className="input_file">
                                <label>
                                    {fileBulk ? fileBulk.name : "Select a document" }
                                    <input 
                                        id="csv_input" 
                                        type="file" 
                                        onChange={handleFileUpload}
                                    />
                                </label>
                            </div>
                            
                        </Grid>
                    </Grid>
                    :
                    <Grid id="go_gridContainer_buttons" container direction="row" justify="center" alignItems="center" >
                        <Link to="/" className="No_Decoration" >
                            <Button
                                variant="outlined"
                                disableElevation
                                className="w-150px mr-2-r"
                            >
                                CANCEL
                            </Button>
                        </Link>
                        <Button
                            type="submit"
                            variant="outlined"
                            disableElevation
                            className="w-150px mr-2-r Go_btn-primary w-150px"
                        >
                            CONTINUE
                        </Button>
                    </Grid>
                }
            </Grid>
        </form>
    )
}
