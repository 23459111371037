import React, { useState, useEffect, Fragment } from "react";

// Componentes
import ListElements from "../../Components/Global/ListElements";
// IMG
//MaterialUI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
//API
import { APIGO } from "./../../constants/API";
import { Paper } from "@material-ui/core";
import CustomersAdd from "./CustomersAdd";

const Customers = () => {
  const [showCreate, setShowCreate] = useState(false);

  const [data, setData] = useState([]);
  const [dataAux, setdataAux] = useState([])

  const [info, setInfo] = useState([]);

  const [refresh, setRefresh] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/users/customers/?page=${page}&limit=20`,
      headers: {
        "Access-Control-Request-Headers": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    APIGO(config)
      .then((response) => {
        if (response.data.status === "error") {
          // List for all vehicles
          setData(false);
        }
        if (response.data.status === "success") {
          setData(response.data.results);
          setInfo(response.data.info);
        }
      })
      .catch(function (error) {
        console.log(error);
        setData(false);
      });
  }, [refresh]);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/users/customers/?page=${page}&limit=20`,
      headers: {
        "Access-Control-Request-Headers": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    APIGO(config)
      .then((response) => {
        if (response.data.status === "error") {
          // List for all vehicles
          setData(false);
        }
        if (response.data.status === "success") {
          setData(response.data.results);
          setInfo(response.data.info);
        }
      })
      .catch(function (error) {
        console.log(error);
        setData(false);
      });
  }, [page]);

  const filterList = (e, search) => {
    e.preventDefault();
    if (search === "") {
        setdataAux([])
    } else {
        let filterData = data.filter(item=>(item.rdga === search)||(item.user_name === search));
        setdataAux(filterData);
    }
  };
  const ActivePage = (e, value) => {
    setPage(value);
  };

  const HandleCustomer = (customer) => {

    const { account, username,email, address, phone } = customer;
    
    const newCustomer = {
      address: address,
      avatar: "",
      deduction: null,
      drivers_abstract: null,
      email: email,
      full_name: username,
      licence: null,
      licence_exp: null,
      phone: phone,
      rdga: account,
      station: null,
      status: 1,
      type: 5,
      user_name: username,
    }

    setData([...data,newCustomer]);
  }

  return (
    <Fragment>
      <CustomersAdd open={showCreate} setOpen={setShowCreate} customer={HandleCustomer} />
      <Grid container>
        <Grid item xs={12} md={2}>
          <Typography variant="h4" gutterBottom className="Page_title">
            Customers
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Box mt={1} px={1}>
            <Button onClick={() => setShowCreate(!showCreate)} variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >New Customer</Button>
          </Box>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={12} md={6}>
          <Box p={1} ml={2} className="bg_white" boxShadow={3}>
            <Grid container className="bg_white">
              <Grid item xs={6} md={6}>
                <Box className="pointer">
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="text_grey"
                    align="center"
                  >
                    <b> TOTAL CUSTOMERS </b>
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="text_primary"
                    align="center"
                  >
                    <b> {info.count} </b>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {data.length > 0 ? (
            <>
              {dataAux.length <= 0 && (
                <ListElements
                  isCustomer={true}
                  page_count={info.pages}
                  activePage={ActivePage}
                  list={data}
                  filter={filterList}
                />
              )}
              {dataAux.length>0 && (
                <ListElements
                  isCustomer={true}
                  page_count={info.pages}
                  activePage={ActivePage}
                  list={dataAux}
                  filter={filterList}
                />
              )}
            </>
          ) : (
              <Paper elevation={3}>
                <Box p={5}>
                  <Typography variant="h6" className="text_primary">
                    There is no customers information &nbsp;
                <span
                      className="try_again"
                      onClick={() => setRefresh(refresh + 1)}
                    >
                      Try again.
                </span>
                  </Typography>
                </Box>
              </Paper>
            )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Customers;
