import React from 'react'
import { Link } from 'react-router-dom';
// IMG
import Portada from './../../Assets/Img/Portada_management.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const HomeSection = ( { portada, title, link, text }) => {
    return(
        <Box m={1} >
            <Link to={ link } className="No_Decoration" >
                <Box>
                    <img src={ portada } alt="" className="Home_Img" />
                </Box>
                <Box p={2} my={-1} className="Home_Content">
                    <Grid container direction="row" justify="space-between" alignItems="center" >
                        <Typography variant="h6" gutterBottom>
                            { title }
                        </Typography>
                        <Button variant="contained" disableElevation className="Go_btn-primary" >
                            { text ? text : 'View more' } 
                        </Button>
                    </Grid>
                </Box>
            </Link>
        </Box>
    )
}
export default HomeSection