import React,  { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from "moment";
// Componentes
import ModalShow from '../Components/Global/Modal';
// IMG
import user from './../Assets/Img/Profile_avatar.png';
import img_add from './../Assets/Img/Icon_add.png'
import edit from './../Assets/Img/Icon_edit.png';
import file_placeholder from './../Assets/Img/file_placeholder.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

//API
import * as firebase from 'firebase';
import { 
    permissionVehicle, 
    permissionDrivers, 
    permissionAccidents, 
    permissionReports, 
    permissionHistory,
    permissionSettings,
    CreateManager, 
    getStation, 
    APIGO 
} from '../constants/API'; 
import { Alert } from '@material-ui/lab';
import { OutlinedInput } from '@material-ui/core';
// import 'firebase/storage';
// import firebasesConfig from './../constants/credentials'; Manager03

const ManagerAdd = () => {

    const history = useHistory();
    const [ stationsData, setStationsData ] = useState([]);
    const [ stations, setStations ] = useState('');
    const [ loading, setLoading ] = useState(false);
    // User information
    const [ fullname, setFullname] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ goid, setGoid ] = useState('');
    const [ licenseExp, setLicenseExp ] = useState('');
    const [ pswrd, setPswrd ] = useState('');
    const [showPass, setShowPass] = useState(false)
    // Documents
    const [ docProfile, setDocProfile] = useState('');
    // Permissions
    // Vehicle
    const [ PV_cread, setPV_cread] = useState( false );
    const [ PV_read, setPV_read] = useState( false );
    const [ PV_edit, setPV_edit] = useState( false );
    const [ PV_deleted, setPV_deleted] = useState( false );
    // Driver
    const [ PD_cread, setPD_cread] = useState( false );
    const [ PD_read, setPD_read] = useState( false );
    const [ PD_edit, setPD_edit] = useState( false );
    const [ PD_deleted, setPD_deleted] = useState( false );
    // Accidents
    const [ PA_cread, setPA_cread] = useState( false );
    const [ PA_read, setPA_read] = useState( false );
    const [ PA_edit, setPA_edit] = useState( false );
    const [ PA_deleted, setPA_deleted] = useState( false );
    // History
    const [ PH_cread, setPH_cread] = useState( false );
    const [ PH_read, setPH_read] = useState( false );
    const [ PH_edit, setPH_edit] = useState( false );
    const [ PH_deleted, setPH_deleted] = useState( false );
    // Reports
    const [ PR_cread, setPR_cread] = useState( false );
    const [ PR_read, setPR_read] = useState( false );
    const [ PR_edit, setPR_edit] = useState( false );
    const [ PR_deleted, setPR_deleted] = useState( false );
    // Settings
    const [ PS_cread, setPS_cread] = useState( false );
    const [ PS_read, setPS_read] = useState( false );
    const [ PS_edit, setPS_edit] = useState( false );
    const [ PS_deleted, setPS_deleted] = useState( false );

    // Error contro9l
    const [ isError, setIsErrors] = useState([{
        iserror: false,
        fullname: false,
        phone: false,
        email: false,
        address: false,
        goid: false,
        stations: false,
        pswrd: false,
        avatar: false,
    }]);
    const [ error, setError] = useState('');
    const [ msg, setMsg] = useState('')
    // Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");
    const [inputsprops, setInputprops] = React.useState({
        amount: '',
        password: false,
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);
    
    const saveElement = async ( e, status ) => {
        const profileImg = docProfile ? document.getElementById('ImgProfile').src : 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2FProfile_avatar.png?alt=media&token=0c02f76e-981b-42a2-9395-74e979f02520' ;
        if ( status === true ){
            setIsConfirm( false );
            
            let config = {
                method: 'post',
                url: CreateManager,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                },
                data: {
                    rdga: goid,
                    user_name: email,
                    email: email,
                    station: stations ,
                    password: pswrd,
                    phone: phone,
                    full_name: fullname,
                    address: address,
                    avatar: profileImg
                }
            }
            APIGO(config)
            .then(response =>{
                console.log(response );
                if ( response.data.status === "error" ) {
                    if(response.data['user_name']){
                        ModalClose()
                        setIsErrors({ userName: true})
                        setMsg(response.data['user_name'])
                        setModalmsg(response.data['user_name']);
                        setIsShow( true );
                        ModalOpen();
                    }
                    if(response.data.msg === 'The username is already taken'){
                        ModalClose()
                        setIsErrors({ userName: true})
                        setMsg('The username is already taken')
                        setModalmsg('The Email is already taken');
                        setIsShow( true );
                        ModalOpen();
                    }
                    if(response.data.msg === `The rdga ${goid} is already use`){
                        ModalClose()
                        setIsErrors({ goid: true})
                        setMsg('The rdga is already taken')
                        setModalmsg('The rdga is already taken');
                        setIsShow( true );
                        ModalOpen();
                    }
                    if(response.data.message === `The email ${email} is already taken`){
                        ModalClose()
                        setIsErrors({ email: true})
                        setMsg('The Email is already taken')
                        setModalmsg('The Email is already taken');
                        setIsShow( true );
                        ModalOpen();
                    }
                    if(response.data['password']){
                        ModalClose()
                        setIsErrors({ pswrd: true });
                        setMsg(response.data.password)
                        setModalmsg(response.data.password);
                        setIsShow( true );
                        ModalOpen();
                    }
                    if(response.data['deduction']){
                        ModalClose()
                        setIsErrors({ deduction: true });
                        setMsg(response.data.deduction)
                        setModalmsg(response.data.deduction);
                        setIsShow( true );
                        ModalOpen();
                    }
                    if(JSON.stringify(response.data.errors[0].password)){
                        ModalClose()
                        setIsErrors({ pswrd: true });
                        setMsg("The password must have uppercase, lowercase and numbers")
                        setModalmsg("The password must have uppercase, lowercase and numbers")
                        setIsShow( true );
                        ModalOpen();
                    }
                    if(JSON.stringify(response.data.msg[0].phone)){
                        ModalClose()
                        setIsErrors({ phone: true });
                        setMsg(response.data.msg[0].phone)
                        setModalmsg(response.data.msg[0].phone)
                        setIsShow( true );
                        ModalOpen();
                    }
                }
                if( response.data.status === "error_username" ){
                    ModalClose()
                    setIsErrors({ userName: true})
                    setIsShow( true );
                    setMsg( 'The username is already taken, try to type something different.' )
                    setModalmsg( 'The username is already taken, try to type something different.' )
                    ModalOpen();
                }
                if(response.data.message){
                    setGoid('')
                    setFullname('')
                    setAddress('')
                    setPhone('')
                    setEmail('')
                    setLicenseExp('')
                    setPswrd('')
                    ModalClose()
                    setModalmsg('Manager added successfully');
                    setModalLink('/manager');
                    Permissions()
                    setIsShow( true );
                    ModalOpen();
                }
            })
            .catch(function (error){
                console.log(error)
            }) 
        }
        else{
            ModalClose()
        }
        setIsConfirm( false );
    }
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const previwImg =  ( e, img ) =>{
        e.preventDefault();
        setLoading( true )
        var storage = firebase.storage().ref();
        var upload = storage.child(`manager/profile/Img${Date.now()}`);
        upload.put(img).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("ImgProfile")
                profileImg.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const handleForm = async e => { 
        e.preventDefault();
        // handleUploadFile(goid);
        if(fullname === ""){
            setIsErrors({ fullname: true, iserror: true });
        }
        else if(phone === ""){
            setIsErrors({ phone: true, iserror: true });
            setMsg('Enter a Phone please')
        }
        else if(email === ""){
            setIsErrors({ email: true, iserror: true });
            setMsg('Enter a email please')
        }
        else if(address === ""){
            setIsErrors({ address: true, iserror: true });
        }
        else if(goid === "" ){
            setIsErrors({ goid: true, iserror: true });
        }
        else if(stations === ""){
            setIsErrors({ stations: true, iserror: true });
            setMsg('Enter a date please')
        }
        else if(pswrd === ""){
            setIsErrors({ pswrd: true, iserror: true });
            setMsg('Enter a Password please')
        }
        // else if(docProfile === ""){
        //     setIsErrors({ avatar: true, iserror: true });
        // }
        else {
            setIsErrors({iserror: false});
            setMsg('')
            setIsConfirm( true );
            setIsShow( false )
            ModalOpen();
        }
    }
    const handleClickShowPassword = () => {
        setInputprops({ ...inputsprops, showPassword: !inputsprops.showPassword });
        setInputprops({...inputsprops, password: !inputsprops.password })
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const Permissions =  ( ) =>{
        let configPV = {
            method: 'put',
            url: `${permissionVehicle}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: goid,
                Create: PV_cread,
                Read: PV_read,
                Update: PV_edit,
                Delete: PV_deleted,
            }
        }
        APIGO(configPV)
        .then(response =>{
            console.log(response)
        })
        .catch( error => {
            console.log(error)
        }) 
        let configPD = {
            method: 'put',
            url: `${permissionDrivers}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: goid,
                Create: PD_cread,
                Read: PD_read,
                Update: PD_edit,
                Delete: PD_deleted,
            }
        }
        APIGO(configPD)
        .then(response =>{
            console.log(response)
        })
        .catch( error => {
            console.log(error)
        }) 
        let configPA = {
            method: 'put',
            url: `${permissionAccidents}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: goid,
                Create: PA_cread,
                Read: PA_read,
                Update: PA_edit,
                Delete: PA_deleted,
            }
        }
        APIGO(configPA)
        .then(response =>{
            console.log(response)
        })
        .catch( error => {
            console.log(error)
        }) 
        let configPH = {
            method: 'put',
            url: `${permissionHistory}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: goid,
                Create: PH_cread,
                Read: PH_read,
                Update: PH_edit,
                Delete: PH_deleted,
            }
        }
        APIGO(configPH)
        .then(response =>{
            console.log(response)
        })
        .catch( error => {
            console.log(error)
        }) 
        let configPR = {
            method: 'put',
            url: `${permissionReports}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: goid,
                Create: PR_cread,
                Read: PR_read,
                Update: PR_edit,
                Delete: PR_deleted,
            }
        }
        APIGO(configPR)
        .then(response =>{
            console.log(response)
        })
        .catch( error => {
            console.log(error)
        }) 
        let configPS = {
            method: 'put',
            url: `${permissionSettings}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: goid,
                Create: PS_cread,
                Read: PS_read,
                Update: PS_edit,
                Delete: PS_deleted,
            }
        }
        APIGO(configPS)
        .then(response =>{
            console.log(response)
        })
        .catch( error => {
            console.log(error)
        }) 
    }
    useEffect( () =>{
        var configStation = {
            method: 'get',
            url: getStation,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configStation)
        .then(response =>{
            setStationsData( response.data )
        })
        .catch(function (error){
            console.log(error)
        })
    }, [  ] );

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isConfirm ?
                            <ModalShow 
                            title="Are you sure you want to save a new Manager? "
                            confirmOperation={ true }
                            Operation={ saveElement }
                            /> : null
                    }    
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>

            <form id='myForm' onSubmit={e=> handleForm(e)} >
                <Grid container spacing={2} >
                    <Grid item xs={4} md={2} className="center_center">
                        <Link to="/manager" className="flex No_Decoration">
                            <ArrowBackIosIcon className="Page_title"/>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Go back
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={8} md={6} >
                        <Typography variant="h4" gutterBottom className="Page_title">
                            New Fleet Manager
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Link to="/manager" className="No_Decoration" >
                            <Button variant="outlined" disableElevation fullWidth className="" type="button" >CANCEL</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button 
                            fullWidth 
                            disableElevation 
                            variant="contained" 
                            className="Go_btn-primary" 
                            type={ loading ? "button" : "submit" } 
                        >
                            { loading ? "LOADING..." : "SAVE" }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                            <Grid container>
                                { isError.iserror && 
                                    <Grid item xs={12}>
                                        <Box p={2}> 
                                            <Alert severity="error"> Please complete all the files required. </Alert> 
                                        </Box>
                                    </Grid>
                                }  
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Box py={3}>
                                            <input type="file" id="ProfileImage" className="fileUpload" onChange={ e => {setDocProfile( ( e.target.files[0] ) ); previwImg(e, e.target.files[0] )  } }  />
                                            <label htmlFor="ProfileImage" className="labelFileImg">
                                            {
                                                docProfile ? 
                                                    <img alt="" className="previw_img rounded" id={'ImgProfile'} />
                                                :
                                                    <img src={user} alt="" className="profileImg rounded" />
                                            }
                                                <img src={ edit } alt="" className="" />
                                            </label><br/>
                                            {
                                                isError.avatar ?
                                                    <span className="text_error"><small> Enter an avatar please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="DriveLastName" className="labelText"><small>NAME</small></label>
                                        {
                                            isError.fullname ?
                                                <TextField id="DriveLastName" type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} error helperText="Enter a valid name please" /> 
                                            : 
                                                <TextField id="DriveLastName" type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="DriverPhone" className="labelText" ><small>PHONE NUMBER</small></label>
                                        {
                                            isError.phone ?
                                                <TextField id="DriverPhone" type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} error helperText={msg}/> 
                                            : 
                                                <TextField id="DriverPhone" type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="email" className="labelText"><small>EMAIL</small></label>
                                        {
                                            isError.email ?
                                                <TextField id="email" type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} error helperText={msg} /> 
                                            : 
                                                <TextField id="email" type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Box p={1}> 
                                        <label htmlFor="DriveAddress" className="labelText"><small> ADDRESS </small></label>
                                        {
                                            isError.address ?
                                                <TextField id="DriveAddress" type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} error helperText="Enter a valid Address please" /> 
                                            : 
                                                <TextField id="DriveAddress" type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="RDGA" className="labelText" ><small>RDGA</small></label>
                                        {
                                            isError.goid ?
                                                <TextField id="RDGA" type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} error helperText="Enter a valid id please" /> 
                                            : 
                                                <TextField id="RDGA" type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="licenseExp" className="labelText" ><small>STATION</small></label>
                                        <Select labelId="stationID" variant="outlined" displayEmpty className={ isError.stations && 'file-error' } fullWidth value={stations} onChange={e =>{ setStations(e.target.value); }} >
                                            <MenuItem value=""><em>All stations</em></MenuItem>
                                            { stationsData.map( item => 
                                                    <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                                )
                                            }
                                        </Select>
                                        { isError.stations ?
                                            <span className="text_error"><small> Enter a Station please </small></span> : null
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="DrivePswrd" className="labelText"><small>PASSWORD</small></label>
                                        {
                                            isError.pswrd ?
                                            <OutlinedInput
                                                id="DrivePswrd"
                                                type={showPass ? 'text' : 'password'}
                                                fullWidth
                                                value={ pswrd }
                                                onChange={e => setPswrd(e.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                endAdornment={
                                                    <InputAdornment 
                                                        position="end"
                                                        onClick={ () => setShowPass( !showPass )  }
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPass ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                labelWidth={0}
                                                helperText={msg}
                                                error 
                                            />
                                            :
                                            <OutlinedInput
                                                id="DrivePswrd"
                                                type={showPass ? 'text' : 'password'}
                                                fullWidth
                                                value={ pswrd }
                                                onChange={e => setPswrd(e.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                endAdornment={
                                                    <InputAdornment 
                                                        position="end"
                                                        onClick={ () => setShowPass( !showPass )  }
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPass ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                labelWidth={0}
                                            />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box p={1} pt={0}>
                                        <Typography variant="h6" gutterBottom className="Page_title">
                                            Permissions
                                        </Typography>
                                        <hr className="divider_blue" />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Vehicles
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PV_read"> Create </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PV_cread}
                                                        onChange={ e => setPV_cread( e.target.checked )}
                                                        color="primary"
                                                        id="PV_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PV_cread"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PV_read}
                                                        onChange={ e => setPV_read( e.target.checked )}
                                                        color="primary"
                                                        id="PV_cread"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PV_edit"> Edit </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PV_edit}
                                                        onChange={ e => setPV_edit( e.target.checked )}
                                                        color="primary"
                                                        id="PV_edit"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PV_deleted"> Delete </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PV_deleted}
                                                        onChange={ e => setPV_deleted( e.target.checked )}
                                                        color="primary"
                                                        id="PV_deleted"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Drivers
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PD_cread"> Create </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PD_cread}
                                                        onChange={ e => setPD_cread( e.target.checked )}
                                                        color="primary"
                                                        id="PD_cread"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PD_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PD_read}
                                                        onChange={ e => setPD_read( e.target.checked )}
                                                        color="primary"
                                                        id="PD_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PD_edit"> Edit </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PD_edit}
                                                        onChange={ e => setPD_edit( e.target.checked )}
                                                        color="primary"
                                                        id="PD_edit"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PD_deleted"> Delete </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PD_deleted}
                                                        onChange={ e => setPD_deleted( e.target.checked )}
                                                        color="primary"
                                                        id="PD_deleted"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Settings
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PS_cread"> Create </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PS_cread}
                                                        onChange={ e => setPS_cread( e.target.checked )}
                                                        color="primary"
                                                        id="PS_cread"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PS_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PS_read}
                                                        onChange={ e => setPS_read( e.target.checked )}
                                                        color="primary"
                                                        id="PS_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PS_edit"> Edit </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PS_edit}
                                                        onChange={ e => setPS_edit( e.target.checked )}
                                                        id="PS_edit"
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PS_deleted"> Delete </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PS_deleted}
                                                        onChange={ e => setPS_deleted( e.target.checked )}
                                                        color="primary"
                                                        id="PS_deleted"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Accidents
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PA_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PA_read}
                                                        onChange={ e => setPA_read( e.target.checked )}
                                                        color="primary"
                                                        id="PA_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PA_edit"> Edit </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PA_edit}
                                                        onChange={ e => setPA_edit( e.target.checked )}
                                                        color="primary"
                                                        id="PA_edit"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            History
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PH_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PH_read}
                                                        onChange={ e => setPH_read( e.target.checked )}
                                                        color="primary"
                                                        id="PH_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Reports
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PR_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PR_read}
                                                        onChange={ e => setPR_read( e.target.checked )}
                                                        color="primary"
                                                        id="PR_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid> */}
                                
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
export default ManagerAdd