import React from 'react'
import { Link } from 'react-router-dom';
//media
import _person from '../Assets/Img/person_go.png';
// Components
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import ListIcon from '@material-ui/icons/FormatListBulletedOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import { Box } from '@material-ui/core';

const DashboardCustomer = () => {
    
    const userName = localStorage.getItem('u_name')

    return (
        <Grid id="go_gridContainer_dashboardCustomer" container direction="row" justify="center" alignItems="center">
            <Grid id="go_gridContainerXs_dashboardCustomer"  item xs={10} >
                <Box boxShadow={3} >
                    <Grid container className="bg_primary " >
                        <Grid item xs={12} md={5} className="center_center">
                            <img src={ _person } alt="" className="w-80" />
                        </Grid>
                        <Grid item xs={12} md={7} className="items-cen-cen">
                            <Typography variant="h3" className="customer__tile-home text-center" >Welcome { userName }!</Typography><br/>
                            <Typography variant="h5" className="text_white text-center" > What are we doing today?</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="bg_white p-3" >
                        <Grid item xs={12} md={4} className="p-3 items-cen-cen"  >
                            <AddIcon className="text_primary fz-75" />
                            <Typography variant="h5" className="text_primary py-3" > New order </Typography>
                            <Link to="/customer/order" className="No_Decoration">
                                <Button  variant="outlined" className="Go_btn-primary w-150px" > CREATE </Button> 
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={4} className="p-3 items-cen-cen"  >
                            <ListIcon className="text_primary fz-75" />
                            <Typography variant="h5" className="text_primary py-3" > Order history </Typography>
                            <Link to="/customer/history" className="No_Decoration" >
                                <Button  variant="outlined" className="Go_btn-primary w-150px" > VIEW MORE </Button> 
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={4} className="p-3 items-cen-cen"  >
                            <DescriptionIcon className="text_primary fz-75" />
                            <Typography variant="h5" className="text_primary py-3" > Billing </Typography>
                            <Link to="#" className="No_Decoration" >
                                <Button  variant="outlined" className="Go_btn-primary w-150px" > VIEW MORE </Button> 
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>        
        </Grid>
    )
}
export default DashboardCustomer