import { APIGO } from "../../constants/API"



export const postLogin = (user, password) => new Promise(async (resolve, reject) => {
    try {
        const result = await APIGO.post("/login", {
            email: user,
            password: password
        })
        resolve(result.data)
    } catch (error) {
        resolve({

        })
    }
})

export const postRegister = (data) => new Promise(async (resolve, reject) => {
    try {
        const results = await APIGO.post("/warehouse/", data)
        resolve(results.data)
    } catch (error) {
        resolve(error.response)
    }
})

export const postRegisterBundle = (bundle) => new Promise(async (resolve, reject) => {
    try {
        const results = await APIGO.post("/warehouse/bundle",
            {
                data: bundle
            });

        console.log(results)
        resolve("")

    } catch (error) {
        console.log(error)
        resolve("")
    }
});

export const deleteWarehouseRegister = (id) => new Promise(async (resolve, reject) => {
    try {
        const results = await APIGO.delete(`/warehouse/?query=warehouse_id=${id}`)
        if (results.data.status) {
            resolve(results.data.result)
        } else {
            resolve(false)
        }
    } catch (error) {
        console.warn(error.response)
        resolve(false)
    }
})

export const updateRegister = (data) => new Promise(async (resolve) => {
    try {
        const results = await APIGO.put("/warehouse/", data)
        resolve(results.data)
    } catch (error) {
        console.warn(error.response)
        resolve({
            status: false,
            error
        })
    }
})

export const updateRegisterData = (data) => new Promise(async (resolve) => {
    try {
        const results = await APIGO.put("/warehouse/data/", data)
        console.log(results)
        resolve(results.data)
    } catch (error) {
        console.log(error.response.data)
        resolve({
            status: false,
            error
        })
    }
})

export const getRegisters = (id, user, page = 1, per_page = 10) => new Promise(async (resolve, reject) => {
    if (id) {
        try {
            const results = await APIGO.get(`/warehouse/?page=${page}&per_page=${per_page}&query=list_id = ${id} `)
            if (results.data.status) {
                resolve(results.data)
            } else {
                resolve(false)
            }
        } catch (error) {
            console.warn(error.response)
            resolve(false)
        }
    } else {
        try {
            const results = await APIGO.get(`/warehouse/?page=${page}&per_page=${per_page}`)
            if (results.data.status) {
                resolve(results.data)
            } else {
                resolve(false)
            }
        } catch (error) {
            console.warn(error.response)
            resolve(false)
        }
    }
})

export const searchInRegisters = (list_id, key, page = 1, per_page = 10, user_id) => new Promise(async (resolve, reject) => {
    if (list_id) {
        try {
            const results = await APIGO.get(`/warehouse/search?search=${key}&page=${page}&per_page=${per_page}&query=list_id=${list_id} `)
            if (results.data.status) {
                resolve(results.data)
            } else {
                resolve(false)
            }
        } catch (error) {
            console.warn(error.response)
            resolve(false)
        }
    } else {
        try {
            const results = await APIGO.get(`/warehouse/search?search=${key}&page=${page}&per_page=${per_page}`)
            if (results.data.status) {
                resolve(results.data)
            } else {
                resolve(false)
            }
        } catch (error) {
            console.warn(error.response)
            resolve(false)
        }
    }
})

export const filter = async (list_id, status, from, to, page, per_page) => {

    try {


        const results = await APIGO.get(`/warehouse/filter?page=${page}&per_page=${per_page}${list_id ? `&list_id=${list_id}` : ""}${status ? `&status=${status}` : ""}${from ? `&from=${from}` : ""}${to ? `&to=${to}` : ""}`);
        console.log(results);
        if (results.data.status) {
            return (results.data)
        } else {
            return (false)
        }


    } catch (error) {
        if (error.response) {
            console.warn(error.response);
        }
        return false;
    }

}

export const searchWithQuery = async (key, page = 1, per_page = 10, query) => {
    if (query) {
        try {
            const results = await APIGO.get(`/warehouse/search?search=${key}&page=${page}&per_page=${per_page}&query=${query} `)
            if (results.data.status) {
                return (results.data)
            } else {
                return (false)
            }
        } catch (error) {
            console.warn(error.response)
            return (false)
        }
    } else {
        try {
            const results = await APIGO.get(`/warehouse/search?search=${key}&page=${page}&per_page=${per_page}`)
            if (results.data.status) {
                return (results.data)
            } else {
                return (false)
            }
        } catch (error) {
            console.warn(error.response)
            return (false)
        }
    }
}

export const postWarehouseList = (user, list_name) => new Promise(async (resolve, reject) => {

    if (!user) {
        resolve({
            status: false,
            error: "empty user"
        })
    }
    const data = {
        user_id: user,
        name: list_name
    }
    try {
        const results = await APIGO.post("/warehouse/list/", data)
        if (results.data.status) {
            resolve({
                status: true,
                result: results.data.result
            })
        } else {
            resolve({
                status: false,
                error: results.data
            })
        }
    } catch (error) {
        console.warn(error.response)
        resolve({
            status: false,
            error: error.response
        })
    }
})

export const getWarehouseLists = (user, page, per_page) => new Promise(async (resolve, reject) => {
    try {
        const results = await APIGO.get(`/warehouse/list/?page=${page}&per_page=${per_page}`)
        //const results = await APIGO.get(`/warehouse/list/?page=${page}&per_page=${per_page}&query= 1= 1 order by created`)

        if (results.data.status) {
            resolve(results.data)
        } else {
            resolve(false)
        }
    } catch (error) {
        resolve(false)
    }
})

export const deleteWarehouseList = (list_id) => new Promise(async (resolve, reject) => {
    try {
        const results = await APIGO.delete(`/warehouse/list?list_id=${list_id}`)
        if (results.data.status) {
            const results_register = await APIGO.delete(`/warehouse/?query=list_id=${list_id}`)
            if (results_register) {
                resolve(results_register.data.result)
            } else {
                resolve(false)
            }
        } else {
            resolve(false)
        }
    } catch (error) {
        console.warn(error.response)
        resolve(false)
    }
})

export const getWarehouseList = (id) => new Promise(async (resolve, reject) => {
    if (id) {
        try {
            const results = await APIGO.get(`/warehouse/list/?query=id=${id}`)
            if (results.data.status) {
                resolve(results.data.result[0])
            } else {
                resolve(false)
            }
        } catch (error) {
            console.warn(error.response)
            resolve(false)
        }
    } else {
        try {
            const results = await APIGO.get(`/warehouse/list/`)
            if (results.data.status) {
                resolve(results.data.result[0])
            } else {
                resolve(false)
            }
        } catch (error) {
            console.warn(error.response)
            resolve(false)
        }
    }
})

export const getWarehouseHistory = (id) => new Promise(async (resolve, reject) => {
    try {
        const results = await APIGO.get(`/warehouse/history/?query=register_id=${id}`)
        if (results.data.status) {
            resolve(results.data.result)
        } else {
            resolve(false)
        }
    } catch (error) {
        console.warn(error.response)
        resolve(false)
    }
})

export const postComment = (data) => new Promise(async (resolve) => {
    try {
        const result = await APIGO.post("/warehouse/history/comment", data)
        if (result.data.status) {
            resolve(true)
        }
    } catch (error) {
        resolve(false)
    }
})

export const postEdiData = (data) => new Promise(async (resolve) => {
    try {
        const result = await APIGO.post("/api/v1/edi/210", data)
        if (result.data.status) {
            resolve(true)
        }
    } catch (error) {
        resolve(false)
    }
})

/**
 * 
 * @param {File} data File or Readable Buffer stream
 * @returns 
 */
export const postEdiInvoices = (data) => new Promise(async (resolve, reject) => {
    try {
        let formData = new FormData()
        formData.append("invoice", data)
        const result = await APIGO.post("/api/v1/edi/invoices", formData)
        resolve({
            status: true,
            ...result.data
        })
    } catch (error) {
        console.warn(error);
        resolve({
            status: false
        })
    }
})


/**
 * 
 * @param {number} page Page
 * @param {number} per_page Results per page default 10
 * @returns {array} invoices
 */
export const getInvoices = (page = 1, per_page = 10) => new Promise(async (resolve) => {
    try {
        const response = await APIGO.get(`/api/v1/edi/invoices?page=${page}&per_page=${per_page}&order=desc`)
        if(response.status == 200){
            resolve(response.data)
        }
    } catch (error) {

    }
})

