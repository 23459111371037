import React, { useState } from 'react'
import { Box, Button, Grid, TextField } from '@material-ui/core';
import img_add from './../../Assets/Img/Icon_add.png'
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { UploadFile } from '../../Components/Functions/UploadFile';
const nouser ='https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2FProfile_avatar.png?alt=media&token=0c02f76e-981b-42a2-9395-74e979f02520'
const nofile ='https://res.cloudinary.com/https-golosuite-com/image/upload/v1613854173/fe8bdoga7ty0e7vmlgqx.png'

const initialState = {
    licenseF: false,
    licenseFMsg: false,
    licenseB: false,
    licenseBMsg: false,
    licenseE: false,
    deduction: false,
    deductionMsg: false,
    abstrap: false,
    abstrapMsg: false,
    loading: false,
}

export const Step3 = ({ _values, _avatar, handleChange, nextStep, backStep }) => {

    const [state, setState] = useState( initialState )
    const [msg, setMsg] = useState( false )
    const [front, setFront] = useState({ change: false, file: '' })
    const [back, setBack] = useState({ change: false, file: ''})
    const [deduc, setDeduc] = useState({ change: false, file: ''})
    const [abst, setAbst] = useState({ change: false, file: ''})
    const { _station, _password1, _rdga, _name, _phone, _email, _address, _licenseExp } = _values

    const handleUpadteLicenseF = () => document.querySelector('#licenseF-File').click();
    const handleUpadteLicenseB = () => document.querySelector('#licenseB-File').click();
    const handleUpadteDeduction = () => document.querySelector('#deductionFile').click();
    const handleUpadteAbstrap = () => document.querySelector('#abstrapFile').click();

    const uploadeLicenseF = async ( e ) =>{
        e.preventDefault();
        const file = e.target.files[0];
        if ( file && ( file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" ) ) {
            setState({...state, loading: true })
            const res = await UploadFile( file )
            if ( res ) {
                setFront({...front, file: res })
                document.getElementById("licenseFImg").src = res
            }
            else{
                setFront({...front, file: nouser })
                setState({licenseFMsg: 'please try again in a few minutes.' })
            }
        }else{
            setState({licenseFMsg: 'Valid formats: png, jpg and jpeg' })
        }
        setTimeout(() => setState({...state, loading: false }) , 500);
    }
    
    const uploadeLicenseB = async ( e ) =>{
        e.preventDefault();
        const file = e.target.files[0];
        if ( file && ( file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" ) ) {
            setState({...state, loading: true })
            const res = await UploadFile( file )
            if ( res ) {
                setBack({...back, file: res })
                document.getElementById("licenseBImg").src = res
            }
            else{
                setBack({...back, file: nouser })
                setState({avatarMsg: 'please try again in a few minutes.' })
            }
        }else{
            setState({avatarMsg: 'Valid formats: png, jpg and jpeg' })
        }
        setTimeout(() => setState({...state, loading: false }) , 500);
    }

    const uploadDeduction = async ( e ) =>{
        e.preventDefault();
        const file = e.target.files[0];
        if ( file && ( file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" ) ) {
            setState({...state, loading: true })
            const res = await UploadFile( file )
            if ( res ) {
                setDeduc({...deduc, file: res })
                document.getElementById("deductionImg").src = res
            }
            else{
                setDeduc({...deduc, file: nouser })
                setState({avatarMsg: 'please try again in a few minutes.' })
            }
        }else{
            setState({avatarMsg: 'Valid formats: png, jpg and jpeg' })
        }
        setTimeout(() => setState({...state, loading: false }) , 500);
    }

    const uploadAbstrap = async ( e ) =>{
        e.preventDefault();
        const file = e.target.files[0];
        if ( file && ( file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" ) ) {
            setState({...state, loading: true })
            const res = await UploadFile( file )
            if ( res ) {
                setAbst({...abst, file: res })
                document.getElementById("abstrapImg").src = res
            }
            else{
                setAbst({...abst, file: nouser })
                setState({avatarMsg: 'please try again in a few minutes.' })
            }
        }else{
            setState({avatarMsg: 'Valid formats: png, jpg and jpeg' })
        }
        setTimeout(() => setState({...state, loading: false }) , 500);
    }

    const handleSave = async ( e ) => {
        e.preventDefault();
        let _front = !front.change && setFront({...front, file: nofile });
        let _back = !back.change && setBack({...back, file: nofile });
        let _deduc = !deduc.change && setDeduc({...deduc, file: nofile });
        let _abst = !abst.change && setAbst({...abst, file: nofile});;

        let config = {
            method: 'post',
            url: `https://golosuite.com:8443/register?station=${_station}&password=${_password1}&rdga=${_rdga}&full_name=${_name}&phone=${_phone}&email=${_email}&address=${_address}&avatar=${_avatar}&licence_front=${front.file}&licence_back=${back.file}&licence_exp=${_licenseExp}&abstract=${abst.file}&deduction=${deduc.file}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        axios(config)
        .then( res => {
            console.log( res );
            if ( res.data.status === 'success' ) {
                return nextStep()
            }
            if ( res.data.status === 'error' ) {
                if ( res.data.msg === "The RDGA is already use" ) {
                    return setMsg('Ooops the RDGA is already use, please select another RDGA.' )
                }
                if ( res.data.msg === "The email is already use" ) {
                    return setMsg('Ooops the Email is already use, please select another Email.')
                }
                return setMsg('We have some problems, please try again in some moments.')
            }
        })
        .catch( error => {
            console.log( error );
            return setMsg('We have some problems, please try again in some moments.')
        })
    }

    return (      
        <>
            { !!msg && <Alert severity="error"> { msg } </Alert> }
            <form onSubmit={ e => handleSave( e ) }> 
                <Grid container>
                    <Grid item xs={12} >.
                        <Box p={1}> 
                            <label htmlFor="date_exp" className="labelText" ><small>DRIVER LICENSE EXPIRATE DATE</small></label>
                            <TextField id="date_exp" name="_licenseExp" type="date" variant="outlined" fullWidth value={ _licenseExp } onChange={ handleChange } autoComplete="on"  error={ !!state._licenseExp } helperText={ state._licenseExp } />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Box p={1} pt={0}>  
                            <input type="file" id="licenseF-File" className="fileUpload" name="fileUpload" onChange={ e => { uploadeLicenseF( e ); setFront({...front, change: true}); }} />
                            <span className="labelText"><small><b> DRIVER LICENSE FRONT </b></small></span>
                            <Grid container className="upload_file pointer" direction="column" justify="center" alignItems="center" onClick={ handleUpadteLicenseF } >
                                <img src={ img_add } className="doc-sm" id='licenseFImg' alt=""/> 
                                <span className="text_primary center_center"> Add file </span>
                            </Grid>
                            { state.licenseF && <Grid container direction="row" justify="center" alignItems="center" style={{color: 'red'}}> { state.licenseFMsg } </Grid> }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Box p={1} pt={0}>  
                            <input type="file" id="licenseB-File" className="fileUpload" name="fileUpload" onChange={ e => { uploadeLicenseB( e ); setBack({...back, change: true}); }}/>
                            <span className="labelText"><small><b> DRIVER LICENSE BACK </b></small></span>
                            <Grid container className="upload_file pointer" direction="column" justify="center" alignItems="center" onClick={ handleUpadteLicenseB } >
                                <img src={ img_add } className="doc-sm" id='licenseBImg' alt=""/> 
                                <span className="text_primary center_center"> Add file </span>
                            </Grid>
                            { state.licenseB && <Grid container direction="row" justify="center" alignItems="center" style={{color: 'red'}}> { state.licenseBMsg } </Grid> }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Box p={1} pt={0}>  
                            <input type="file" id="deductionFile" className="fileUpload" name="fileUpload" onChange={ e => { uploadDeduction( e ); setDeduc({...deduc, change: true}); }}/>
                            <span className="labelText"><small><b> DEDUCTION FORM </b></small></span>
                            <Grid container className="upload_file pointer" direction="column" justify="center" alignItems="center" onClick={ handleUpadteDeduction } >
                                <img src={ img_add } className="doc-sm" id='deductionImg' alt=""/> 
                                <span className="text_primary center_center"> Add file </span>
                            </Grid>
                            { state.deduction && <Grid container direction="row" justify="center" alignItems="center" style={{color: 'red'}}> { state.deductionMsg } </Grid> }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Box p={1} pt={0}>  
                            <input type="file" id="abstrapFile" className="fileUpload" name="fileUpload" onChange={ e => { uploadAbstrap( e ); setAbst({...abst, change: true}); }}/>
                            <span className="labelText"><small><b> DRIVERS ABSTRACT </b></small></span>
                            <Grid container className="upload_file pointer" direction="column" justify="center" alignItems="center" onClick={ handleUpadteAbstrap } >
                                <img src={ img_add } className="doc-sm" id='abstrapImg' alt=""/> 
                                <span className="text_primary center_center"> Add file </span>
                            </Grid>
                            { state.abstrap && <Grid container direction="row" justify="center" alignItems="center" style={{color: 'red'}}> { state.abstrapMsg } </Grid> }
                        </Box>
                    </Grid>
                    <Grid container direction="row" justify="flex-start" alignItems="center" >
                        <Box pl={1} mr={3} mt={2}> 
                            <Button 
                                variant="outlined" 
                                disableElevation 
                                onClick={ backStep }
                            >
                                back
                            </Button> 
                        </Box>
                        <Box pl={1} mr={3} mt={2}> 
                            <Button 
                                type={ state.loading ? "button" : "submit" }  
                                variant="outlined" 
                                className="Go_btn-primary"
                            > 
                                { state.loading ? "Loading..." : "Next" } 
                            </Button> 
                        </Box>
                    </Grid>
                </Grid>
            </form> 
        </>
    )
}
