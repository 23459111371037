import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import play from './../../Assets/Img/andorid.png';
import app from './../../Assets/Img/ios.png';
import { VideoDialog } from './VideoDialog';

export const Step4 = ({ resetStep }) => {

    const [open, setopen] = useState(false)
    const [video_, setVideo_] = useState({
        title: '',
        active: ''
    })
    const handleVideoActive = ( active ) => {
        if ( active === 1 ) {
            setVideo_({ 
                title: "How to login on the DRIVER'S APP",
                active: 1
            })
        }
        if ( active === 2 ) {
            setVideo_({ 
                title: "How to do a circle check using the DRIVER'S APP",
                active: 2
            })
        }
        setopen( true )
    }

    return (      
        <Grid container>
            <VideoDialog open={ open } setOpen={ setopen } active={ video_ }  />
            <Grid container direction="column" justify="center" alignItems="center">
                <Typography className="text-primary" variant="h6"> Register complete! </Typography>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item={6}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Typography className="text-primary" variant="subtitle1"> How to login on the DRIVER'S APP </Typography>
                        <Button 
                            type="button" 
                            variant="outlined" 
                            className="Go_btn-primary"
                            onClick={ () => handleVideoActive( 1 ) }
                        > 
                            Open video
                        </Button> 
                    </Grid>
                </Grid>
                <Grid item={6}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Typography className="text-primary" variant="subtitle1"> How to do a circle check using the DRIVER'S APP </Typography>
                        <Button 
                            type="button" 
                            variant="outlined" 
                            className="Go_btn-primary"
                            onClick={ () => handleVideoActive( 2 ) }
                        > 
                            Open video
                        </Button> 
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center">
                <Typography className="text-primary" variant="subtitle1"> DOWNLOAD THE DRIVER'S APP BY CLICKING ON THE FOLLOWING LINKS </Typography>
                <Box direction="felx" style={{ textAlign: 'center'}} >
                    <a href="https://play.google.com/store/apps/details?id=com.dynamic.gologistics.driver&hl=es_PE"  style={{ marginRight: '5px'}} target="_blank">
                        <img src={ play } alt="" className="store-md" />
                    </a>
                    <img src={ app } alt="" className="store-md" />
                </Box>
            </Grid>

            <Grid container direction="row" justify="flex-start" alignItems="center" >
                <Box pl={1} mr={3} mt={2}> 
                    <Button 
                        type="button" 
                        variant="outlined" 
                        className="Go_btn-primary"
                        onClick={ resetStep }
                    > 
                        Finish
                    </Button> 
                </Box>
            </Grid>
        </Grid>
    )
}
