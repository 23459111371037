import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, Grid, Hidden, IconButton, TextField, Typography } from '@material-ui/core'
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { deleteAddress, getAddress, saveNewAddress } from '../../../Components/Functions/LabelPdf/LabelFunctions';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { isPhone, isNumber, isText, isZipCode } from '../../../Components/Functions/validate'
import DeleteIcon from '@material-ui/icons/Delete';
import { DialogConfirm } from '../functions/DialogConfirm';
import Loader from '../../../Components/Global/Loaders';
import PlacesAutocomplete from '../../../Components/Global/PlacesAutocomplete';

const initialState = {
    loading: false,
    msgType: '',
    msg: '',
    refresh: 0,
    dialogOpen: false,
}

export const Step2 = ({ formData, setFormData, formDataBulk, setFormDataBulk, backStep, nextStep }) => {

    const u_id                              = localStorage.getItem('u_id')
    const [addressList, setAddressList]     = useState([]);
    const [state, setState]                 = useState( initialState );
    const [message, setMessage]             = useState({});
    const [dialogOpen, setDialogOpen]       = useState( false );
    const [zipCodeError, setZipCodeError]   = useState( false );



    
    const SaveAddress = async () => {
        let addressData = {
            account: u_id,
            address: `${formData.pickUpLocNum} ${formData.pickUpLocation}`,
            city: formData.pickUpCity,
            province: formData.pickUpProvince,
            country: formData.pickUpCountry,
            postal: formData.pickUpPostal,
            phone: formData.pickUpPhone,
            contact: formData.pickUpName,
            name: formData.pickUpTitle,
            numInt: formData.pickUpLocNumInt,
            type: 'pickup'
        }
        const resp = await saveNewAddress(addressData)
        if (resp.status) {
            console.log( resp );
            setState({
                ...state,
                msgType: 'success',
                msg: 'New address created.',
                refresh: state.refresh + 1,
                checkSaved: true,
                checkNew: false
            })
            setFormData({ ...formData,
                pickUpNew: false,
                pickUpSaved: true,
                pickUpTitle: '',
                pickUpName: '',
                pickUpPhone: '',
                pickUpId: resp.address_id,
                pickUpData:{
                    address: formData.pickUpLocation+' '+formData.pickUpLocNum,
                    city: formData.pickUpCity,
                    province: formData.pickUpProvince,
                    country: formData.pickUpCountry,
                    postal: formData.pickUpPostal,
                    name: formData.pickUpName,
                    phone: formData.pickUpPhone,
                },
            })
            return nextStep()
        } else {
            setState({ ...state, msgType: 'error', msg: 'We have some problems, please try again in some moments.' })
        }
    }
    const handleGetAddress = async () => {
        setState({ ...state, loading: true })
        const resp = await getAddress( u_id, 'pickup' )
        console.log("ESTO REGRESA LA FUNCION DEL BULK")
        console.log(formDataBulk.data)
        
        if (resp.status) {
            setAddressList(resp.results)
        } else {
            setAddressList(false)
            setState({ ...state, error: resp.msg })
        }
        setState({ ...state, loading: false })
    }
    const onDeleteAddress = async () => {
        const resp = await deleteAddress(u_id, formData.pickUpId)
        if (resp.status) {
            setDialogOpen(false)
            setState({
                ...state,
                msgType: 'success',
                msg: 'Address was removed successfully',
                refresh: state.refresh + 1
            })
            setFormData({...formData, pickUpId: '' })
        } else {
            setState({
                ...state,
                msgType: 'error',
                msg: 'We have some problems, please try again in some moments.',
                refresh: state.refresh + 1
            })
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage({})
        if (formData.pickUpNew) {
            if (!formData.pickUpTitle){ return setMessage({ pickUpTitle: 'Title is required.' }) }
                
            else if (!isText(formData.pickUpTitle)){ return setMessage({ pickUpTitle: 'Title has to be a text and cannot start with a number.' }) }
                
            else if (!formData.pickUpName){ return setMessage({ pickUpName: 'Contact name is required.' }) }
                
            else if (!isText(formData.pickUpName)){ return setMessage({ pickUpName: 'Contact name has to be a text and cannot start with a number.' }) }
                
            else if (!formData.pickUpPhone){ return setMessage({ pickUpPhone: 'Phone number is required.' }) }
                
                
            else if (!formData.pickUpLocation || !formData.pickUpCity || !formData.pickUpCountry){ return setMessage({ pickUpLocation: 'Address Location is required.' }) }

            else if(zipCodeError){

                if(!formData.pickUpPostal){
                    return setMessage({ pickUpLocationSecond: 'Please introduce a valid zip code here' })    
                }

                else if(isZipCode(formData.pickUpPostal)){
                    return SaveAddress()
                }

                else {
                    return setMessage({ pickUpLocationSecond: 'The zip code captured is invalid' })
                }
            }
                
            else if (!formData.pickUpPostal){
                setZipCodeError(true);
                return setMessage({ pickUpLocation: "Sorry, we couldn't find your zip code, please enter it in the following field" })
            }
            



            else {return SaveAddress()}
        }

        if (formData.pickUpSaved) {
            if (!formData.pickUpId)
                return setState({
                    ...state,
                    msgType: 'error',
                    msg: 'Please Select an address to continue.'
                })
            else{
                setState(initialState)
                return nextStep()
            }
        }
    }

    useEffect(() => { handleGetAddress() }, [state.refresh])

    return (
        <form 
            id="go_gridContainer_stepper_form"
            onSubmit={handleSubmit}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}
        >
            <DialogConfirm
                open={dialogOpen}
                setOpen={setDialogOpen}
                body="Are you sure to delete the address?"
                onAction={onDeleteAddress}
            />
            <Grid container className="text_grey">
                {!!state.msg &&
                    <Grid container direction="row" justify="center" alignItems="center" >
                        <Box my={3} > <Alert severity={state.msgType}> {state.msg} </Alert> </Box>
                    </Grid>
                }
                <Grid item md={12} >
                    <Grid  container direction="row" justify="center" alignItems="center" >
                        <Typography variant="h6" className="text_primary" >
                            Pick up address
                        </Typography>
                    </Grid>
                    <Grid id="go_selectorAdress" container direction="row" justify="space-evenly" alignItems="center" >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.pickUpNew}
                                    name="checkNew"
                                    color="primary"
                                    onClick={e => setFormData({ ...formData, 
                                        pickUpNew: e.target.checked, 
                                        pickUpSaved: !e.target.checked 
                                    })}
                                />
                            }
                            label="New pick up address"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.pickUpSaved}
                                    name="checkSaved"
                                    color="primary"
                                    onClick={ e => setFormData({ ...formData, 
                                        pickUpNew: !e.target.checked, 
                                        pickUpSaved: e.target.checked 
                                    })}
                                />
                            }
                            label="Saved addresses"
                        />
                    </Grid>
                </Grid>
                {formData.pickUpNew &&
                    <>
                        <Grid item xs={12} md={4} lg={4}>
                            <Box m={1} >
                                <label htmlFor="_newPickUpTitle" > Title </label>
                                <TextField id="_newPickUpTitle" name="newPickUpTitle" type="text" variant="outlined" fullWidth value={formData.pickUpTitle} onChange={e => setFormData({ ...formData, pickUpTitle: e.target.value })} autoComplete="off" error={!!message.pickUpTitle} helperText={message.pickUpTitle} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Box m={1} >
                                <label htmlFor="_newPickUpName" > Contact name </label>
                                <TextField id="_newPickUpName" name="newPickUpName" type="text" variant="outlined" fullWidth value={formData.pickUpName} onChange={e => setFormData({ ...formData, pickUpName: e.target.value })} autoComplete="off" error={!!message.pickUpName} helperText={message.pickUpName} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Box m={1} >
                                <label htmlFor="_newPickUpPhone" > Phone number </label>
                                <TextField id="_newPickUpPhone" name="newPickUpPhone" type="number" variant="outlined" fullWidth value={formData.pickUpPhone} onChange={e => setFormData({ ...formData, pickUpPhone: e.target.value })} autoComplete="off" error={!!message.pickUpPhone} helperText={message.pickUpPhone} />
                            </Box>
                        </Grid>
                        <Grid item  xs={12} md={12} lg={8}>
                            <Box m={1} >
                                <label htmlFor="_newPickUpLocation" > Address </label>
                                <PlacesAutocomplete handleSelected={(place, details) => {
                                    setFormData({
                                        ...formData,
                                        pickUpCity:     details?.locality?.long_name,
                                        pickUpCountry:  details?.country?.long_name,
                                        pickUpLocation: details?.route?.long_name,
                                        pickUpLocNum:  details?.street_number?.long_name,
                                        pickUpPostal:   details?.postal_code?.long_name,
                                        pickUpProvince: details?.administrative_area_level_1?.long_name
                                    })
                                }} >
                                    <TextField id="_newPickUpLocation" name="newPickUpLocation" type="text" variant="outlined" fullWidth autoComplete="off" error={!!message.pickUpLocation} helperText={message.pickUpLocation} />
                                </PlacesAutocomplete>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <Box m={1} >
                                <label htmlFor="_newPickUpLocNumInt" > Apartment/Unit number </label>
                                <TextField id="_newPickUpLocNumInt" name="newPickUpLocNumInt" type="text" variant="outlined" fullWidth value={formData.pickUpLocNumInt} onChange={e => setFormData({ ...formData, pickUpLocNumInt: e.target.value })} autoComplete="off" error={!!message.pickUpLocNumInt} helperText={message.pickUpLocNumInt} />
                            </Box>
                        </Grid>

                        { zipCodeError?
                            <Grid item xs={12} md={4} lg={4}>
                                <Box m={1} >
                                    <label htmlFor="_newPickUpZipCode" > Zip Code </label>
                                    <TextField id="_newPickUpZipCode" name="newPickUpZipCode" type="number" variant="outlined" fullWidth value={formData.pickUpPostal} onChange={e => setFormData({ ...formData, pickUpPostal: e.target.value })} autoComplete="off" error={!!message.pickUpLocationSecond} helperText={message.pickUpLocationSecond} />
                                </Box>
                            </Grid>
                            :
                            <span></span>
                        }


                    </>
                }
                {formData.pickUpSaved &&
                    <>
                        {state.loading
                            ? <Grid container direction="row" justify="center" alignItems="center" > <Loader onlyLoad={true} /> </Grid>
                            : addressList ? addressList.map( i =>
                                <Grid item xs={12} md={6} key={i.id} >
                                    <div className="customers_comment flex m-2">
                                        <IconButton 
                                            onClick={() => setFormData({ ...formData, 
                                                pickUpId: i.id,
                                                pickUpData:{
                                                    address: i.address,
                                                    city: i.city,
                                                    province: i.province,
                                                    country: i.country,
                                                    postal: i.postal,
                                                    name: i.contact,
                                                    phone: i.phone,
                                                }
                                            })} 
                                        >
                                            { formData.pickUpId === i.id ? <CheckIcon /> : <UncheckedIcon /> }
                                        </IconButton>
                                        <div>
                                            <span className="customer__list-label"> {i.name} </span><br />
                                            <span className="customer__list-text"> {i.contact} </span><br />
                                            <span className="customer__list-text">
                                                {`${(i.address !== 'null' || i.address !== 'undefined') && i.address}, ${(i.city !== 'null' || i.city !== 'undefined') && i.city}, ${(i.country !== 'null' || i.country !== 'undefined') && i.country}.`}
                                            </span><br />
                                            { i.apartment_num && <><span className="customer__list-text">Apartment/Number: { i.apartment_num }</span><br /></> }
                                            <span className="customer__list-text"> {i.phone} </span><br />
                                        </div>
                                        <IconButton 
                                            onClick={() => { 
                                                setDialogOpen(true); 
                                                setFormData({ ...formData,  pickUpId: i.id }) 
                                            }} 
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </Grid>
                            )
                                : <Typography variant="subtitle1" gutterBottom > You don't have saved addresses yet </Typography>
                        }
                    </>
                }
                <Grid id="go_gridContainer_stepper_buttons" container direction="row" justify="center" alignItems="center" >
                    <Button
                        variant="outlined"
                        disableElevation
                        className="w-150px mr-2-r mb-2-r"
                        onClick={ backStep }
                    >
                        CANCEL
                    </Button>
                    <Button
                        type="submit"
                        variant="outlined"
                        className="Go_btn-primary w-150px"
                    >
                        {state._loading ? 'Loading...' : 'CONTINUE'}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
