import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
//Layout
import LayoutOutSide from './../../Pages/LayoutOutSide';

import {AuthContext} from '../Functions/Auth';


const PublicRoute = ({ component: RouteComponent, ...rest }) => {
    const {token} = useContext(AuthContext)

    return (
        <Route { ...rest } render = { routeProps =>
            token ? 
            <Redirect to={"/"} />
            :
            <LayoutOutSide>
                <RouteComponent {...routeProps} />
            </LayoutOutSide> 
        }/>
    )
}

export default PublicRoute;