import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
//styles
import '../../Assets/Styles/Layout.css';
// IMG
import Logo from "../../Assets/Img/Logo.png"

import Notification from '../../Assets/Img/Icon_notification.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
//

import { Badge, Hidden, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { AuthContext } from '../../Components/Functions/Auth';
import { APIGO } from '../../constants/API';

const UserMenu = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

const WarehouseServiceLayout = ({ children }) => {

    const history = useHistory();
    const userType = localStorage.getItem('Type');
    const { handlelogout, isChatActive, setIsChatActive, type, token } = useContext(AuthContext);
    const [chats, setChats] = useState('');
    const [movil, setMovil] = useState(null);

    const handleClick = (event) => setMovil(event.currentTarget);
    const handleClose = () => setMovil(null);

    useEffect(() => {
        let config = {
            method: 'GET',
            url: '/users/profile',
            headers: {
                'Access-Control': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
        APIGO(config)
            .then(res => {
                getNotifications(res.data.station)
            })
            .catch(err => console.log(err))
    }, [])

    const getNotifications = async (stationID) => {
        let chatsActive = [];

    }

    const LogOut = (e) => {
        e.preventDefault();
        history.push("/login");
        window.location.replace('');
    }

    return (
        <Grid container spacing={3} justify="center">
            <Grid item xs={12} >
                <AppBar position="static" className="navBar" >
                    <Toolbar>
                        <Grid container direction="row" justify="space-between" alignItems="center" className="navBar_movil" >
                            <Box px={3} className="bar_logo">
                                <Link to="/home">
                                    <img src={Logo} alt="Logo Go Logistics" className="navBar_logo" />
                                </Link>
                            </Box>
                            <Box px={3} className="navBar_menu" >
                                <Hidden only={['xs', 'sm']}>

                                    <Link to="/wh/scan/in">
                                        <Button >Scan In</Button>
                                    </Link>
                                    <Link to="/wh/scan/out">
                                        <Button >Scan out List</Button>
                                    </Link>
                                    <Link to="/wh/scan/returns">
                                        <Button >Returns</Button>
                                    </Link>

                                    <Link to="/wh/find/products">
                                        <Button >Find products</Button>
                                    </Link>

                                    <Link to="/login">
                                        <Button onClick={e => { handlelogout(); LogOut(e); }} >Logout</Button>
                                    </Link>
                                    <Button aria-controls="notificationsMenu"  >
                                        <Link to="/notifications">
                                            <img src={Notification} alt="Notification" />
                                        </Link>
                                    </Button>
                                </Hidden>
                                {/* Menu movil */}
                                <Hidden mdUp>
                                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                        <MoreIcon />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={movil}
                                        keepMounted
                                        open={Boolean(movil)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem>
                                            <Link className="navBar_menu-text" to="/wh/scan/in">Scan In</Link>
                                        </MenuItem>

                                        <MenuItem>
                                            <Link className="navBar_menu-text" to="/wh/scan/out">Scan out List</Link>
                                        </MenuItem>

                                        <MenuItem>
                                            <Link className="navBar_menu-text" to="/wh/scan/returns">Returns</Link>
                                        </MenuItem>

                                        <MenuItem>
                                            <Link className="navBar_menu-text" to="/wh/find/products">Find products</Link>
                                        </MenuItem>

                                        <MenuItem >
                                            <Link to="/notifications" className="navBar_menu-text"> Notifications </Link>
                                        </MenuItem>
                                        <MenuItem onClick={e => { handlelogout(); LogOut(e); }} className="navBar_menu-text"> Logout </MenuItem>
                                    </Menu>
                                </Hidden>
                            </Box>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    )
}
export default WarehouseServiceLayout