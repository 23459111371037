import React from 'react'
import Chat from './Chat'
import UserChat from './UserChat'
import { useEffect } from 'react'
import { useState } from 'react'


const IndexChat = () => {

    const VerifyType =  async() => {
        return  await (localStorage.Type === "1" ||  localStorage.Type === "2") ? true : false;
    }
    const [ TypeUser, setTypeUser ] = useState(null)
    let result = null;
    useEffect(() =>{
        const verify = async() => {
            result = await VerifyType().then(res =>{
                // console.log('res', res);
                setTypeUser(res)
            })
        }
        verify()
    },[])
    
    return(
        <>
            { TypeUser ? <Chat/> : <UserChat/>}
        </>
    )
}

export default IndexChat;