import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { getRegisters, getWarehouseList } from '../../middleware/warehouse/Warehouse';
import {
    Container, Box, Button, Typography,
    Grid, Card, CardContent, FormControl,
    TextField, Select, MenuItem, Tooltip
} from "@material-ui/core"
import { useContext } from 'react';
import { AuthContext } from '../../Components/Functions/Auth';

import moment from 'moment';
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import WarehouseModal from './WarehouseModal';
import Pagination from '@material-ui/lab/Pagination';
import { pageMakerFilter, pagerMaker, Operators } from '../../utils/paginator';
import DialogMessage from '../../Components/Global/Modals/DialogMessage';
import WarehouseExemption from './WarehouseExemption';
import { getWarehouseProducts } from '../../middleware/warehouse/WarehouseProducts';
import { getWarehouseLocation } from '../../middleware/warehouse/WarehouseLocations';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const RegisterItem = ({ item, onHistory }) => {

    const {
        name,
        client,
        order_number,
        model_number,
        serial_number,
        location_id,
        location_name,
        created
    } = item

    

    return (
        <TableRow>
            <TableCell>
                <Typography >{name}</Typography>
            </TableCell>
            <TableCell>
                <Typography >{order_number}</Typography>
            </TableCell>
            <TableCell>
                <Typography >{serial_number}</Typography>
            </TableCell>
            <TableCell>
                <Typography >{model_number}</Typography>
            </TableCell>
            <TableCell>
                <Typography >{client}</Typography>
            </TableCell>
            <TableCell>
                <Typography >{location_name}</Typography>
            </TableCell>
            <TableCell>
                <Typography >{created}</Typography>
            </TableCell>
        </TableRow>
    )
}

const WarehouseInventoryList = () => {

    const history = useHistory()
    /**
     * id integer list id when comes from customer service lists
     * scan applies only on warehouse user types, defines status update on action sent out print label and returns
     */
    const { user: { u_id } } = useContext(AuthContext)

    const [searchText, setSearchText] = React.useState("")

    //Stores no paged result array
    const [registersTemp, setRegistersTemp] = React.useState()

    //Main array to show on front
    const [registers, setRegisters] = React.useState()

    //Stores Paged chunks registers
    const [pagedRegisters, setPagedRegisters] = React.useState([])

    //Stores page view of registers
    const [page, setPage] = React.useState(1)

    //Stores status filter default all
    const [statusFilter, setStatusFilter] = React.useState("all")

    const [filterOptions, setFilerOptions] = React.useState()


    //On scan type changed reload registers
    React.useEffect(() => {
        loadRegisters()
    }, [])

    //Trigger current page selection change
    React.useEffect(() => {
        setRegisters(pagedRegisters[page - 1])
    }, [page])

    //Set first array of paged registers, when paged registers are loaded
    React.useEffect(() => {
        setRegisters(pagedRegisters[0])
    }, [pagedRegisters])

    React.useEffect(() => {

    }, [statusFilter])

    // Loads data from api
    const loadRegisters = () => {
        //async function await for results
        (async () => {
            //Api middleware function load all registers returns true or false
            const result = await getWarehouseProducts()
            if (result) {
                setRegistersTemp(result)
                //If page view type is returns filter registers

                const _list = pagerMaker(result, 15)
                setPagedRegisters(_list)
                setRegisters(_list[page - 1])
                setPage(1)

            }
        })()
    }

    const handleStatusFilter = (e) => {
        const { value } = e.target

        if (value == "all") {
            const _list = pagerMaker(registersTemp, 15)
            if (_list.length > 0) {
                setRegisters(_list[0])
                setPagedRegisters(_list)
            } else {
                setRegisters([])
                setPagedRegisters([])
            }
        } else {
            const _list = pageMakerFilter(registersTemp, 15, {
                [Operators.Eq]: {
                    field: "status",
                    compare: value
                }
            })
            if (_list.length > 0) {
                setRegisters(_list[0])
                setPagedRegisters(_list)
            } else {
                setRegisters([])
                setPagedRegisters([])
            }
        }
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        console.log(searchText.toLowerCase())
        const _filtered = registersTemp.filter((item) => {
            //If exacts matches
            if (JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())) {
                return true
            } else {
                return false
            }
        })
        // TODO What happen  when match more than 1 register
        if (_filtered.length > 0) {
            setPagedRegisters(pagerMaker(_filtered, 15))
            setPage(1)
            const item = { ..._filtered[0], found: true }
            setSearchText("")
        }
    }

    return (
        <Container disableGutters maxWidth="xl" >

            {/* <Grid container style={{ marginBottom: 20 }} >
                <Grid item xs={6} style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }} >
                    <Box ml={1} mr={1} ><Typography>From:</Typography></Box>
                    <TextField type="date" size="small" id="outlined-basic" variant="outlined" />
                    <Box ml={1} mr={1} ><Typography> To: </Typography></Box>
                    <TextField type="date" size="small" id="outlined-basic" variant="outlined" />
                </Grid>
                <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", alignItems: "center" }} >
                    <Typography> Status: </Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select onChange={handleStatusFilter} >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"canceled"}>Canceled</MenuItem>
                            <MenuItem value={"received"}>Received</MenuItem>
                            <MenuItem value={"exemption"}>Received-as-X</MenuItem>
                            <MenuItem value={"on_route"}>On route</MenuItem>
                            <MenuItem value={"delivered"}>Delivered</MenuItem>
                            <MenuItem value={"returned"}>Returned</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", alignItems: "center" }}  >
                    <Tooltip title="Export all current filtered data">
                        <Button variant="outlined" color="secondary" onClick={() => history.push("/wh/export/", pagedRegisters)} >Export data</Button>
                    </Tooltip>
                </Grid>
            </Grid> */}

            <Grid container justifyContent="center">
                <Grid item xs={12} >
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="center" alignItems="center" >
                                <Grid item xs={3} >
                                    <Typography align="center" >Search:</Typography>
                                </Grid>
                                <Grid item xs={9} >
                                    <form style={{ display: "flex" }} onSubmit={handleSearchSubmit}>
                                        <TextField autoFocus onChange={({ target }) => setSearchText(target.value)} value={searchText} size="small" id="outlined-basic" fullWidth variant="outlined" />
                                        <Button type="submit" variant="contained" color="secondary" >Search</Button>
                                    </form>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: 20 }} >
                                <Grid item xs={12}>
                                    <Table style={{ width: "100%" }} >
                                        <TableHead>
                                            <TableRow>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Order number</TableCell>
                                            <TableCell>Serial number</TableCell>
                                            <TableCell>Model number</TableCell>
                                            <TableCell>Client</TableCell>
                                            <TableCell>Location</TableCell>
                                            <TableCell>Created</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                registers && <React.Fragment>
                                                    {
                                                        registers.map((item, index) => (
                                                            <RegisterItem item={item} />
                                                        ))
                                                    }
                                                </React.Fragment>
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" >
                                <Pagination page={page} count={pagedRegisters.length} variant="outlined" shape="rounded"
                                    onChange={(e, value) => {
                                        setPage(value)
                                    }} />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default WarehouseInventoryList

const defaultFilter = {
    date_from: "",
    date_to: "",
    search: "",
    status: ""
}
const applyFilters = (array, filters = defaultFilter) => {





}