import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import styles from './chat.module.css'

import { CHATS_REF } from './Contants'
import { generate } from '../Functions/Driver'
import { APIGO, getProfile } from '../../../constants/API'

export const getMyProfile = setMyProfile => {

    try{
        let config = {
            method: 'GET',
            url   : getProfile,
            headers: {
                'Access-Control':'multipart/form-data',
                'Authorization': `Bearer ${localStorage.Token}`
            }
        }
        APIGO(config)
        .then(res => setMyProfile(res.data))
        .catch(err => console.log(err))
    }catch(err){
        console.log(err);
    }
}


export const Message = ({ msg, myProfile }) => {

    if(myProfile.rdga === msg[1].user._id){
        return(
        <Grid container spacing={2} >
            <Grid item xs={9} md={11}>
                {
                    msg[1].link 
                    ? (<a target="_blank" href={msg[1].link}>
                            <Box className={`chat_msg msg_received`} >
                                <img src="https://abriltimes.files.wordpress.com/2020/07/file_icon.png" width="35px" height="50px"/>&nbsp;{msg[1].message}
                            </Box>
                        </a>) 
                    : (<Box className={`chat_msg msg_received`} >{msg[1].message}</Box>)
                }
            </Grid>
            <Grid item xs={3} md={1}>
                <img src={ msg[1].user.avatar } alt="" className={ styles.avatar_driver } />
            </Grid>
        </Grid>
        )
    }else{
        return (
        <Grid container spacing={2} >
            <Grid item xs={3} md={1}>
                <img src={ msg[1].user.avatar } alt="" className={ styles.avatar_driver } />
            </Grid>
            <Grid item xs={9} md={11}>
                {
                    msg[1].link 
                    ? (<a target="_blank" href={msg[1].link}>
                            <Box className={`chat_msg msg_sent`} >
                                <img src="https://abriltimes.files.wordpress.com/2020/07/file_icon.png" width="35px" height="50px"/>&nbsp;{msg[1].message}
                            </Box>
                        </a>) 
                    : (<Box className={`chat_msg msg_sent`} >{msg[1].message}</Box>)
                }
            </Grid>
        </Grid>
    )}
}