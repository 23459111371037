import React from 'react'

import {
    List, ListItem, ListItemAvatar,
    Avatar, ListItemText, CardContent,
    Container, Card, Grid,
    Typography, Button,
    TextField, LinearProgress, Box, ListItemSecondaryAction, IconButton
} from '@material-ui/core';

import LocationOnIcon from '@material-ui/icons/LocationOn';

import PlacesAutocomplete from '../../Components/Global/PlacesAutocomplete';
import { getWarehouseLocations, postWarehouseLocation, deleteWarehouseLocation } from '../../middleware/warehouse/WarehouseLocations';
import BaseDialog from '../../Components/Global/Modals/BaseDialog';
import DeleteIcon from '@material-ui/icons/Delete';

import { useTheme } from '@material-ui/core';


export const WarehouseLocationItem = ({ title, subtitle, item, onDelete }) => {

    const handleDelete = async () => {
        const result = await deleteWarehouseLocation(item.id)
        if(result){
            onDelete(item)
        }
    }

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <LocationOnIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={title} secondary={subtitle} />
            <ListItemSecondaryAction>
                <IconButton onClick={handleDelete} color="error" edge="end" aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

const WarehouseLocations = () => {

    const theme = useTheme()

    const [addLocation, setAddLocation] = React.useState(false)

    const [placesList, setPlacesList] = React.useState([])
    const [placeResult, setPlaceResult] = React.useState()

    const [locationForm, setLocationForm] = React.useState({
        name: "",
        code: "",
        lat: "none",
        lng: "none"
    })
    const handleChange = ({ target }) => {
        const { name, value } = target
        setLocationForm({
            ...locationForm,
            [name]: value
        })
    }

    React.useEffect(() => {
        (async () => {
            const response = await getWarehouseLocations()
            if (response) {
                setPlacesList(response)
            }
        })()
    }, [])

    const onPlaceDelete = (place) => {
        let _placesList = placesList.filter((item)=>place.id!==item.id)
        setPlacesList(_placesList)
    }

    const handlePlaceResult = (value, details) => {
        setPlaceResult({
            value,
            ...details
        })
        setLocationForm({
            ...locationForm,
            code: value?.description
        })
    }

    const handleSubmitLocation = async (e) => {
        e.preventDefault()

        const result = await postWarehouseLocation(locationForm)
        console.log(result)
        if (result) {
            setAddLocation(false)
            setPlacesList([
                ...placesList,
                ...[result]
            ])
        }
    }

    return (
        <Container disableGutters >

            <BaseDialog open={addLocation} onClose={() => setAddLocation(false)} >
                <Box style={{ background: theme.palette.primary.main }} color="#fff" p={3}  >
                    <Typography variant="h6" color="inherit">Add Location</Typography>
                </Box>
                <form onSubmit={handleSubmitLocation} >
                    <Box m={3} >
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12}>
                                <Typography>Name/Address:</Typography>
                                <TextField required name="name" placeholder="" onChange={handleChange} variant="outlined" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Code:</Typography>
                                <TextField required placeholder="A3" name="code" onChange={handleChange} variant="outlined" fullWidth size="small" />
                                {/* <PlacesAutocomplete handleSelected={handlePlaceResult} >
                                    <TextField required placeholder="Type to search location" variant="outlined" fullWidth size="small" />
                                </PlacesAutocomplete> */}
                            </Grid>
                            <Grid item xs={8}>
                                <Box display="flex" flexDirection="row" justifyContent="space-around" >
                                    <Button onClick={() => setAddLocation(false)} variant="outlined" size="small" > CANCEL </Button>
                                    <Button type="submit" variant="contained" color="secondary" size="small" > SAVE </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </BaseDialog>

            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" flexDirection="row">
                                <Typography variant="h5" >Locations</Typography>
                                <Button variant="contained" color="secondary" onClick={() => setAddLocation(true)} >Add New</Button>
                            </Box>
                            {
                                placesList ?
                                    <List title="Locations" >
                                        {
                                            placesList.map((item, index) => {
                                                return (
                                                    <WarehouseLocationItem 
                                                        id={item.id} 
                                                        title={item.name} 
                                                        subtitle={`${item?.code}`} 
                                                        item={item}
                                                        onDelete={onPlaceDelete}
                                                        />
                                                )
                                            })
                                        }
                                    </List>
                                    : <LinearProgress color="secondary" />
                            }
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </Container>
    )
}

export default WarehouseLocations
