import React, { useContext, useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { ChatContext } from '../components/ChatContext'
import {  Message } from '../Functions/Manager'
import { CHATS_REF } from '../Functions/Contants'


const ChatBody = () => {

    const { idChatActive, myProfile } = useContext( ChatContext )
    const [ messages, setMessages ] = useState([])

    const renderMessages = () => {
        
        if(messages){
            return Object.entries(messages).map(msg=>{
                return (
                    <Message msg={msg} myProfile={myProfile} key={msg[0]} />
                )
            })
        }
        return <Box>No messages yet</Box>
    }

    useEffect(() => {
        if(idChatActive){
            CHATS_REF.child(`/${idChatActive}/messages/`).on('value', snap => {
            setMessages(snap.val())
        })
        }
    },[ idChatActive ])
    
    return(
        <Grid item xs={12} className="chat_content" >
            { messages && renderMessages() }
        </Grid>
    )
}

export default ChatBody;