/**
 * @author Angel
 * @email [example@mail.com]
 * @create date 2021-07-24 23:43:56
 * @modify date 2021-07-26 09:14:49
 * @desc [description]
 */
import { APIGO } from "../../constants/API"

export const getWarehouseLocations = async () => {
    try {
        const result = await APIGO.get(`/warehouse/locations?query= 1=1 order by code`)
        if(result.data.result){
            return result.data.result
        }else{
            return false
        }
    } catch (error) {
        console.warn(error.response)
        return false
    }
}

export const getWarehouseLocation = async (id) => {
    try {
        const result = await APIGO.get(`/warehouse/locations?query=id=${id}`)
        if(result.data.result){
            return result.data.result
        }else{
            return false
        }
    } catch (error) {
        console.warn(error.response)
        return false
    }
}

export const getWarehouseLocationByParams = async (params) => {
    try {
        const result = await APIGO.get(`/warehouse/locations?query=${params}`)
        if(result.data.result){
            return result.data.result
        }else{
            return false
        }
    } catch (error) {
        console.warn(error.response)
        return false
    }
}

export const postWarehouseLocation = async (data) => {
    try {
        const result = await APIGO.post(`/warehouse/locations`,data)
        if(result.data.result){
            return result.data.result
        }else{
            return false
        }
    } catch (error) {
        console.warn(error.response)
        return false
    }
}

export const deleteWarehouseLocation = async (id) => {
    try {
        const result = await APIGO.delete(`/warehouse/locations?query=id=${id}`)
        if(result.data.status){
            return true
        }else{
            return false
        }
    } catch (error) {
        console.warn(error.response)
        return false
    }
}