import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
// Componentes
import ModalShow from '../Components/Global/Modal';
// IMG
import Portada from './../Assets/Img/Portada_management.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import VendorItem from '../Components/Global/SettingsElementItem';
import Modal from '@material-ui/core/Modal';
//API
import { getStation, deleteStations, APIGO } from './../constants/API'; 
import { Hidden } from '@material-ui/core';

const Station = () => {

    const history           = useHistory();  
    const [ data, setData ] = useState([]);
    const [ deleteID, setDeleteID ] = useState('');
    const [ refresh, setRefresh ]   = useState( false );
    // Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    useEffect( () =>{
        var config = {
            method: 'get',
            url: getStation,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then(response =>{
            setData(response.data);  
        })
        .catch(function (error){
            console.log(error)
        })
    }, [refresh] );

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    const deleteElement = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            let config = {
                method: 'delete',
                url: `${deleteStations}${deleteID}`,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
            APIGO(config)
            .then(response =>{
                if(response.data.status == "success"){
                    setRefresh( true )
                    setModalmsg('Element was removed successfully');
                    setModalLink('/station')
                    setIsDeleted( false );
                    setIsShow( true );
                    ModalOpen()
                }else {
                    setModalmsg(response.data.msg);
                    setIsShow( true );
                    setIsDeleted( false );
                    setModalmsg('Oops! a problem happened, please try again.');
                    ModalOpen()
                }
            })
            .catch(function (error){
                console.log(error)
                setModalmsg( "Oops! a problem happen, please try again." );
                setIsShow( true );
                setIsDeleted( false );
                ModalOpen();
            })  
        }
        else{
            ModalClose();
        }
    }

    const onDelete = ( e, id ) => {
        e.preventDefault();
        setDeleteID( id )
        setIsDeleted( true );
        setIsConfirm( false );
        setIsShow( false );
        ModalOpen();
    }

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted ?
                            <ModalShow 
                                title=" Are you sure to delete? "
                                confirmDelete={ true }
                                Delete={ deleteElement }
                            /> : null
                    }  
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            <Grid container spacing={2} >
                <Grid item xs={12} md={2} className="center_center">
                    <Link to="/settings" className="flex No_Decoration">
                        <ArrowBackIosIcon className="Page_title"/>
                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                            Go back
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h4" gutterBottom className="Page_title">
                        Station
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box alignItems="right" >
                        { 
                            localStorage.getItem('Type') === '1' &&  
                                <Link to="/station/new" className="No_Decoration" >
                                    <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >New Stations</Button>
                                </Link>
                        }
                        { 
                            localStorage.getItem('Type') === '2' && 
                                localStorage.getItem('PS_C') === '1' ? 
                                    <Link to="/station/new" className="No_Decoration" >
                                        <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >New Stations</Button>
                                    </Link>
                                : null
                        }
                        
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={2} ml={2} className="bg_white" boxShadow={3} >
                        <Grid container className="bg_white" >
                            <Hidden only={['xs', 'sm']}>
                                <Grid item md={3}>
                                    <Typography variant="body1" gutterBottom className="text_grey">
                                        <b>
                                            STATION
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item md={8}>
                                    <Typography variant="body1" gutterBottom className="text_grey">
                                        <b>
                                            ADDRESS
                                        </b>
                                    </Typography>
                                </Grid>
                            </Hidden>
                            <Grid item md={12}>
                                {
                                    data.map( item => ( 
                                        <VendorItem 
                                            key={ item.st_id } 
                                            isStation={ true }
                                            id={ item.station_id } 
                                            name={ item.station_address } 
                                            e_id={ item.station_id } 
                                            link={ `/station/details/${item.st_id}` } 
                                            onDelete={ onDelete } 
                                        /> 
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );

}
export default Station