import React from 'react'
import { Link } from 'react-router-dom';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const DashboardElement = ( { icon, title, link }) => {
    return(
        <Box m={3} p={2} className="dash_content" >
            <Grid container direction="row" justify="space-between" alignItems="center" >
                <Box display="flex">
                    <img src={ icon } alt="" className="dash_Img" />
                    <Typography variant="h6" gutterBottom className="dash_title">
                        { title }
                    </Typography>
                </Box>
                <Link to={ link } className="No_Decoration" >
                    <Button variant="contained" disableElevation className="Go_btn-primary" >
                        View more
                    </Button>
                </Link>    
            </Grid>
        </Box>
    )
}
export default DashboardElement