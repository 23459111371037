import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from '@material-ui/data-grid';
import { Grid } from '@material-ui/core';
import { getOrderAccount } from '../../../Components/Functions/LabelPdf/LabelFunctions';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <Grid>
                <GridToolbarExport />
            </Grid>
        </GridToolbarContainer>
    );
}

export default function OrdersExport() {

    const u_id = localStorage.getItem('u_id')
    const [rawData, setrawData] = useState([])
    const [rows, setrows] = useState([])
    const [state, setstate] = useState({
        loading: false,
        error: ''
    });
    const width = 150;
    const columns = [ 
        { field: "order_id", headerName: "order number", width: width, type:"text" },
        { field: "order_date", headerName: "date", width: width ,type:"text"},
        { field: "ready_time", headerName: "ready time", width: width ,type:"text"},
        { field: "total_packages", headerName: "total packages", width: width ,type:"text"},
        { field: "total_weight", headerName: "total weight", width: width ,type:"text"},
        { field: "instructions", headerName: "instructions", width: width ,type:"text"},
        { field: "status", headerName: "status", width: width ,type:"text"},
        { field: "service", headerName: "service", width: width ,type:"text"},
        { field: "pickup_name", headerName: "pickup name", width: width ,type:"text"},
        { field: "pickup_address", headerName: "pickup address", width: width ,type:"text"},
        { field: "pickup_city", headerName: "pickup city", width: width ,type:"text"},
        { field: "pickup_province", headerName: "pickup province", width: width ,type:"text"},
        { field: "pickup_country", headerName: "pickup country", width: width ,type:"text"},
        { field: "pickup_postal", headerName: "pickup postal", width: width ,type:"text"},
        { field: "pickup_phone", headerName: "pickup phone", width: width ,type:"text"},
        { field: "pickup_contact", headerName: "pickup contact", width: width ,type:"text"},
        { field: "delivery_name", headerName: "delivery name", width: width ,type:"text"},
        { field: "delivery_address", headerName: "delivery address", width: width ,type:"text"},
        { field: "delivery_city", headerName: "delivery city", width: width ,type:"text"},
        { field: "delivery_province", headerName: "delivery province", width: width ,type:"text"},
        { field: "delivery_country", headerName: "delivery country", width: width ,type:"text"},
        { field: "delivery_postal", headerName: "delivery postal", width: width ,type:"text"},
        { field: "delivery_phone", headerName: "delivery phone", width: width ,type:"text"},
        { field: "delivery_contact", headerName: "delivery contact", width: width ,type:"text"},
    ];

    const handleGetLabel = async () => {
        setstate({...state, loading: true })
        
        const resp = await getOrderAccount( u_id, 500, 1 ) // account_id, Limit, Page
        if ( resp.status ) {
            setrawData( resp.results )
        } else {
            setrawData( false )
            setstate({...state, error: resp.msg })
        }
        setstate({...state, loading: false })
    }

    useEffect(() => { handleGetLabel() }, [])
    useEffect(() => {
        let r = []
        rawData.map( (baseItem, index) => {
            baseItem.id = index
            r.push(baseItem)
        })
        setrows(r)
    }, [rawData])

    return (
        <div style={{ height: '80vh', width: '100%', backgroundColor: 'white'}}>
            <DataGrid
                columns={ columns }
                rows={ rows }
                dataSet="Commodity"
                rowLength={4}
                maxColumns={6}
                disableColumnMenu={true}
                checkboxSelection={true}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}