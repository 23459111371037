import React, { useContext } from 'react'

//UI
import { Container, Grid, makeStyles, Card, CardContent, Typography, LinearProgress, Box, Button } from '@material-ui/core'

//Media
import { Icons } from "../../Assets"

//Utils
import csvtojson from 'csvtojson'

//Functions
import { postRegister, postWarehouseList, postRegisterBundle, postEdiData, postEdiInvoices } from "../../middleware/warehouse/Warehouse"
import BaseDialog from '../../Components/Global/Modals/BaseDialog'
import DialogMessage from '../../Components/Global/Modals/DialogMessage'
import { AuthContext } from '../../Components/Functions/Auth';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {

    },
    header: {
        width: "100%",
        height: "auto",
        backgroundColor: theme.palette.primary.main,
        color: "#fff"
    }
}))

const GenerateEdi = () => {

    const history = useHistory()

    const context = useContext(AuthContext)

    const classes = useStyles()

    const [progress, setProgress] = React.useState(0)

    const [file, setFile] = React.useState(false)
    const [ediUri, setEdiUri] = React.useState(false)

    const [dialog, setDialog] = React.useState({
        open: false,
        title: "File uploaded successfully",
        message: "Pres back to upload another file or continue to check EDI 210 Result",
        type: "success",
    })

    const normalize = (obj) => {
        const result = {};
        Object.keys(obj).forEach(function (key) {
            let new_key = key.split(' ').join('_')
            new_key = new_key.toLowerCase()
            result[new_key] = obj[key];
        });
        return result;
    }

    const make_chunks = (arr, len) => {
        var chunks = [], i = 0, n = arr.length;
        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }
        return chunks;
    }

    const submitData = async (data, file) => {

        if (data && data.length > 0) {

            const list_result = await postWarehouseList(context?.user?.u_id, file.name)

            console.log(list_result)

            if (!list_result.status) {
                console.log("interrupt error")
                return
            }

            console.log("begin upload csv")

            const count = data.length
            //Await for all results
            let _progress = 0

            let bundle_all = []

            await Promise.all(data.map(async (item, index) => {
                //Normalize replace space to _
                item.list_id = list_result.result.id
                item.user_id = context.user.u_id
                item.username = context.user.name

                let _item = normalize(item)
                const result = await postRegister(_item)

                _progress += 1

                setProgress((_progress * 100) / count)
            }))

            setDialog({
                ...dialog,
                title: "File uploaded successfully",
                message: "Pres back to upload another file or continue to check your new register list",
                open: true
            })

        } else {

        }
    }

    const sendToPhp = async (file) => {

        const form = new FormData();
        form.append("tipo", "xlsx"); //Sending custom variables
        form.append("fileSent", file, "File_X12.xlsx");

        const headers = new Headers();
        headers.append("Accept", "application/json");

        const urlEndPoint = `${process.env.REACT_APP_EDI_API_URL}/generateEDI_ConTabs.php`;

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: form,
            redirect: 'follow'
        }

        fetch(urlEndPoint, requestOptions).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)

            const data = result

            if (data.error) {
                console.log(data.error)
                setDialog({
                    ...dialog,
                    open: true,
                    title: "Some was wrong",
                    message: "Please check the uploaded file",
                    type: "error"
                })
                setProgress(0)
                return
            }
            setProgress(25)

            postEdiString(data)

        }).catch(e => {
            console.log(e)
        })

    };

    const postEdiString = async (data) => {

        setEdiUri(`${process.env.REACT_APP_EDI_API_URL}/${data.pathEdi}`)

        const postData = await postEdiData({ ediData: data.stringReturnEdi })

        console.log(postData)

        setProgress(100)
        setDialog({
            ...dialog,
            title: "File uploaded successfully",
            message: "Pres back to upload another file or continue to check EDI 210 Result",
            type: "success",
            open: true,
        })
    }

    const positiveAction = () => {
        setFile(false)
        setProgress(0)
        setDialog({
            ...dialog,
            open: false
        })
        /* if (dialog.type !== "error") {
            window.open(ediUri, '_blank', 'noopener,noreferrer')
        } */
    }

    const negativeAction = () => {
        setFile(false)
        setProgress(0)
        setDialog({
            ...dialog,
            open: false
        })
    }

    const handleFileChosen = async (e) => {
        const _file = e.target.files[0]

        //Validate if file is a csv type
        const isValid = /\.csv$/i.test(_file.name);

        if(!isValid){
            setDialog({
                open: true,
                title: "Error",
                message: "This file format is not a valid .csv",
                type: "error",
            })
            return
        }

        //Set file on state
        if (_file) {
            setFile(_file)
        }
        const result = await postEdiInvoices(_file)
        if (result.status) {
            setDialog({
                open: true,
                title: "File uploaded successfully",
                message: "Pres back to upload another file or continue to check EDI 210 Result",
                type: "success",
            })
        } else {
            setDialog({
                open: true,
                title: "Upload error",
                message: "Please try again in a few seconds",
                type: "error",
            })
        }
        setProgress(100)
    }

    return (
        <Container>

            <DialogMessage
                {...dialog}
                positiveAction={positiveAction}
                positiveText="Continue"
                negativeAction={negativeAction}
                negativeText="Back"
                onClose={negativeAction}
            />

            <Grid container justify="center" >
                <Grid item xs={12} lg={10} >
                    <Card className={classes.root} >
                        <div className={classes.header} >
                            <Grid container justify="center">
                                <Grid item>
                                    <img src={Icons.ic_scan_in} alt="icon" />
                                    <Typography variant="h4" color="inherit" >Upload a file</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems='center' justify='flex-end' >
                                <Grid item>
                                    <Button onClick={() => history.push("/customer_service/edi_history")} style={{ marginRight: 16, marginLeft: 16 }} variant='contained' color='secondary' >View History</Button>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.content} >
                            <CardContent>
                                {
                                    !file &&
                                    <div className="input_file">
                                        <label>
                                            <img src={Icons.ic_csv} alt="icon" />
                                            <Typography variant="h6" color="primary" >Upload a file</Typography>
                                            <Typography variant="h6" >Click to load your .csv file</Typography>
                                            <input
                                                id="csv_input"
                                                type="file"
                                                accept=".csv"
                                                onChange={handleFileChosen} />
                                        </label>
                                    </div>
                                }
                                {
                                    file &&
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <Box width="100%" mr={1}>
                                                    <Typography>{file.name}</Typography>
                                                </Box>
                                                <Box minWidth={35}>
                                                    <Typography variant="body2" color="textSecondary">{`${Math.round(
                                                        progress,
                                                    )}%`}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LinearProgress color="secondary" variant={progress == 0 ? "indeterminate" : "determinate"} value={progress} />
                                        </Grid>
                                    </Grid>
                                }

                                <Grid container justify="center" >
                                    <Grid item>
                                        <Box mt={5}>
                                            <Button disabled={!file ? true : false} onClick={() => history.goBack()} variant="outlined">Cancel</Button>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default GenerateEdi
