import React from 'react';
// Router React
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// routes
import PrivateRoute from './Components/Functions/PrivateRoute';
import PublicRoute from './Components/Functions/PublicRoute';
import { AuthProvider } from "./Components/Functions/Auth";
// views
import Login from "./Pages/Login";
import ForgotPwrd from "./Pages/ForgotPwrd";
import Home from "./Pages/Home";
import Dashboard from "./Pages/Dashboard";
import DashboardCustomer from "./Pages/DashboardCustomer";
import Drivers from "./Pages/Drivers";
import DriversAdd from "./Pages/DriversAdd";
import DriversDetails from "./Pages/DriversDetails";
import History from "./Pages/History";
import HistoryDetails from "./Pages/HistoryDetails";
import Reports from "./Pages/Reports";
import Accidents from "./Pages/Accidents";
import AccidentsDetails from "./Pages/AccidentsDetails";
import Vehicles from "./Pages/Vehicles";
import VehiclesAdd from "./Pages/VehiclesAdd";
import VehiclesDetails from "./Pages/VehiclesDetails";
import Notifications from "./Pages/Notifications";
import Settings from "./Pages/Settings";
import Vendor from "./Pages/Vendor";
import VendorAdd from "./Pages/VendorAdd";
import VendorDetails from "./Pages/VendorDetails";
import VehicleModel from "./Pages/VehicleModel";
import VehicleModelAdd from "./Pages/VehicleModelAdd";
import VehicleModelDetails from "./Pages/VehicleModelDetails";
import Station from "./Pages/Station";
import StationAdd from "./Pages/StationAdd";
import StationDetails from "./Pages/StationDetails";
import Manager from './Pages/Manager'
import ManagerAdd from './Pages/ManagerAdd'
import ManagersDetails from './Pages/ManagersDetails'
import IndexChat from './Pages/NewChat/IndexChat'
import { OpenRegistry } from './Pages/Auth/OpenRegistry';
import DriversApprovedList from './Pages/DriversApprovedList';
import Customers from './Pages/Costumers/Customers'
import CustomersAdd from './Pages/Costumers/CustomersAdd'
import CustomersDetails from './Pages/Costumers/CustomersDetails'
import GasReceipts from './Pages/GasReceipts';

//styles
import './Assets/Styles/Global.css';
import './Assets/Styles/Media.css';
import './Assets/Styles/responsive.css'

import { NewOrder } from './Pages/Costumers/Order/NewOrder';
import { OrderHistory } from './Pages/Costumers/OrderHistory/OrderHistory';
import { OrderHistoryDetails } from './Pages/Costumers/OrderHistory/OrderHistoryDetails';
import { Billing } from './Pages/Costumers/Billing/Billing';
import { PdfView } from './Pages/Costumers/functions/PdfView';
import VehiclesCsv from './Pages/VehiclesCsv';
import VehiclesCsvImport from './Pages/VehiclesCsvImport';
import SettingsGeneral from './Pages/Settings/SettingsGeneral';
import OrdersExport from './Pages/Costumers/OrderHistory/OrdersExport';
import OrdersImport from './Pages/Costumers/OrderHistory/OrdersImport';
import CustomerServiceRoutes from './Routes/CustomerServiceRoutes';
import PrivateCustomerService from './Routes/components/PrivateCustomerService';
import PrivateWareHouseRoutes from './Routes/components/PrivateWareHouseRoutes';
import WarehouseRoutes from './Routes/WarehouseRoutes';
import WarehouseLocations from './Pages/Settings/WarehouseLocations';

function App() {

  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/driversRegistry" component={OpenRegistry} />
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/forgotPassword" component={ForgotPwrd} />

          <PrivateRoute exact path="/customer/history" component={OrderHistory} />
          <PrivateRoute exact path="/customer/history/export" component={OrdersExport} />
          <PrivateRoute exact path="/customer/history/import" component={OrdersImport} />
          <PrivateRoute exact path="/customer/history/details/:id" component={OrderHistoryDetails} />
          <PrivateRoute exact path="/customer/pdf/:id" component={PdfView} />
          <PrivateRoute exact path="/customer/order" component={NewOrder} />
          <PrivateRoute exact path="/customer/billing" component={Billing} />

          <PrivateRoute path="/customers/dashboard/" component={DashboardCustomer} />
          <PrivateRoute path="/customers" component={Customers} />
          <PrivateRoute path="/customers/add" component={CustomersAdd} />
          <PrivateRoute exact path="/customer/details/:id" component={CustomersDetails} />

          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/drivers" component={Drivers} />
          <PrivateRoute exact path="/drivers/new" component={DriversAdd} />
          <PrivateRoute exact path="/drivers/approved" component={DriversApprovedList} />
          <PrivateRoute exact path="/drivers/details/:id" component={DriversDetails} />
          <PrivateRoute exact path="/history" component={History} />
          <PrivateRoute exact path="/history/details/:id" component={HistoryDetails} />
          <PrivateRoute exact path="/vehicles" component={Vehicles} />
          <PrivateRoute exact path="/vehicles/new" component={VehiclesAdd} />
          <PrivateRoute exact path="/vehicles/csv/" component={VehiclesCsv} />
          <PrivateRoute exact path="/vehicles/import/" component={VehiclesCsvImport} />
          <PrivateRoute exact path="/vehicles/details/:id" component={VehiclesDetails} />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute exact path="/accidents" component={Accidents} />
          <PrivateRoute exact path="/accidents/details/:id/:id" component={AccidentsDetails} />
          <PrivateRoute exact path="/notifications" component={Notifications} />
          <PrivateRoute exact path="/chat" component={IndexChat} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/settings/general/" component={SettingsGeneral} />
          <PrivateRoute exact path="/settings/locations/" component={WarehouseLocations} />
          <PrivateRoute exact path="/vendor" component={Vendor} />
          <PrivateRoute exact path="/vendor/new" component={VendorAdd} />
          <PrivateRoute exact path="/vendor/details/:id" component={VendorDetails} />
          <PrivateRoute exact path="/station" component={Station} />
          <PrivateRoute exact path="/station/new" component={StationAdd} />
          <PrivateRoute exact path="/station/details/:id" component={StationDetails} />
          <PrivateRoute exact path="/model" component={VehicleModel} />
          <PrivateRoute exact path="/model/new" component={VehicleModelAdd} />
          <PrivateRoute exact path="/model/details/:id" component={VehicleModelDetails} />
          <PrivateRoute exact path="/manager" component={Manager} />
          <PrivateRoute exact path="/manager/new" component={ManagerAdd} />
          <PrivateRoute exact path="/manager/details/:id" component={ManagersDetails} />

          <PrivateRoute exact path="/drivers/gas" component={GasReceipts} />

          {/* Private Warehouse routes */}
          <PrivateCustomerService path="/customer_service" component={CustomerServiceRoutes} />

          {/* Private Customer service routes */}
          <PrivateWareHouseRoutes path="/wh" component={WarehouseRoutes} />


        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
