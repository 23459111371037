import React from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';

const AccidentNotes = ( { text, user, date } ) => {
    
    return (
        <Box p={1}>
            <Box borderRadius={5} p={2} className="bg_grey">
                <Typography variant="body1" >
                    { text }
                </Typography>
                <Typography variant="body2" className="text_primary" >
                    By { user } at  { moment( date ).format('MMMM Do YYYY, h:mm:ss a') }
                </Typography>
            </Box>
        </Box>
        
    );
}

AccidentNotes.propTypes = {

};

export default AccidentNotes;