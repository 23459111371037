import React, { useState, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import moment from "moment";
// Componnet
import AccidentNotes from "./../Components/Global/AccidentNotes";
import AcidentEstimation from '../Components/Global/AcidentEstimation';
import ModalShow from '../Components/Global/Modal';
import AccidentForm from '../Components/Functions/AccidentsForm/Accidenstsform'
// IMG
import img_add from './../Assets/Img/Icon_add.png'
import img_download from './../Assets/Img/Icon-download.png'
// Material UI
import { Grid, Typography, Box, Button } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
//API
import * as firebase from 'firebase';
import { 
    APIGO, 
    usersbyid,
    getEstimates,
    createNotesDRM,
    createNotesCFM,
    getNotesCFMbyId, 
    getNotesDRMbyId,
    createEstimates,
    getVehiclesById,
    changeEstimates,
    getAditionalFile,
    getAccidentsbyid, 
    CoveredAccidentBy,
    CreateAditionalFile,
} from './../constants/API';

const AccidentsDetails = () => {

    const history   = useHistory();
    const match     = useRouteMatch('/accidents/details/:id');
    const rdga      = match.params.id;
    const matchA_id = useRouteMatch('/accidents/details/:id/:id');
    const A_id      = matchA_id.params.id;

    const [ data, setData ] = useState([]);
    const [ dataVehicle, setDataVehicle ] = useState([]);
    const [ dataDriver, setDataDriver ] = useState([]);
    const [ refresh, setRefresh ] = useState(0);
    // Cover by
    const [ isCoverBy, setIsCoverBy ] = useState(false);
    const [ coverBy, setCoverBy ] = useState('');

    // Aditional Files
    const [ dataAditinalFiles, setDataAditinalFiles ] = useState([]);
    const [ isAdditional, setIsAdditional ]     = useState(false);
    const [ docAdditional, setdocAdditional ]     = useState(false);
    const [ nameAdditional, setNameAdditional ]     = useState(false);
    
    // Notes
    const [ dataNotesDRM, setDataNotesDRM ] = useState([]);
    const [ dataNotesCFM, setDataNotesCFM ] = useState([]);
    const [ isDataNotesDRM, setIsDataNotesDRM ] = useState([]);
    const [ isDataNotesCFM, setIsDataNotesCFM ] = useState([]);
    const [ isNotesDRM, setIsNotesDRM ]     = useState(false);
    const [ isNotesCFM, setIsNotesCFM ]     = useState(false);
    const [ notesDRM, setNotesDRM ]         = useState('');
    const [ notesCFM, setNotesCFM ]         = useState('');
    // Estimations
    const [ files, setFiles ] = useState('');
    const [ dataEstimate, setdataEstimate ] = useState([]);
    const [ previwDocfiles, setPreviwDocfiles ] = useState('');
    const [ E_value, setE_Value] = useState('');
    const [ E_files, setE_Files] = useState('');
    const [ isEstimation, setIsEstimation ] = useState(false);
    const [ isEstimationChange, setIsEstimationChange ]     = useState(false);
    const [ estimationCheck, setEstimationCheck ] = useState('');
    // Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");
    const [ isError, setIsErrors] = useState([{
        NotesDRM: false,
        NotesCFM: false,
        E_value: false,
        E_file: false,
        coverBy: false,
    }]);

    useEffect( () =>{
        console.log(`id=${A_id}`);
        // accident data
        var config_accident = {
            method: 'get',
            url: `/accident?id=${A_id}`,
            // url: `${getAccidentsbyid}${A_id}`,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_accident)
        .then(response =>{
            if ( response.data.status === 'error' ) {
                console.log(response.data.msg);
            }
            if ( response.data.status === 'success' )  {
                setData( response.data.results[0] );
                setCoverBy( response.data.results[0].covered_by )
            }
        })
        .catch(function (error){
            console.log(error)
        })

        // Notes
        var config_notesDRM = {
            method: 'get',
            url: `${getNotesDRMbyId}${A_id}`,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_notesDRM)
        .then(response =>{
            
            if ( response.data.status === 'error' ) {
                setModalmsg('No information for notes')
                setNotesDRM('')
                ModalClose();
                setIsShow( true );
                setIsConfirm( false );
                ModalOpen();
            }
            else{
                if ( response.data.msg === 'No notes to show' ) {
                    setIsDataNotesDRM( false )
                }
                else{
                    setIsDataNotesDRM( true )
                    setDataNotesDRM( response.data );
                }
            }
        })
        .catch(function (error){
            console.log(error)
            ModalClose()
            setModalmsg('Sorry We have some problems to save a new note');
            setIsShow( true );
            setIsConfirm( false );
            ModalOpen();
        })
        
        // Notes 
        var config_notesCFM = {
            method: 'get',
            url: `${getNotesCFMbyId}${A_id}`,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_notesCFM)
        .then(response =>{
            if ( response.data.status === 'error' ) {
                setModalmsg('No information for notes')
                ModalClose();
                setIsShow( true );
                setIsConfirm( false );
                ModalOpen();
            }
            else{
                if ( response.data.msg === 'No notes to show' ) {
                    setIsDataNotesCFM( false )
                }
                else{
                    setIsDataNotesCFM( true )
                    setDataNotesCFM( response.data );
                }
            }
        })
        .catch(function (error){
            console.log(error)
            ModalClose()
            setModalmsg('Sorry We have some problems to save a new note');
            setIsShow( true );
            setIsConfirm( false );
            ModalOpen();
        })
        
        
        getEstimations()
        getAdtitinalFiles()
        // getvehicle()
        // getDriver()
        
    }, [ refresh ] );
    
    const getEstimations = () => {
        // Estimations
        var config_estimates = {
            method: 'get',
            url: `${getEstimates}${A_id}`,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_estimates)
        .then(response =>{
            if ( response.data.status === 'error' ) {
                setModalmsg('No information for estimations')
                ModalClose();
                setIsShow( true );
                setIsConfirm( false );
                ModalOpen();
            }
            else{
                if ( response.data.msg === "No estimates to show" ) {
                    setdataEstimate( false )
                }
                else{
                    // setdataEstimate( response.data );
                    setdataEstimate( response.data );
                }
            }
            // setdataEstimate( response.data );
        })
        .catch(function (error){
            console.log(error)
            ModalClose()
            setModalmsg('Sorry We have some problems to save a new note');
            setIsShow( true );
            setIsConfirm( false );
            ModalOpen();
        })
    }
    const getAdtitinalFiles = () => {
        // Estimations
        var config_estimates = {
            method: 'get',
            url: `${getAditionalFile}${A_id}`,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_estimates)
        .then(response =>{
            if ( response.data.status === 'error' ) {
                setModalmsg('No information for additional files')
                ModalClose();
                setIsShow( true );
                setIsConfirm( false );
                ModalOpen();
            }
            else{
                if ( response.data === [] ) {
                    setDataAditinalFiles( false )
                }
                else{
                    setDataAditinalFiles( response.data );
                }
            }
        })
        .catch(function (error){
            console.log(error)
            ModalClose()
            setModalmsg('Sorry We have some problems to save a new note');
            setIsShow( true );
            setIsConfirm( false );
            ModalOpen();
        })
    }
    const getvehicle = () => {
        // vehicle
        var config_driver = {
            method: 'get',
            url: `${getVehiclesById}${data.v_id}`,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_driver)
        .then(response =>{
            if ( response.data.status === 'error' ) {
                setModalmsg('No information for vehicle')
                ModalClose();
                setIsShow( true );
                setIsConfirm( false );
                ModalOpen();
            }
            else{
                setDataVehicle( response.data[0] );
            }
        })
        .catch(function (error){
            console.log(error)
            ModalClose()
            setModalmsg('Sorry We have some problems to get the Vehicle data');
            setIsShow( true );
            setIsConfirm( false );
            ModalOpen();
        })
    }
    const getDriver = () => {
        // vehicle
        var config_driver = {
            method: 'get',
            url: `${usersbyid}${rdga}`,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_driver)
        .then(response =>{
            if ( response.data.status === 'error' ) {
                setModalmsg('No information for vehicle')
                ModalClose();
                setIsShow( true );
                setIsConfirm( false );
                ModalOpen();
            }
            else{
                setDataDriver( response.data );
            }
        })
        .catch(function (error){
            console.log(error)
            ModalClose()
            setModalmsg('Sorry We have some problems to get the estimations');
            setIsShow( true );
            setIsConfirm( false );
            ModalOpen();
        })
    }
    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const saveElement = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            if ( isNotesDRM ) {
                let config_saveDRM = {
                    method: 'post',
                    url: createNotesDRM,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        id_accident: A_id,
                        notes: notesDRM,
                    }
                }
                APIGO(config_saveDRM)
                .then(response =>{
                    if( response.data.status === "error" ){
                        setModalmsg('oops something wrong happened please try again ')
                        setNotesDRM('')
                        ModalClose();
                        setIsShow( true );
                        setIsConfirm( false );
                        ModalOpen();
                    }
                    if( response.data.status === "success" ){
                        ModalClose()
                        setNotesDRM('')
                        setRefresh( refresh + 1 )
                        setModalmsg('Note added successfully');
                        setIsShow( true );
                        setIsConfirm( false );
                        ModalOpen();
                    }
                })
                .catch(function (error){
                    console.log(error)
                    setNotesDRM('')
                    ModalClose()
                    setModalmsg('Sorry We have some problems to save a new note');
                    setIsShow( true );
                    setIsConfirm( false );
                    ModalOpen();
                }) 
            }
            if ( isNotesCFM ) {
                let config_saveCDM = {
                    method: 'post',
                    url: createNotesCFM,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        id_accident: A_id,
                        notes: notesCFM,
                    }
                }
                APIGO(config_saveCDM)
                .then(response =>{
                    if( response.data.status === "error" ){
                        setModalmsg(response.data.msg)
                        ModalClose();
                        setNotesCFM('')
                        setIsShow( true );
                        setIsConfirm( false );
                        ModalOpen();
                    }
                    if( response.data.status === "success" ){
                        ModalClose()
                        setRefresh( refresh + 1 )
                        setNotesCFM('')
                        setModalmsg('Note added successfully');
                        setIsShow( true );
                        setIsConfirm( false );
                        ModalOpen();
                    }
                })
                .catch(function (error){
                    console.log(error)
                    ModalClose()
                    setNotesCFM('')
                    setModalmsg('Sorry We have some problems to save a new note');
                    setIsShow( true );
                    setIsConfirm( false );
                    ModalOpen();
                }) 
            }
            if ( isEstimation ) {
                const storageRef = firebase.storage().ref();
                const upload = storageRef.child(`files/accident/${A_id}/estimate/`);
                upload.put(E_files).then(snapshot=>{
                    upload.getDownloadURL().then(url=>{
                        
                        let config_saveEstimate = {
                            method: 'post',
                            url: createEstimates,
                            headers: { 
                                'Access-Control-Request-Headers': 'multipart/form-data', 
                                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                            },
                            data: {
                                id_accident: A_id,
                                amount:     E_value,
                                fileurl:    url
                            }
                        }
                        APIGO(config_saveEstimate)
                            .then(response =>{

                            if( response.data.status === "error" ){
                                setModalmsg(response.data.msg)
                                ModalClose();
                                setNotesCFM('')
                                setIsShow( true );
                                setIsConfirm( false );
                                ModalOpen();
                            }
                            if( response.data.status === "success" ){
                                ModalClose()
                                setRefresh( refresh + 1 )
                                setNotesCFM('')
                                setE_Value('')
                                setIsEstimation( false )
                                setModalmsg('Estimation added successfully');
                                setIsShow( true );
                                setIsConfirm( false );
                                ModalOpen();
                            }
                        })
                        .catch(function (error){
                            console.log(error)
                            ModalClose()
                            setNotesCFM('')
                            setModalmsg('Sorry We have some problems to save a new estimation');
                            setIsShow( true );
                            setIsConfirm( false );
                            ModalOpen();
                        }) 

                    })
                })
            }
            if ( isEstimationChange ) {
                let config_saveEstimation = {
                    method: 'post',
                    url: changeEstimates,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        id: estimationCheck,
                    }
                }
                APIGO(config_saveEstimation)
                .then(response =>{
                    if( response.data.status === "error" ){
                        setModalmsg(response.data.msg)
                        ModalClose();
                        setNotesCFM('')
                        setIsShow( true );
                        setIsConfirm( false );
                        ModalOpen();
                    }
                    if( response.data.status === "success" ){
                        ModalClose()
                        setRefresh( refresh + 1 )
                        setNotesCFM('')
                        setModalmsg('Estimate was changed successfully');
                        setIsShow( true );
                        setIsConfirm( false );
                        ModalOpen();
                    }
                })
                .catch(function (error){
                    console.log(error)
                    ModalClose()
                    setNotesCFM('')
                    setModalmsg('Sorry We have some problems to save a new note');
                    setIsShow( true );
                    setIsConfirm( false );
                    ModalOpen();
                }) 
            }
            if ( isAdditional ) {
                const storageRef = firebase.storage().ref();
                const upload = storageRef.child(`files/accident/${A_id}/additional${Math.random() * 100}/`);
                upload.put(docAdditional).then(snapshot=>{
                    upload.getDownloadURL().then(url=>{
                        let config_saveEstimate = {
                            method: 'post',
                            url: CreateAditionalFile,
                            headers: { 
                                'Access-Control-Request-Headers': 'multipart/form-data', 
                                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                            },
                            data: {
                                id_accident: A_id,
                                name: nameAdditional,
                                url:    url
                            }
                        }
                        APIGO(config_saveEstimate)
                        .then(response =>{
                            if( response.data.status === "error" ){
                                setModalmsg(response.data.msg)
                                ModalClose();
                                setNotesCFM('')
                                setIsShow( true );
                                setIsConfirm( false );
                                ModalOpen();
                            }
                            if( response.data.status === "success" ){
                                ModalClose()
                                setRefresh( refresh + 1 )
                                setNotesCFM('')
                                setE_Value('')
                                setIsEstimation( false )
                                setModalmsg('File uploaded successfully');
                                setIsShow( true );
                                setIsConfirm( false );
                                ModalOpen();
                            }
                        })
                        .catch(function (error){
                            console.log(error)
                            ModalClose()
                            setNotesCFM('')
                            setModalmsg('Sorry We have some problems to save an additional file');
                            setIsShow( true );
                            setIsConfirm( false );
                            ModalOpen();
                        }) 
                    })
                })
            }
            if ( isCoverBy ) {
                // coverBy
                // 0 == Go Logistics
                // 1 == Insurance
                let config_saveDRM = {
                    method: 'put',
                    url: CoveredAccidentBy,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        id: A_id,
                        cover: coverBy,
                    }
                }
                APIGO(config_saveDRM)
                .then(response =>{
                    if( response.data.status === "error" ){
                        setModalmsg('oops something wrong happened please try again ')
                        setNotesDRM('')
                        ModalClose();
                        setIsShow( true );
                        setIsConfirm( false );
                        ModalOpen();
                    }
                    if( response.data.status === "success" ){
                        ModalClose()
                        setNotesDRM('')
                        setRefresh( refresh + 1 )
                        setModalmsg('Accident cover was added successfully');
                        setIsShow( true );
                        setIsConfirm( false );
                        ModalOpen();
                    }
                })
                .catch(function (error){
                    console.log(error)
                    setNotesDRM('')
                    ModalClose()
                    setModalmsg('Sorry We have some problems please try again');
                    setIsShow( true );
                    setIsConfirm( false );
                    ModalOpen();
                }) 
            }
        }
        else{
            setIsCoverBy( false )
            setIsNotesDRM( false )
            setIsNotesCFM( false )
            setIsEstimation( false )
            setIsAdditional( false )
            setIsEstimationChange( false )
            ModalClose()
        }
    }
    const handleEstimation = ( e ) => {
        e.preventDefault();
        if( E_value === "" ){ setIsErrors({ E_value: true }); }
        else if( E_files === "" ){ setIsErrors({ E_file: true }); }
        else {
            setIsErrors('');
            setIsEstimation( true );
            setIsConfirm( true );
            setIsShow( false );
            ModalOpen()
        }
    }
    const handleNotesDRM = ( e ) => {
        e.preventDefault();
        if(notesDRM === "" ){
            setIsErrors({ NotesDRM: true });
        }
        else {
            setIsErrors('');
            setIsConfirm( true );
            setIsNotesDRM( true );
            setIsShow( false );
            ModalOpen()
        }
    }
    const handleNotesCFM = ( e ) => {
        e.preventDefault();
        if(notesCFM === "" ){
            setIsErrors({ NotesCFM: true });
        }
        else {
            setIsErrors('');
            setIsShow( false );
            setIsConfirm( true );
            setIsNotesCFM( true );
            ModalOpen()
        }
    }
    const handleCoverBy = ( e ) => {
        e.preventDefault();
        if( coverBy  == 0 || coverBy  == 1 ){
            setIsErrors('');
            setIsShow( false );
            setIsConfirm( true );
            setIsCoverBy( true );
            ModalOpen()
        }
    }
    const previwAditionalFiles = ( e ) =>{
        try { setPreviwDocfiles( URL.createObjectURL( e.target.files[0] ) ) } 
        catch (error) { console.log( error ); }
    }
    const addEstimation = ( e ) => {
        e.preventDefault();
        setIsEstimation( true );
    }
    const changeEstimation = ( e, id_estimation ) => {
        e.preventDefault();
        setEstimationCheck( id_estimation );
        setIsEstimationChange( true );
        setIsShow( false );
        setIsConfirm( true );
        ModalOpen()
    }
    const AdditionalFile = ( e ) => {
        e.preventDefault();
        setdocAdditional(e.target.files[0]); 
        setNameAdditional(e.target.files[0].name)
        previwAditionalFiles(e)

        setIsAdditional( true );
        setIsShow( false );
        setIsConfirm( true );
        ModalOpen()
    }

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isConfirm ?
                            <ModalShow 
                            title=" Are you sure you want to save a new element? "
                            confirmOperation={ true }
                            Operation={ saveElement }
                            /> : null
                    }    
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            <Grid container spacing={2} >
                <Grid item xs={12} md={2} className="center_center">
                    <Link to="/accidents" className="flex No_Decoration">
                        <ArrowBackIosIcon className="Page_title"/>
                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                            Go back
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box textAlign="left" className="" >
                        <Typography variant="h4" gutterBottom className="Page_title">
                            Accidents Details
                        </Typography>
                        <span className="labelText"> ID { data?.id } </span> 
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box textAlign="right" className="Text_grey" >
                        { moment( data?.details?.form?.A?.fields[0]?.value ).format("MMMM Do YYYY") }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={3} className="box_content" borderRadius={5} boxShadow={3} >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Box p={1} pt={0}>
                                    <Typography variant="h6" gutterBottom className="Page_title">
                                        Vehicle information
                                    </Typography>
                                    <hr className="divider_blue" />
                                    <Grid container>
                                        <Grid item xs={6} md={3} >
                                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                                Vehicle ID
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className="Text_grey">
                                                { data?.v_id }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                                License plate
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className="Text_grey">
                                                { data?.details?.form?.C?.fields[0]?.sub_form[3].value }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={3} >
                                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                                RDGA
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className="Text_grey">
                                                { data?.details?.driver_data?.rdga }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={3} >
                                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                                Insurance claim number
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className="Text_grey">
                                                { data?.details?.covered }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <Box p={1} pt={0}>
                                    <Typography variant="h6" gutterBottom className="Page_title">
                                        Driver assigned
                                    </Typography>
                                    <hr className="divider_blue" />
                                    <Grid container className="bg_history">
                                        <Grid item xs={12} md={4} >
                                            <img src="{ data.driver_avatar }" alt="" className="historyImg rounded" />
                                        </Grid>
                                        <Grid item xs={12} md={8} >
                                            <span className="History_title" >
                                                { data.driver_name }
                                                data
                                            </span>
                                            <br/>
                                            <span className="Text_blod-grey">
                                                ID {data.driver_id}
                                                ID Data
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Box p={1} pt={0}>
                                    <Typography variant="h6" gutterBottom className="Page_title">
                                    Covered by
                                    </Typography>
                                    <hr className="divider_blue" />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1} pt={0}>
                                
                                { 
                                    coverBy === 0 ?
                                        <IconButton onClick={ e => {handleCoverBy( e ); setCoverBy( '0' ) } } >
                                            <CheckCircleOutlineIcon />
                                            <Typography variant="body1" gutterBottom>
                                                Go Logistics
                                            </Typography>
                                        </IconButton>
                                    :
                                        <IconButton onClick={ e => {handleCoverBy( e ); setCoverBy( '0' ) } } >
                                            <RadioButtonUncheckedIcon />
                                            <Typography variant="body1" gutterBottom>
                                                Go Logistics
                                            </Typography>
                                        </IconButton>
                                }
                                { 
                                    coverBy === 1 ?
                                        <IconButton onClick={ e => {handleCoverBy( e ); setCoverBy( '1' ) } } >
                                            <CheckCircleOutlineIcon />
                                            <Typography variant="body1" gutterBottom>
                                                Insurance
                                            </Typography>
                                        </IconButton>
                                    :
                                        <IconButton onClick={ e => {handleCoverBy( e ); setCoverBy( '1' ) } } >
                                            <RadioButtonUncheckedIcon />
                                            <Typography variant="body1" gutterBottom>
                                                Insurance
                                            </Typography>
                                        </IconButton>
                                }
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1} pt={0}>
                                    <Typography variant="h6" gutterBottom className="Page_title">
                                        Acident form
                                    </Typography>
                                    <hr className="divider_blue" />
                                    
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1} m={1} textAlign="center" className="border_solid-grey pointer" onClick={ e => AccidentForm(data)} >
                                    <img src={ img_download } alt="" /> Download &nbsp;&nbsp;
                                </Box>                                        
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1} pt={0}>
                                    <Typography variant="h6" gutterBottom className="Page_title">
                                        Estimate
                                    </Typography>
                                    <hr className="divider_blue" />                     
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1} pt={0}> 
                                    {
                                        dataEstimate ?
                                            <>
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <Typography variant="body1" gutterBottom className="Page_title">
                                                            Amount
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body1" gutterBottom className="Page_title">
                                                            Download file
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography variant="body1" gutterBottom className="Page_title">
                                                            Select estimate
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        
                                                        {
                                                            dataEstimate.map( item => (
                                                                <Box m={1} key={ item.id}>
                                                                    <Grid container className="bg_grey">
                                                                        <Grid item xs={3}>
                                                                            <Box textAlign="center" fontSize="h6.fontSize" className="text_grey" >
                                                                                ${ item.amount }
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={7}>
                                                                            <Box p={1} my={1} textAlign="center" className="border_solid-grey" key={ item.id } >
                                                                                <img src={ img_download } alt=""/> &nbsp;&nbsp;
                                                                                <a href={ item.url } target="_blank" rel="noopener noreferrer" className="No_Decoration text_grey">
                                                                                    Estimation { item.id }
                                                                                </a>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <Box textAlign="center" >
                                                                                { 
                                                                                    item.active === 1 ?
                                                                                        <IconButton >
                                                                                            <CheckCircleOutlineIcon />
                                                                                        </IconButton>
                                                                                    :
                                                                                        <IconButton onClick={ e => changeEstimation( e, item.id ) } >
                                                                                            <RadioButtonUncheckedIcon />
                                                                                        </IconButton>
                                                                                }
                                                                                
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            ) ) 
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </>
                                            : null
                                    }
                                </Box>
                                <Box p={1} pt={0}>
                                    {
                                        isEstimation && 
                                            <form onSubmit={ handleEstimation }>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={3}>
                                                        <Typography variant="body1" className="text_primary">
                                                            AMOUNT
                                                        </Typography>
                                                        <TextField id="DriveLastName" type="number" variant="outlined" size="small" fullWidth value={ E_value } onChange={e => setE_Value(e.target.value)}  />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body1" className="text_primary">
                                                            UPLOAD FILE
                                                        </Typography>
                                                        <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => { setE_Files(e.target.files[0]); } }  />
                                                        <label for="uploadFile" className="text_GREY pointer">
                                                            <Grid container className="upload_file-grey" direction="column" justify="center" alignItems="center" >
                                                                <Typography p={1} className="text_grey" >
                                                                    {/* <img src={ img_add }  alt=""/> */}
                                                                    Add estimate
                                                                </Typography>
                                                            </Grid>
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={3} className="center_center">
                                                        <Button variant="contained" disableElevation type="submit" fullWidth className="Go_btn-primary" >
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                    }
                                </Box>                                        
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1} pt={0}>  
                                    <p className="text_primary pointer" onClick={ e => addEstimation(e) }>
                                        <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                            <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add estimate</p>
                                        </Grid>
                                    </p>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1} pt={0}>
                                    <Typography variant="h6" gutterBottom className="Page_title">
                                        Additional files
                                    </Typography>
                                    <hr className="divider_blue" />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    dataAditinalFiles.map( item => 
                                        <Box p={1} my={1} mx={1} textAlign="center" className="border_solid-grey" key={ item.id } >
                                            <img src={ img_download } alt=""/> &nbsp;&nbsp;
                                            <a href={ item.url } target="_blank" rel="noopener noreferrer" className="No_Decoration text_grey">
                                                { item.namefile }
                                            </a>
                                        </Box>
                                    )
                                }
                                <Box p={1} pt={0}>  
                                    <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => { AdditionalFile(e); } }  />
                                    <label htmlFor="uploadFile" className="text_primary pointer">
                                        <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                            {
                                                files ? 
                                                    <img src={ previwDocfiles } alt="" className="previw_img" />
                                                    :
                                                    <img src=""  alt=""/>
                                            }
                                            <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                        </Grid>
                                    </label>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={1} pt={0}>
                                    <Typography variant="h6" gutterBottom className="Page_title">
                                        Notes
                                    </Typography>
                                    <hr className="divider_blue" />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box p={1} pt={0}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" gutterBottom className="Page_title">
                                            DRM
                                        </Typography>
                                        {
                                            dataNotesDRM ?
                                                dataNotesDRM.map( item => 
                                                    <AccidentNotes 
                                                        key={ item.id }
                                                        text={item.note}
                                                        user={ item.created_by }
                                                        date={ item.created }
                                                    />
                                                )
                                            : 
                                                <AccidentNotes 
                                                    text="no data found"
                                                />
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={1} pt={0}>
                                            <form onSubmit={e => handleNotesDRM(e)} >
                                                <Typography variant="body1" className="text_grey"><b> ADD NOTE </b></Typography>
                                                <TextareaAutosize aria-label="minimum height" rowsMin={4} className="text_area-style" value={ notesDRM } onChange={e => setNotesDRM(e.target.value)} />
                                                {
                                                    isError.NotesDRM ?
                                                        <> <span className="text_error"><small> Enter a note please </small></span> <br/> </> : null
                                                }
                                                <Button variant="contained" disableElevation className="Go_btn-primary" type="submit" >
                                                    Submit
                                                </Button>
                                            </form>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box p={1} pt={0}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" gutterBottom className="Page_title">
                                            CFM
                                        </Typography>
                                        {
                                            dataNotesCFM ?
                                                dataNotesCFM.map( item => 
                                                    <AccidentNotes 
                                                        key={ item.id }
                                                        text={item.note}
                                                        user={ item.created_by }
                                                        date={ item.created }
                                                    />
                                                )
                                            : 
                                                <AccidentNotes 
                                                    text="no data found"
                                                />
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={1} pt={0}>
                                            <form onSubmit={e => handleNotesCFM(e)} >
                                                <Typography variant="body1" className="text_grey"><b> ADD NOTE</b></Typography>
                                                <TextareaAutosize aria-label="minimum height" rowsMin={4} className="text_area-style"  value={ notesCFM } onChange={e => setNotesCFM(e.target.value)} />
                                                {
                                                    isError.NotesCFM ?
                                                        <> <span className="text_error"><small> Enter a note please </small></span> <br/> </> : null
                                                }
                                                <Button variant="contained" disableElevation className="Go_btn-primary" type="submit" >
                                                    Submit
                                                </Button>
                                            </form>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default AccidentsDetails;