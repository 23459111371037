import React, { useState, useEffect } from 'react'
// Componentes
import NotificacionItem from '../Components/Global/NotificacionItem';
// IMG
import icon_alert from './../Assets/Img/Icon_danger.png'
import icon_salir from './../Assets/Img/Icon_out.png'
//MaterialUI
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// 

const item = {
    'title': 'New functions',
    'date': '27 junio',
    'body_title': 'Drivers',
    'body': 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut cumque rem, quae consequatur perferendis similique assumenda ipsa dolore enim ex. ',
    'btn_action': 'action'
} 

const Notidications = () => {

    const [ searchBy, setSearchBy ] = useState('');

    return(

        <Grid container spacing={2} >
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom className="Page_title">
                    Notifications 
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box p={3} boxShadow={3} borderRadius={5} className="box_content" >

                    <NotificacionItem 
                        icon={ icon_alert }
                        title={ item.title }
                        date={ item.date }
                        body_title={ item.body_title}
                        body={ item.body }
                        btn_action={ item.btn_action }
                    />
                    <NotificacionItem 
                        icon={ icon_alert }
                        title={ item.title }
                        date={ item.date }
                        body_title={ item.body_title}
                        body={ item.body }
                        btn_action={ item.btn_action }
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
export default Notidications
    