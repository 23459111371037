import React,  { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import moment from "moment";

// Componentes
// IMG
import _avatar from './../Assets/Img/Profile_avatar.png';
import Loaders from './../Components/Global/Loaders';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//API
import { APIGO } from './../constants/API'; 
import { Avatar, Button, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { DialogApprove } from '../Components/Global/DialogApprove';

const DriversApprovedList = () => {

    const [ data, setData ]       = useState([]);
    const [active, setActive]     = useState(null)
    const [open, setOpen]         = useState(false)
    const [ refresh, setRefresh ] = useState( 1 );
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ]       = useState( 1 );

    useEffect( () =>{
        var config = {
            method: 'get',
            url: `/users/to-be-approved`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        setLoading( true ) 
        APIGO(config)
        .then(response => {
            if ( response.data.status === "error" ) {
                // List for all vehicles
                setData( false );
            }
            if ( response.data.status === "success" ) {
                setData( response.data.results ); 
            }
            setLoading( false )
        })
        .catch(function (error){
            console.log(error)
            setData( false );
        })
    }, [ refresh ] );

    const handleSelect = ( driver ) => {
        setActive( driver ) 
        setOpen( true ) 
    }

    const ActivePage = ( e, value ) =>{
        setPage( value )
    }

    return(
        <Grid container >
            <DialogApprove open={ open } setOpen={ setOpen } driver={ active } setRefresh={ setRefresh } refresh={ refresh }  />
            <Grid item xs={4} md={2} className="center_center">
                <Link to="/drivers" className="flex No_Decoration">
                    <ArrowBackIosIcon className="Page_title"/>
                    <Typography variant="subtitle1" gutterBottom className="Page_title">
                        Go back
                    </Typography>
                </Link>
            </Grid>
            <Grid item xs={6} md={4} >
                <Typography variant="h4" gutterBottom className="Page_title">
                    Drivers to be approved
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3} style={{minHeight: '150px'}}>
                    { loading && <Loaders onlyLoad={ true } /> }
                    { data.length > 0 ?
                        data.map( (driver, index) => 
                        <Box key={`${index}-d`} px={3} py={0} >
                            <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                                <Grid item xs={12} md={1}>
                                    <Box borderRadius="50%" className="center_center">
                                        <Avatar 
                                            src={ ( driver.avatar === "undefined" || driver.avatar === "" ) ? _avatar : driver.avatar } alt="" className="avatar_large"/>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <span className="ListItem_title"> { driver.full_name }  </span> <br/> 
                                    <span className="ListItem_info"> Id: { driver.rdga } </span><br/>
                                    <span className="ListItem_info"> License expiration:  { moment( driver.licence_exp ).format("MMM Do YY") } </span>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" onClick={ () => handleSelect( driver ) } >DETAILS</Button>
                                </Grid>
                            </Grid>
                            <hr />
                        </Box>
                        )
                        : <Box p={3}> <span className="ListItem_title"> No information at the moment. </span> </Box>
                    }   
                </Paper>
            </Grid>
        </Grid>
    )
}
export default DriversApprovedList
    
