import React, { useState, useEffect } from 'react'
import moment from "moment";
// Componentes
// IMG
import vehicle_O from './../Assets/Img/icon-O.png';
import vehicle_R from './../Assets/Img/icon-R.png';
import vehicle_L from './../Assets/Img/icon-L.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
// graphics
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
// API
import { 
    APIGO,
    getStation, 
    getVehicles, 
    getAccidents, 
    getCicleCheck, 
    getAllEstimates, 
} from './../constants/API'; 
import { Button, Hidden } from '@material-ui/core';


const Reports = () => {

    const [ estimation, setEstimation ] = useState(0);
    const [ data_CC, setData_CC ] = useState([]);
    const [ vehicleInfo, setVehicleInfo] = useState([]);
    const [ claims, setClaims ]   = useState([]);
    const [ refresh, setRefresh ] = useState(0);
    const [ filter, setfilter ]   = useState(1);
    const [ gCc, setGCc] = useState({ 
        'january': 0,
        'february': 0,
        'march': 0, 
        'april': 0,
        'may': 0,
        'june': 0,
        'july': 0,
        'august': 0,
        'september': 0,
        'october': 0,
        'november': 0,
        'december': 0,
    });
    const [ gAccidents, setGAccidents] = useState({ 
        'january': 0,
        'february': 0,
        'march': 0, 
        'april': 0,
        'may': 0,
        'june': 0,
        'july': 0,
        'august': 0,
        'september': 0,
        'october': 0,
        'november': 0,
        'december': 0,
    });

    const [ dateFrom, setDateFrom ]   = useState('');
    const [ dateTo, setDateTo ]   = useState('');
    const [ stations, setStations ] = useState('');
    const [ stationsData, setStationsData ] = useState([]);
    const [ dateFilter, setDateFilter ]   = useState( false );
    const [ stationsFilter, setStationsFilter ] = useState( false );

    useEffect( () =>{
        var config_vehicle = {
            method: 'get',
            url: '/vehicles/?page=1&limit=20',
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_vehicle)
        .then(response =>{
            if ( response.data.status === 'success' ) {
                setVehicleInfo( response.data.info );
            }
            if ( response.data.status === 'error' ) {
                setVehicleInfo( false );
            }
        })
        .catch(function (error){
            console.log(error)
        })

        var config_accident = {
            method: 'get',
            url: '/accident',
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_accident)
        .then(response =>{
            
            if ( response.data.status === 'error' ) {
                setClaims( false );
            }
            if ( response.data.status === 'success' ) {
                setClaims( response.data.info );
                response.data.results.forEach( accident => {
                    switch ( moment( accident.details?.form?.A?.fields[0]?.value ).format('MMMM').toLowerCase() ) {
                        case 'january':
                            setGAccidents({ ...gAccidents, 'january': gAccidents.january++ })
                            break;
                        case 'february':
                            setGAccidents({ ...gAccidents, 'february': gAccidents.february++ })
                            break;
                        case 'march':
                            setGAccidents({ ...gAccidents, 'march': gAccidents.march++ })
                            break;
                        case 'april':
                            setGAccidents({ ...gAccidents, 'april': gAccidents.april++ })
                            break;
                        case 'may':
                            setGAccidents({ ...gAccidents, 'may': gAccidents.may++ })
                            break;
                        case 'june':
                            setGAccidents({ ...gAccidents, 'june': gAccidents.june++ })
                            break;
                        case 'july':
                            setGAccidents({ ...gAccidents, 'july': gAccidents.july++ })
                            break;
                        case 'august':
                            setGAccidents({ ...gAccidents, 'august': gAccidents.august++ })
                            break;
                        case 'september':
                            setGAccidents({ ...gAccidents, 'september': gAccidents.september++ })
                            break;
                        case 'october':
                            setGAccidents({ ...gAccidents, 'october': gAccidents.october++ })
                            break;
                        case 'november':
                            setGAccidents({ ...gAccidents, 'november': gAccidents.november++ })
                            break;
                        case 'december':
                            setGAccidents({ ...gAccidents, 'december': gAccidents.december++ })
                            break;
                    
                        default:
                            break;
                    }
                })
            }
        })
        .catch(function (error){
            console.log(error)
        })

        var config = {
            method: 'get',
            url: getCicleCheck,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then (response => {
            setData_CC( response.data );
            response.data.forEach( cc => {
                switch ( moment( cc.timestamp ).format('MMMM').toLowerCase() ) {
                    case 'january':
                        setGCc({ ...gCc, 'january': gCc.january++ })
                        break;
                    case 'february':
                        setGCc({ ...gCc, 'february': gCc.february++ })
                        break;
                    case 'march':
                        setGCc({ ...gCc, 'march': gCc.march++ })
                        break;
                    case 'april':
                        setGCc({ ...gCc, 'april': gCc.april++ })
                        break;
                    case 'may':
                        setGCc({ ...gCc, 'may': gCc.may++ })
                        break;
                    case 'june':
                        setGCc({ ...gCc, 'june': gCc.june++ })
                        break;
                    case 'july':
                        setGCc({ ...gCc, 'july': gCc.july++ })
                        break;
                    case 'august':
                        setGCc({ ...gCc, 'august': gCc.august++ })
                        break;
                    case 'september':
                        setGCc({ ...gCc, 'september': gCc.september++ })
                        break;
                    case 'october':
                        setGCc({ ...gCc, 'october': gCc.october++ })
                        break;
                    case 'november':
                        setGCc({ ...gCc, 'november': gCc.november++ })
                        break;
                    case 'december':
                        setGCc({ ...gCc, 'december': gCc.december++ })
                        break;
                
                    default:
                        break;
                }
            })
        })
        .catch(function (error){
            console.log(error)
        })

        var configEstimation = {
            method: 'get',
            url: getAllEstimates,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configEstimation)
        .then(response =>{

            if ( dateFilter ) {
                if ( dateFrom && dateTo ) {
                    const newList = response.data.filter( item => { 
                        const dateToCheck = item.created;
                        if ( dateFrom <= dateToCheck && dateToCheck <= dateTo ) {
                            return item
                        }
                    })
                    if ( newList.length <= 0 ) {
                        setClaims( false )
                    }
                    else{
                        let count = 0;
                        newList.forEach( index => {
                            if ( index.active === 1 ) {
                                count = count + index.amount;
                            }
                        })
                        setEstimation(count)
                        setClaims( newList )
                    }
                }
                if ( dateFrom ) {
                    const newList = response.data.filter( item => { 
                        const dateToCheck = item.created;
                        if ( dateFrom <= dateToCheck ) {
                            return item
                        }
                    })
                    if ( newList.length <= 0 ) {
                        setClaims( false )
                    }
                    else{
                        let count = 0;
                        newList.forEach( index => {
                            if ( index.active === 1 ) {
                                count = count + index.amount;
                                //setEstimation( estimation + index.amount ) 
                            }
                        })
                        setEstimation(count)
                        setClaims( newList )
                    } 
                }       
                if ( dateTo ) {
                    const newList = response.data.filter( item => { 
                        const dateToCheck = item.created;
                        if ( dateToCheck <= dateTo ) {
                            return item
                        }
                    })
                    if ( newList.length <= 0 ) {
                        setClaims( false )
                    }
                    else{
                        let count = 0;
                        newList.forEach( index => {
                            if ( index.active === 1 ) {
                                count = count + index.amount;
                                //setEstimation( estimation + index.amount ) 
                            }
                        })
                        setEstimation(count)
                        setClaims( newList )
                    }
                }
            }
            if ( !stationsFilter && !dateFilter ){
                let count = 0;
                response.data.forEach( index => {
                    if ( index.active === 1 ) {
                        count = count + index.amount;
                        //setEstimation( estimation + index.amount ) 
                    }
                })
                setEstimation(count)
            }
        })
        .catch(function (error){
            console.log(error)
        })

        var configStation = {
            method: 'get',
            url: getStation,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configStation)
        .then(response =>{
            setStationsData( response.data )
        })
        .catch(function (error){
            console.log(error)
        })
    }, [ refresh ] );

    useEffect( () => {
        let config_accident;
        if ( stationsFilter ) {
            config_accident = {
                method: 'get',
                url: `/accident?isFilter=true&byStation=${stations}`,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        APIGO(config_accident)
        .then( response => {
            if ( response.data.status === 'error' ) {
                // List for all vehicles
                setClaims( false );
            }
            if ( response.data.status === 'success' ) {
                if ( dateFilter ) {
                    const newList = response.data.results.filter( item => { 
                        const dateToCheck = item.details?.form?.A?.fields[0]?.value;
                        if ( moment(dateToCheck).isBetween(dateFrom, dateTo) ) {
                            return item
                        }
                    })
                    if ( newList.length > 0 ) {
                        resetCountAccident()
                        setClaims( newList )
                        newList.forEach( cc => {
                            switch ( moment( cc.timestamp ).format('MMMM').toLowerCase() ) {
                                case 'january':
                                    setGAccidents({ ...gAccidents, 'january': gAccidents.january++ })
                                    break;
                                case 'february':
                                    setGAccidents({ ...gAccidents, 'february': gAccidents.february++ })
                                    break;
                                case 'march':
                                    setGAccidents({ ...gAccidents, 'march': gAccidents.march++ })
                                    break;
                                case 'april':
                                    setGAccidents({ ...gAccidents, 'april': gAccidents.april++ })
                                    break;
                                case 'may':
                                    setGAccidents({ ...gAccidents, 'may': gAccidents.may++ })
                                    break;
                                case 'june':
                                    setGAccidents({ ...gAccidents, 'june': gAccidents.june++ })
                                    break;
                                case 'july':
                                    setGAccidents({ ...gAccidents, 'july': gAccidents.july++ })
                                    break;
                                case 'august':
                                    setGAccidents({ ...gAccidents, 'august': gAccidents.august++ })
                                    break;
                                case 'september':
                                    setGAccidents({ ...gAccidents, 'september': gAccidents.september++ })
                                    break;
                                case 'october':
                                    setGAccidents({ ...gAccidents, 'october': gAccidents.october++ })
                                    break;
                                case 'november':
                                    setGAccidents({ ...gAccidents, 'november': gAccidents.november++ })
                                    break;
                                case 'december':
                                    setGAccidents({ ...gAccidents, 'december': gAccidents.december++ })
                                    break;
                            
                                default:
                                    break;
                            }
                        })
                    }
                    else{
                        setClaims( false )
                    }
                }
                if ( response.data.info.count > 0 ) {
                    resetCountAccident()
                    setClaims( response.data.info );
                    response.data.results.forEach( accident => {
                        switch ( moment( accident.details?.form?.A?.fields[0]?.value ).format('MMMM').toLowerCase() ) {
                            case 'january':
                                setGAccidents({ ...gAccidents, 'january': gAccidents.january++ })
                                break;
                            case 'february':
                                setGAccidents({ ...gAccidents, 'february': gAccidents.february++ })
                                break;
                            case 'march':
                                setGAccidents({ ...gAccidents, 'march': gAccidents.march++ })
                                break;
                            case 'april':
                                setGAccidents({ ...gAccidents, 'april': gAccidents.april++ })
                                break;
                            case 'may':
                                setGAccidents({ ...gAccidents, 'may': gAccidents.may++ })
                                break;
                            case 'june':
                                setGAccidents({ ...gAccidents, 'june': gAccidents.june++ })
                                break;
                            case 'july':
                                setGAccidents({ ...gAccidents, 'july': gAccidents.july++ })
                                break;
                            case 'august':
                                setGAccidents({ ...gAccidents, 'august': gAccidents.august++ })
                                break;
                            case 'september':
                                setGAccidents({ ...gAccidents, 'september': gAccidents.september++ })
                                break;
                            case 'october':
                                setGAccidents({ ...gAccidents, 'october': gAccidents.october++ })
                                break;
                            case 'november':
                                setGAccidents({ ...gAccidents, 'november': gAccidents.november++ })
                                break;
                            case 'december':
                                setGAccidents({ ...gAccidents, 'december': gAccidents.december++ })
                                break;
                        
                            default:
                                break;
                        }
                    })
                }
                else{
                    setClaims( false )
                }
            }
        })
        .catch(function (error){
            console.log(error)
            setClaims( false );
        }) 

        let config_vehicle;
        if ( stationsFilter ) {
            config_vehicle = {
                method: 'get',
                url: `/vehicles/?page=1&limit=20&filter_by_station=${stations}`,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        APIGO(config_vehicle)
        .then( response => {
            if ( response.data.status === 'error' ) {
                // List for all vehicles
                setVehicleInfo( false );
            }
            if ( response.data.status === 'success' ) {
                // List for all vehicles
                setVehicleInfo( response.data.info );
            }
        })
        .catch(function (error){
            console.log(error)
            setVehicleInfo( false );
        }) 
        

        var configCicleCheck = {
            method: 'get',
            url: getCicleCheck,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configCicleCheck)
        .then ( response => {
            if ( stationsFilter ) {
                resetCountCc()
                console.log( gCc );
                const newList = response.data.filter( item => { 
                    if ( item.id_station === stations ) {
                        return item
                    }
                })
                if ( newList.length > 0 ) {
                    setData_CC( newList );
                    newList.forEach( cc => {
                        switch ( moment( cc.timestamp ).format('MMMM').toLowerCase() ) {
                            case 'january':
                                setGCc({ ...gCc, 'january': gCc.january++ })
                                break;
                            case 'february':
                                setGCc({ ...gCc, 'february': gCc.february++ })
                                break;
                            case 'march':
                                setGCc({ ...gCc, 'march': gCc.march++ })
                                break;
                            case 'april':
                                setGCc({ ...gCc, 'april': gCc.april++ })
                                break;
                            case 'may':
                                setGCc({ ...gCc, 'may': gCc.may++ })
                                break;
                            case 'june':
                                setGCc({ ...gCc, 'june': gCc.june++ })
                                break;
                            case 'july':
                                setGCc({ ...gCc, 'july': gCc.july++ })
                                break;
                            case 'august':
                                setGCc({ ...gCc, 'august': gCc.august++ })
                                break;
                            case 'september':
                                setGCc({ ...gCc, 'september': gCc.september++ })
                                break;
                            case 'october':
                                setGCc({ ...gCc, 'october': gCc.october++ })
                                break;
                            case 'november':
                                setGCc({ ...gCc, 'november': gCc.november++ })
                                break;
                            case 'december':
                                setGCc({ ...gCc, 'december': gCc.december++ })
                                break;
                        
                            default:
                                break;
                        }
                    })
                }
                else{
                    resetCountCc()
                    setData_CC([]);
                }
            }
            if ( dateFilter ) {
                if ( dateFrom && dateTo ) {
                    const newList = data_CC.filter( item => { 
                        const dateToCheck = item.start_date;
                        if ( moment(dateToCheck).isBetween(dateFrom, dateTo) ) {
                            return item
                        }
                    })
                    if ( newList.length > 0 ) {
                        setData_CC( newList )
                        newList.forEach( cc => {
                            switch ( moment( cc.timestamp ).format('MMMM').toLowerCase() ) {
                                case 'january':
                                    setGCc({ ...gCc, 'january': gCc.january++ })
                                    break;
                                case 'february':
                                    setGCc({ ...gCc, 'february': gCc.february++ })
                                    break;
                                case 'march':
                                    setGCc({ ...gCc, 'march': gCc.march++ })
                                    break;
                                case 'april':
                                    setGCc({ ...gCc, 'april': gCc.april++ })
                                    break;
                                case 'may':
                                    setGCc({ ...gCc, 'may': gCc.may++ })
                                    break;
                                case 'june':
                                    setGCc({ ...gCc, 'june': gCc.june++ })
                                    break;
                                case 'july':
                                    setGCc({ ...gCc, 'july': gCc.july++ })
                                    break;
                                case 'august':
                                    setGCc({ ...gCc, 'august': gCc.august++ })
                                    break;
                                case 'september':
                                    setGCc({ ...gCc, 'september': gCc.september++ })
                                    break;
                                case 'october':
                                    setGCc({ ...gCc, 'october': gCc.october++ })
                                    break;
                                case 'november':
                                    setGCc({ ...gCc, 'november': gCc.november++ })
                                    break;
                                case 'december':
                                    setGCc({ ...gCc, 'december': gCc.december++ })
                                    break;
                            
                                default:
                                    break;
                            }
                        })
                    }
                    else{
                        setData_CC( [] )
                    }
                }
            }
        })
        .catch(function (error){
            console.log(error)
        })

    }, [ filter ] );

    const filterByStation = (e) => {
        setStations( e );
        // Reset Data for filters
        setDateFrom('');
        setDateTo('');
        setDateFilter( false );
        setStationsFilter( true );
        setfilter( filter + 1 );
    }
    const filterByDate = (e) => {
        setStations('');
        setStationsFilter( false )
        setDateFilter( true )
        setfilter( filter + 1 );
    }
    const resetCountAccident = () => {
        setGAccidents({
            'january': 0,
            'february': 0,
            'march': 0, 
            'april': 0,
            'may': 0,
            'june': 0,
            'july': 0,
            'august': 0,
            'september': 0,
            'october': 0,
            'november': 0,
            'december': 0,
        })
    }
    const resetCountCc = () => {
        setGCc({
            'january': 0,
            'february': 0,
            'march': 0, 
            'april': 0,
            'may': 0,
            'june': 0,
            'july': 0,
            'august': 0,
            'september': 0,
            'october': 0,
            'november': 0,
            'december': 0,
        })
    }
    const resetFilters = () => {
        // Reset Data for filters
        setStations('');
        setDateFrom('');
        setDateTo('');
        setDateFilter( false );
        setStationsFilter( false );
        setRefresh( refresh + 1 );
    }


    return(
        <Grid container spacing={2} >
            <Grid item xs={12} md={2}>
                <Typography variant="h4" gutterBottom className="Page_title">
                    Reports
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Grid container className="" >
                    <Hidden only={['xs', 'sm']}>
                        <Grid item xs={6}>
                            <Box pl={1}>
                                Browse from: 
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box pl={1}>
                                All stations:
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={3}>
                        <Box p={1}>
                            <TextField id="to" type="date" variant="outlined" fullWidth value={ dateFrom } onChange={e => { setDateFrom(e.target.value); filterByDate(e); }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box p={1}>
                            <TextField id="from" type="date" variant="outlined" fullWidth value={ dateTo } onChange={e => { setDateTo(e.target.value); filterByDate(e);  } } />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box p={1} >
                            <Select labelId="stationID" variant="outlined" displayEmpty className="" fullWidth value={stations} onChange={ e =>{ filterByStation(e.target.value); }} >
                                <MenuItem value=""><em>All stations</em></MenuItem>
                                {
                                    stationsData.map( item => 
                                        <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                    )
                                }
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box p={1} >
                            <Button variant="contained" disableElevation className="Go_btn-primary" onClick={ resetFilters } >Remove filters</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} >
                <Box px={2} py={2} className="bg_white" borderRadius={5} boxShadow={3} >
                    <Grid container>
                        {/* <Grid item xs={6} md={2}>
                            <img src="" alt=""/>
                        </Grid> */}
                        {/* <Grid item xs={3}>
                            <Typography variant="h6" gutterBottom className="Page_title">
                                Total Claims <br/>
                                { claims.length }
                            </Typography>
                        </Grid> */}
                        <Grid item xs={6} md={3}>
                            <Typography variant="h6" gutterBottom className="Page_title">
                                Total Accidents / Claims<br/>
                                { 
                                    !dateFilter 
                                    ? claims.count ? claims.count : '0'
                                    : claims.length
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom className="Page_title" align="right"  >
                                Total value 
                            </Typography>
                            <Typography variant="h6" gutterBottom className="text_grey" align="right" >
                                <b>
                                    ${ estimation }
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box px={5} pb={5} className="box_content" borderRadius={5} boxShadow={3} >
                    <Grid container>
                        <Grid item xs={8}>
                            <Box pt={5}>
                                <Typography variant="h6" gutterBottom className="Page_title">
                                    Total circle check
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="75%" pt={5} className="reportBox_primary" >
                                { data_CC ? data_CC.length : 0 }
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={5}>
                                <Bar
                                    data={{ 
                                        labels: [
                                            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
                                        ],
                                        datasets: [ {
                                            label: 'Circle check',
                                            backgroundColor: '#05396B',
                                            borderColor: '#05396B',
                                            borderWidth: 2,
                                            data: data_CC.length > 0 ? 
                                            [
                                                gCc.january,
                                                gCc.february,
                                                gCc.march,
                                                gCc.april,
                                                gCc.may,
                                                gCc.june,
                                                gCc.july,
                                                gCc.august,
                                                gCc.september,
                                                gCc.october,
                                                gCc.november,
                                                gCc.december,
                                            ]
                                            : [0,0,0,0,0,0,0,0,0,0,0,0]
                                        }]
                                    }}
                                    options={{
                                        legend:{
                                            display:true,
                                            position:'bottom'
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* <Grid item xs={12} md={6}>
                <Box px={5} pb={5} className="box_content" borderRadius={5} boxShadow={3} >
                    <Grid container>
                        <Grid item xs={8}>
                            <Box pt={5}>
                                <Typography variant="h6" gutterBottom className="Page_title">
                                    Daily Avg. GC
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="75%" pt={5} className="reportBox_primary" >
                                
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box pt={5}>
                                <Line
                                    data={dataLineGraphics}
                                    options={{
                                        // title:{
                                        //     display:true,
                                        //     text:'Average Rainfall per month',
                                        //     fontSize:20
                                        // },
                                        legend:{
                                            display:true,
                                            position:'bottom'
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid> */}
            <Grid item xs={12} md={6}>
                <Box px={5} pb={5} className="box_content" borderRadius={5} boxShadow={3} >
                    <Grid container>
                        <Grid item xs={8}>
                            <Box pt={5}>
                                <Typography variant="h6" gutterBottom className="Page_title">
                                    Accidents / Claims
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="75%" pt={5} className="reportBox_primary" >
                                { 
                                    !dateFilter 
                                    ? claims.count ? claims.count : '0'
                                    : claims.length
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={5}>
                            <Bar
                                    data={{ 
                                        labels: [
                                            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
                                        ],
                                        datasets: [ {
                                            label: 'Count',
                                            backgroundColor: '#05396B',
                                            borderColor: '#05396B',
                                            borderWidth: 2,
                                            data: claims.count > 0 
                                            ? [
                                                gAccidents.january,
                                                gAccidents.february,
                                                gAccidents.march,
                                                gAccidents.april,
                                                gAccidents.may,
                                                gAccidents.june,
                                                gAccidents.july,
                                                gAccidents.august,
                                                gAccidents.september,
                                                gAccidents.october,
                                                gAccidents.november,
                                                gAccidents.december,
                                            ]
                                            : [0,0,0,0,0,0,0,0,0,0,0,0] 
                                        }]
                                    }}
                                    options={{
                                        legend:{
                                            display:true,
                                            position:'bottom'
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box px={5} pb={5} className="box_content" borderRadius={5} boxShadow={3} >
                    <Grid container>
                        <Grid item xs={8}>
                            <Box pt={5}>
                                <Typography variant="h6" gutterBottom className="Page_title">
                                    Vehicle fleet
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box width="75%" pt={5} className="reportBox_primary" >
                                { vehicleInfo.count }
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box border={1} m={1} mt={2} >
                                <Box p={2} textAlign="center" >
                                    <img src={ vehicle_O } alt=""/>    
                                </Box>
                                <Box py={2}>
                                    <Typography variant="body1" align="center" className="bg_primary text_white" >
                                        <b> Owned vehicles </b>
                                    </Typography>
                                </Box>
                                <Box p={2} >
                                    <Typography variant="h6" align="center" className="text_primary" >
                                        <b> { vehicleInfo.count_owned } </b>
                                    </Typography>
                                </Box>  
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box border={1} m={1} mt={2} >
                                <Box p={2} textAlign="center" >
                                    <img src={ vehicle_R } alt=""/>    
                                </Box>
                                <Box py={2} >
                                    <Typography variant="body1" align="center" className="bg_primary text_white" >
                                        <b> Rented vehicles </b>
                                    </Typography>
                                </Box>
                                <Box p={2} >
                                    <Typography variant="h6" align="center" className="text_primary" >
                                        <b> { vehicleInfo.count_rented } </b>
                                    </Typography>
                                </Box>  
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box border={1} m={1} mt={2} >
                                <Box p={2} textAlign="center" >
                                    <img src={ vehicle_L } alt=""/>    
                                </Box>
                                <Box py={2} >
                                    <Typography variant="body1" align="center" className="bg_primary text_white" >
                                        <b> Leased vehicles </b>
                                    </Typography>
                                </Box>
                                <Box p={2} >
                                    <Typography variant="h6" align="center" className="text_primary" >
                                        <b> { vehicleInfo.count_leased } </b>
                                    </Typography>
                                </Box>  
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}
export default Reports