import React,  { useState, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import moment from "moment";
// Componentes
import ModalShow from '../Components/Global/Modal';
import HistoryItem from '../Components/Global/HistoryItem';
// IMG
import edit from './../Assets/Img/Icon_edit.png';
import img_add from './../Assets/Img/Icon_add.png'
import file from './../Assets/Img/file_placeholder.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
//API
import { 
    APIGO, 
    getStation, 
    deleteuser, 
    UpdateAvatar,
    UpdateLicense, 
    getDriversById,
    EditDriverAsAdmin,
    getCicleCheckByRDGA, 
    updateDriverDocument,
} from './../constants/API'; 
import * as firebase from 'firebase';
import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { findAllByDisplayValue } from '@testing-library/react';


const Drivers = () => {

    const history   = useHistory();
    const match     = useRouteMatch('/drivers/details/:id');
    const id        = match.params.id;
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState('');
    const [ dataCC, setDataCC ] = useState([]);
    const [ stationsData, setStationsData ] = useState([]);
    const [ stations, setStations ] = useState('');
    const [ msg, setMsg ] = useState('');
    const [ refresh, setRefresh ] = useState(0);

    const [ fullname, setFullname] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ address, setAddress ] = useState('');

    const [ goid, setGoid ] = useState('');
    const [ licenseExp, setLicenseExp ] = useState('');
    const [ licenseExpMsg, setLicenseExpMsg ] = useState('');

    const [ docProfile, setDocProfile] = useState('');
    const [ previwIMG, setPreviwIMG] = useState('');
    const [ AvatarChange, setAvatarChange] = useState( false );

    const [ docDeduction, setDocDeduction ] = useState('https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25');
    const [ previwDeduction, setPreviwDeduction ] = useState('');
    const [ changeDeduction, setChangeDeduction ] = useState( false );
    
    const [ docDriversAbstract, setDocDriversAbstract ] = useState('https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25');
    const [ previwDriversAbstract, setPreviwDriversAbstract ] = useState('');
    const [ changeDriversAbstract, setChangeDriversAbstract ] = useState( false );
    
    const [ docLicenseFront, setDocLicenseFront ] = useState('https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25');
    const [ previwLicenseFront, setPreviwLicenseFront ] = useState('');
    const [ changeLicenseFront, setChangeLicenseFront ] = useState( false );
    
    const [ docProLicenseBack, setDocProLicenseBack ] = useState('https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25');
    const [ previwLicenseBack, setPreviwLicenseBack ] = useState('');
    const [ changeLicenseBack, setChangeLicenseBack ] = useState( false );
    
    const [ isChangeDocuments, setIsChangeDocuments ] = useState(false);
    const [ isChangeLicense, setIsChangeLicense ] = useState(false);
    const [ IsChangeAvatar, setIsChangeAvatar ] = useState( false );
    const [ IsAdmin, setIsAdmin ]   = useState( false );
    const [ isPass, setIsPass]      = useState(false)
    
    const [ changeExp_date, setChangeExp_date ] = useState('');
    const [ pswrd, setPswrd ] = useState('');
    const [ userName, setUserName ] = useState('');
    const [ isError, setIsErrors] = useState([{
        name: false,
        lastName: false,
        phone: false,
        address: false,
        userName: false,
        pswrd: false,
        license_exp: false,
        docLicense_front: false,
        docLicense_back: false,
        docDeduction: false,
        Abstract: false,
        Avatar: false,
        pswrd: false 
    }]);
    // Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");

    const [showPass, setShowPass] = useState(false)
    const [ inputsprops, setInputprops] = useState({
        password: false,
        showPassword: false,
    });
    
    const ModalOpen = () =>  setModalStaus(true);
    const ModalClose = () => setModalStaus(false);
    const handleClickShowPassword = () => {
        setInputprops({ ...inputsprops, showPassword: !inputsprops.showPassword });
        setInputprops({...inputsprops, password: !inputsprops.password })
    };
    const handleMouseDownPassword = (event) => {
    event.preventDefault();
    };
    const getCCbyRdga = () => {
        var config = {
            method: 'get',
            url: `${getCicleCheckByRDGA}${id}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.status !== "error"){
                setDataCC( response.data )
            }
        })
        .catch(function (error){
            console.log(error)
            setModalmsg( "There is no information." );
            setIsShow( true );
            ModalOpen()
        })
    }

    const saveElement = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            // Save element 
            setIsShow( false );
            setIsConfirm( false );
            ModalClose();
            if ( isChangeLicense ) {
                setIsChangeLicense( false )
                const front = changeLicenseFront ? document.getElementById('licenceFront').src : docLicenseFront;
                const back  = changeLicenseBack ? document.getElementById('licenceBack').src : docProLicenseBack;
                const exp   = changeLicenseBack ? document.getElementById('licenceBack').src : docProLicenseBack;

                let config = {
                    method: 'put',
                    url: UpdateLicense+id,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        exp_date: licenseExp,
                        licence_front: front,
                        licence_back: back, 
                    }
                }
                APIGO(config)
                .then(response => {
                    // console.log( response.data );
                    if(response.data.status === "success"){
                        setRefresh( refresh + 1 );
                        setModalmsg(" Information was updated ");
                        setIsShow( true );
                        ModalOpen();
                    }else if(response.data.status === "error"){
                        setModalmsg(response.data.msg);
                        setIsShow( true );
                        ModalOpen();
                        setModalLink('')
                    }
                })
                .catch(function (error){
                    console.log(error)
                })  
            }
            if ( isChangeDocuments ) {
                setIsChangeDocuments( false );
                
                const deductionIMG = changeDeduction ? document.getElementById('DeductionIMG').src : docDeduction ;
                const abstractIMG = changeDriversAbstract ? document.getElementById('Abstractimg').src : docDriversAbstract;
                
                let config = {
                    method: 'put',
                    url: `${updateDriverDocument}${id}`,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        deduction: deductionIMG,
                        drivers_abstract: abstractIMG,
                    }
                }
                APIGO(config)
                .then(response =>{
                    // console.log( response.data );
                    if(response.data.status === "success"){
                        setRefresh( refresh + 1 );
                        setModalmsg(" Information was updated ");
                        setIsShow( true );
                        ModalOpen();
                    }else if(response.data.status === "error"){
                        setModalmsg(response.data.msg);
                        setIsShow( true );
                        ModalOpen();
                        setModalLink('')
                    }
                })
                .catch(function (error){
                    console.log(error)
                })  
            }
            if ( IsChangeAvatar ) {
                setIsChangeDocuments( false );
                const avatarIMG = AvatarChange ? document.getElementById('AvatarIMG').src : docProfile ;
                console.log( AvatarChange ? document.getElementById('AvatarIMG').src : docProfile );
                let config = {
                    method: 'put',
                    url: `${UpdateAvatar}${id}`,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        avatar: avatarIMG,
                    }
                }
                APIGO(config)
                .then(response =>{
                    // console.log( response.data );
                    if(response.data.status === "success"){
                        setRefresh( refresh + 1 );
                        setModalmsg(" Information was updated ");
                        setIsShow( true );
                        ModalOpen();
                    }else if(response.data.status === "error"){
                        setModalmsg(response.data.msg);
                        setIsShow( true );
                        ModalOpen();
                        setModalLink('')
                    }
                })
                .catch(function (error){
                    console.log(error)
                })  
            }
            if ( isPass ) {
                setIsPass( false )
                let config = {
                    method: 'put',
                    url: '/users/resetPassword',
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        rdga: goid,
                        password: pswrd
                    }
                }
                APIGO(config)
                .then(response =>{
                    // console.log( response.data );
                    if(response.data.status === "success"){
                        setRefresh( refresh + 1 );
                        setModalmsg(" Password was updated ");
                        setIsErrors({ ...isError, pswrd: findAllByDisplayValue })
                        setIsShow( true );
                        ModalOpen();
                    }
                    else if( response.data.status === "error" ){
                        if ( response.data.msg[0].password ) {
                            setModalmsg('The password must have uppercase, lowercase, numbers and min length 8');
                            setIsErrors({ ...isError, pswrd: true })
                        }
                        else{
                            setModalmsg('A problem happened please try again in a few minutes.');
                        }
                        setIsShow( true );
                        ModalOpen();
                        setModalLink('')
                    }
                })
                .catch(function (error){
                    console.log(error)
                }) 
            }
            if ( IsAdmin ) {
                setIsAdmin( false )

                let avatarIMG       = AvatarChange ? document.getElementById('AvatarIMG').src : docProfile ;
                let deductionIMG    = changeDeduction ? document.getElementById('DeductionIMG').src : docDeduction ;
                let abstractIMG     = changeDriversAbstract ? document.getElementById('Abstractimg').src : docDriversAbstract;
                let licenceFront    = changeLicenseFront ? document.getElementById('licenceFront').src : docLicenseFront ;
                let licenceBack     = changeLicenseBack ? document.getElementById('licenceBack').src : docProLicenseBack;
                let config = {
                    method: 'put',
                    url: `${EditDriverAsAdmin}`,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        rdga: goid,
                        email: email,
                        phone: phone,
                        type: 3,
                        full_name: fullname,
                        driver_username: username,
                        address: address,
                        licence_exp: licenseExp !== 'Invalid date' ? licenseExp : '2020-01-01' ,
                        station: stations,
                        avatar: avatarIMG,
                        deduction: deductionIMG,
                        licence_front: licenceFront,
                        licence_back: licenceBack,
                        drivers_abstract: abstractIMG
                    }
                }
                APIGO(config)
                .then(response =>{
                    // console.log( response );
                    if(response.data.status === "success"){
                        setRefresh( refresh + 1 );
                        setModalmsg(" Information was updated ");
                        setIsShow( true );
                        ModalOpen();
                    }
                    else if(response.data.status === "error"){
                        if ( response.data.msg[0].licence_front && response.data.msg[1].licence_back ) {
                            setModalmsg('License are required');
                        }
                        setIsShow( true );
                        ModalOpen();
                        setModalLink('')
                    }
                })
                .catch(function (error){
                    console.log(error)
                }) 
            }
        }   
        if ( status === false ){
            setIsDeleted( false );
            setIsConfirm( false );
            ModalClose()
        }
    }

    const deleteElement = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            setIsDeleted( false );
            ModalClose()
            let config = {
                method: 'delete',
                url: deleteuser+id,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
            APIGO(config)
            .then(response =>{
                if(response.data.status == "success"){
                    setModalmsg('Driver was removed successfully');
                    setModalLink('/drivers')
                    setIsShow( true );
                    ModalOpen()
                }else {
                    setModalmsg(response.data.msg);
                    setIsShow( true );
                    setModalmsg('Oops! a problem happened, please try again.');
                    ModalOpen()
                }
            })
            .catch(function (error){
                console.log(error)
                setModalmsg( "Oops! a problem happened, please try again." );
                setIsShow( true );
                ModalOpen()
            })  
        }
        if ( status === false ){
            setIsDeleted( false );
            setIsConfirm( false )
            ModalClose()
        }
    }
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const chackDate = ( e ) => {
        if ( moment( e.target.value , "YYYY-MM-DD").fromNow().includes('ago') ) {
            setIsErrors({ license_exp: true });
            setLicenseExpMsg( 'License has expired' )
        }
        else if (  moment( e.target.value , "YYYY-MM-DD").fromNow().includes('days') ) {
            setIsErrors({ license_exp: true });
            setLicenseExpMsg( 'License is near to expire' )
        }
        else{
            setIsErrors({ license_exp: false });
        }
    }
    const handleForm = e => { 
        e.preventDefault();
        
        if( !changeExp_date ){ setIsErrors({ license_exp: true }); }
        else if( !changeLicenseFront ){ setIsErrors({ docLicense_front: true }); }
        else if( !changeLicenseBack ){ setIsErrors({ docLicense_back: true }); }
        else {
            setIsErrors([{
                license_exp: false,
                docLicense_front: false,
                docLicense_back: false
            }]);
            setIsShow( false )
            setIsConfirm( true );
            setIsChangeLicense( true )
            ModalOpen( true );
        }

        if( !changeDeduction ){ setIsErrors({ docDeduction: true }); }
        else if( !changeDriversAbstract ){ setIsErrors({ Abstract: true }); }
        else {
            setIsErrors([{
                Abstract: false,
                docDeduction: false,
            }]);
            setIsChangeDocuments( true );
            setIsConfirm( true );
            setIsShow( false );
            ModalOpen( true );
        }

        if( !AvatarChange ){ setIsErrors({ Avatar: true }); }
        else {
            setIsErrors([{ Avatar: false }]);
            setIsChangeAvatar( true );
            setIsConfirm( true );
            setIsShow( false );
            ModalOpen( true );
        }
    }
    const handleFormAdmin = e => { 
        e.preventDefault();
        if(goid === "" ){ setIsErrors({ goid: true }); }
        else if(fullname === ""){ setIsErrors({ fullname: true }); }
        else if(phone === ""){ setIsErrors({ phone: true }); setMsg('Enter a Phone please') }
        else if(address === ""){ setIsErrors({ address: true }); }
        else if(email === ""){ setIsErrors({ email: true }); setMsg('Enter a email please') }
        // else if(licenseExp === ""){ setIsErrors({ license_exp: true }); setMsg('Enter a date please') }
        else if(stations === ""){ setIsErrors({ station: true }); setMsg('Enter a station please') }
        else {
            setIsErrors('');
            setMsg('')
            setIsAdmin( true );
            setIsConfirm( true );
            setIsShow( false )
            ModalOpen( true );
        }
    }
    const onDelete = () => {
        ModalOpen()
        setIsShow( false )
        setIsDeleted( true )
        setIsConfirm( false )
    }
    const previwDocAbstract = ( e ) =>{
        e.preventDefault();
        setLoading( true )
        setDocDriversAbstract(e.target.files[0]);
        var storage = firebase.storage().ref();
        var upload = storage.child(`/drivers/abstract/${id}`);
        upload.put( e.target.files[0] ).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                console.log( url );
                const profileImg =  document.getElementById("Abstractimg")
                const PreviwIMG =  document.getElementById("AbstractPreviw")
                profileImg.src = url;
                PreviwIMG.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
        console.log( changeDriversAbstract );
    }
    const previwDocDeduction = ( e ) =>{
        e.preventDefault();
        setLoading( true )
        setDocDeduction(e.target.files[0]);
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/deduction/${id}`);
        upload.put( e.target.files[0] ).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("DeductionIMG")
                const PreviwIMG =  document.getElementById("DeductionPreviw")
                profileImg.src = url;
                PreviwIMG.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwLicenseFrontDoc = ( e ) =>{
        e.preventDefault();
        setLoading( true )
        setDocLicenseFront(e.target.files[0]);
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/licence/front_${id}`);
        upload.put( e.target.files[0] ).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("licenceFront")
                const PreviwIMG =  document.getElementById("licenceFrontPreviw")
                profileImg.src = url;
                PreviwIMG.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwsetLicenseBackDoc = ( e ) =>{
        e.preventDefault();
        setLoading( true )
        setDocProLicenseBack(e.target.files[0]);
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/licence/back_${id}`);
        upload.put( e.target.files[0] ).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("licenceBack")
                const PreviwIMG =  document.getElementById("licenceBackPreviw")
                profileImg.src = url;
                PreviwIMG.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwAvatar = ( e ) =>{
        e.preventDefault();
        setLoading( true )
        setDocProfile( e.target.files[0] );
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/avatar/rdga_${id}`);
        upload.put( e.target.files[0] ).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("AvatarIMG")
                profileImg.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwImg = ( e ) =>{
        try { setPreviwIMG( URL.createObjectURL( e.target.files[0] ) ) } 
        catch (error) { console.log( error ); }
    }
    useEffect( () =>{
        var configDrop = {
            method: 'get',
            url: getStation,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configDrop)
        .then(response =>{
            setStationsData( response.data )
        })
        .catch( error => { console.log(error) } )

        var config = {
            method: 'get',
            url: `${getDriversById}${id}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.message){
                setModalmsg( response.data.message );
                setIsShow( true );
                ModalOpen()
            }
            else{
                setData({
                    avatar: response.data.avatar,
                    full_name: response.data.full_name,
                    phone: response.data.phone,
                    address: response.data.address,
                    user_name: response.data.user_name,
                    go_id: response.data.go_id,
                    license_expiration: response.data.license_expiration,
                    doc_deduction: response.data.doc_deduction,
                    doc_license_front: response.data.doc_license_front,
                    doc_license_back: response.data.doc_license_back,
                    doc_abstract: response.data.drivers_abstract
                });
                setDocProfile(response.data.avatar)
                setFullname(response.data.full_name)
                setPhone(response.data.phone)
                setUsername(response.data.user_name)
                setAddress(response.data.address)
                setGoid(response.data.rdga)
                setStations(response.data.station)
                setEmail(response.data.email)
                setLicenseExp( moment( response.data.licence_exp ).format('YYYY-MM-DD') )
                // 
                setDocDeduction( response.data.deduction )
                setDocDriversAbstract( response.data.drivers_abstract )
                setDocLicenseFront( response.data.licence.licence_front )
                setDocProLicenseBack( response.data.licence.licence_back )
                if ( moment( response.data.licence_exp , "YYYY-MM-DD").fromNow().includes('ago') ) {
                    setIsErrors({ license_exp: true });
                    setLicenseExpMsg( 'License has expired' )
                }
                if (  moment( response.data.licence_exp , "YYYY-MM-DD").fromNow().includes('days') ) {
                    setIsErrors({ license_exp: true });
                    setLicenseExpMsg( 'License is near to expire' )
                }
            }
        })
        .catch(function (error){
            console.log(error)
            setModalmsg( "We couldn’t get all the data. It’s possible the profile is not complete. Check and complete when possible" );
            setIsShow( true );
            ModalOpen()
        })
        getCCbyRdga()
    }, [ refresh ] );

    if ( localStorage.getItem('Type') === '1' || localStorage.getItem('Type') === '4' ) {
        return(
            <>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                    { isDeleted ?
                            <ModalShow 
                                title=" Are you sure to delete? "
                                confirmDelete={ true }
                                Delete={ deleteElement }
                            /> : null
                    }
                    { isConfirm ?
                            <ModalShow 
                            title=" Are you sure you save changes at driver? "
                            confirmOperation={ true }
                            Operation={ saveElement }
                            /> : null
                    }    
                    { isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                    </Grid>
                </Modal>
                <form onSubmit={ e => handleFormAdmin(e) } >
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={2} className="center_center">
                            <Link to="/drivers" className="flex No_Decoration">
                                <ArrowBackIosIcon className="Page_title"/>
                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                    Go back
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={8} md={6} >
                            <Typography variant="h4" gutterBottom className="Page_title">
                                Driver details
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Button variant="outlined" disableElevation fullWidth className="" onClick={ onDelete } >DELETE</Button>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Button 
                                variant="contained" 
                                disableElevation 
                                fullWidth 
                                className="Go_btn-primary" 
                                type={ loading ? "button" : "submit" } 
                            >
                                { loading ? "LOADING..." : "SAVE" }
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Box py={3}>
                                                <input type="file" id="ProfileImage" className="fileUpload" onChange={ e => { previwImg(e); setAvatarChange( true ); previwAvatar( e ); } }  />
                                                <label htmlFor="ProfileImage" className="labelFileImg">
                                                { AvatarChange ? 
                                                    <img src={ previwIMG } alt="previw" className="profileImg rounded" />
                                                    :
                                                    <img src={ docProfile } alt="profile" className="previw_img rounded" />
                                                }
                                                    <img src={ edit } alt="" className="" />
                                                </label>
                                                <img src="" alt="" className="previw_img d-none" id={'AvatarIMG'} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="DriveLastName" className="labelText"><small>NAME</small></label>
                                            { isError.fullname ?
                                                    <TextField id="DriveLastName" type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} error helperText="Enter a valid name please" /> 
                                                : 
                                                    <TextField id="DriveLastName" type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="DriverPhone" className="labelText" ><small>PHONE NUMBER</small></label>
                                            { isError.phone ?
                                                    <TextField id="DriverPhone" type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} error helperText={msg}/> 
                                                : 
                                                    <TextField id="DriverPhone" type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="email" className="labelText"><small>EMAIL</small></label>
                                            { isError.email ?
                                                    <TextField id="email" type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} error helperText={msg} /> 
                                                : 
                                                    <TextField id="email" type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="DriveUsername" className="labelText"><small> USERNAME </small></label>
                                            { isError.userName ?
                                                    <TextField id="DriveUsername" type="text" variant="outlined" fullWidth value={ username } onChange={e => setUsername(e.target.value)} error helperText="Enter a valid Username please" /> 
                                                : 
                                                    <TextField id="DriveUsername" type="text" variant="outlined" fullWidth value={ username } onChange={e => setUsername(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="DriveAddress" className="labelText"><small> ADDRESS </small></label>
                                            { isError.address ?
                                                    <TextField id="DriveAddress" type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} error helperText="Enter a valid Address please" /> 
                                                : 
                                                    <TextField id="DriveAddress" type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="Station" className="labelText"><small>Station</small></label>
                                            <Select labelId="Station" variant="outlined" value={stations} onChange={e => setStations(e.target.value)} displayEmpty className="" fullWidth >
                                                <MenuItem value=""><em>Select</em></MenuItem>
                                                { stationsData?.map( item => (
                                                        <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            { isError.Avatar ?
                                                    <span className="text_error"><small> Enter a Station please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="RDGA" className="labelText" ><small>RDGA</small></label>
                                            { isError.goid ?
                                                    <TextField id="RDGA" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} error helperText="Enter a valid id please" /> 
                                                : 
                                                    <TextField id="RDGA" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="date" className="labelText" ><small>DRIVER LICENSE EXPIRATE DATE</small></label>
                                            { isError.license_exp ?
                                                    <TextField id="date" type="date" variant="outlined" fullWidth value={ licenseExp } onChange={e =>{ setLicenseExp(e.target.value); setChangeExp_date( true ); chackDate(e) } } error helperText={ licenseExpMsg } /> 
                                                : 
                                                    <TextField id="date" type="date" variant="outlined" fullWidth value={ licenseExp } onChange={e =>{ setLicenseExp(e.target.value); setChangeExp_date( true ); chackDate(e)  } } />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                        <label htmlFor="DrivePswrd" className="labelText"><small>PASSWORD</small></label>
                                        { isError.pswrd ?
                                            <OutlinedInput id="DrivePswrd" type={showPass ? 'text' : 'password'} fullWidth value={ pswrd } onChange={e => {setPswrd(e.target.value); setIsPass(true) }}  labelWidth={0} helperText={msg} error
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                endAdornment={
                                                    <InputAdornment 
                                                        position="end"
                                                        onClick={ () => setShowPass( !showPass )  }
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPass ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            :
                                            <OutlinedInput id="DrivePswrd" type={showPass ? 'text' : 'password'} fullWidth value={ pswrd } onChange={e => {setPswrd(e.target.value); setIsPass(true) }} labelWidth={0}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                endAdornment={
                                                    <InputAdornment 
                                                        position="end"
                                                        onClick={ () => setShowPass( !showPass )  }
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPass ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                        </Box>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={3}>
                                        <Box p={2} pt={0}>  
                                            <span className="labelText"><small>DRIVERS ABSTRACT</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                <a href={ docDriversAbstract } target="_blank" rel="noopener noreferrer">
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        { docDriversAbstract ?
                                                                changeDriversAbstract ?
                                                                    <img src={ file } alt="file" className="previw_img" id={'AbstractPreviw'} />
                                                                    :
                                                                    <img src={ docDriversAbstract } alt="file-chek" className="previw_img" />
                                                            :   null
                                                        }
                                                    </Grid>
                                                </a>
                                                {  previwDriversAbstract && previwDriversAbstract }
                                                <img src="" alt="" className="previw_img d-none" id={'Abstractimg'} />
                                                <input type="file" id="uploadFileAbstract" className="fileUpload" name="fileUpload" onChange={ e => { setChangeDriversAbstract( true ); setPreviwDriversAbstract(e.target.files[0].name); previwDocAbstract( e ); } }  />
                                                <label htmlFor="uploadFileAbstract" className="text_primary pointer">
                                                    <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                                </label>
                                            </Grid>
                                            { isError.Abstract ?
                                                    <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2} pt={0}>  
                                            <span className="labelText"><small>DEDUCTION FORM</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                <a href={ docDeduction } target="_blank" rel="noopener noreferrer">
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        { docDeduction ?
                                                                changeDeduction ?
                                                                    <img src={ file } alt="file" className="previw_img" id={'DeductionPreviw'} />
                                                                    :
                                                                    <img src={ docDeduction } alt="file-chek" className="previw_img" />
                                                            :   null
                                                        }
                                                    </Grid>
                                                </a>
                                                {  previwDeduction && previwDeduction }
                                                <img src="" alt="" className="previw_img d-none" id={'DeductionIMG'} />
                                                <input type="file" id="uploadFileDeduction" className="fileUpload" name="fileUpload" onChange={ e => { setChangeDeduction( true ); setPreviwDeduction(e.target.files[0].name); previwDocDeduction( e ); } }  />
                                                <label htmlFor="uploadFileDeduction" className="text_primary pointer">
                                                    <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                                </label>
                                            </Grid>
                                            { isError.docDeduction ?
                                                    <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2} pt={0}>  
                                            <span className="labelText"><small>DRIVER LICENSE FRONT</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                <a href={ docLicenseFront } target="_blank" rel="noopener noreferrer">
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        { docLicenseFront ?
                                                                changeLicenseFront ?
                                                                    <img src={ file } alt="file" className="previw_img" id={'licenceFrontPreviw'} />
                                                                    :
                                                                    <img src={ docLicenseFront } alt="file-chek" className="previw_img" />
                                                            : null
                                                        }
                                                    </Grid>
                                                </a>
                                                {  previwLicenseFront && previwLicenseFront }
                                                <img src="" alt="" className="previw_img d-none" id={'licenceFront'} />
                                                <input type="file" id="uploadFileLicenseFront" className="fileUpload" name="fileUpload" onChange={ e => { setChangeLicenseFront( true ); setPreviwLicenseFront(e.target.files[0].name); previwLicenseFrontDoc( e );  } }  />
                                                <label htmlFor="uploadFileLicenseFront" className="text_primary pointer">
                                                    <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                                </label>
                                            </Grid>
                                            { isError.docLicense_front ?
                                                <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2} pt={0}>  
                                            <span className="labelText"><small>DRIVER LICENSE BACK</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                <a href={ docProLicenseBack } target="_blank" rel="noopener noreferrer">
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        { docProLicenseBack ?
                                                                changeLicenseBack ?
                                                                    <img src={ file } alt="file" className="previw_img" id={'licenceBackPreviw'} />
                                                                    :
                                                                    <img src={ docProLicenseBack } alt="file-chek" className="previw_img" />
                                                            : null
                                                        }
                                                    </Grid>
                                                </a>
                                                {  previwLicenseBack && previwLicenseBack }
                                                <img src="" alt="" className="previw_img d-none" id={'licenceBack'} />
                                                <input type="file" id="uploadFileLicenseBack" className="fileUpload" name="fileUpload" onChange={ e => { setChangeLicenseBack( true ); setPreviwLicenseBack(e.target.files[0].name); previwsetLicenseBackDoc( e ); } }  />
                                                <label htmlFor="uploadFileLicenseBack" className="text_primary pointer">
                                                    <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                                </label>
                                            </Grid>
                                            { isError.docLicense_back ?
                                                <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    { localStorage.getItem('Type') !== '4' &&
                                    <>
                                        <Grid item xs={12}>
                                            <Box p={1} py={0} mt={2}> 
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Circle checks and history
                                                    <hr className="divider_blue" />
                                                </Typography>        
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={1}  py={0}>
                                                { dataCC ?
                                                    dataCC.map( item => (
                                                        <HistoryItem
                                                            key={item.check_id}
                                                            id={item.driver_id}
                                                            img={item.driver_avatar}
                                                            name={item.name}
                                                            start_date={item.start_date}
                                                            end_date={item.end_date}
                                                            cicleID={item.check_id}
                                                            link={`/history/details/${item.check_id}`}
                                                            status={3}
                                                        />
                                                    ) )
                                                    :
                                                    <Typography variant="body2" gutterBottom className="Page_title">
                                                        No information
                                                    </Typography>
                                                }
                                            </Box>
                                        </Grid>
                                    </>
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
    
    if ( localStorage.getItem('Type') === '2' ) {
        return(
            <>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                    {
                        isDeleted ?
                            <ModalShow 
                                title=" Are you sure to delete? "
                                confirmDelete={ true }
                                Delete={ deleteElement }
                            /> : null
                    }
                    {
                        isConfirm ?
                            <ModalShow 
                            title=" Are you sure you save changes at driver? "
                            confirmOperation={ true }
                            Operation={ saveElement }
                            /> : null
                    }    
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                    </Grid>
                </Modal>
                <form onSubmit={e=> handleForm(e)} >
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={2} className="center_center">
                            <Link to="/drivers" className="flex No_Decoration">
                                <ArrowBackIosIcon className="Page_title"/>
                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                    Go back
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={8} md={6} >
                            <Typography variant="h4" gutterBottom className="Page_title">
                                Driver details
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            { 
                                localStorage.getItem('PD_D') === '1' && 
                                    <Button variant="outlined" disableElevation fullWidth className="" onClick={ onDelete } >DELETE</Button>
                            }
                        </Grid>
                        <Grid item xs={6} md={2}>
                            { localStorage.getItem('PD_U') === '1' && 
                                <Button 
                                    variant="contained" 
                                    disableElevation 
                                    fullWidth 
                                    className="Go_btn-primary" 
                                    type={ loading ? "button" : "submit" } 
                                >
                                    { loading ? "LOADING..." : "SAVE" }
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Box py={3}>
                                                { localStorage.getItem('PD_U') === '1' ?
                                                    <input type="file" id="ProfileImage" className="fileUpload" onChange={ e => { previwImg(e); setAvatarChange( true ); previwAvatar( e ); } }  />
                                                    :
                                                    <input type="file" id="ProfileImage" className="fileUpload" disabled  />
                                                }
                                                <label htmlFor="ProfileImage" className="labelFileImg">
                                                { AvatarChange ? 
                                                    <>
                                                        <img src={ previwIMG } alt="previw" className="profileImg rounded" />
                                                        <img src={ edit } alt="" className="" />
                                                    </>
                                                    :
                                                    <img src={ docProfile } alt="profile" className="previw_img rounded" />
                                                }
                                                </label>
                                                <img src="" alt="" className="previw_img d-none" id={'AvatarIMG'} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="DriveLastName" className="labelText"><small>NAME</small></label>
                                            { isError.fullname ?
                                                <TextField id="DriveLastName" disabled type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} error helperText="Enter a valid name please" /> 
                                                : 
                                                <TextField id="DriveLastName" disabled type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="DriverPhone" className="labelText" ><small>PHONE NUMBER</small></label>
                                            { isError.phone ?
                                                <TextField id="DriverPhone" disabled type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} error helperText={msg}/> 
                                                : 
                                                <TextField id="DriverPhone" disabled type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="email" className="labelText"><small>EMAIL</small></label>
                                            { isError.email ?
                                                <TextField id="email" disabled type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} error helperText={msg} /> 
                                                : 
                                                <TextField id="email" disabled type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="DriverUsername" className="labelText"><small> USERNAME </small></label>
                                            { isError.address ?
                                                <TextField id="DriverUsername" disabled type="text" variant="outlined" fullWidth value={ username } onChange={e => setUsername(e.target.value)} error helperText="Enter a valid Username please" /> 
                                                : 
                                                <TextField id="DriverUsername" disabled type="text" variant="outlined" fullWidth value={ username } onChange={e => setUsername(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Box p={1}> 
                                            <label htmlFor="DriveAddress" className="labelText"><small> ADDRESS </small></label>
                                            { isError.userName ?
                                                <TextField id="DriveAddress" disabled type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} error helperText="Enter a valid Address please" /> 
                                                : 
                                                <TextField id="DriveAddress" disabled type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="RDGA" className="labelText" ><small>RDGA</small></label>
                                            { isError.goid ?
                                                <TextField id="RDGA" disabled type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} error helperText="Enter a valid id please" /> 
                                                : 
                                                <TextField id="RDGA" disabled type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="date" className="labelText" ><small>DRIVER LICENSE EXPIRATE DATE</small></label>
                                            { localStorage.getItem('PD_U') === '1' ?
                                                isError.license_exp ?
                                                    <TextField id="date" type="date" variant="outlined" fullWidth value={ licenseExp } onChange={e =>{ setLicenseExp(e.target.value); setChangeExp_date( true ); chackDate(e) } } error helperText={ licenseExpMsg } /> 
                                                    : 
                                                    <TextField id="date" type="date" variant="outlined" fullWidth value={ licenseExp } onChange={e =>{ setLicenseExp(e.target.value); setChangeExp_date( true ); chackDate(e)  } } />
                                                :
                                                    isError.license_exp ?
                                                    <TextField id="date" type="date" variant="outlined" fullWidth value={ licenseExp } disabled error helperText={ licenseExpMsg } /> 
                                                    : 
                                                    <TextField id="date" type="date" variant="outlined" fullWidth value={ licenseExp } disabled />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box p={1}> 
                                            <label htmlFor="Station" className="labelText"><small>Station</small></label>
                                            <Select labelId="Station" disabled variant="outlined" value={stations} onChange={e => setStations(e.target.value)} displayEmpty className="" fullWidth >
                                                <MenuItem value=""><em>Select</em></MenuItem>
                                                {
                                                    stationsData?.map( item => (
                                                        <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2} pt={0}>  
                                            <span className="labelText"><small>DRIVERS ABSTRACT</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                <a href={ docDriversAbstract } target="_blank" rel="noopener noreferrer">
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        { docDriversAbstract ?
                                                                changeDriversAbstract ?
                                                                <img src={ file } alt="file" className="previw_img" id={'AbstractPreviw'} />
                                                                :
                                                                <img src={ docDriversAbstract } alt="file" className="previw_img" />
                                                            :   null
                                                        }
                                                    </Grid>
                                                </a>
                                                {  previwDriversAbstract && previwDriversAbstract }
                                                <img src="" alt="" className="previw_img d-none" id={'Abstractimg'} />
                                                { localStorage.getItem('PD_U') === '1' ?
                                                    <input type="file" id="uploadFileAbstract" className="fileUpload" name="fileUpload" onChange={ e => { setChangeDriversAbstract( true ); setPreviwDriversAbstract(e.target.files[0].name); previwDocAbstract(e); } }  />
                                                    :
                                                    <input type="file" id="uploadFileAbstract" className="fileUpload" name="fileUpload" disabled  />
                                                }
                                                <label htmlFor="uploadFileAbstract" className="text_primary pointer">
                                                    <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                                </label>
                                            </Grid>
                                            { isError.Abstract ?
                                                <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2} pt={0}>  
                                            <span className="labelText"><small>DEDUCTION FORM</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                <a href={ docDeduction } target="_blank" rel="noopener noreferrer">
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        { docDeduction ?
                                                            changeDeduction ?
                                                            <img src={ file } alt="file" className="previw_img" id={'DeductionPreviw'} />
                                                            :
                                                            <img src={ docDeduction } alt="file" className="previw_img" />
                                                            :   null
                                                        }
                                                    </Grid>
                                                </a>
                                                {  previwDeduction && previwDeduction }
                                                <img src="" alt="" className="previw_img d-none" id={'DeductionIMG'} />
                                                { 
                                                    localStorage.getItem('PD_U') === '1' ?
                                                        <input type="file" id="uploadFileDeduction" className="fileUpload" name="fileUpload" onChange={ e => { setChangeDeduction( true ); setPreviwDeduction(e.target.files[0].name); previwDocDeduction(e); } }  />
                                                        :
                                                        <input type="file" id="uploadFileDeduction" className="fileUpload" name="fileUpload" disabled />
                                                }
                                                <label htmlFor="uploadFileDeduction" className="text_primary pointer">
                                                    <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                                </label>
                                            </Grid>
                                            {
                                                isError.docDeduction ?
                                                    <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2} pt={0}>  
                                            <span className="labelText"><small>DRIVER LICENSE FRONT</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                <a href={ docLicenseFront } target="_blank" rel="noopener noreferrer">
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        {
                                                            docLicenseFront ?
                                                                changeLicenseFront ?
                                                                <img src={ file } alt="file" className="previw_img" id={'licenceFrontPreviw'} />
                                                                :
                                                                <img src={ docLicenseFront } alt="file" className="previw_img" />
                                                            :   null
                                                        }
                                                    </Grid>
                                                </a>
                                                {  previwLicenseFront && previwLicenseFront }
                                                <img src="" alt="" className="previw_img d-none" id={'licenceFront'} />
                                                { 
                                                    localStorage.getItem('PD_U') === '1' ?
                                                        <input type="file" id="uploadFileLicenseFront" className="fileUpload" name="fileUpload" onChange={ e => { setChangeLicenseFront( true ); setPreviwLicenseFront(e.target.files[0].name); previwLicenseFrontDoc(e); } }  />
                                                        :
                                                        <input type="file" id="uploadFileLicenseFront" className="fileUpload" name="fileUpload" disabled />
                                                }
                                                <label htmlFor="uploadFileLicenseFront" className="text_primary pointer">
                                                    <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                                </label>
                                            </Grid>
                                            {
                                                isError.docLicense_front ?
                                                    <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box p={2} pt={0}>  
                                            <span className="labelText"><small>DRIVER LICENSE BACK</small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                <a href={ docProLicenseBack } target="_blank" rel="noopener noreferrer">
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        {
                                                            docProLicenseBack ?
                                                                changeLicenseBack ?
                                                                <img src={ file } alt="file" className="previw_img" id={'licenceBackPreviw'} />
                                                                :
                                                                <img src={ docProLicenseBack } alt="file" className="previw_img" />
                                                            :   null
                                                        }
                                                    </Grid>
                                                </a>
                                                {  previwLicenseBack && previwLicenseBack }
                                                <img src="" alt="" className="previw_img d-none" id={'licenceBack'} />
                                                { 
                                                    localStorage.getItem('PD_U') === '1' ?
                                                        <input type="file" id="uploadFileLicenseBack" className="fileUpload" name="fileUpload" onChange={ e => { setChangeLicenseBack( true ); setPreviwLicenseBack(e.target.files[0].name); previwsetLicenseBackDoc(e); } }  />
                                                        :
                                                        <input type="file" id="uploadFileLicenseBack" className="fileUpload" name="fileUpload" disabled />
                                                }
                                                <label htmlFor="uploadFileLicenseBack" className="text_primary pointer">
                                                    <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                                </label>
                                            </Grid>
                                            {
                                                isError.docLicense_back ?
                                                    <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={1} py={0} mt={2}> 
                                            <Typography variant="h6" gutterBottom className="Page_title">
                                                Circle checks and history
                                                <hr className="divider_blue" />
                                            </Typography>        
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={1}  py={0}>
                                            {
                                                dataCC ?
                                                    dataCC.map( item => (
                                                        <HistoryItem
                                                            key={item.check_id}
                                                            id={item.driver_id}
                                                            img={item.driver_avatar}
                                                            name={item.name}
                                                            start_date={item.start_date}
                                                            end_date={item.end_date}
                                                            cicleID={item.check_id}
                                                            link={`/history/details/${item.check_id}`}
                                                            status={3}
                                                        />
                                                    ) )
                                                :
                                                    <Typography variant="body2" gutterBottom className="Page_title">
                                                        No information
                                                    </Typography>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}
export default Drivers
