import React,  { useState, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import moment from "moment";
// Componentes
import ModalShow from '../Components/Global/Modal';
import ModalConfirm from '../Components/Global/ModalConfirm';
// IMG
import user from './../Assets/Img/user_example.png';
import edit from './../Assets/Img/Icon_edit.png';
import img_add from './../Assets/Img/Icon_add.png'
import file_placeholder from './../Assets/Img/file_placeholder.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//API
import { 
    APIGO, 
    getStation, 
    deleteuser, 
    UpdateAvatar,
    DeleteManager,
    GetManagerByID,
    permissionVehicle, 
    permissionDrivers, 
    permissionAccidents, 
    permissionReports, 
    permissionHistory,
    permissionSettings,
    UpdateStationManager, 
} from '../constants/API'; 
import * as firebase from 'firebase';
import { MD5 } from 'crypto-js';


const ManagersDetails = () => {

    const history   = useHistory();
    const match     = useRouteMatch('/manager/details/:id');
    const id        = match.params.id;
    const [ stationsData, setStationsData ] = useState([]);
    const [ refresh, setRefresh ] = useState(0);
    const [ msg, setMsg ] = useState('');
    const [ loading, setLoading ] = useState(false);
    // General information 
    const [ fullname, setFullname] = useState('');
    const [ username, setUsername] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ address, setAddress ] = useState('')
    const [ stations, setStations ] = useState('');
    const [ goid, setGoid ] = useState('');
    const [ docProfile, setDocProfile] = useState('');
    const [ previwIMG, setPreviwIMG] = useState('');
    const [ AvatarChange, setAvatarChange] = useState( false );
    const [ isAvatar, setIsAvatar] = useState(false);
    const [ isPermissions, setIsPermissions] = useState( false );
    const [ isStation, setIsStation] = useState( false );

    // Permissions
    // Vehicle
    const [ PV_change, setPV_change] = useState( false );
    const [ PV_cread, setPV_cread] = useState( false );
    const [ PV_read, setPV_read] = useState( false );
    const [ PV_edit, setPV_edit] = useState( false );
    const [ PV_deleted, setPV_deleted] = useState( false );
    // Driver
    const [ PD_change, setPD_change] = useState( false );
    const [ PD_cread, setPD_cread] = useState( false );
    const [ PD_read, setPD_read] = useState( false );
    const [ PD_edit, setPD_edit] = useState( false );
    const [ PD_deleted, setPD_deleted] = useState( false );
    // Accidents
    const [ PA_change, setPA_change] = useState( false );
    const [ PA_cread, setPA_cread] = useState( false );
    const [ PA_read, setPA_read] = useState( false );
    const [ PA_edit, setPA_edit] = useState( false );
    const [ PA_deleted, setPA_deleted] = useState( false );
    // History
    const [ PH_change, setPH_change] = useState( false );
    const [ PH_cread, setPH_cread] = useState( false );
    const [ PH_read, setPH_read] = useState( false );
    const [ PH_edit, setPH_edit] = useState( false );
    const [ PH_deleted, setPH_deleted] = useState( false );
    // Reports
    const [ PR_change, setPR_change] = useState( false );
    const [ PR_cread, setPR_cread] = useState( false );
    const [ PR_read, setPR_read] = useState( false );
    const [ PR_edit, setPR_edit] = useState( false );
    const [ PR_deleted, setPR_deleted] = useState( false );
    // Settings
    const [ PS_change, setPS_change] = useState( false );
    const [ PS_cread, setPS_cread] = useState( false );
    const [ PS_read, setPS_read] = useState( false );
    const [ PS_edit, setPS_edit] = useState( false );
    const [ PS_deleted, setPS_deleted] = useState( false );
    
    const [ isChange, setisChange ] = useState(false);
    const [ isError, setIsErrors] = useState([{
        name: false,
        lastName: false,
        phone: false,
        address: false,
        userName: false,
        pswrd: false,
        license_exp: false,
        docLicense_front: false,
        docLicense_back: false,
        docDeduction: false,
        Abstract: false,
        Avatar: false
    }]);
    // Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");
    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);
    const saveElement = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            // Save element 
            setIsShow( false );
            setIsConfirm( false );
            ModalClose();
            if ( PV_change ) { PermissionVehicle() }
            if ( PD_change ) { PermissionDrivers() }
            if ( PA_change ) { PermissionAccidents() }
            if ( PH_change ) { Permissionhistory() }
            if ( PR_change ) { PermissionReports() }
            if ( PS_change ) { PermissionSettings() }
            if ( isChange ) {
                const avatarIMG = document.getElementById('AvatarIMG').src;
                let config = {
                    method: 'put',
                    url: `${UpdateAvatar}${id}`,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        avatar: avatarIMG,
                    }
                }
                APIGO(config)
                .then(response =>{
                    console.log(response)
                    if(response.data.status === "success"){
                        setRefresh( refresh + 1 );
                        setModalmsg(" Information was updated ");
                        setIsShow( true );
                        ModalOpen();
                    }else if(response.data.status === "error"){
                        setModalmsg(response.data.msg);
                        setIsShow( true );
                        ModalOpen();
                        setModalLink('')
                    }
                })
                .catch(function (error){
                    console.log(error)
                })  
            }
            if ( isStation ) {
                let config = {
                    method: 'put',
                    url: `${UpdateStationManager}`,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        rdga: id,
                        station: stations
                    }
                }
                APIGO(config)
                .then(response =>{
                    if(response.data.status === "success"){
                        setRefresh( refresh + 1 );
                        setModalmsg(" Information was updated ");
                        setIsShow( true );
                        ModalOpen();
                    }else if(response.data.status === "error"){
                        setModalmsg(response.data.msg);
                        setIsShow( true );
                        ModalOpen();
                        setModalLink('')
                    }
                })
                .catch(function (error){
                    console.log(error)
                }) 
            }
        }
        if ( status === false ){
            setIsDeleted( false );
            setIsConfirm( false );
            ModalClose()
        }
    }
    const deleteElement = ( e, status, pass ) => {
        e.preventDefault();
        if ( status === true ){
            // deleted element 
            setIsDeleted( false );
            ModalClose()
            let config = {
                method: 'delete',
                url: DeleteManager,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                },
                data: {
                    go_id: id,
                    admin_password: pass,
                }
            }
            APIGO(config)
            .then(response =>{
                if(response.data.status == "success"){
                    setModalmsg('Manager was removed successfully');
                    setModalLink('/manager')
                    setIsShow( true );
                    ModalOpen()
                }
                if ( response.data.status == "error" ) {
                    if (  response.data.msg == "the password for your user was wrong!" ) {
                        setIsShow( true );
                        setModalmsg('Oops! a problem happened, please try again.');
                        ModalOpen()
                    }
                    else{
                        setIsShow( true );
                        setModalmsg('Oops! a problem happened, please try again.');
                        ModalOpen()
                    }
                }
            })
            .catch(function (error){
                console.log(error)
                setModalmsg( "Oops! a problem happen, please try again." );
                setIsShow( true );
                ModalOpen()
            })  
        }
        if ( status === false ){
            setIsDeleted( false );
            setIsConfirm( false )
            ModalClose()
        }
    }
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const handleForm = e => { 
        e.preventDefault();
        
        if( !AvatarChange ){ setIsErrors({ Avatar: true }); }
        else {
            setIsErrors([{ Avatar: false }]);
            setIsConfirm( true );
            setIsDeleted( false );
            setIsShow( false );
            ModalOpen( true );
        }

        if( PV_change || PD_change || PA_change || PH_change || PR_change || PS_change ){
            setIsConfirm( true );
            setIsShow( false );
            setIsDeleted( false );
            ModalOpen( true );
        }
        if( isStation ){
            setIsConfirm( true );
            setIsShow( false );
            setIsDeleted( false );
            ModalOpen( true );
        }
    }
    const onDelete = () => {
        ModalOpen()
        setIsShow( false )
        setIsDeleted( true )
        setIsConfirm( false )
    }
    const previwAvatar = ( e ) =>{
        e.preventDefault();
        setLoading( true )
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/abstract/Img${Date.now()}`);
        upload.put( docProfile ).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("AvatarIMG")
                console.log(url)
                profileImg.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwImg = ( e ) =>{
        try { setPreviwIMG( URL.createObjectURL( e.target.files[0] ) ) } 
        catch (error) { console.log( error ); }
    }
    const PermissionVehicle = ( ) =>{
        let config = {
            method: 'put',
            url: `${permissionVehicle}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: id,
                Create: PV_cread,
                Read: PV_read,
                Update: PV_edit,
                Delete: PV_deleted,
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.status === "success"){
                setRefresh( refresh + 1 );
                setModalmsg(" Information was updated ");
                setIsShow( true );
                ModalOpen();
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }
        })
        .catch(function (error){
            console.log(error)
        }) 
    }
    const PermissionDrivers = ( ) =>{
        let config = {
            method: 'put',
            url: `${permissionDrivers}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: id,
                Create: PD_cread,
                Read: PD_read,
                Update: PD_edit,
                Delete: PD_deleted,
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.status === "success"){
                setRefresh( refresh + 1 );
                setModalmsg(" Information was updated ");
                // setModalLink('/drivers')
                setIsShow( true );
                ModalOpen();
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }
        })
        .catch(function (error){
            console.log(error)
        }) 
    }
    const PermissionAccidents = ( ) =>{
        let config = {
            method: 'put',
            url: `${permissionAccidents}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: id,
                Create: PA_cread,
                Read: PA_read,
                Update: PA_edit,
                Delete: PA_deleted,
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.status === "success"){
                setRefresh( refresh + 1 );
                setModalmsg(" Information was updated ");
                // setModalLink('/drivers')
                setIsShow( true );
                ModalOpen();
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }
        })
        .catch(function (error){
            console.log(error)
        }) 
        
    }
    const Permissionhistory = ( ) =>{
        let config = {
            method: 'put',
            url: `${permissionHistory}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: id,
                Create: PH_cread,
                Read: PH_read,
                Update: PH_edit,
                Delete: PH_deleted,
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.status === "success"){
                setRefresh( refresh + 1 );
                setModalmsg(" Information was updated ");
                // setModalLink('/drivers')
                setIsShow( true );
                ModalOpen();
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }
        })
        .catch(function (error){
            console.log(error)
        }) 
    }
    const PermissionReports = ( ) =>{
        let config = {
            method: 'put',
            url: `${permissionReports}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: id,
                Create: PR_cread,
                Read: PR_read,
                Update: PR_edit,
                Delete: PR_deleted,
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.status === "success"){
                setRefresh( refresh + 1 );
                setModalmsg(" Information was updated ");
                // setModalLink('/drivers')
                setIsShow( true );
                ModalOpen();
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }else if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }
        })
        .catch(function (error){
            console.log(error)
        })
    }
    const PermissionSettings = ( ) =>{
        let config = {
            method: 'put',
            url: `${permissionSettings}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            },
            data: {
                rdga: id,
                Create: PS_cread,
                Read: PS_read,
                Update: PS_edit,
                Delete: PS_deleted,
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.status === "success"){
                setRefresh( refresh + 1 );
                setModalmsg(" Information was updated ");
                setIsShow( true );
                ModalOpen();
            }
            if(response.data.status === "error"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }
            if(response.data === "do it"){
                setModalmsg(response.data.msg);
                setIsShow( true );
                ModalOpen();
                setModalLink('')
            }
        })
        .catch(function (error){
            console.log(error)
        }) 
    }
    useEffect( () =>{
        var config = {
            method: 'get',
            url: `${GetManagerByID}${id}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then(response =>{
            if(response.data.message){
                setModalmsg( response.data.message );
                setIsShow( true );
                ModalOpen()
            }
            else{
                // console.log( response.data.results[0].permissions );
                const dataPremissions = response.data.results[0].permissions ; 
                setDocProfile(response.data.results[0].avatar)
                setFullname(response.data.results[0].full_name)
                setPhone(response.data.results[0].phone)
                setAddress(response.data.results[0].address)
                setUsername(response.data.results[0].user_name)
                setEmail(response.data.results[0].email)
                setGoid(response.data.results[0].rdga)
                setStations(response.data.results[0].station)

                parseInt( dataPremissions.Vehicle_C ) === 1 ? setPV_cread( true ) : setPV_cread( false );
                parseInt( dataPremissions.Vehicle_R ) === 1 ? setPV_read( true ) : setPV_read( false );
                parseInt( dataPremissions.Vehicle_U ) === 1 ? setPV_edit( true ) : setPV_edit( false );
                parseInt( dataPremissions.Vehicle_D ) === 1 ? setPV_deleted( true ) : setPV_deleted( false );

                parseInt( dataPremissions.Driver_C ) === 1 ? setPD_cread( true ) : setPD_cread( false );
                parseInt( dataPremissions.Driver_R ) === 1 ? setPD_read( true ) : setPD_read( false );
                parseInt( dataPremissions.Driver_U ) === 1 ? setPD_edit( true ) : setPD_edit( false );
                parseInt( dataPremissions.Driver_D ) === 1 ? setPD_deleted( true ) : setPD_deleted( false );

                parseInt( dataPremissions.Settings_C ) === 1 ? setPS_cread( true ) : setPS_cread( false );
                parseInt( dataPremissions.Settings_R ) === 1 ? setPS_read( true ) : setPS_read( false );
                parseInt( dataPremissions.Settings_U ) === 1 ? setPS_edit( true ) : setPS_edit( false );
                parseInt( dataPremissions.Settings_D ) === 1 ? setPS_deleted( true ) : setPS_deleted( false );
                
                parseInt( dataPremissions.Accident_R ) === 1 ? setPA_read( true ) : setPA_read( false );
                parseInt( dataPremissions.Accident_U ) === 1 ? setPA_edit( true ) : setPA_edit( false );
                
                parseInt( dataPremissions.History_R ) === 1 ? setPH_read( true ) : setPH_read( false );
                parseInt( dataPremissions.Reports_R ) === 1 ? setPR_read( true ) : setPR_read( false );
                
            }
        })
        .catch(function (error){
            console.log(error)
            setModalmsg( "There is no information." );
            setIsShow( true );
            ModalOpen()
        })

        var configStation = {
            method: 'get',
            url: getStation,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configStation)
        .then(response =>{
            setStationsData( response.data )
        })
        .catch(function (error){
            console.log(error)
        })
    }, [ refresh ] );

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                { isDeleted &&
                    <ModalConfirm 
                        Operation={ deleteElement }
                    /> 
                }
                { isConfirm &&
                    <ModalShow 
                        title=" Are you sure you want to save changes at driver? "
                        confirmOperation={ true }
                        Operation={ saveElement }
                    /> 
                }    
                { isShow &&
                    <ModalShow 
                        title={ modalMsg }
                        onlyShow={ true }
                        showGoTo={ modalGoTo }
                        goTo={ modalLink }
                    /> 
                }
                </Grid>
            </Modal>
            <form onSubmit={e=> handleForm(e)} >
                <Grid container spacing={2} >
                    <Grid item xs={4} md={2} className="center_center">
                        <Link to="/manager" className="flex No_Decoration">
                            <ArrowBackIosIcon className="Page_title"/>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Go back
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={8} md={6} >
                        <Typography variant="h4" gutterBottom className="Page_title">
                            Fleet Manager Details
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button variant="outlined" disableElevation fullWidth className="" onClick={ onDelete } >DELETE</Button>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button 
                            fullWidth 
                            disableElevation 
                            variant="contained" 
                            className="Go_btn-primary" 
                            type={ loading ? "button" : "submit" } 
                        >
                            { loading ? "LOADING..." : "SAVE" }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Box py={3}>
                                            <input type="file" id="ProfileImage" className="fileUpload" onChange={ e => {setDocProfile( ( e.target.files[0] ) ); previwImg(e); setAvatarChange( true ); setisChange(true); previwAvatar(e) } }  />
                                            <label htmlFor="ProfileImage" className="labelFileImg">
                                            {
                                                AvatarChange ? 
                                                <img src={ previwIMG } alt="previw" className="profileImg rounded" />
                                                :
                                                <img src={ docProfile } alt="profile" className="previw_img rounded" />
                                            }
                                                <img src={ edit } alt="" className="" />
                                            </label>
                                            <img src="" alt="" className="previw_img d-none" id={'AvatarIMG'} />
                                            
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="DriveLastName" className="labelText"><small>NAME</small></label>
                                        {
                                            isError.fullname ?
                                                <TextField id="DriveLastName" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} error helperText="Enter a valid name please" /> 
                                            : 
                                                <TextField id="DriveLastName" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="DriverPhone" className="labelText" ><small>PHONE NUMBER</small></label>
                                        {
                                            isError.phone ?
                                                <TextField id="DriverPhone" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} error helperText={msg}/> 
                                            : 
                                                <TextField id="DriverPhone" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="email" className="labelText"><small>EMAIL</small></label>
                                        {
                                            isError.email ?
                                                <TextField id="email" InputProps={{ readOnly: true, }} type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} error helperText={msg} /> 
                                            : 
                                                <TextField id="email" InputProps={{ readOnly: true, }} type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Box p={1}> 
                                        <label htmlFor="DriveAddress" className="labelText"><small> ADDRESS </small></label>
                                        {
                                            isError.address ?
                                                <TextField id="DriveAddress" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} error helperText="Enter a valid Address please" /> 
                                            : 
                                                <TextField id="DriveAddress" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="RDGA" className="labelText" ><small>RDGA</small></label>
                                        {
                                            isError.goid ?
                                                <TextField id="RDGA" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} error helperText="Enter a valid id please" /> 
                                            : 
                                                <TextField id="RDGA" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="licenseExp" className="labelText" ><small>STATION</small></label>
                                        <Select labelId="stationID" variant="outlined" displayEmpty  className="" fullWidth value={stations} onChange={e =>{ setStations(e.target.value); setIsStation( true )}} >
                                            <MenuItem value=""><em>All stations</em></MenuItem>
                                            {
                                                stationsData.map( item => 
                                                    <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="ManagerUsername" className="labelText"><small>USERNAME</small></label>
                                        {
                                            isError.goid ?
                                                <TextField id="ManagerUsername" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ username } onChange={e => setUsername(e.target.value)} error helperText="Enter a valid id please" /> 
                                            : 
                                                <TextField id="ManagerUsername" InputProps={{ readOnly: true, }} type="text" variant="outlined" fullWidth value={ username } onChange={e => setUsername(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box p={1} pt={0}>
                                        <Typography variant="h6" gutterBottom className="Page_title">
                                            Permissions
                                        </Typography>
                                        <hr className="divider_blue" />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Vehicles
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PV_read"> Create </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PV_cread}
                                                        onChange={ e => {setPV_cread( e.target.checked ); setPV_change( true ); }}
                                                        color="primary"
                                                        id="PV_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PV_cread"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PV_read}
                                                        onChange={ e => {setPV_read( e.target.checked ); setPV_change( true ); } }
                                                        color="primary"
                                                        id="PV_cread"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PV_edit"> Edit </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PV_edit}
                                                        onChange={ e => {setPV_edit( e.target.checked ); setPV_change( true ); } }
                                                        color="primary"
                                                        id="PV_edit"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PV_deleted"> Delete </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PV_deleted}
                                                        onChange={ e => {setPV_deleted( e.target.checked ); setPV_change( true ); }}
                                                        color="primary"
                                                        id="PV_deleted"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Drivers
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PD_cread"> Create </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PD_cread}
                                                        onChange={ e => {setPD_cread( e.target.checked ); setPD_change( true ); }}
                                                        color="primary"
                                                        id="PD_cread"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PD_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PD_read}
                                                        onChange={ e => {setPD_read( e.target.checked ); setPD_change( true ); }}
                                                        color="primary"
                                                        id="PD_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PD_edit"> Edit </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PD_edit}
                                                        onChange={ e => {setPD_edit( e.target.checked ); setPD_change( true );}}
                                                        color="primary"
                                                        id="PD_edit"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PD_deleted"> Delete </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PD_deleted}
                                                        onChange={ e => {setPD_deleted( e.target.checked ); setPD_change( true );}}
                                                        color="primary"
                                                        id="PD_deleted"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Settings
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PS_cread"> Create </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PS_cread}
                                                        onChange={ e => {setPS_cread( e.target.checked ); console.log( e.target.checked ); setPS_change( true );}}
                                                        color="primary"
                                                        id="PS_cread"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PS_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PS_read}
                                                        onChange={ e => {setPS_read( e.target.checked ); setPS_change( true );}}
                                                        color="primary"
                                                        id="PS_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PS_edit"> Edit </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PS_edit}
                                                        onChange={ e => {setPS_edit( e.target.checked ); setPS_change( true );}}
                                                        id="PS_edit"
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PS_deleted"> Delete </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PS_deleted}
                                                        onChange={ e => {setPS_deleted( e.target.checked ); setPS_change( true );}}
                                                        color="primary"
                                                        id="PS_deleted"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Accidents
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PA_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PA_read}
                                                        onChange={ e => {setPA_read( e.target.checked ); setPA_change( true );}}
                                                        color="primary"
                                                        id="PA_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PA_edit"> Edit </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PA_edit}
                                                        onChange={ e => {setPA_edit( e.target.checked ); setPA_change( true );}}
                                                        color="primary"
                                                        id="PA_edit"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            History
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PH_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PH_read}
                                                        onChange={ e => {setPH_read( e.target.checked ); setPH_change( true );}}
                                                        color="primary"
                                                        id="PH_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box className="bg_grey" p={1} m={1}>
                                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                                            Reports
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="left">
                                                    <label htmlFor="PR_read"> View </label>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box flexGrow={1} textAlign="right">
                                                    <Switch
                                                        checked={PR_read}
                                                        onChange={ e => {setPR_read( e.target.checked ); setPR_change( true );}}
                                                        color="primary"
                                                        id="PR_read"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
export default ManagersDetails
    
