import React, { useContext } from 'react'

import { makeStyles, Box, Typography, Button, Grid, Divider, useTheme, TextareaAutosize, TextField } from "@material-ui/core"

import BaseDialog from '../../Components/Global/Modals/BaseDialog'
import RegisterHistory from './RegisterHistory'
import DialogMessageForm from '../../Components/Global/Modals/DialogMessageForm'
import { postComment, postRegister, updateRegister } from '../../middleware/warehouse/Warehouse'
import { AuthContext } from '../../Components/Functions/Auth';
import { useHistory, useParams } from 'react-router-dom'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    banner: {
        height: "auto",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    bold: {
        fontWeight: "bold",
        color: theme.palette.grey[600]
    },
    actions: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    danger: {
        backgroundColor: theme.palette.error.main,
        color: "#fff"
    }
}))


const WarehouseExemption = ({ open, onClose, data, onDataChange, list_id:listId }) => {

    const { scan } = useParams()//in out returns

    const { user } = useContext(AuthContext)

    const classes = useStyles()

    const theme = useTheme()

    const [cancelAlert, setCancelAlert] = React.useState(false)
    const [cancelReason, setCancelReason] = React.useState(false)
    const [exemptionId, setExemptionId] = React.useState()

    const handleCancelOrder = async () => {
        const _data = {
            register_id: exemptionId,
            status: "canceled",
            username: user.name,
            user_id: user.u_id,
            reason: cancelReason
        }
        const result = await updateRegister(_data)
        if (result.status) {
            if (onDataChange) onDataChange()
            onClose()
            setFormData(defaultForm)
            setCancelAlert(false)
            alert("Operation complete")
        }else{
            alert("An error occurred")
        }
    }

    const defaultForm = {
        client: "",
        order_no: "",
        model_no: "",
        serial_no: "",
        comments: ""
    }
    const [formData, setFormData] = React.useState(defaultForm)
    const handleChange = ({ target: { value, name } }) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }
    const handleExemption = async (cancel) => {
        if (formData.model_no === "" || formData.serial_no === "") {
            alert("Complete required fields")
            return
        }

        const data = {
            shipment_no: formData.order_no ,
            sales_order_no: formData.order_no,
            first_name: formData.client,
            description: formData.model_no,
            serial_no: formData.serial_no,
            username: user.name,
            user_id: user.u_id,
            list_id: listId?listId:0,
            comments: formData.comments,
            status: "exemption"
        }

        const result = await postRegister(data)

        if (cancel) {
            setExemptionId(result.result.warehouse_id)
            setCancelAlert(true)
        } else if (result.status) {
            if (onDataChange) onDataChange()
            alert("Received-as-X complete")
            onClose()
            setFormData(defaultForm)
        } else {
            alert("Error adding exemption")
        }
    }

    return (
        <BaseDialog open={open} onClose={onClose} maxWidth="xs"  >

            <DialogMessageForm open={cancelAlert} title="Cancel Order" message="Are you sure to cancel this order?" type="error"
                onClose={() => setCancelAlert(false)}
                positiveText="Continue"
                positiveAction={handleCancelOrder}
                negativeText="Back"
                negativeAction={() => setCancelAlert(false)}
                formView={
                    <React.Fragment>
                        <Typography>Reason</Typography>
                        <TextareaAutosize
                            onChange={({ target }) => setCancelReason(target.value)}
                            style={{ width: "100%", height: theme.spacing(10) }}
                            maxRows={15}
                        />
                    </React.Fragment>
                }
            />
            <Box mb={2} style={{ backgroundColor: theme.palette.primary.main }} color={theme.palette.primary.contrastText} textAlign="left" display="flex" alignItems="center" flexDirection="row" p={1} pt={2} pb={2} >
                <Typography color="inherit" variant="h6" >Add exemption</Typography>
            </Box>

            <Grid container justifyContent="center" alignItems="center" justify="center" >
                <Grid item xs={11} >
                    <form>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography>Client</Typography>
                                <TextField onChange={handleChange} name="client" value={formData.client} variant="outlined" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Order number</Typography>
                                <TextField onChange={handleChange} name="order_no" value={formData.order_no} variant="outlined" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Model number</Typography>
                                <TextField onChange={handleChange} name="model_no" value={formData.model_no} variant="outlined" fullWidth size="small" />
                                <Typography variant="caption" >This field is required*</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Serial number</Typography>
                                <TextField onChange={handleChange} name="serial_no" value={formData.serial_no} variant="outlined" fullWidth size="small" />
                                <Typography variant="caption" >This field is required*</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Comments</Typography>
                                <TextareaAutosize
                                    onChange={handleChange}
                                    name="comments" value={formData.comments}
                                    style={{ width: "100%", height: theme.spacing(10) }}
                                    maxRows={15}
                                    variant="outlined" fullWidth size="small" />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>

            <Grid className={classes.actions} container justify="center" >
                <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }} >
                    <Button onClick={() => handleExemption(true)} className={classes.danger} variant="contained" >Cancel</Button>
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }} >
                    <Button onClick={() => handleExemption(false)} color="secondary" variant="contained" >Save</Button>
                </Grid>
            </Grid>

        </BaseDialog>
    )
}

WarehouseExemption.defaultProps = {
    open: false
};

export default WarehouseExemption