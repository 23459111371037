import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import moment from "moment";
// Componentes
import { getDriver } from './../Components/Functions/GetInfo'
import HistoryDetailsInfo from './../Components/Global/HistoryDetailsInfo'
import ModalShow from '../Components/Global/Modal';
// IMG
import Portada from './../Assets/Img/Portada_management.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
//API
import { 
    APIGO, 
    getCicleCheckById, 
    getNotesCFMbyV_Id, 
    getNotesDRMbyV_Id 
} from './../constants/API'; 

const HistoryDetails = () => {
    
    const history   = useHistory();  
    const match     = useRouteMatch('/history/details/:id');
    const id        = match.params.id;
    
    const [ ccInfo, setccInfo ] = useState(2);
    const [ data, setData ] = useState( [] );
    const [ isError, setIsError ] = useState( false );
    const [ checkOutList, setCheckOutList ] = useState([]);
    const [ checkInList, setCheckInList ] = useState('');
    const [ checkOutPictures, setCheckOutPictures ] = useState('');
    const [ checkInPictures, setCheckInPictures ] = useState('');
    const [ prevDamage, setPrevDamage ] = useState('');
    const [ finalDetails, setFinalDetails ] = useState('');
    const [ signature, setSignature ] = useState({});

    // Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");

    // data.prev_damage
    useEffect( () =>{
        getData();
    }, [] );

    const getData = async() => {
        var config = {
            method: 'get',
            url: `${getCicleCheckById}/${id}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then (response => {
            if ( response.data.status === "error" ) {
                setIsError( true );
            }
            setData( response.data[0] );
            if ( response.data[0].check_out_list ){
                setCheckOutList({
                    'g': response.data[0].check_out_list[0].key,
                    'g1_t': response.data[0].check_out_list[0].data[0].text,
                    'g1_v': response.data[0].check_out_list[0].data[0].value,
                    'g2_t': response.data[0].check_out_list[0].data[1].text,
                    'g2_v': response.data[0].check_out_list[0].data[1].value,
                    'g3_t': response.data[0].check_out_list[0].data[2].text,
                    'g3_v': response.data[0].check_out_list[0].data[2].value,
                    'g4_t': response.data[0].check_out_list[0].data[3].text,
                    'g4_v': response.data[0].check_out_list[0].data[3].value,
                    'g5_t': response.data[0].check_out_list[0].data[4].value,
                    'g5_v': response.data[0].check_out_list[0].data[4].value,
                    'e': response.data[0].check_out_list[1].key,
                    'e1_t': response.data[0].check_out_list[1].data[0].text,
                    'e1_v': response.data[0].check_out_list[1].data[0].value,
                    'e2_t': response.data[0].check_out_list[1].data[1].text,
                    'e2_v': response.data[0].check_out_list[1].data[1].value,
                    'e3_t': response.data[0].check_out_list[1].data[2].text,
                    'e3_v': response.data[0].check_out_list[1].data[2].value,
                    'e4_t': response.data[0].check_out_list[1].data[3].text,
                    'e4_v': response.data[0].check_out_list[1].data[3].value,
                    'e5_t': response.data[0].check_out_list[1].data[4].value,
                    'e5_v': response.data[0].check_out_list[1].data[4].value,
                    'e6_t': response.data[0].check_out_list[1].data[5].text,
                    'e6_v': response.data[0].check_out_list[1].data[5].value,
                    'e7_t': response.data[0].check_out_list[1].data[6].text,
                    'e7_v': response.data[0].check_out_list[1].data[6].value,
                    'e8_t': response.data[0].check_out_list[1].data[7].text,
                    'e8_v': response.data[0].check_out_list[1].data[7].value,
                    // 'e9_t': response.data[0].check_out_list[1].data[8].text,
                    // 'e9_v': response.data[0].check_out_list[1].data[8].value,
                    // 'e10_t': response.data[0].check_out_list[1].data[9].value,
                    // 'e10_v': response.data[0].check_out_list[1].data[9].value,
                    // 'e11_t': response.data[0].check_out_list[1].data[10].value,
                    // 'e11_v': response.data[0].check_out_list[1].data[10].value,
                    // 'e12_t': response.data[0].check_out_list[1].data[11].value,
                    // 'e12_v': response.data[0].check_out_list[1].data[11].value,
                    'i': response.data[0].check_out_list[2].key,
                    'i1_t': response.data[0].check_out_list[2].data[0].text,
                    'i1_v': response.data[0].check_out_list[2].data[0].value,
                    'i2_t': response.data[0].check_out_list[2].data[1].text,
                    'i2_v': response.data[0].check_out_list[2].data[1].value,
                    'i3_t': response.data[0].check_out_list[2].data[2].text,
                    'i3_v': response.data[0].check_out_list[2].data[2].value,
                    'i4_t': response.data[0].check_out_list[2].data[3].text,
                    'i4_v': response.data[0].check_out_list[2].data[3].value,
                    'i5_t': response.data[0].check_out_list[2].data[4].value,
                    'i5_v': response.data[0].check_out_list[2].data[4].value,
                    'i6_t': response.data[0].check_out_list[2].data[5].text,
                    'i6_v': response.data[0].check_out_list[2].data[5].value,
                    'i7_t': response.data[0].check_out_list[2].data[6].text,
                    'i7_v': response.data[0].check_out_list[2].data[6].value,
                    'i8_t': response.data[0].check_out_list[2].data[7].text,
                    'i8_v': response.data[0].check_out_list[2].data[7].value,
                    'm': response.data[0].check_out_list[3].key,
                    'm1_t': response.data[0].check_out_list[3].data[0].text,
                    'm1_v': response.data[0].check_out_list[3].data[0].value,
                    'm2_t': response.data[0].check_out_list[3].data[1].text,
                    'm2_v': response.data[0].check_out_list[3].data[1].value,
                    'm3_t': response.data[0].check_out_list[3].data[2].text,
                    'm3_v': response.data[0].check_out_list[3].data[2].value,
                    'm4_t': response.data[0].check_out_list[3].data[3].text,
                    'm4_v': response.data[0].check_out_list[3].data[3].value,
                    'm5_t': response.data[0].check_out_list[3].data[4].value,
                    'm5_v': response.data[0].check_out_list[3].data[4].value,
                    // 'm6_t': response.data[0].check_out_list[3].data[5].text,
                    // 'm6_v': response.data[0].check_out_list[3].data[5].value,
                    // 'm7_t': response.data[0].check_out_list[3].data[6].text,
                    // 'm7_v': response.data[0].check_out_list[3].data[6].value,
                    // 'm8_t': response.data[0].check_out_list[3].data[7].text,
                    // 'm8_v': response.data[0].check_out_list[3].data[7].value,
                })
            }
            if ( response.data[0].check_in_list ){
                setCheckInList({
                    'g': response.data[0].check_in_list[0].key,
                    'g1_t': response.data[0].check_in_list[0].data[0].text,
                    'g1_v': response.data[0].check_in_list[0].data[0].value,
                    'g2_t': response.data[0].check_in_list[0].data[1].text,
                    'g2_v': response.data[0].check_in_list[0].data[1].value,
                    'g3_t': response.data[0].check_in_list[0].data[2].text,
                    'g3_v': response.data[0].check_in_list[0].data[2].value,
                    'g4_t': response.data[0].check_in_list[0].data[3].text,
                    'g4_v': response.data[0].check_in_list[0].data[3].value,
                    'g5_t': response.data[0].check_in_list[0].data[4].value,
                    'g5_v': response.data[0].check_in_list[0].data[4].value,
                    'e': response.data[0].check_in_list[1].key,
                    'e1_t': response.data[0].check_in_list[1].data[0].text,
                    'e1_v': response.data[0].check_in_list[1].data[0].value,
                    'e2_t': response.data[0].check_in_list[1].data[1].text,
                    'e2_v': response.data[0].check_in_list[1].data[1].value,
                    'e3_t': response.data[0].check_in_list[1].data[2].text,
                    'e3_v': response.data[0].check_in_list[1].data[2].value,
                    'e4_t': response.data[0].check_in_list[1].data[3].text,
                    'e4_v': response.data[0].check_in_list[1].data[3].value,
                    'e5_t': response.data[0].check_in_list[1].data[4].value,
                    'e5_v': response.data[0].check_in_list[1].data[4].value,
                    'e6_t': response.data[0].check_in_list[1].data[5].text,
                    'e6_v': response.data[0].check_in_list[1].data[5].value,
                    'e7_t': response.data[0].check_in_list[1].data[6].text,
                    'e7_v': response.data[0].check_in_list[1].data[6].value,
                    'e8_t': response.data[0].check_in_list[1].data[7].text,
                    'e8_v': response.data[0].check_in_list[1].data[7].value,
                    'e9_t': response.data[0].check_in_list[1].data[8].text,
                    'e9_v': response.data[0].check_in_list[1].data[8].value,
                    'e10_t': response.data[0].check_in_list[1].data[9].value,
                    'e10_v': response.data[0].check_in_list[1].data[9].value,
                    'e11_t': response.data[0].check_in_list[1].data[10].value,
                    'e11_v': response.data[0].check_in_list[1].data[10].value,
                    'e12_t': response.data[0].check_in_list[1].data[11].value,
                    'e12_v': response.data[0].check_in_list[1].data[11].value,
                    'i': response.data[0].check_in_list[2].key,
                    'i1_t': response.data[0].check_in_list[2].data[0].text,
                    'i1_v': response.data[0].check_in_list[2].data[0].value,
                    'i2_t': response.data[0].check_in_list[2].data[1].text,
                    'i2_v': response.data[0].check_in_list[2].data[1].value,
                    'i3_t': response.data[0].check_in_list[2].data[2].text,
                    'i3_v': response.data[0].check_in_list[2].data[2].value,
                    'i4_t': response.data[0].check_in_list[2].data[3].text,
                    'i4_v': response.data[0].check_in_list[2].data[3].value,
                    'i5_t': response.data[0].check_in_list[2].data[4].value,
                    'i5_v': response.data[0].check_in_list[2].data[4].value,
                    'i6_t': response.data[0].check_in_list[2].data[5].text,
                    'i6_v': response.data[0].check_in_list[2].data[5].value,
                    'i7_t': response.data[0].check_in_list[2].data[6].text,
                    'i7_v': response.data[0].check_in_list[2].data[6].value,
                    'i8_t': response.data[0].check_in_list[2].data[7].text,
                    'i8_v': response.data[0].check_in_list[2].data[7].value,
                    'm': response.data[0].check_in_list[3].key,
                    'm1_t': response.data[0].check_in_list[3].data[0].text,
                    'm1_v': response.data[0].check_in_list[3].data[0].value,
                    'm2_t': response.data[0].check_in_list[3].data[1].text,
                    'm2_v': response.data[0].check_in_list[3].data[1].value,
                    'm3_t': response.data[0].check_in_list[3].data[2].text,
                    'm3_v': response.data[0].check_in_list[3].data[2].value,
                    'm4_t': response.data[0].check_in_list[3].data[3].text,
                    'm4_v': response.data[0].check_in_list[3].data[3].value,
                    'm5_t': response.data[0].check_in_list[3].data[4].value,
                    'm5_v': response.data[0].check_in_list[3].data[4].value,
                    'm6_t': response.data[0].check_in_list[3].data[5].text,
                    'm6_v': response.data[0].check_in_list[3].data[5].value,
                    'm7_t': response.data[0].check_in_list[3].data[6].text,
                    'm7_v': response.data[0].check_in_list[3].data[6].value,
                    'm8_t': response.data[0].check_in_list[3].data[7].text,
                    'm8_v': response.data[0].check_in_list[3].data[7].value,
                })
            }
            if ( response.data[0].check_out_pictures ){
                setCheckOutPictures( response.data[0].check_out_pictures )
            }
            if ( response.data[0].check_in_pictures ){
                setCheckInPictures( response.data[0].check_in_pictures )
            }
            setPrevDamage( response.data[0].prev_damage )
            setFinalDetails( response.data[0].final_details )
            setSignature({
                fleetManager: response.data[0].check_in_pictures.signature,
                driver: response.data[0].check_out_pictures.signature
            })

        })
        .catch(function (error){
            console.log(error)
            setIsError(true);
        })
    }
    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const ShowccInfo = ( e, status ) => {
        e.preventDefault();
        setccInfo( status );
    }

    return(        
        <Box mb={3}>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>
            {
            !isError ?
                <Grid container spacing={2} >
                    <Grid item xs={12} md={2} className="center_center">
                        <Link to="/history" className="flex No_Decoration">
                            <ArrowBackIosIcon className="Page_title"/>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Go back
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box textAlign="left" className="" >
                            <Typography variant="h4" gutterBottom className="Page_title">
                                Circle check
                            </Typography>
                            <span className="labelText"> ID { data.check_id } </span> 
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box textAlign="right" className="Text_grey" >
                            { moment( data.created ).format("MMMM Do YYYY") }
                            <br/>
                            Start time: { moment( data.start_date ).format("h:mm:ss a") } 
                            <br/>
                            End time: { data.end_date ? moment( data.end_date ).format("h:mm:ss a") : "In progress" }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Box p={3}>
                                        <Typography variant="h6" gutterBottom className="Page_title">
                                            Vehicle information
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container>
                                            <Grid item xs={12} md={4} >
                                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                                    Vehicle ID
                                                </Typography>
                                                <Typography variant="body1" gutterBottom className="Text_grey">
                                                    {data.vehicle_id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                                    Licence plate
                                                </Typography>
                                                <Typography variant="body1" gutterBottom className="Text_grey">
                                                    { data.vehicle_licence }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4} >
                                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                                    RDGA
                                                </Typography>
                                                <Typography variant="body1" gutterBottom className="Text_grey">
                                                    { data.rdga }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box p={3}>
                                        <Typography variant="h6" gutterBottom className="Page_title">
                                            Assigned driver
                                        </Typography>
                                        <hr className="divider_blue" />
                                        <Grid container className="bg_history">
                                            <Grid item xs={12} md={4} >
                                                <img src={ data.driver_avatar } alt="" className="historyImg rounded" />
                                            </Grid>
                                            <Grid item xs={12} md={8} >
                                                <span className="History_title" >
                                                    { data.driver_name }
                                                </span>
                                                {/* <span className="History_route">
                                                    Route X01 Needed
                                                </span> */}
                                                <br/>
                                                <span className="Text_blod-grey">
                                                    ID {data.driver_id}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} className="bg_history">
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={ e => ShowccInfo( e, 2 ) } >
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Circle check OUT
                                                    { ccInfo === 2 ? <hr className="divider_blue" /> : null }
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={ e => ShowccInfo( e, 1 ) } >
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Circle check in
                                                    { ccInfo === 1 ? <hr className="divider_blue" /> : null }
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        { 
                                            ccInfo === 1 ? 
                                                <HistoryDetailsInfo
                                                    checkList={ checkOutList }
                                                    checkPictures={ checkOutPictures }
                                                    prevDamage={ prevDamage }
                                                    finalDetails={ finalDetails }
                                                    signature={ signature }
                                                />
                                            : 
                                                <HistoryDetailsInfo
                                                    checkList={ checkInList }
                                                    checkPictures={ checkInPictures }
                                                    prevDamage={ prevDamage }
                                                    finalDetails={ finalDetails }
                                                    signature={ signature }
                                                /> 
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                
            : 
                <Typography variant="body1" gutterBottom className="Page_title">
                    Something strange is happened, please try again in a few minutes
                </Typography>
            }
        </Box>
    )
}

export default HistoryDetails