import React from 'react'
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
// 

const Loader = ( { isUser, isVehicle, onlyLoad } ) => {
    if ( isUser ) {
        return(
            <Grid container >
                <Grid item xs={2}>
                    <Skeleton variant="circle" width={70} height={70} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rect" width={150} height={50} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rect" width={150} height={50} />
                </Grid>
            </Grid>
        )
    }

    if ( isVehicle ) {
        return(
            <Grid container >
                <Grid item xs={2}>
                    <Box px={3}>
                        <Skeleton variant="text" />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box px={3}>
                        <Skeleton variant="text" />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box px={3}>
                        <Skeleton variant="text" />
                    </Box>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={3}>
                    <Box px={3}>
                        <Skeleton variant="rect" width={150} height={50} />
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if( onlyLoad ){
        return(
            <Grid container direction="row" justify="center" alignItems="center">
                <CircularProgress />
            </Grid>
        )
    }
}
export default Loader
