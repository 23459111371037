import React, { useState, useEffect } from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getStationById, APIGO } from '../../constants/API';

const AccidentItem = ( { id, date, changeType, link, status, user, Previous, New, stationData, A_status, driver } ) => {

    const [ station_previos, setStation_previos ] = useState([]);
    const [ station_new, setStation_new ] = useState([]);

    useEffect( () =>{
        
        if ( Previous !== 0 ) {
            var config_Station_prev = {
                method: 'get',
                url: `${getStationById}${Previous}`,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
            APIGO(config_Station_prev)
            .then(response =>{
                setStation_previos( response.data[0] )
            })
            .catch(function (error){
                console.log(error)
            })
        }

        if ( New !== 0 ) {
            var config_Station_new = {
                method: 'get',
                url: `${getStationById}${New}`,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
            APIGO(config_Station_new)
            .then(response =>{
                setStation_new( response.data[0] )
            })
            .catch(function (error){
                console.log(error)
            })
        }


    }, [ ] );

    // if ( A_status === 1 ) {
    //     return(
    //         <Box p={1} m={1} className="bg_grey" >
    //             <Grid container >
    //                 <Grid item xs={1}>
    //                     <Typography variant="body1" className="text_grey">
    //                         { id }
    //                     </Typography>
    //                 </Grid>
    //                 <Grid item xs={2}>
    //                     <Typography variant="body1" className="text_grey">
    //                     { moment( date ).format("MMMM Do YYYY") }
    //                     </Typography>
    //                 </Grid>
    //                 <Grid item xs={2}>
    //                     <Typography variant="body1" className="text_grey">
    //                         { driver }
    //                         {
    //                             status === 1 &&
    //                                 <span className="status_error" >
    //                                     ACCIDENT
    //                                 </span>
    //                         }
    //                     </Typography>
    //                 </Grid>
    //             </Grid>
    //         </Box>
    //     )
    // }
    
    // if ( A_status === 0 ) {
        return (
            <Box p={1} m={1} className="bg_grey" >
                <Grid container >
                    <Grid item xs={1}>
                        <Typography variant="body1" className="text_grey">
                            { id }
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" className="text_grey">
                            { moment( date ).format("MMMM Do YYYY") }
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" className="text_grey">
                            { changeType }
                            {/* {
                                status === 0 &&
                                    <span className="status_issues" >
                                        ISSUE
                                    </span>
                            } */}
                            {/* {
                                status === 1 &&
                                    <span className="status_error" >
                                        ACCIDENT
                                    </span>
                            } */}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        { user }
                    </Grid>
                    <Grid item xs={2}>
                        { Previous !== '0' ? station_previos.station_id : null  }
                    </Grid>
                    <Grid item xs={2}>
                        { New !== '0' ? station_new.station_id : null }
                    </Grid>
                </Grid>
            </Box>
        );
    // }
};

AccidentItem.propTypes = {
    
};

export default AccidentItem;