/**
 * @author Fidel
 * @email [example@mail.com]
 * @create date 2021-06-24 18:37:09
 * @modify date 2021-06-24 18:37:09
 * @desc [description]
 * @param arr Array
 * @param len Int items per page
 */

const Actions = {
  Eq: (a, b) => a === b,
  Lt: (a, b) => a < b,
  Gt: (a, b) => a > b,
  Stw: (a, text) => a.startsWith(text),
  Edw: (a, text) => a.endsWith(text),
  Ctn: (a, text) => a.contains(text),
}
/** 
* @namespace
* @property {string} Eq Equals
* @return {String}
*/
export const Operators = {
  Eq: "Eq",
  Lt: "Lt",
  Gt: "Gt",
  Stw: "Stw",
  Edw: "Edw",
  Ctn: "Ctn",
}

/** 
* Returns paged array in n parts given
* @param {Array} arr - Array of objects to paginate
* @param {Int} len - Size of items per page
* @return {Array[]} Returns Array Of Pages
*/
export const pagerMaker = (arr, len) => {
  var chunks = [], i = 0, n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }
  return chunks;
}

/** 
* Returns paged array in n parts given
* @func pagerFilter
* @summary Same of @pager except, this only include matched items with filterOptions
* @param {Array} arr - Array of objects to paginate
* @param {Int} len - Size of items per page
* @param {Object} filterOptions - Size of items per page
* @return {Array[]} Returns Array Of Pages
* 
*/

/* const opt = {
  [Operators.Eq]:{
    field:"idShipping",
    compare:2
  }
} */

export const pageMakerFilter = (arr, len, filterOptions) => {

  let filtered = []

  console.log(filterOptions)

  arr.map(async (item) => {

    Object.entries(filterOptions).forEach(([key, value]) => {
      
      const { field, compare } = value

      let result = Actions[key](item[field], compare);

      if (result) {
        filtered.unshift(item)
      }

      return result
    })
  })

  var chunks = [], i = 0, n = filtered.length;
  while (i < n) {
    chunks.push(filtered.slice(i, i += len));
  }
  return chunks;
}

