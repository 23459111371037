import React, { useEffect, useState } from 'react'
// import Logo from './../../Assets/Img/Logo.png';
import { Grid, Hidden, makeStyles, Step, StepConnector, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core'
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { getCustomerService, saveOrder, saveOrderBulk } from '../../../Components/Functions/LabelPdf/LabelFunctions';
import iconPlus from '../../../Assets/Img/plus-square.png';
import { useHistory } from 'react-router-dom';
import './../../../Assets/Styles/Stepper.css'
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: 'white'
    },
}));

const StepperConnector = withStyles({
    active: {
        '& $line': {
            borderColor: '99AEC2',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#99AEC2',
        },
    },
    line: {
        backgroundColor: '#99AEC2',
    }
})(StepConnector);

export const NewOrder = () => {

    const history = useHistory();
    const classes = useStyles();
    const u_id = localStorage.getItem('u_id')
    const steps = ['Order Type', 'Pick up','Deliver to','Order information', 'Complete'];
    const [step, setStep] = useState( 0 )
    const [service, setService] = useState([]);
    const [formStep1, setFormStep1] = useState({
        // false === Individual order
        // true === Bulk order
        orderType: false
    });
    const [formStep2, setFormStep2] = useState({
        pickUpId: '', 
        pickUpData: '',
        pickUpNew: true,
        pickUpSaved: false,
        pickUpTitle: '',
        pickUpName: '',
        pickUpPhone: '',
        pickUpCity: '',
        pickUpCountry: '',
        pickUpLocation: '',
        pickUpLocNum: '',
        pickUpLocNumInt: '',
        pickUpPostal: '',
        pickUpProvince: ''
    });
    const [formStep3, setFormStep3] = useState({
        deliveryId: '', 
        deliveryData: '',
        deliveryNew: true,
        deliverySave: false,
        deliverySaved: false,
        deliveryTitle: '',
        deliveryName: '',
        deliveryPhone: '',
        deliveryCity: '',
        deliveryCountry: '',
        deliveryLocation: '',
        deliveryLocNum: '',
        deliveryLocNumInt: '',
        deliveryPostal: '',
        deliveryProvince: '',
    });
    const [formStep4, setFormStep4] = useState({
        pickUpDate: '',
        readyTime: '',
        service_id: '',
        service: '',
        isInsurance: false,
        insuranceValue: '',
        comments: '',
        packageNumber: '',
        packageWeight: 0,
        weightType: false,
        dimensionsType: false,
        package: [
            {
                act: true,
                package_num: '1',
                weight: 0,
                endWeight: 0,
                dimensionsL: '',
                dimensionsW: '',
                dimensionsH: ''
            }
        ],
    });
    const [formStepBulk, setFormStepBulk] = useState({
        isBulk:false,
        data: [{}]
    });
    const [state, setState] = useState({
        loading: '',
        isError: '',
        isMsg: ''
    });
    const reloadBulk = () => {
        alert("entro al reloadBulk")
        getStepContent(5)
    }
    const nextStep = () => setStep( step + 1 )
    const backStep = () => setStep( step - 1)
    const toBulk = () => setStep( step + 4)
    const getStepContent = ( step ) => {
        switch ( step ) {
            case 0:
            return <Step1
                formData={ formStep1 }
                formDataBulk={formStepBulk}
                setFormData={ setFormStep1 }
                setFormDataBulk={ setFormStepBulk }
                nextStep={ nextStep }
                toBulk={toBulk}
            />;
            case 1:
            return <Step2
                formData={ formStep2 }
                setFormData={ setFormStep2 }
                formDataBulk={formStepBulk}
                setFormDataBulk={ setFormStepBulk }
                nextStep={ nextStep }
                backStep={ backStep }
            />;
            case 2:
            return <Step3
                formData={ formStep3 }
                setFormData={ setFormStep3 }
                pickUpId={ formStep2.pickUpId }
                nextStep={ nextStep }
                backStep={ backStep }
            />;
            case 3:
            return <Step4
                formData={ formStep4 }
                setFormData={ setFormStep4 }
                orderType={ formStep1.orderType }
                nextStep={ nextStep }
                backStep={ backStep }
            />;
            case 4:
            return <Step5 
                dataDelivery={ formStep3 }
                dataPackage={ formStep4 }
                formDataBulk={formStepBulk}
                setFormDataBulk={ setFormStepBulk }
                saveOrder={ handleSave }
                saveOrderBulk={ handleSaveBulk }
                backStep={ backStep }
            />;
            case 5:
            return <Step5 
                dataDelivery={ formStep3 }
                dataPackage={ formStep4 }
                formDataBulk={formStepBulk}
                setFormDataBulk={ setFormStepBulk }
                saveOrder={ handleSave }
                saveOrderBulk={ handleSaveBulk }
                backStep={ backStep }
            />;
            default:
            return 'Unknown stepIndex';
        }
    }
    const stepMovil = () => {
        switch ( step ) {
            case 0: return 'Order Type'
            case 1: return 'Pick up'
            case 2: return 'Deliver to'
            case 3: return 'Order information'
            case 4: return 'Complete'
            default:return 'Unknown stepIndex';
        }
    }
    const handleSave = async () => {
        const _formData = {
            pickUpId: formStep2.pickUpId,
            deliveryId: formStep3.deliveryId,
            deliverySaved: formStep3.deliverySaved,
            deliveryTitle: formStep3.deliveryTitle,
            deliveryName: formStep3.deliveryName,
            deliveryPhone: formStep3.deliveryPhone,
            deliveryLocation: formStep3.deliveryLocation,
            deliveryCity: formStep3.deliveryCity,
            deliveryProvince: formStep3.deliveryProvince,
            deliveryPostal: formStep3.deliveryPostal,
            deliveryCountry: formStep3.deliveryCountry,
            deliveryApartment:formStep3.deliveryLocNumInt,
            pickUpDate: formStep4.pickUpDate,
            readyTime: formStep4.readyTime,
            service: formStep4.service_id,
            package: formStep4.package,
            packageNumber: formStep4.package.length,
            packageWeight: formStep4.packageWeight,
            packageUnit: formStep4.dimensionsType,
            weightUnit: formStep4.weightType,
            comments: formStep4.comments,
            insuranceValue: formStep4.isInsurance ? formStep4.insuranceValue : ''
        }
        console.log("object",_formData)
        const resp = await saveOrder( _formData )
        if ( resp.status ) {
            history.push(`/customer/history/details/${resp.response.order_id}`);
        } else {
            setState({...state, isError: 'We have some problems, please try again in some moments.'})
        }
    }
    const GetServices = async () => {
        const resp = await getCustomerService(u_id)
        if (resp.status) {
            setService(resp.results)
        } else {
            setService(false)
        }
    }
    const handleSaveBulk = () => {
        
        const dataBulk = formStepBulk.data
        dataBulk.forEach( async index => {
            let _service = null;
            service.forEach( i => {
                if ( index.service == i.service ) {
                    _service = i.service_id
                }
            })
            if ( !_service ) {
                return setState({...state, isError:'Service is not availity, please select a valid service.'});
            }
            else{
                const formDataBulk = {
                    pickUpDate: index.pickUpDate, 
                    service: _service,
                    pickupAddress: index.pickUpLocation,
                    pickupApartment: index.pickUpApartment ? index.pickUpApartment : '',
                    pickupCity: index.pickUpCity ,
                    pickupProvince: index.pickUpProvince ,
                    pickupPostal: index.pickUpPostal ,
                    pickupCountry: index.pickUpCountry ,
                    pickupContact: index.pickUpName ,
                    pickupPhone: index.pickUpPhone ,
                    deliveryAddress: index.deliveryLocation ,
                    deliveryApartment: index.deliveryApartment ? index.deliveryApartment : '',
                    deliveryCity: index.deliveryCity ,
                    deliveryProvince: index.deliveryProvince,
                    deliveryPostal: index.deliveryPostal,
                    deliveryCountry: index.deliveryCountry,
                    deliveryContact: index.deliveryName,
                    deliveryPhone: index.deliveryPhone,
                    totalPackages: index.packageNumber ,
                    totalWeight: index.packageWeight ,
                    packageType: index.dimensionsType,
                    instructions: index.comments 
                }
                const resp = await saveOrderBulk( formDataBulk )
                index.isSaved = true;
                index.ordId = resp.response.order_id;
            }
        })
        return setTimeout(() => { history.push(`/customer/history`); }, 1000); 
    }
    
    useEffect(() => { GetServices() }, [])

    return (
        <Grid container className="newOrder_body" >
            <Grid container direction="row" justify="center" alignItems="center" className="sepper_header" >
                <div className="w-100 text-center"> <img src={ iconPlus } alt=""/> </div>
                <Typography variant="h4" className="text_white" >
                    New shipment
                </Typography>
                <div className={classes.root} >
                    <Hidden only={['xs', 'sm']}>
                        <Stepper 
                            id="newOrder"
                            activeStep={ step } 
                            alternativeLabel 
                            className="bg_primary" 
                            connector={<StepperConnector />} 
                        >
                            { steps.map( label => 
                                <Step key={label} >
                                    <StepLabel >{ label }</StepLabel>
                                </Step>
                            )}
                        </Stepper>
                    </Hidden>
                    <Hidden mdUp><Typography variant="h5" className="text_white text-center" > { stepMovil() }</Typography></Hidden>
                </div>
            </Grid>
            <Grid container className="sepper_body">
                { !!state.isError && <Alert severity="error" style={{marginBottom: '25px'}} > {state.isError} </Alert> }
                { !!state.isMsg && <Alert severity="success" style={{marginBottom: '25px'}} > {state.isMsg} </Alert> }
                { step !== steps.length &&  getStepContent( step ) }
            </Grid>
        </Grid>
    )
}
