import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import csvtojson from 'csvtojson'

const InputFile = ({ accept, label, handleJsonResult }) => {

    let fileReader;

    const inputFile = useRef()

    const [file, setfile] = useState()

    const handleLoaded = (e) => {
        const content = fileReader.result;

        csvtojson().fromString(content)
            .then(json =>{
                handleJsonResult(json)
            })
            .catch(error=>{
                console.log("error",error);
            })
    }

    const handleFileChosen = (e) => {
        e.preventDefault();
        let file = e.target.files[0];

        setfile(file)

        fileReader = new FileReader()
        fileReader.onloadend = handleLoaded;
        fileReader.readAsText(file);
    }

    return (
        <div className="input_file">
            <label>
                {file ? file.name : label }
                <input 
                    id="csv_input" 
                    type="file" 
                    accept={accept} 
                    ref={inputFile}
                    onChange={handleFileChosen} />
            </label>
        </div>
    )
}

InputFile.propTypes = {
    label: PropTypes.string,
    accept: PropTypes.string,
    handleJsonResult: PropTypes.func
};

export default InputFile
