import { Box, Button, Card, CardContent, Grid, TextField, Typography, useTheme } from '@material-ui/core'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import { decodeFromBase64DataUri } from 'pdf-lib'
import React, { useContext } from 'react'
import { AuthContext } from '../../Components/Functions/Auth'
import BaseDialog from '../../Components/Global/Modals/BaseDialog'
import { updateRegisterData } from '../../middleware/warehouse/Warehouse'

const EditRegisterModal = ({ item, open, onClose, onDataChange }) => {

    const theme = useTheme()

    const { user } = useContext(AuthContext)

    const defaultData = {
        reason: "_",
        shipment_no: "",
        serial_number: "",
        description: "",
        first_name: "",
        driverkey: "",
        drivername: "",
        orderqty: 0,
        sales_order_no: "",
        stopnumber: ""
    }
    const [formData, setFormData] = React.useState(defaultData)

    React.useEffect(() => {
        const {
            shipment_no,
            serial_number,
            description,
            first_name,
            driverkey,
            drivername,
            orderqty,
            sales_order_no,
            stopnumber,
            status
        } = item
        setFormData({
            username: user.name,
            user_id: user.u_id,
            register_id: item.warehouse_id,
            shipment_no,
            serial_number,
            description,
            first_name,
            driverkey,
            drivername,
            orderqty,
            sales_order_no,
            stopnumber,
            status
        })
    }, [item])


    const onChange = ({ target }) => {
        const { name, value } = target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const result = await updateRegisterData(formData)
        if (result.status) {
            onClose()
            if (onDataChange) {
                onDataChange()
            }
        } else {
            alert("some was wrong")
        }
    }

    return (
        <React.Fragment>
            <BaseDialog open={open} onClose={onClose} >
                <Card>
                    <Box style={{ color: "#fff", background: theme.palette.primary.main }} p={3} >
                        <Typography color="inherit" variant="h6">Details</Typography>
                    </Box>
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <Typography>Order number:</Typography>
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        name="shipment_no"
                                        value={formData.shipment_no}
                                        variant="outlined" size="small" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Serial number:</Typography>
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        name="serial_number"
                                        value={formData.serial_number}
                                        variant="outlined" size="small" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Product:</Typography>
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        name="description"
                                        value={formData.description}
                                        variant="outlined" size="small" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography> Status: </Typography>
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <Select value={formData.status} name="status" onChange={onChange} >
                                            <MenuItem value={"pending"}>Pending</MenuItem>
                                            <MenuItem value={"canceled"}>Canceled</MenuItem>
                                            <MenuItem value={"received"}>Received</MenuItem>
                                            <MenuItem value={"exemption"}>Received-as-X</MenuItem>
                                            <MenuItem value={"on_route"}>On route</MenuItem>
                                            <MenuItem value={"delivered"}>Delivered</MenuItem>
                                            <MenuItem value={"returned"}>Returned</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Client:</Typography>
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        name="first_name"
                                        value={`${formData.first_name}`}
                                        variant="outlined" size="small" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Driver Key:</Typography>
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={formData.driverkey}
                                        name="driverkey" variant="outlined" size="small" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Stop number:</Typography>
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        value={formData.stopnumber}
                                        name="stopnumber" variant="outlined" size="small" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Driver Name:</Typography>
                                    <TextField
                                        fullWidth
                                        onChange={onChange}
                                        name="drivername"
                                        value={formData.drivername}
                                        variant="outlined" size="small" />
                                </Grid>
                                <Grid item xs={12} >
                                    <Box display="flex" justifyContent="flex-end" flexDirection="row" >
                                        <Button type="submit" color="secondary" variant="contained">
                                            save
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </BaseDialog>
        </React.Fragment>
    )
}

export default EditRegisterModal
