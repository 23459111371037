var download = require( 'downloadjs' );
var axios = require('axios');
var { PDFDocument, StandardFonts, rgb, degrees } =require('pdf-lib')
var {APIGO, AccidentPDF} = require('../../../constants/API')
const IMGplaceholder = 'https://cdn4.iconfinder.com/data/icons/1-maps-location-line-black/240/307_-_Placeholder-512.png'
      

export default async function ActionForm(data){
  // Get an existing PDF document
  const url = 'https://golosuite.com:8443/pdfAccident'
  const url2 = 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/accident_claim%2F2020%2F7%2Ffleet_manager_sign03d110c1-691a-4bd3-83b7-8498e5023d2e.png?alt=media&token=6dcbb69d-e3a3-4793-b9f6-763fcfaad454.png';
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
  // console.log(data)

  var bytes = new Uint8Array(existingPdfBytes);
  const pdfDoc = await PDFDocument.load(bytes);
  
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

  console.log( data );

  let scene;
  let signature1;    
  let signature2;

  if ( data.details.scene !== null ) {
    console.log( 'scene' );
    scene = await fetch( data?.details?.scene, requestOptions).then((res) => res.arrayBuffer()).catch((error)=>console.log(error));
  }
  if ( data.details.signatures.fleet_manager.sign_uri !== null ) {
    console.log( 'signature1' );
    signature1 = await fetch( data?.details?.signatures?.fleet_manager?.sign_uri, requestOptions).then((res) => res.arrayBuffer()).catch((error)=>console.log(error));    
  }
  if ( data.details.signatures.operation_manager.sign_uri !== null ) {
    console.log( 'signature2' );
    signature2 = await fetch( data?.details?.signatures?.operation_manager?.sign_uri, requestOptions).then((res) => res.arrayBuffer() ).catch((error)=>console.log(error));
  }
  
  const pages = pdfDoc.getPages()
  const firstPage = pages[0];
  const secondPage = pages[1];
  const thirdPage = pages[2];
  const fourthPage = pages[3];
  const fitthPage = pages[4];
  const sixthPage = pages[5];
  const imgscene = data.details.scene;
  const signaturefleet = data.details.signatures.fleet_manager.sign_uri;
  const signatureop = data.details.signatures.operation_manager.sign_uri;
  
  let pngImage; 
  let pngSign;
  let pngSign2; 
  // scene
  if ( data.details.scene !== null ) {
    console.log( 'scene' );
    pngImage = await pdfDoc.embedPng( scene )
  }
  if ( data.details.signatures.fleet_manager.sign_uri !== null ) {
    console.log( 'signature1' );
    pngSign = await pdfDoc.embedPng( signature1 )
  }
  if ( data.details.signatures.operation_manager.sign_uri !== null ) {
    console.log( 'signature2' );
    pngSign2 = await pdfDoc.embedPng( signature2 ) 
  }

  var myHeaders = new Headers();
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Accept", "*/*");
  myHeaders.append("content-type", "image/png");
  

  var requestOptions = {
    method: 'GET',
    // redirect: 'follow',
    headers: myHeaders
  };
  
  firstPage.drawImage(pngImage, {
    x: 0,
    y: 150,
    width: 600,
    height: 600,
  })

  firstPage.drawText(data.details.form.K.fields[13].value, {x:275, y: 90, size: 9, font: helveticaFont, color: rgb(0, 0, 0),}) //13

  if ( data.details.signatures.fleet_manager.sign_uri !== null ) {
    firstPage.drawImage(pngSign, {
      x: 285,
      y: 115,
      width: 100,
      height: 75,
    })

    sixthPage.drawImage(pngSign, {
      x: 120,
      y: 390,
      width: 200,
      height: 150,
    })
  }

  if ( data.details.signatures.operation_manager.sign_uri !== null ) {
    sixthPage.drawImage(pngSign2, {
      x: 120,
      y: 75,
      width: 200,
      height: 150,
    })
  }

  // const { width, height } = firstPage.getSize()
  /* 
  x = Right - Left
  */
  //Second Page
  //Driver name
  secondPage.drawText(data.details.driver_data.rdga, {x:140, y: 652, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //RDGA
  secondPage.drawText(data.details.driver_data.rdga, {x:405, y: 652, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Age
  secondPage.drawText('', {x:492, y: 652, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //DA Licence
  secondPage.drawText('', {x:135, y: 630, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //phone
  secondPage.drawText('', {x:365, y: 630, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //vehicle owner
  secondPage.drawText('', {x:145, y: 608, size: 10, font: helveticaFont, color: rgb(0, 0, 0), })
  //licence plate
  secondPage.drawText('', {x:360, y: 608, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Van#
  secondPage.drawText('', {x:470, y: 608, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  
  //A
  //Date
  secondPage.drawText(data.details.form.A.fields[0].value, {x:100, y: 550, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Time
  secondPage.drawText(data.details.form.A.fields[1].value, {x:375, y: 550, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //City
  secondPage.drawText(data.details.form.A.fields[2].value, {x:100, y: 525, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Providence
  secondPage.drawText(data.details.form.A.fields[3].value, {x:320, y: 525, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Street or highway
  secondPage.drawText(data.details.form.A.fields[4].value, {x:100, y: 495, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Address
  secondPage.drawText(data.details.form.A.fields[5].value, {x:100, y: 470, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Distance and direction
  secondPage.drawText(data.details.form.A.fields[6].value, {x:150, y: 430, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  if(data.details.form.A.fields[7].value[1].value){
    //Residential
    secondPage.drawText('x', {x:73, y: 394, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.A.fields[7].value[3].value){
    //Bussines Shopping 
    secondPage.drawText('x', {x:177, y: 403, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.A.fields[7].value[4].value){
    //Manufacturing 
    secondPage.drawText('x', {x:177, y: 392, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.A.fields[7].value[2].value){
    //Other Describe
    secondPage.drawText('Address Example', {x:165, y: 368, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  
  //B
  //NAME
  secondPage.drawText(data.details.form.B.fields[0].value, {x:109, y: 305, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Address
  secondPage.drawText(data.details.form.B.fields[1].value, {x:115, y: 286, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Phone
  secondPage.drawText(data.details.form.B.fields[2].value, {x:415, y: 286, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //NAME #2
  secondPage.drawText(data.details.form.B.fields[3].value, {x:110, y: 270, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Address #2
  secondPage.drawText(data.details.form.B.fields[4].value, {x:115, y: 253, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Phone #2
  secondPage.drawText(data.details.form.B.fields[5].value, {x:415, y: 253, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Licence number and description
  secondPage.drawText(data.details.form.B.fields[6].value, {x:80, y: 225, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Licence number and description #2
  // secondPage.drawText(data.details.form.B.fields[7].value, {x:80, y: 210, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})

  //Investigating officer
  //NAME
  secondPage.drawText(data.details.form.B.fields[8].value, {x:105, y: 169, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Badge
  secondPage.drawText(data.details.form.B.fields[9].value, {x:417, y: 169, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Service Dpt
  secondPage.drawText(data.details.form.B.fields[10].value, {x:135, y: 151, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Citation you
  secondPage.drawText(data.details.form.B.fields[11].value, {x:135, y: 134, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other
  secondPage.drawText(data.details.form.B.fields[12].value, {x:333, y: 135, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //CRC Location
  secondPage.drawText(data.details.form.B.fields[13].value, {x:135, y: 115, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Tow#
  secondPage.drawText(data.details.form.B.fields[14].value, {x:105, y: 95, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Tow Driver Name
  secondPage.drawText(data.details.form.B.fields[15].value, {x:242 , y: 95, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})

  //third Page
  //C
  //Make & Model
  thirdPage.drawText(data.details.form.C.fields[0].sub_form[0].value, {x:75 , y: 650, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Vin No
  thirdPage.drawText(data.details.form.C.fields[0].sub_form[1].value, {x:50 , y: 625, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Fleet No
  thirdPage.drawText(data.details.form.C.fields[0].sub_form[2].value, {x:240 , y: 625, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Lic. Plate & Prov
  thirdPage.drawText(data.details.form.C.fields[0].sub_form[3].value, {x:120 , y: 611, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})

  //Other Vehicle #2
  //Make & Model #2
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[0].value, {x:65 , y: 566, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Lic. Plate & Prov #2
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[1].value, {x:65 , y: 540, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Driver #2
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[2].value, {x:63 , y: 526, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Address #2
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[3].value, {x:70 , y: 513, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Licence No #2
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[4].value, {x:135 , y: 500, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //name, address and phone of owner  if not the driver
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[5].value, {x:40 , y: 472, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //name, address and phone of owner  if not the driver #2
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[5].value, {x:40 , y: 460, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Insurance Co
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[6].value, {x:95 , y: 447, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Policy No
  thirdPage.drawText(data.details.form.C.fields[1].sub_form[7].value, {x:235 , y: 447, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})


  //Other Vehicle #3
  //Make & Model #3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[0].value, {x:65 , y: 400, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Lic. Plate & Prov #3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[1].value, {x:65 , y: 378, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Driver #3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[2].value, {x:65 , y: 365, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Address #3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[3].value, {x:70 , y: 353, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Driver Licence #3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[4].value, {x:135 , y: 338, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //name address and phone of owner #3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[5].value, {x:177 , y: 324, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //name address and phone of owner #3 Pt2
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[5].value, {x:35 , y: 308, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //name address and phone of owner #3 Pt3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[5].value, {x:35 , y: 295, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Insurance Co #3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[6].value, {x:95 , y: 281, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Policy No #3
  thirdPage.drawText(data.details.form.C.fields[2].sub_form[7].value, {x:235 , y: 281, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})

  //Injured Persons
  //Numbers of persons injured
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[0].value, {x:141 , y: 235, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Fatality
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[1].value, {x:225 , y: 235, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Name
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[2].value, {x:65 , y: 222, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Age
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[3].value, {x:255 , y: 222, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Address
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[4].value, {x:70 , y: 209, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Injuries
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[5].value, {x:67 , y: 195, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Where Taken
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[6].value, {x:87 , y: 183, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Name#2
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[7].value, {x:70 , y: 170, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Age #2
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[8].value, {x:262 , y: 168, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Address #2
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[9].value, {x:70 , y: 155, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Injuries #2
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[10].value, {x:70 , y: 140, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //where taken
  thirdPage.drawText(data.details.form.C.fields[3].sub_form[11].value, {x:90 , y: 127, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Estimate of property damage
  thirdPage.drawText(data.details.form.C.fields[4].value, {x:170 , y: 112, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Ambulance
  thirdPage.drawText(data.details.form.C.fields[5].value, {x:88 , y: 98, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Ambulance
  thirdPage.drawText(data.details.form.C.fields[6].value, {x:145 , y: 98, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  //Ambulance
  thirdPage.drawText(data.details.form.C.fields[7].value, {x:233 , y: 98, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})

  //D
  //Type of collition
  if(data.details.form.D.fields[0].value[0].value){
    //Collition with Other Vehicle
    thirdPage.drawText('x', {x:317 , y: 669, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[0].value[1].value){
    //Collition with fixed Object
    thirdPage.drawText('x', {x:457 , y: 669, size: 10, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  // if(data.details.form.D.fields[1].value[0]){
    //Ran off Road 
    // thirdPage.drawText('X', {x:316.5 , y: 639, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  // }
  if(data.details.form.D.fields[1].value[0].v1){
    //Ran off Road V1
    thirdPage.drawText('X', {x:459 , y: 639, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[0].v2){
    //Rann off Road V2
    thirdPage.drawText('X', {x:507.5 , y: 639, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[0].v3){
    //Ran off road v3
    thirdPage.drawText('X', {x:556 , y: 639, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  // if(data.details.form.D.fields[1].value[4]){
    //Overturn in Road
    // thirdPage.drawText('X', {x:316.5 , y: 626, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  // }
  if(data.details.form.D.fields[1].value[1].v1){
    //Overturn in Road V1
    thirdPage.drawText('X', {x:459 , y: 625, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[1].v2){
    //OverTurn in Road V2
    thirdPage.drawText('X', {x:507.5 , y: 625, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[1].v3){
    //OverTurn in Road V3
    thirdPage.drawText('X', {x:556 , y: 625, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  //Mecanical Defect
  // thirdPage.drawText('X', {x:316.5 , y: 613, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  if(data.details.form.D.fields[1].value[2].v1){
    //Mecanical Defect V1
    thirdPage.drawText('X', {x:459 , y: 612, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[2].v2){
    //Mecanical Defect V2
    thirdPage.drawText('X', {x:507.5 , y: 612, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[2].v3){
    //Mecanical Defect V3
    thirdPage.drawText('X', {x:556 , y: 612, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Fire
  // thirdPage.drawText('X', {x:316.5 , y: 600, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  if(data.details.form.D.fields[1].value[3].v1){
    //Fire V1
    thirdPage.drawText('X', {x:459 , y: 598, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[3].v2){
    //Fire V2
    thirdPage.drawText('X', {x:507.5 , y: 598, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[3].v3){
    //Fire V3
    thirdPage.drawText('X', {x:556 , y: 598, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  
  //Loading or Unloading
  // thirdPage.drawText('X', {x:316.5 , y: 587, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  
  if(data.details.form.D.fields[1].value[4].v1){
    //Loading or Unloading V1
    thirdPage.drawText('X', {x:459 , y: 584, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[4].v2){
    //Loading or Unloading V2
    thirdPage.drawText('X', {x:507.5 , y: 584, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[4].v3){
    //Loading or Unloading V3
    thirdPage.drawText('X', {x:556 , y: 584, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Boarding / Alilighting
  // thirdPage.drawText('X', {x:317 , y: 573.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  if(data.details.form.D.fields[1].value[5].v1){
    //Boarding / Alilighting V1
    thirdPage.drawText('X', {x:459.5 , y: 571, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[5].v2){
    //Boarding / Alilighting V2
    thirdPage.drawText('X', {x:508 , y: 571, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[5].v3){
    //Boarding / Alilighting V3
    thirdPage.drawText('X', {x:556.5 , y: 571, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Occupant fell out
  // thirdPage.drawText('X', {x:317 , y: 560, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  if(data.details.form.D.fields[1].value[6].v1){
    //Occupant fell out V1
    thirdPage.drawText('X', {x:459.5 , y: 557.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[6].v2){
    //Occupant fell out V2
    thirdPage.drawText('X', {x:508 , y: 557.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[6].v3){
    //Occupant fell out v3
    thirdPage.drawText('X', {x:556.5 , y: 557.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Occupant injured inside vehicle
  // thirdPage.drawText('X', {x:317 , y: 547.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  if(data.details.form.D.fields[1].value[7].v1){
    //Occupant injured inside vehicleV1
    thirdPage.drawText('X', {x:459 , y: 532.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[7].v2){
    //Occupant injured inside vehicle V2
    thirdPage.drawText('X', {x:508 , y: 532.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[1].value[7].v3){
    //Occupant injured inside vehicle
    thirdPage.drawText('X', {x:556.5 , y: 533, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  //Other
  // thirdPage.drawText('X', {x:317 , y: 519, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other text
  thirdPage.drawText(data.details.form.D.fields[2].value, {x:353 , y: 518, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  if(data.details.form.D.fields[3].value[0].value){
    //Crossing at intersection
    thirdPage.drawText('X', {x:418 , y: 492, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[3].value[1].value){
    //Between Intersections
    thirdPage.drawText('X', {x:555 , y: 491.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[3].value[2].value){
    //With Signal
    thirdPage.drawText('X', {x:370 , y: 477.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[3].value[3].value){
    //Against Signal
    thirdPage.drawText('X', {x:525 , y: 478, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  // if(data.details.form.D.fields[3].value[7].value){
    //No signal
    // thirdPage.drawText('X', {x:357.5 , y: 464.7, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  // }
  // if(data.details.form.D.fields[3].value[7].value){
    //Diagonally
    // thirdPage.drawText('X', {x:510 , y: 464.4, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  // }
  if(data.details.form.D.fields[3].value[4].value){
    //Walking in Roadway
    thirdPage.drawText('X', {x:405.2 , y: 451, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[3].value[5].value){
    //Sidewalk
    thirdPage.drawText('X', {x:479.7 , y: 451, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[3].value[6].value){
    //No sidewalk
    thirdPage.drawText('X', {x:558.5 , y: 450.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[3].value[7].value){
    //With Traffic
    thirdPage.drawText('X', {x:363.6 , y: 437, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.D.fields[3].value[8].value){
    //Against Traffic
    thirdPage.drawText('X', {x:527 , y: 436.4, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  //Other 
  // thirdPage.drawText('Example Text', {x:386 , y: 425, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other P2
  thirdPage.drawText(data.details.form.D.fields[4].value , {x:319 , y: 414, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  

  //E
  if(data.details.form.E.fields[0].value[0].v1){
    //Straight Ahead V1
    thirdPage.drawText('X', {x:459.2 , y: 367, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[0].v2){
    //Straight Ahead V2
    thirdPage.drawText('X', {x:508 , y: 367, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[0].v3){
    //Straight Ahead V3
    thirdPage.drawText('X', {x:556.4, y: 367, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[1].v1){
    //Turning Right V1
    thirdPage.drawText('X', {x:459.2, y: 353.2, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[1].v2){
    //Turning Right V2
    thirdPage.drawText('X', {x:508, y: 353.2, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[1].v3){
    //Turning Right V3
    thirdPage.drawText('X', {x:556.4, y: 353.2, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[2].v1){
    //Turning Left V1
    thirdPage.drawText('X', {x:459.2, y: 340, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[2].v2){
    //Turning Left V2
    thirdPage.drawText('X', {x:508, y: 340, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[2].v3){
    //Turning Left V3
    thirdPage.drawText('X', {x:556.4, y: 340, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[3].v1){
    //Slowing or Stopping V1
    thirdPage.drawText('X', {x:459.2, y: 326, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[3].v2){
    //Slowing or Stopping V2
    thirdPage.drawText('X', {x:508, y: 326, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[3].v3){
    //Slowing or Stopping V3
    thirdPage.drawText('X', {x:556.4, y: 326, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[4].v1){
    //Stopper in Traffic V1
    thirdPage.drawText('X', {x:459.2, y: 312, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[4].v2){
    //Stopper in Traffic V2
    thirdPage.drawText('X', {x:508, y: 312, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[4].v3){
    //Stopper in Traffic V3
    thirdPage.drawText('X', {x:556.4, y: 312, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[5].v1){
    //Starting in Traffic V1
    thirdPage.drawText('X', {x:459.2, y: 299, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[5].v2){
    //Starting in Traffic V2
    thirdPage.drawText('X', {x:508, y: 299, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[5].v3){
    //Starting in Traffic V3
    thirdPage.drawText('X', {x:556.4, y: 299, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[6].v1){
    //Starting from curb or Shoulder V1
    thirdPage.drawText('X', {x:459.2, y: 286, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[6].v2){
    //Starting from curb or Shoulder V2
    thirdPage.drawText('X', {x:508, y: 286, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[6].v3){
    //Starting from curb or Shoulder V3
    thirdPage.drawText('X', {x:556.4, y: 286, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[7].v1){
    //Parked V1
    thirdPage.drawText('X', {x:459.2, y: 272.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[7].v2){
    //Parked V2
    thirdPage.drawText('X', {x:508, y: 272.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[7].v3){
    //Parked V3
    thirdPage.drawText('X', {x:556.4, y: 272.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[8].v1){
    //Backing V1
    thirdPage.drawText('X', {x:459.2, y: 258.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[8].v2){
    //Backing V2
    thirdPage.drawText('X', {x:508, y: 258.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[8].v3){
    //Backing V3
    thirdPage.drawText('X', {x:556.4, y: 258.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[9].v1){
    //U-Turn V1
    thirdPage.drawText('X', {x:459.2, y: 245, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[9].v2){
    //U-Turn V2
    thirdPage.drawText('X', {x:508, y: 245, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[9].v3){
    //U-Turn V3
    thirdPage.drawText('X', {x:556.4, y: 245, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[10].v1){
    //Skidding V1
    thirdPage.drawText('X', {x:459.2, y: 231, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[10].v2){
    //Skidding V2
    thirdPage.drawText('X', {x:508, y: 231, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[10].v3){
    //Skidding V3
    thirdPage.drawText('X', {x:556.4, y: 231, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[11].v1){
    //Overtaking V1
    thirdPage.drawText('X', {x:459.2, y: 217, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[11].v2){
    //Overtaking V2
    thirdPage.drawText('X', {x:508, y: 217, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[11].v3){
    //Overtaking V3
    thirdPage.drawText('X', {x:556.4, y: 217, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[12].v1){
    //Weaving V1
    thirdPage.drawText('X', {x:459.2, y: 203, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[12].v2){
    //Weaving V2
    thirdPage.drawText('X', {x:508, y: 203, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[12].v3){
    //Weaving V3
    thirdPage.drawText('X', {x:556.4, y: 203, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[13].v1){
    //Wrong Side V1
    thirdPage.drawText('X', {x:459.2, y: 189, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[13].v2){
    //Wrong Side V2
    thirdPage.drawText('X', {x:508, y: 189, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[13].v3){
    //Wrong Side V3
    thirdPage.drawText('X', {x:556.4, y: 189, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[14].v1){
    //Crowded off Road V1
    thirdPage.drawText('X', {x:459.2, y: 175, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[14].v2){
    //Crowded off Road V2
    thirdPage.drawText('X', {x:508, y: 175, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[14].v3){
    //Crowded off Road V3
    thirdPage.drawText('X', {x:556.4, y: 175, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.E.fields[0].value[15].v1){
    //Evasive Action V1
    thirdPage.drawText('X', {x:459.2, y: 161, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[15].v2){
    //Evasive Action V2
    thirdPage.drawText('X', {x:508, y: 161, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.E.fields[0].value[15].v3){
    //Evasive Action V3
    thirdPage.drawText('X', {x:556.4, y: 161, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }


  //Other P1
  thirdPage.drawText(data.details.form.E.fields[1].value, {x:340, y: 150, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other P2
  thirdPage.drawText('Example Text', {x:319, y: 138, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other P3
  thirdPage.drawText('Example Text', {x:319, y: 126, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other P4
  thirdPage.drawText('Example Text', {x:319, y: 113, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other P5
  thirdPage.drawText('Example Text', {x:319, y: 99, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  //4 Page
  //F
  if(data.details.form.F.fields[0].value[0].v1){
    //No Defect V1
    fourthPage.drawText('X', {x:182, y: 664, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[0].v2){
    //No Defect V2
    fourthPage.drawText('X', {x:230, y: 664, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[0].v3){
    //No Defect V3
    fourthPage.drawText('X', {x:278, y: 664, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.F.fields[0].value[1].v1){
    //Lights V1
    fourthPage.drawText('X', {x:182, y: 650, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[1].v2){
    //Lights V2
    fourthPage.drawText('X', {x:230, y: 650, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[1].v3){
    //Lights V3
    fourthPage.drawText('X', {x:278, y: 650, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.F.fields[0].value[2].v1){
    //Brakes V1
    fourthPage.drawText('X', {x:182, y: 636, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[2].v2){
    //Brakes V2
    fourthPage.drawText('X', {x:230, y: 636, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[2].v3){
    //Brakes V3
    fourthPage.drawText('X', {x:278, y: 636, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.F.fields[0].value[3].v1){
    //Tires/Wheels V1
    fourthPage.drawText('X', {x:182, y: 623, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[3].v2){
    //Tires/Wheels V2
    fourthPage.drawText('X', {x:230, y: 623, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[3].v3){
    //Tires/Wheels V3
    fourthPage.drawText('X', {x:278, y: 623, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.F.fields[0].value[4].v1){
    //Engine V1
    fourthPage.drawText('X', {x:182, y: 610, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[4].v2){
    //Engine V2
    fourthPage.drawText('X', {x:230, y: 610, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[4].v3){
    //Engine V3
    fourthPage.drawText('X', {x:278, y: 610, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.F.fields[0].value[5].v1){
    //Couplings V1
    fourthPage.drawText('X', {x:182, y: 597, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[5].v2){
    //Couplings V2
    fourthPage.drawText('X', {x:230, y: 597, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[5].v3){
    //Couplings V3
    fourthPage.drawText('X', {x:278, y: 597, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.F.fields[0].value[6].v1){
    //Windshield/Windows V1
    fourthPage.drawText('X', {x:182, y: 584, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[6].v2){
    //Windshield/Windows V2
    fourthPage.drawText('X', {x:230, y: 584, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[6].v3){
    //Windshield/Windows V3
    fourthPage.drawText('X', {x:278, y: 584, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.F.fields[0].value[7].v1){
    //Disabled V1
    fourthPage.drawText('X', {x:182, y: 571, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[7].v2){
    //Disabled V2
    fourthPage.drawText('X', {x:230, y: 571, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.F.fields[0].value[7].v3){
    //Disabled V3
    fourthPage.drawText('X', {x:278, y: 571, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Other
  fourthPage.drawText(data.details.form.F.fields[1].value, {x:80, y: 557, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  

  //G
  if(data.details.form.G.fields[0].value[0].value){
    //Not a Intersection
    fourthPage.drawText('X', {x:41, y: 523, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[0].value[4].value){
    //Bridge Overpass
    fourthPage.drawText('X', {x:185, y: 523, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[0].value[1].value){
    //Street Intersection
    fourthPage.drawText('X', {x:41, y: 511, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[0].value[5].value){
    //Underpass
    fourthPage.drawText('X', {x:185, y: 511, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[0].value[2].value){
    //Drive or Alley
    fourthPage.drawText('X', {x:41, y: 498, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[0].value[6].value){
    //Private property
    fourthPage.drawText('X', {x:185, y: 498.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[0].value[3].value){
    //Crosswalk
    fourthPage.drawText('X', {x:41, y: 485, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[0].value[7].value){
    //Other off-street
    fourthPage.drawText('X', {x:185, y: 486, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Other
  fourthPage.drawText(data.details.form.G.fields[1].value, {x:120, y: 471, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  
  if(data.details.form.G.fields[2].value[0].value){
    //Not Divided
    fourthPage.drawText('X', {x:41, y: 458, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[2].value[1].value){
    //Divided
    fourthPage.drawText('X', {x:139, y: 458, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[2].value[2].value){
    //Limited Access
    fourthPage.drawText('X', {x:213.5, y: 458, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  }


  //No of Lanes
  // fourthPage.drawText('X', {x:41, y: 444, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  //Specify
  fourthPage.drawText(data.details.form.G.fields[3].value, {x:172, y: 444, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})

  //Road Surface

  if(data.details.form.G.fields[4].value[0].value){
    //Lanes Marked
    fourthPage.drawText('X', {x:41, y: 405, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[4].value[1].value){
    //Concrete
    fourthPage.drawText('X', {x:41, y: 392, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[4].value[2].value){
    //Blacktop
    fourthPage.drawText('X', {x:41, y: 379, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[4].value[3].value){
    //Metal Grating (Bridge)
    fourthPage.drawText('X', {x:41, y: 366, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[4].value[4].value){
    //Unmarked
    fourthPage.drawText('X', {x:185, y: 405, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[4].value[5].value){
    //Gravel
    fourthPage.drawText('X', {x:185, y: 392, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[4].value[6].value){
    //Other Unpaved
    fourthPage.drawText('X', {x:185, y: 379, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  
  //Other(specify)
  // fourthPage.drawText('X', {x:56, y: 353, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other Text
  fourthPage.drawText(data.details.form.G.fields[5].value, {x:138, y: 353, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  if(data.details.form.G.fields[6].value[0].value){
    //No Defects
    fourthPage.drawText('X', {x:41, y: 340, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[1].value){
    //Dry
    fourthPage.drawText('X', {x:41, y: 326, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[2].value){
    //Wet
    fourthPage.drawText('X', {x:41, y: 312, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[3].value){
    //Ice
    fourthPage.drawText('X', {x:41, y: 298, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[4].value){
    //Snow
    fourthPage.drawText('X', {x:41, y: 287, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[5].value){
    //Mud
    fourthPage.drawText('X', {x:185, y: 340, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[6].value){
    //Loose Material
    fourthPage.drawText('X', {x:185, y: 326, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[7].value){
    //Cracks, holes, etc.
    fourthPage.drawText('X', {x:185, y: 313, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[8].value){
    //Fresh Oil
    fourthPage.drawText('X', {x:185, y: 300, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[6].value[9].value){
    //Under construction or repair
    fourthPage.drawText('X', {x:185, y: 288, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Other
  // fourthPage.drawText('X', {x:56, y: 261, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //describe
  fourthPage.drawText(data.details.form.G.fields[7].value, {x:140, y: 277, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  if(data.details.form.G.fields[8].value[0].value){
    //Straight
    fourthPage.drawText('X', {x:41.5, y: 249.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[1].value){
    //Hills
    fourthPage.drawText('X', {x:154, y: 249.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[2].value){
    //Moderate
    fourthPage.drawText('X', {x:248.5, y: 249.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[3].value){
    //R
    fourthPage.drawText('X', {x:93, y: 235.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[4].value){
    //Sharp
    fourthPage.drawText('X', {x:179.5, y: 235.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[5].value){
    //Level
    fourthPage.drawText('X', {x:105, y: 249.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[6].value){
    //Steep
    fourthPage.drawText('X', {x:199.5, y: 249.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[7].value){
    //Curve
    fourthPage.drawText('X', {x:41.5, y: 235.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[8].value){
    //L
    fourthPage.drawText('X', {x:131, y: 235.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[8].value[9].value){
    //Moderate
    fourthPage.drawText('X', {x:234, y: 235.5, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  

  //Traffic controls
  if(data.details.form.G.fields[9].value[0].value){
    //Traffic Light
    fourthPage.drawText('X', {x:41, y: 208, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[9].value[1].value){
    //Stop Sign
    fourthPage.drawText('X', {x:41, y: 195, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[9].value[2].value){
    //Yield Sign
    fourthPage.drawText('X', {x:41, y: 182, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[9].value[3].value){
    //Police Officer
    fourthPage.drawText('X', {x:41, y: 169, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[9].value[4].value){
    //Crosing Signal
    fourthPage.drawText('X', {x:185, y: 208, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[9].value[5].value){
    //No Traffic Control
    fourthPage.drawText('X', {x:185, y: 195, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[9].value[6].value){
    //Posted Speed Limit
    fourthPage.drawText('X', {x:185, y: 182, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  
  //Speed limit text
  // fourthPage.drawText('###', {x:275, y: 182, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other
  // fourthPage.drawText('X', {x:185, y: 169, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  //Other Text
  fourthPage.drawText(data.details.form.G.fields[10].value, {x:222, y: 169, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  
  if(data.details.form.G.fields[11].value[0].value.yes){
    //Yes
    fourthPage.drawText('X', {x:162.5, y: 154, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[11].value[0].value.no){
    //No
    fourthPage.drawText('X', {x:221.5, y: 154, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }


  //weather conditions

  if(data.details.form.G.fields[12].value[0].value){
    //Clear
    fourthPage.drawText('X', {x:318, y: 698, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[1].value){
    //Snow
    fourthPage.drawText('X', {x:318, y: 684, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[2].value){
    //Sleet
    fourthPage.drawText('X', {x:318, y: 670, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[3].value){
    //Fog
    fourthPage.drawText('X', {x:317, y: 657, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[4].value){
    //Rain
    fourthPage.drawText('X', {x:317, y: 643, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[5].value){
    //Daylight
    fourthPage.drawText('X', {x:461, y: 698, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[6].value){
    //Dawn
    fourthPage.drawText('X', {x:461, y: 684, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[7].value){
    //Sunset
    fourthPage.drawText('X', {x:461, y: 670, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[8].value){
    //Dark - road lighted
    fourthPage.drawText('X', {x:461, y: 658, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.G.fields[12].value[9].value){
    //Dark - road unlighted
    fourthPage.drawText('X', {x:461, y: 645, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Other
  // fourthPage.drawText('X', {x:332, y: 631, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other Text
  fourthPage.drawText(data.details.form.G.fields[13].value, {x:403, y: 632, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  //H
  if(data.details.form.H.fields[0].value[0].v1){
    //Front V1
    fourthPage.drawText('X', {x:458, y: 568, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[0].v2){
    //Front V2
    fourthPage.drawText('X', {x:506.5, y: 568, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[0].v3){
    //Front V3
    fourthPage.drawText('X', {x:554.2, y: 568, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.H.fields[0].value[1].v1){
    //Rear V1
    fourthPage.drawText('X', {x:458, y: 555, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[1].v2){
    //Rear V2
    fourthPage.drawText('X', {x:506.5, y: 555, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[1].v3){
    //Rear V3
    fourthPage.drawText('X', {x:554.2, y: 555, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.H.fields[0].value[2].v1){
    //Right Front V1
    fourthPage.drawText('X', {x:458, y: 542, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[2].v2){
    //Right Front V2
    fourthPage.drawText('X', {x:506.5, y: 542, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[2].v3){
    //Right Front V3
    fourthPage.drawText('X', {x:554.2, y: 542, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  
  if(data.details.form.H.fields[0].value[3].v1){
    //Left Front V1
    fourthPage.drawText('X', {x:458, y: 529, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[3].v2){
    //Left Front V2
    fourthPage.drawText('X', {x:506.5, y: 529, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[3].v3){
    //Left Front V3
    fourthPage.drawText('X', {x:554.2, y: 529, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.H.fields[0].value[4].v1){
    //Right Rear V1
    fourthPage.drawText('X', {x:458, y: 516, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[4].v2){
    //Right Rear V2
    fourthPage.drawText('X', {x:506.5, y: 516, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[4].v3){
    //Right Rear V3
    fourthPage.drawText('X', {x:554.2, y: 516, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.H.fields[0].value[5].v1){
    //Left Rear V1
    fourthPage.drawText('X', {x:458, y: 503, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[5].v2){
    //Left Rear V2
    fourthPage.drawText('X', {x:506.5, y: 503, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[5].v3){
    //Left Rear V3
    fourthPage.drawText('X', {x:554.2, y: 503, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.H.fields[0].value[6].v1){
    //Right Side V1
    fourthPage.drawText('X', {x:458, y: 488, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[6].v2){
    //Right Side V2
    fourthPage.drawText('X', {x:506.5, y: 488, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[6].v3){
    //Right Side V3
    fourthPage.drawText('X', {x:554.2, y: 488, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.H.fields[0].value[7].v1){
    //Left Side V1
    fourthPage.drawText('X', {x:458, y: 475, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[7].v2){
    //Left Side V2
    fourthPage.drawText('X', {x:506.5, y: 475, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[7].v3){
    //Left Side V3
    fourthPage.drawText('X', {x:554.2, y: 475, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  if(data.details.form.H.fields[0].value[8].v1){
    //Roof V1
    fourthPage.drawText('X', {x:458, y: 462, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[8].v2){
    //Roof V2
    fourthPage.drawText('X', {x:506.5, y: 462, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }
  if(data.details.form.H.fields[0].value[8].v3){
    //Roof V3
    fourthPage.drawText('X', {x:554.2, y: 462, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  }

  //Other
  fourthPage.drawText(data.details.form.H.fields[1].value, {x:340, y: 450, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  // Cargo Weight / type
  fourthPage.drawText(data.details.form.H.fields[2].value, {x:400, y: 438, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  // Cargo Weight / type 2
  fourthPage.drawText('Example Text', {x:316, y: 426, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Cargo Damage
  fourthPage.drawText(data.details.form.H.fields[3].value, {x:380, y: 413, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  // Cargo Damage 2
  fourthPage.drawText('Example Text', {x:316, y: 400, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other Property Damage
  fourthPage.drawText(data.details.form.H.fields[4].value, {x:413, y: 386, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other Property Damage 2
  fourthPage.drawText('Example Text', {x:316, y: 374, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  //J
  //Notification(Date)
  fourthPage.drawText(data.details.form.J.fields[0].value, {x:390, y: 337, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //(Time)
  fourthPage.drawText(data.details.form.J.fields[1].value, {x:515, y: 337, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //LC
  fourthPage.drawText(data.details.form.J.fields[2].value, {x:330, y: 323, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  //OPS Manager
  fourthPage.drawText(data.details.form.J.fields[3].value, {x:370, y: 310, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Risk Manager
  fourthPage.drawText(data.details.form.J.fields[4].value, {x:370, y: 297, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Enterprise
  fourthPage.drawText(data.details.form.J.fields[5].value, {x:355, y: 284, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Nacora
  fourthPage.drawText(data.details.form.J.fields[6].value, {x:343, y: 271, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Aviva
  fourthPage.drawText(data.details.form.J.fields[7].value, {x:340, y: 258, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //Agency
  fourthPage.drawText(data.details.form.J.fields[8].value, {x:345, y: 244, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  //I 
  //1
  fourthPage.drawText(data.details.form.I.fields[0].value, {x:320, y: 206, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //2
  fourthPage.drawText('Example Text', {x:320, y: 195, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //3
  fourthPage.drawText('Example Text', {x:320, y: 182, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //4
  fourthPage.drawText('Example Text', {x:320, y: 170, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //5
  fourthPage.drawText('Example Text', {x:320, y: 157, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //6
  fourthPage.drawText('Example Text', {x:320, y: 144, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //7
  fourthPage.drawText('Example Text', {x:320, y: 131, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //8
  fourthPage.drawText('Example Text', {x:320, y: 119, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //9
  fourthPage.drawText('Example Text', {x:320, y: 106, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //10
  fourthPage.drawText('Example Text', {x:320, y: 93, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //11
  fourthPage.drawText('Example Text', {x:320, y: 80, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})
  //12
  fourthPage.drawText('Example Text', {x:320, y: 67, size: 8, font: helveticaFont, color: rgb(0, 0, 0),})

  //5 Page
  //K
  //K1
  fitthPage.drawText(data.details.form.K.fields[0].value, {x:245, y: 670, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  //K2
  fitthPage.drawText(data.details.form.K.fields[1].value, {x:180, y: 650, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  //K3
  fitthPage.drawText(data.details.form.K.fields[2].value, {x:205, y: 630, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})

  //Describe Driver Statement:
  fitthPage.drawText(data.details.form.K.fields[3].value, {x:70, y: 550, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  // fitthPage.drawText('Example Text', {x:70, y: 535, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})

  //Describe damage to 
  //Your Vehicle
  fitthPage.drawText(data.details.form.K.fields[5].value, {x:130, y: 230, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  fitthPage.drawText('Example Text', {x:73, y: 210, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  //Other Vehicle
  fitthPage.drawText(data.details.form.K.fields[6].value, {x:85, y: 177, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  fitthPage.drawText('Example Text', {x:72, y: 159, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  //Cargo
  fitthPage.drawText(data.details.form.K.fields[7].value, {x:99, y: 137, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  fitthPage.drawText('Example Text', {x:72, y: 117, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  //Property
  fitthPage.drawText(data.details.form.K.fields[8].value, {x:105, y: 97, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  fitthPage.drawText('Example Text', {x:72, y: 77, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})

  //6 Page
  //Logistic Coordinator Report
  sixthPage.drawText(data.details.form.K.fields[10].value, {x:72, y: 660, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  sixthPage.drawText(data.details.form.K.fields[11].value, {x:403, y: 390, size: 9, font: helveticaFont, color: rgb(0, 0, 0),}) //11
  


  //Operations Manager Report:
  sixthPage.drawText(data.details.form.K.fields[12].value, {x:72, y: 345, size: 9, font: helveticaFont, color: rgb(0, 0, 0),})
  sixthPage.drawText(data.details.form.K.fields[13].value, {x:403, y: 80, size: 9, font: helveticaFont, color: rgb(0, 0, 0),}) //13

  const pdfBytes = await pdfDoc.save()
  download(pdfBytes, "pdf-accidents.pdf", "application/pdf");

}
