import React, { useState, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
// Componentes
import ModalShow from '../Components/Global/Modal';
// IMG
import Portada from './../Assets/Img/Portada_management.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
//API
import { UpdateVendor, getVendorById, APIGO } from '../constants/API'; 
import * as firebase from 'firebase';

const VendorDatails = () => {

    const history = useHistory();
    const match   = useRouteMatch('/vendor/details/:id');
    const id      = match.params.id;   
    const [ name, setName ]              = useState('');
    const [ files, setFiles]             = useState('');
    const [ onlyName, setOnlyName]       = useState( false );
    const [ onlyLogo, setOnlylogo]       = useState( false );
    const [ previewLogo, setPreviewLogo] = useState( false );
    const [ previewTxt, setPreviewTxt]   = useState( false );
    const [ isError, setIsErrors]        = useState([{
        logo: false,
        name: false,
    }]);
    // Modal
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    }; 
    const saveElement = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            setIsConfirm( false );
            if ( onlyName && onlyLogo ) {
                const storageRef = firebase.storage().ref();
                const upload = storageRef.child(`vendor/${name}/`);
                upload.put(files).then(snapshot=>{
                    upload.getDownloadURL().then(url=>{
                        let config = {
                            method: 'put',
                            url: `${UpdateVendor}${id}`,
                            headers: { 
                                'Access-Control-Request-Headers': 'multipart/form-data', 
                                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                            },
                            data: {
                                name: name,
                                logo: url,
                            }
                        }
                        APIGO(config)
                        .then(response =>{
                            if ( response.data.status === "error" ) {
                                ModalClose()
                                setModalmsg('Sorry the name is already taken');
                                setModalLink('/vendor');
                                setIsShow( true );
                                ModalOpen();
                            }
                            if ( response.data.status === "success" ) {
                                ModalClose()
                                setModalmsg('Element added successfully');
                                setModalLink('/vendor');
                                setIsShow( true );
                                ModalOpen();
                            }
                        })
                        .catch(function (error){
                            console.log(error)
                            ModalClose()
                            setModalmsg('Sorry We have some problems please try again in a frew minutes');
                            setModalLink('/vendor');
                            setIsShow( true );
                            ModalOpen();
                        })
                    })
                })
            }
        }
        else{
            ModalClose()
        }
    }
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const handleForm = e => { 
        e.preventDefault();
        if( !onlyName  ){
            setIsErrors({ name: true });
        }else if( !onlyLogo ){
            setIsErrors({ logo: true });
        }else  {
            setIsErrors('');
            setIsConfirm( true );
            setModalStaus( true );
        }
    }

    const preview = ( e ) => {
        try { 
            setPreviewLogo( URL.createObjectURL( e.target.files[0] ) ) 
            setPreviewTxt(  e.target.files[0].name ) 
        } 
        catch (error) { console.log( error ); }
    }
    useEffect( () =>{

        var config = {
            method: 'get',
            url: `${getVendorById}${id}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then(response =>{
            setFiles( response.data[0].v_logo )
            setName( response.data[0].v_name )
        })
        .catch(function (error){
            console.log(error)
        })

    }, [ ] );

    if ( localStorage.getItem('Type') === '1' ) {
        return (
            <>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        {
                            isConfirm ?
                                <ModalShow 
                                title="Are you sure you want to save a new element? "
                                confirmOperation={ true }
                                Operation={ saveElement }
                                /> : null
                        }    
                        {
                            isShow ?
                                <ModalShow 
                                    title={ modalMsg }
                                    onlyShow={ true }
                                    showGoTo={ modalGoTo }
                                    goTo={ modalLink }
                                /> : null
                        }
                    </Grid>
                </Modal>
    
                <form onSubmit={ e => handleForm( e ) }>
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={2} className="center_center">
                            <Link to="/vendor" className="flex No_Decoration">
                                <ArrowBackIosIcon className="Page_title"/>
                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                    Go back
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" gutterBottom className="Page_title">
                                Vendors
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Link to="/vendor" className="flex No_Decoration">
                                <Button variant="outlined" disableElevation fullWidth className="" >CANCEL</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >SAVE</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Box p={1} ml={2} className="bg_white" boxShadow={3} >
                                <Grid container className="bg_white" >
                                    <Grid item md={6}>
                                        <Box p={1}>
                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                LOGO
                                            </Typography>
                                            <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => { setFiles(e.target.files[0]); setOnlylogo( true ); preview( e ) }}  />
                                            <label htmlFor="uploadFile" className="text_grey pointer">
                                                <Grid container className="upload_file-grey" direction="column" justify="center" alignItems="center" >
                                                    {
                                                        files ?
                                                            onlyLogo ?
                                                                <>
                                                                    <img src={ previewLogo }  alt="" className="img_brand" />
                                                                    <p>{ previewTxt }</p>
                                                                </>
                                                                :
                                                                <img src={ files }  alt="" className="img_brand" />
                                                        :
                                                            <Typography p={1} className="text_grey" >
                                                                Add photo
                                                            </Typography>
                                                    }
                                                </Grid>
                                            </label>
                                            {
                                                isError.logo ?
                                                    <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box p={1}>
                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                NAME
                                            </Typography>
                                            { 
                                                isError.name ?
                                                    <TextField id="VehicleID" type="text" size="small" variant="outlined" fullWidth value={ name } onChange={e => { setName(e.target.value); setOnlyName( true ); } } error helperText="Enter a value for name please" /> 
                                                : 
                                                    <TextField id="VehicleID" type="text" size="small" variant="outlined" fullWidth value={ name } onChange={e => { setName(e.target.value); setOnlyName( true ); } } />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    }
    
    if ( localStorage.getItem('Type') === '2' ) {
        return (
            <>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        {
                            isConfirm ?
                                <ModalShow 
                                title="Are you sure you want to save a new element? "
                                confirmOperation={ true }
                                Operation={ saveElement }
                                /> : null
                        }    
                        {
                            isShow ?
                                <ModalShow 
                                    title={ modalMsg }
                                    onlyShow={ true }
                                    showGoTo={ modalGoTo }
                                    goTo={ modalLink }
                                /> : null
                        }
                    </Grid>
                </Modal>
    
                <form onSubmit={ e => handleForm( e ) }>
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={2} className="center_center">
                            <Link to="/vendor" className="flex No_Decoration">
                                <ArrowBackIosIcon className="Page_title"/>
                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                    Go back
                                </Typography>
                            </Link>
                        </Grid>
                        { 
                            localStorage.getItem('PS_U') === '1' ?
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h4" gutterBottom className="Page_title">
                                        Vendors
                                    </Typography>
                                </Grid>
                            :    
                                <Grid item xs={12} md={8}>
                                    <Typography variant="h4" gutterBottom className="Page_title">
                                        Vendors
                                    </Typography>
                                </Grid>
                        }
                        <Grid item xs={6} md={2}>
                            <Link to="/vendor" className="flex No_Decoration">
                                <Button variant="outlined" disableElevation fullWidth className="" >CANCEL</Button>
                            </Link>
                        </Grid>
                        { 
                            localStorage.getItem('PS_U') === '1' && 
                                <Grid item xs={6} md={2}>
                                    <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >SAVE</Button>
                                </Grid>
                        }
                        <Grid item xs={12}>
                            <Box p={1} ml={2} className="bg_white" boxShadow={3} >
                                <Grid container className="bg_white" >
                                    <Grid item md={6}>
                                        <Box p={1}>
                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                LOGO
                                            </Typography>
                                            { 
                                                localStorage.getItem('PS_U') === '1' ?
                                                    <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" onChange={ e => { setFiles(e.target.files[0]); setOnlylogo( true ); preview( e ) }}  />
                                                    :
                                                    <input type="file" id="uploadFile" className="fileUpload" name="fileUpload" disabled />
                                            }
                                            <label htmlFor="uploadFile" className="text_grey pointer">
                                                <Grid container className="upload_file-grey" direction="column" justify="center" alignItems="center" >
                                                    {
                                                        files ?
                                                            onlyLogo ?
                                                                <>
                                                                    <img src={ previewLogo }  alt="" className="img_brand" />
                                                                    <p>{ previewTxt }</p>
                                                                </>
                                                                :
                                                                <img src={ files }  alt="" className="img_brand" />
                                                        :
                                                            <Typography p={1} className="text_grey" >
                                                                Add photo
                                                            </Typography>
                                                    }
                                                </Grid>
                                            </label>
                                            {
                                                isError.logo ?
                                                    <span className="text_error"><small> Enter a valid file please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box p={1}>
                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                NAME
                                            </Typography>
                                            { 
                                                localStorage.getItem('PS_U') === '1' ?
                                                    isError.name ?
                                                        <TextField id="VehicleID" type="text" size="small" variant="outlined" fullWidth value={ name } onChange={e => { setName(e.target.value); setOnlyName( true ); } } error helperText="Enter a value for name please" /> 
                                                        : 
                                                        <TextField id="VehicleID" type="text" size="small" variant="outlined" fullWidth value={ name } onChange={e => { setName(e.target.value); setOnlyName( true ); } } />
                                                :
                                                    isError.name ?
                                                        <TextField id="VehicleID" type="text" size="small" variant="outlined" disabled fullWidth value={ name } error helperText="Enter a value for name please" /> 
                                                        : 
                                                        <TextField id="VehicleID" type="text" size="small" variant="outlined" disabled fullWidth value={ name } />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    }
}
export default VendorDatails