import React from 'react'
import { BrowserRouter, Switch, useRouteMatch } from 'react-router-dom'

//Page views
import CustomerServiceDashboard from '../Pages/CustomerService/CustomerServiceDashboard'
import UploadCsv from '../Pages/CustomerService/UploadCsv'
import History from '../Pages/CustomerService/CustomerServiceHistory'
import RegisterList from '../Pages/Warehouse/RegistersList'

import RoutePrivate from './components/RoutePrivate'
import WarehousePrintLabel from '../Pages/Warehouse/WarehousePrintLabel'
import GenerateEdi from '../Pages/CustomerService/GenerateEdi'
import GeneratedEdiHistory from '../Pages/CustomerService/GeneratedEdiHistory'


const CustomerServiceRoutes = () => {

    const { url } = useRouteMatch()

    return (
        <Switch>
            <RoutePrivate exact path={`${url}`} component={CustomerServiceDashboard} />
            <RoutePrivate exact path={`${url}/upload`} component={UploadCsv} />
            <RoutePrivate exact path={`${url}/generate_edi`} component={GenerateEdi} />
            <RoutePrivate exact path={`${url}/edi_history`} component={GeneratedEdiHistory} />
            <RoutePrivate exact path={`${url}/history`} component={History} />
            <RoutePrivate exact path={`${url}/list/:id`} component={RegisterList} />
            <RoutePrivate exact path={`${url}/print/:label`} component={WarehousePrintLabel} />
            <RoutePrivate exact path={`${url}/find/products`} component={RegisterList} />
        </Switch>
    )
}

export default CustomerServiceRoutes
