import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

const SettingsElementItem = ( { title, link } ) => {

    

    return (
        <Box p={1} className="" >
            <Grid container spacing={2} >
                <Grid item xs={6}>
                    <Typography variant="h6" className="text_grey">
                        { title }
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box textAlign="right">
                        <Link to={ link } className="No_Decoration">
                            <Button variant="contained" disableElevation className="Go_btn-primary" >SEE MORE</Button>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <hr className="divider_grey" />
                </Grid>
            </Grid>
        </Box>
    );
};

SettingsElementItem.propTypes = {
    
};

export default SettingsElementItem;