import React, { useContext } from 'react'

//UI
import { Container, Grid, makeStyles, Card, CardContent, Typography, LinearProgress, Box, Button } from '@material-ui/core'

//Media
import { Icons } from "../../Assets"

//Utils
import csvtojson from 'csvtojson'

//Functions
import { postRegister, postWarehouseList, postRegisterBundle } from "../../middleware/warehouse/Warehouse"
import BaseDialog from '../../Components/Global/Modals/BaseDialog'
import DialogMessage from '../../Components/Global/Modals/DialogMessage'
import { AuthContext } from '../../Components/Functions/Auth';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {

    },
    header: {
        width: "100%",
        height: theme.spacing(30),
        backgroundColor: theme.palette.primary.main,
        color: "#fff"
    }
}))

const UploadCsv = () => {

    const history = useHistory()

    const context = useContext(AuthContext)

    const classes = useStyles()

    const [progress, setProgress] = React.useState(0)

    const [file, setFile] = React.useState(false)

    const [dialog, setDialog] = React.useState({
        open: false,
        title: "File uploaded successfully",
        message: "Pres back to upload another file or continue to check your new register list",
        type: "success",
    })

    const normalize = (obj) => {
        const result = {};
        Object.keys(obj).forEach(function (key) {
            let new_key = key.split(' ').join('_')
            new_key = new_key.toLowerCase()
            result[new_key] = obj[key];
        });
        return result;
    }

    const make_chunks = (arr, len) => {
        var chunks = [], i = 0, n = arr.length;
        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }
        return chunks;
    }

    const submitData = async (data, file) => {

        if (data && data.length > 0) {

            const list_result = await postWarehouseList(context?.user?.u_id, file.name)

            console.log(list_result)

            if (!list_result.status) {
                console.log("interrupt error")
                return
            }

            console.log("begin upload csv")

            const count = data.length
            //Await for all results
            let _progress = 0

            let bundle_all = []

            await Promise.all(data.map(async (item, index) => {
                //Normalize replace space to _
                item.list_id = list_result.result.id
                item.user_id = context.user.u_id
                item.username = context.user.name

                let _item = normalize(item)
                const result = await postRegister(_item)
                
                _progress += 1

                setProgress((_progress * 100) / count)
            }))

            setDialog({
                ...dialog,
                title: "File uploaded successfully",
                message: "Pres back to upload another file or continue to check your new register list",
                open: true
            })

        } else {

        }
    }

    const handleFileChosen = (e) => {
        const _file = e.target.files[0]
        //Set file on state
        if (_file) {
            setFile(_file)
            //Read as text
            const fileReader = new FileReader()
            //Parse to json
            fileReader.onloadend = async () => {
                const _data = await csvtojson().fromString(fileReader.result)
                //Post all data
                submitData(_data, _file)
            }
            fileReader.readAsText(_file)
        }
    }

    const positiveAction = () => {
        setFile(false)
        setProgress(0)
        setDialog({
            ...dialog,
            open: false
        })
        history.push("/customer_service/history")
    }

    const negativeAction = () => {
        setFile(false)
        setProgress(0)
        setDialog({
            ...dialog,
            open: false
        })
    }

    return (
        <Container>

            <DialogMessage
                {...dialog}
                positiveAction={positiveAction}
                positiveText="Continue"
                negativeAction={negativeAction}
                negativeText="Back"
                onClose={negativeAction}
            />

            <Grid container justify="center" >
                <Grid item xs={12} lg={10} >
                    <Card className={classes.root} >
                        <div className={classes.header} >
                            <Grid container justify="center">
                                <Grid item>
                                    <img src={Icons.ic_scan_in} alt="icon" />
                                    <Typography variant="h4" color="inherit" >Upload a file</Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.content} >
                            <CardContent>
                                {
                                    !file &&
                                    <div className="input_file">
                                        <label>
                                            <img src={Icons.ic_csv} alt="icon" />
                                            <Typography variant="h6" color="primary" >Upload a file</Typography>
                                            <Typography variant="p" >Click to load your .csv file</Typography>
                                            <input
                                                id="csv_input"
                                                type="file"
                                                accept=".csv"
                                                onChange={handleFileChosen} />
                                        </label>
                                    </div>
                                }
                                {
                                    file &&
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <Box width="100%" mr={1}>
                                                    <Typography>{file.name}</Typography>
                                                </Box>
                                                <Box minWidth={35}>
                                                    <Typography variant="body2" color="textSecondary">{`${Math.round(
                                                        progress,
                                                    )}%`}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LinearProgress color="secondary" variant="determinate" value={progress} />
                                        </Grid>
                                    </Grid>
                                }

                                <Grid container justify="center" >
                                    <Grid item>
                                        <Box mt={5}>
                                            <Button disabled={file} onClick={() => history.goBack()} variant="outlined">Cancel</Button>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default UploadCsv
