import React from 'react'
import PropTypes from 'prop-types'

const ImageIcon = ({ src, size }) => {

    let icon_size = 24

    switch (size) {
        case "xs":
            icon_size = 16
            break
        case "md":
            icon_size = 32
            break
        case "lg":
            icon_size = 64
            break
        case "xl":
            icon_size = 128
            break
        default:
            icon_size = 24
            break
    }

    return <img width={icon_size} height={icon_size} src={src} alt="" />
}

const BaseIcon = (props) => {
    return (
        <ImageIcon {...props} />
    )
}

BaseIcon.propTypes = {
    src: PropTypes.string,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])
}

export default BaseIcon