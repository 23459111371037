import React, { useState, useEffect } from 'react';
import { ChatContext } from './components/ChatContext'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import SideChats from './components/SideChats'
import ChatBody from './components/ChatBody'
import FormChat from './components/FormChat'
import HeaderChat from './components/HeaderChat'

import { getMyProfile } from './Functions/Manager'
import { getProfile, APIGO } from '../../constants/API'


const Chat  = () =>{

    // local

    // state data
    const [ idChatActive, setIdChatActive ] = useState(null)
    const [ availableChats, setAvailableChats ] = useState([])
    const [ driverUser, setDriverUser ] = useState(null)
    const [ myProfile, setMyProfile ] = useState([])   

    useEffect(()=>{
        getMyProfile(setMyProfile)
    },[])
    
    return(
        <ChatContext.Provider
            value={{
                availableChats,
                setAvailableChats,
                idChatActive,
                setIdChatActive,
                driverUser,
                setDriverUser,
                myProfile
            }}
        >
            <Grid container>
                <Grid item xs={ 12 } md={ 12 }>
                    <Typography variant="h4" gutterBottom className="Page_title">
                        Chat
                    </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                    <Box className="box_content" p={ 3 } boxShadow={ 3 }>
                        <Grid container spacing={ 3 }>
                            <Grid item xs={ 12 } md={ 3 } className="side_chats">
                                <SideChats/>
                            </Grid>
                            <Grid item xs={ 12 } md={ 9 } className="bg-grey">
                                <Grid container className="bg_history" spacing={ 2 }>
                                    <HeaderChat/>
                                    <ChatBody/>
                                    <FormChat/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </ChatContext.Provider>
    )
}

export default Chat;