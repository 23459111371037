import { Box, Button, Grid, Input, Typography } from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import React, { useRef, useState } from 'react'
import { Fragment } from 'react'

import { CSVReader } from 'react-papaparse';
import InputFile from '../Components/Global/InputFile';

import empty_image from '../Assets/Img/empty_data.png'
import { APIGO } from '../constants/API';
import { useHistory } from 'react-router';
import Modal from '../Components/Global/Modal';
import Dialog from '../Components/Global/Dialog';

const VehiclesCsvImport = () => {
    const history = useHistory();

    const [columns, set_columns] = useState([])
    const [rows, set_rows] = useState([])
    const [isLoading, setisLoading] = useState(false)

    const handleJsonResult = (json_array) => {

        const map = json_array[0]

        let column_map = mapColumns(map)

        let row_map = mapRows(json_array)

        set_columns(column_map)
        set_rows(row_map)

    }

    const mapColumns = (json) => {
        let column_map = []
        const jsonf = formatKeys(json)
        Object.keys(jsonf).map(key => {
            column_map.push({
                field: key,
                width: 150,
                type: "text"
            })
        })
        return column_map;
    }

    const mapRows = (json_array) => {
        let row_map = []
        json_array.map((json, index) => {

            json.id = index
            const newjson = formatKeys(json)
            row_map.push(newjson)
        })
        return row_map;
    }

    //Format object keys
    const formatKeys = (obj) => {
        const keyValues = Object.keys(obj).map(key => {
            const newKey = key.replaceAll(" ", "_").toLowerCase();
            return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }

    const handleSubmit = () => {

        if (rows.length === 0) {
            alert("Select file first")
            return
        }

        let config = {
            method: 'post',
            url: 'vehicles/batch',
            data: {
                create: rows
            },
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`,
            },
        }

        APIGO(config)
            .then(res => {
                console.log(res);
                const { status } = res.data
                if (status === "success") {
                    setisLoading(false)
                    history.push('/vehicles')
                }
            })
            .catch(err => {
                console.log(err);
            })

        setisLoading(true)
    }


    return (
        <Fragment>
            < Dialog
                open={isLoading}
                setOpen={setisLoading}
                title={"Uploading data"}
                message={"This may take long time..."}

            />
            <Grid className="header__details" container direction="row" justify="center" alignItems="center">
                <Grid item><Typography align="center" >Upload your csv file to parse...</Typography></Grid>
                <Grid item>
                    <InputFile
                        accept=".csv"
                        label="Click to upload your csv"
                        handleJsonResult={handleJsonResult}
                    />
                </Grid>
            </Grid>
            <Grid container direction="column"   >
                <Grid item><Typography align="center" >Preview</Typography></Grid>
                <Grid item>
                    <div style={{ height: '60vh', width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            rows={rows}
                            dataSet="Commodity"
                            rowLength={4}
                            maxColumns={6}
                            disableColumnMenu={true}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" >
                <Grid item style={{ paddingTop: 20 }} >
                    <Button className="Go_btn-primary" variant="contained" onClick={handleSubmit} >SUBMIT</Button>
                </Grid>
            </Grid>
        </Fragment>
    )
}

function CustomNoRowsOverlay() {

    return (
        <GridOverlay>
            <Grid container justify="center" alignItems="center" direction="column">
                <Grid item xs={3}>
                    <img src={empty_image} alt="No data imported yet..." width={64} height={64} />
                </Grid>
                <Grid item xs={3}>
                    <Typography>No data imported yet...</Typography>
                </Grid>
            </Grid>
        </GridOverlay>
    )

}

export default VehiclesCsvImport
