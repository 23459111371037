import React, { useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CircularProgress from '@material-ui/core/CircularProgress';

import { sendMessage, uploadFile } from '../Functions/Driver'
import { ChatContext } from './ChatContext'
import styles from '../Functions/chat.module.css'

const FormChat = () => {

    const [ message, setMessage ]     = useState('')
    const { myProfile, idChatActive } = useContext(ChatContext)
    const [ loading, setLoading ]     = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        sendMessage(message, myProfile, idChatActive)
        setMessage("")
    }

    const handleClick = () => {
        document.getElementById('upload_file').click()
    }
    const handleChangeFile = (e) => {
        uploadFile(e.target.files[0], myProfile, idChatActive, setLoading)
    }

    return(
        <form className="form_chat_user" onSubmit={(e) => handleSubmit(e) } >
            <Grid container spacing={2}>
                <Grid item xs={12} md={7} className="" >
                    <TextField 
                        id="message" 
                        type="text" 
                        variant="outlined"
                        size="small" 
                        placeholder="Type a message..."
                        value={ message } 
                        onChange={(e) => setMessage(e.target.value) } 
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} md={2} className="" >
                    <Button variant="contained" 
                    disableElevation 
                    fullWidth
                    onClick={ handleClick }
                    disabled={ !idChatActive }
                    >
                        {loading?<CircularProgress className={ styles.attach_loader } />: <AttachFileIcon />} 
                    </Button>
                </Grid>
                <Grid item xs={6} md={3} className="" >
                    <Button variant="contained" 
                        disableElevation 
                        fullWidth 
                        className="Go_btn-primary" 
                        type="submit"
                        disabled={!message || !idChatActive }
                        >send 
                    </Button>
                </Grid>  
                
            </Grid>
            <div>
                <input type="file" 
                hidden 
                id="upload_file" 
                onChange={e => handleChangeFile(e)}
            />
            </div>
        </form>    
    )
}

export default FormChat;