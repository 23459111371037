import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
// IMG
import vehicle_O from './../../Assets/Img/icon-O.png';
import vehicle_R from './../../Assets/Img/icon-R.png';
import vehicle_L from './../../Assets/Img/icon-L.png';
import ProfileAvatar from './../../Assets/Img/Profile_avatar.png';
//MAterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const ContactItem = ( 
    { 
        isUser, isCustomer, isManager, img, name, info, exp_date, link, 
        isVehicle, v_status, v_fleetNumber, v_vin, v_licensePlate, v_brand, v_station, v_vendor, 
        isAccident, a_status, a_date, a_accident_id, a_fleet_number, a_station, a_driver, a_license, a_claim_number, a_draft
    } 
) => {
    if ( isCustomer ) {
        return(
            <Box p={3} pb={0}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={1}>
                        <Box borderRadius="50%" className="center_center">
                            <img src={ ProfileAvatar } alt="" className="rounded img_user-list"/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span className="ListItem_title">
                            { name } 
                        </span> <br/> 
                        <span className="ListItem_info">
                            ID: { info }
                        </span><br/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Link to={ link } className="No_Decoration" >
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >DETAILS</Button>
                        </Link>         
                    </Grid>
                </Grid>
                <hr />
            </Box>
        )
    }
    if ( isUser ) {
        return(
            <Box p={3} pb={0}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={1}>
                        <Box borderRadius="50%" className="center_center">
                            <img src={ img } alt="" className="rounded img_user-list"/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span className="ListItem_title">
                            { name } 
                        </span> <br/> 
                        <span className="ListItem_info">
                            ID: { info }
                        </span><br/>
                        {/* {
                            moment( exp_date , "YYYY-MM-DD").fromNow().includes('ago') ?
                                <span className="ListItem_info text_error">
                                    <>EXPIRATE DATE:  { moment( exp_date ).format("MMM Do YY") }</>
                                </span>
                            :
                                <span className="ListItem_info ">
                                    <>EXPIRATE DATE:  { moment( exp_date ).format("MMM Do YY") }</>
                                </span>
                            } */}
                        { exp_date &&
                            <span className="ListItem_info ">
                            <>LICENSE EXPIRATION:  { moment( exp_date ).format("MMM Do YY") }</>
                        </span>
                        }
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Link to={ link } className="No_Decoration" >
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >DETAILS</Button>
                        </Link>         
                    </Grid>
                </Grid>
                <hr />
            </Box>
        )
    }
    if ( isManager ) {
        return(
            <Box p={3} pb={0}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={1}>
                        <Box borderRadius="50%" className="center_center">
                            <img src={ img } alt="" className="rounded img_user-list"/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span className="ListItem_title">
                            { name } 
                        </span> <br/> 
                        <span className="ListItem_info">
                            ID: { info }
                        </span>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Link to={ link } className="No_Decoration" >
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >DETAILS</Button>
                        </Link>         
                    </Grid>
                </Grid>
                <hr />
            </Box>
        )
    }
    if( isVehicle ){
        return(
            <Box >
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={6} md={1}>
                        <Box className="ListItem_info" textAlign="center" >
                            { v_status === 1 && <img src={ vehicle_O } alt=""/> }
                            { v_status === 2 && <img src={ vehicle_R } alt=""/> }
                            { v_status === 3 && <img src={ vehicle_L } alt=""/> }
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Box className="ListItem_info" textAlign="center" >
                            { v_fleetNumber }
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box className="ListItem_info" textAlign="center" >
                            { v_vin }
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box className="ListItem_info" textAlign="center" >
                            { v_licensePlate }
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Box className="ListItem_info" textAlign="center" >
                            { v_brand }
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box className="ListItem_info" textAlign="center" >
                            { v_station }
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Box className="ListItem_info" textAlign="center" >
                            <img src={ v_vendor } alt="" className="img_vendor" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Link to={ link } className="No_Decoration">
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >DETAILS</Button>
                        </Link>         
                    </Grid>
                </Grid>
                <hr/>
            </Box>
        )       
    }
    if ( isAccident ) {
        return(
            <Box >
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={2} md={1}>
                        <Box className="ListItem_info" textAlign="center" >
                            { a_status === 1 && <img src={ vehicle_O } alt=""/> }
                            { a_status === 2 && <img src={ vehicle_R } alt=""/> }
                            { a_status === 3 && <img src={ vehicle_L } alt=""/> }
                        </Box>
                    </Grid>
                    <Hidden only={['xs', 'sm']}>
                        <Grid item xs={2} md={1} >
                            <Box className="ListItem_info" textAlign="center" >
                                {  a_draft === 1 
                                    ? <IconButton > <CheckCircleOutlineIcon /> </IconButton> 
                                    : <IconButton > <RadioButtonUncheckedIcon /> </IconButton>
                                }
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item xs={2} md={1} >
                        <Box className="ListItem_info" textAlign="center" >
                            {/* { moment( a_date ).format("MMM Do YY") }  */}
                            { a_date }
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={2} >
                        <Box className="ListItem_info" textAlign="center" >
                            { a_claim_number }
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={1} >
                        <Box className="ListItem_info" textAlign="center" >
                            { a_accident_id } 
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={1} >
                        <Box className="ListItem_info" textAlign="center" >
                            { a_fleet_number } 
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={1} >
                        <Box className="ListItem_info" textAlign="center" >
                            { a_license } 
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={1} >
                        <Box className="ListItem_info" textAlign="center" >
                            { a_station }
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={1} >
                        <Box className="ListItem_info" textAlign="center" >
                            { a_driver } 
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Link to={ link } className="No_Decoration">
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" >DETAILS</Button>
                        </Link>         
                    </Grid>
                </Grid>
                <hr/>
            </Box>
        ) 
    }
}

export default ContactItem