import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { deleteAddress, getAddress, saveNewAddress } from '../../../Components/Functions/LabelPdf/LabelFunctions';
import { Alert } from '@material-ui/lab';
import PlacesAutocomplete from '../../../Components/Global/PlacesAutocomplete';
import { isPhone, isNumber, isText, isZipCode } from '../../../Components/Functions/validate'
import DeleteIcon from '@material-ui/icons/Delete';
import { DialogConfirm } from '../functions/DialogConfirm';
import Loader from '../../../Components/Global/Loaders';

const initialState = {
    _loading: false,
    msgType: '',
    msg: '',
    refresh: 0,
    saveAddress: false,
}

export const Step3 = ({ formData, setFormData, pickUpId, backStep, nextStep }) => {

    const u_id = localStorage.getItem('u_id')
    const [addressList, setAddressList] = useState([]);
    const [state, setState] = useState( initialState );
    const [message, setMessage] = useState({});
    const [dialogOpen, setDialogOpen] = useState( false );
    const [zipCodeError, setZipCodeError]   = useState( false );

    const SaveAddress = async () => {
        let addressData = {
            account: u_id,
            address: `${formData.deliveryLocNum} ${formData.deliveryLocation}`,
            city: formData.deliveryCity,
            province: formData.deliveryProvince,
            country: formData.deliveryCountry,
            postal: formData.deliveryPostal,
            phone: formData.deliveryPhone,
            contact: formData.deliveryName,
            name: formData.deliveryTitle,
            numInt: formData.deliveryLocNumInt,
            type: 'delivery'
        }
        const resp = await saveNewAddress( addressData )
        if ( resp.status ) {
            setState({
                ...state, 
                msgType: 'success', 
                msg: 'New address created, Please select the address to continue.', 
                refresh: state.refresh + 1, 
                checkSaved: true,
                checkNew: false
            });
            setFormData({ ...formData,
                deliveryNew: false,
                deliverySave: true,
                deliveryId: resp.address_id,
                deliveryData:{
                    address: formData.deliveryLocation+' '+formData.deliveryLocNum,
                    num: formData.deliveryLocNumInt,
                    city: formData.deliveryCity,
                    province: formData.deliveryProvince,
                    country: formData.deliveryCountry,
                    postal: formData.deliveryPostal,
                    name: formData.deliveryName,
                    phone: formData.deliveryPhone,
                },
                deliveryTitle: formData.deliveryTitle,
                deliveryName: formData.deliveryName,
                deliveryPhone: formData.deliveryPhone,
                deliveryLocNumInt:formData.deliveryLocNumInt,
            })
            return nextStep()
        } else {
            setState({...state, msgType: 'error', msg: 'We have some problems, please try again in some moments.' })
        }
    }
    const handleGetAddress = async () => {
        setState({...state, loading: true })
        const resp = await getAddress( u_id, 'delivery' )
        if ( resp.status ) {
            setAddressList( resp.results )
        } else {
            setAddressList( false )
            setState({...state, error: resp.msg })
        }
        setState({...state, loading: false })
    }
    const onDeleteAddress = async () => {
        const resp = await deleteAddress( u_id, formData.deliveryId )
        if ( resp.status ) {
            setDialogOpen( false )
            setState({
                ...state, 
                msgType: 'success', 
                msg: 'Address was removed successfully', 
                refresh: state.refresh + 1
            })
            setFormData({...formData, deliveryId: '' })
        } else {
            setState({
                ...state, 
                msgType: 'error', 
                msg: 'We have some problems, please try again in some moments.', 
                refresh: state.refresh + 1
            })
        }
    }
    const handleSubmit = ( e ) => {
        e.preventDefault();
        setMessage({})
        if ( formData.deliveryNew ) {
            if ( !formData.deliveryTitle ){ return setMessage({deliveryTitle: 'Title is required.' }) }
                
            else if ( !isText(formData.deliveryTitle) ){ return setMessage({deliveryTitle: 'Title has to be a text and cannot start with a number.' }) }
                
            else if ( !formData.deliveryName ){ return setMessage({deliveryName: 'Contact name is required.' }) }
                
            else if ( !isText(formData.deliveryName) ){ return setMessage({deliveryName: 'Contact name has to be a text and cannot start with a number.' }) }
                
            else if ( !formData.deliveryPhone ){ return setMessage({deliveryPhone: 'Phone number is required.' }) }

                
            else if ( !formData.deliveryLocation || !formData.deliveryCity || !formData.deliveryCountry  ){ return setMessage({deliveryLocation: 'Address location is required.' }) }
            
            else if(zipCodeError){

                if(!formData.deliveryPostal){
                    return setMessage({ deliveryLocationSecond: 'Please introduce a valid zip code here' })    
                }

                else if(isZipCode(formData.deliveryPostal)){
                    return SaveAddress()
                }

                else {
                    return setMessage({ deliveryLocationSecond: 'The zip code captured is invalid' })
                }
            }
                
            else if (!formData.deliveryPostal ){
                setZipCodeError(true);
                return setMessage({ deliveryLocation: "Sorry, we couldn't find your zip code, please enter it in the following field" })
            }
            



            else {return SaveAddress()}
            
        }

        if ( formData.deliverySave ) {
            if ( !formData.deliveryId )
                return setState({
                    ...state, 
                    msgType: 'error', 
                    msg: 'Please select an address to continue.'
                })
            else{
                setFormData({ ...formData, deliverySaved: true })
            }
        }
        
        setState( initialState )
        return nextStep()
    }

    useEffect(() => { 
        handleGetAddress();
    }, [ state.refresh ])

    return (
        <form 
            id="go_gridContainer_stepper_form"
            onSubmit={ handleSubmit }
            style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%' 
            }} 
        >
            <DialogConfirm 
                open={ dialogOpen } 
                setOpen={ setDialogOpen } 
                body="Are you sure to delete the address?" 
                onAction={ onDeleteAddress } 
            />
            <Grid container className="text_grey">
                { !!state.msg && 
                    <Grid container direction="row" justify="center" alignItems="center" >
                        <Box my={3} > <Alert severity={state.msgType}> { state.msg } </Alert> </Box>
                    </Grid> 
                }
                <Grid item md={12} >
                    <Grid container direction="row" justify="center" alignItems="center" >
                        <Typography variant="h6" className="text_primary" >
                            Delivery address
                        </Typography>
                    </Grid>
                    <Grid id="go_selectorAdress" container direction="row" justify="space-evenly" alignItems="center" >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.deliveryNew}
                                    name="checkNew"
                                    color="primary"
                                    onClick={e => setFormData({ ...formData, 
                                        deliveryNew: e.target.checked, 
                                        deliverySave: !e.target.checked 
                                    })}
                                />
                            }
                            label="New delivery address"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.deliverySave}
                                    name="checkSaved"
                                    color="primary"
                                    onClick={e => setFormData({ ...formData, 
                                        deliveryNew: !e.target.checked, 
                                        deliverySave: e.target.checked 
                                    })}
                                />
                            }
                            label="Saved addresses"
                        />
                    </Grid> 
                </Grid>
                { formData.deliveryNew && 
                <>
                    <Grid item xs={12} md={4}>
                        <Box m={1} > 
                            <label htmlFor="_newAddressTitle" > Title </label>
                            <TextField id="_newAddressTitle" name="newAddressTitle" type="text" variant="outlined" fullWidth value={ formData.deliveryTitle } onChange={e => setFormData({...formData,  deliveryTitle: e.target.value})} autoComplete="off" error={ !!message.deliveryTitle } helperText={ message.deliveryTitle } />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box m={1} > 
                            <label htmlFor="_newAddressName" > Contact name </label>
                            <TextField id="_newAddressName" name="newAddressName" type="text" variant="outlined" fullWidth value={ formData.deliveryName } onChange={e => setFormData({...formData,  deliveryName: e.target.value})} autoComplete="off" error={ !!message.deliveryName } helperText={ message.deliveryName } />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box m={1} > 
                            <label htmlFor="_newAddressPhone" > Phone number </label>
                            <TextField id="_newAddressPhone" name="newAddressPhone" type="number" variant="outlined" fullWidth value={ formData.deliveryPhone } onChange={e => setFormData({...formData,  deliveryPhone: e.target.value})} autoComplete="off" error={ !!message.deliveryPhone } helperText={ message.deliveryPhone } />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={8}>
                        <Box m={1} > 
                            <label htmlFor="_newAddressLocation" > Address </label>
                            <PlacesAutocomplete handleSelected={(place, details) => {
                                setFormData({
                                    ...formData,
                                    deliveryCity:       details?.locality?.long_name,
                                    deliveryCountry:    details?.country?.long_name,
                                    deliveryLocation:   details?.route?.long_name,
                                    deliveryLocNum:     details?.street_number?.long_name,
                                    deliveryPostal:     details?.postal_code?.long_name,
                                    deliveryProvince:   details?.administrative_area_level_1?.long_name
                                })
                            }} >
                                <TextField id="_newAddressLocation" name="newAddressLocation" type="text" variant="outlined" fullWidth autoComplete="off" error={ !!message.deliveryLocation } helperText={ message.deliveryLocation } />
                            </PlacesAutocomplete>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Box m={1} >
                            <label htmlFor="_newLocNumInt" > Apartment/Unit number </label>
                            <TextField id="_newLocNumInt" name="newLocNumInt" type="text" variant="outlined" fullWidth value={formData.deliveryLocNumInt} onChange={e => setFormData({ ...formData, deliveryLocNumInt: e.target.value })} autoComplete="off" error={!!message.AddressLocNumInt} helperText={message.AddressLocNumInt} />
                        </Box>
                    </Grid>

                    { zipCodeError?
                            <Grid item xs={12} md={4} lg={4}>
                                <Box m={1} >
                                    <label htmlFor="_newDeliveryZipCode" > Zip Code </label>
                                    <TextField id="_newDeliveryZipCode" name="newDeliveryZipCode" type="number" variant="outlined" fullWidth value={formData.deliveryPostal} onChange={e => setFormData({ ...formData, deliveryPostal: e.target.value })} autoComplete="off" error={!!message.deliveryLocationSecond} helperText={message.deliveryLocationSecond} />
                                </Box>
                            </Grid>
                            :
                            <span></span>
                        }
                </>
                }
                { formData.deliverySave && 
                <>
                    { state.loading 
                        ? <Grid container direction="row" justify="center" alignItems="center" > <Loader onlyLoad={ true } /> </Grid>
                        : addressList ? addressList.map( i => {
                            if ( i.id !== pickUpId ) {
                                return(
                                    <Grid item xs={12} md={6} key={ i.id } >
                                        <div className="customers_comment flex m-2">
                                            <IconButton 
                                                onClick={() => setFormData({ ...formData, 
                                                    deliveryId: i.id,
                                                    deliveryData:{
                                                        address: i.address,
                                                        num: i.apartment_num,
                                                        city: i.city,
                                                        province: i.province,
                                                        country: i.country,
                                                        postal: i.postal,
                                                        name: i.contact,
                                                        phone: i.phone,
                                                    }
                                                })}  
                                            >
                                                { formData.deliveryId === i.id ? <CheckIcon /> : <UncheckedIcon /> }
                                            </IconButton>
                                            <div>
                                                <span className="customer__list-label"> {i.name} </span><br/>
                                                <span className="customer__list-text"> { i.contact } </span><br/>
                                                <span className="customer__list-text"> 
                                                {`${ (i.address !== 'null' || i.address !== 'undefined') && i.address }, ${ (i.city !== 'null' || i.city !== 'undefined') && i.city }, ${ (i.country !== 'null' || i.country !== 'undefined') && i.country}.`} 
                                                </span><br/>
                                                { i.apartment_num && <><span className="customer__list-text">Apartment/Number: { i.apartment_num }</span><br /></> }
                                                <span className="customer__list-text"> { i.phone } </span><br/>
                                            </div>
                                            <IconButton 
                                                onClick={ () => { 
                                                    setDialogOpen(true); 
                                                    setFormData({ ...formData, deliveryId: i.id }) 
                                                }} 
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                )   
                            } else{ return false }
                        })
                        : <Typography variant="subtitle1" gutterBottom > You don't have saved addresses yet </Typography>
                    }
                </>
                }
                <Grid  id="go_gridContainer_stepper_buttons" container direction="row" justify="center" alignItems="center" >
                    <Button 
                        variant="outlined"
                        disableElevation 
                        className="w-150px mr-2-r mb-2-r" 
                        onClick={ backStep }
                    > 
                        Back 
                    </Button>
                    <Button 
                        type="submit"  
                        variant="outlined" 
                        className="Go_btn-primary w-150px"
                    > 
                        { state._loading ? 'Loading...' : 'CONTINUE' } 
                    </Button> 
                </Grid>
            </Grid>
        </form>
    )
}
