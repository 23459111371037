import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode'
import JsBarcode from 'jsbarcode'
import { Grid, CircularProgress, Typography, TextField, MenuItem } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    section: {
        margin: 5,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: '#fff'
    },
    col: {
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        display: "flex"
    },
    text_center: {
        textAlign: "center"
    },
    barcode: {
        width: 150,
        height: 50
    },
    divider:{
        width:"80%",
        height:2,
        backgroundColor:"#000"
    }
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const WarehousePrintLabel = () => {

    const params = useQuery()

    const [isLoading, setisLoading] = React.useState(true)

    const route = params.get("route")
    const order_number = params.get("order_number")

    let canvas = document.createElement('canvas');
    canvas = document.createElement('canvas');
    JsBarcode(canvas, order_number, { displayValue: false });
    const barcode = canvas.toDataURL();

    /* Document format */
    const [pageSize, setpageSize] = useState("ID1")

    const [orientation, setOrientation] = useState("landscape")

    const OnRender = () => {
        setisLoading(false);
    }


    return (
        <React.Fragment>
            {isLoading && <CircularProgress />}
            <PDFViewer>
                <Document onRender={OnRender} title={`ORDER_${3}`} >
                    <Page size={pageSize} style={styles.page} orientation={orientation} >
                        <View style={styles.col} >
                            <View style={styles.section}>
                                <Text>Route Number</Text>
                                <Text>{route === null ? "Not set" : route }</Text>
                            </View>
                            <View style={styles.divider} ></View>
                            <View style={styles.section}>
                                <Text style={{ fontSize: 12 }} >Order number</Text>
                                <Image style={styles.barcode} src={barcode} />
                                <Text style={{ fontSize: 10 }} >{order_number}</Text>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </React.Fragment>
    )
}

export default WarehousePrintLabel
