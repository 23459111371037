import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
//MAterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


const ContactItem = ( { id, img, name, cicleID, start_date, end_date, time, link, status } ) => {

    return(
        <Link to={ link } className="No_Decoration" >
            <Box my={2} p={1} className="bg_grey" >
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <span className="Title_primary">
                                Circle check
                            </span>
                            <Box display="flex">
                                {
                                    status === 3?
                                        <span className="status_issues" >
                                            ISSUE
                                        </span> : null
                                }
                                {
                                    status === 2?
                                        <span className="status_issues" >
                                            PENDING ESTIMATE
                                        </span> : null
                                }
                                {
                                    status === 1?
                                        <span className="status_ok" >
                                            ESTIMATE OK
                                        </span> : null
                                }
                                {
                                    status === 0?
                                        <span className="status_error" >
                                            ACCIDENT
                                        </span> : null
                                }
                                <span className="Title_primary">
                                    ID {cicleID}
                                </span>
                            </Box>
                        </Grid>
                        <hr className="divider_blue" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Box display="flex" p={0}>
                                <img src={ img } alt="" className="historyImg rounded" />
                                <Box className="">
                                    <span className="History_title" >
                                        { name }
                                    </span><br/>
                                    <span className="Text_blod-grey">
                                        ID { id }
                                    </span>
                                </Box>
                            </Box>
                            <Box textAlign="right" className="Text_grey" >
                                Start: { start_date ? moment( start_date ).format( "MMMM Do YYYY h:mm:ss a" ) : "No information" }
                                <br/>
                                End: { end_date ? moment( end_date ).format( "MMMM Do YYYY h:mm:ss a") : "In progress" }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Link>
    )
    
}

export default ContactItem