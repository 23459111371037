import React,  { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import moment from "moment";
// Componentes
import ListElements from '../Components/Global/ListElements';
// IMG
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
//API
import { getManager, APIGO } from '../constants/API'; 

const Manager = () => {

    const [ data, setData ]             = useState([]);
    const [ info, setInfo ]             = useState([]);
    const [ search, setSearch ]     = useState('');
    const [ page, setPage ]             = useState(1);
    const [ refresh, setRefresh ]             = useState(1);
    const [ isData, setIsData ]         = useState( true );
    const [ IsSearch, setIsSearch ] = useState( false );

    useEffect( () =>{
        var config = {
            method: 'get',
            url: `/users/admin/?page=${page}&limit=20&type=2`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then(response =>{
            if ( response.data.status === "error" ) {
                // List for all vehicles
                setData( false );
            }
            if ( response.data.status === "success" ) {
                setData( response.data.results );  
                setInfo( response.data.info )
            }
        })
        .catch(function (error){
            console.log(error)
            setData( false );
        })
    }, [] );

    useEffect( () =>{
        let config;
        
        if ( isData ) {
            config = {
                method: 'get',
                url: `/users/admin/?page=${page}&limit=20&type=2`,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        if ( IsSearch ) {
            config = {
                method: 'get',
                url: `/users/search?page=${page}&limit=20&type=2&search=${search}`,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        
        APIGO(config)
        .then(response =>{
            if ( response.data.status === "error" ) {
                // List for all vehicles
                setData( false );
            }
            if ( response.data.status === "success" ) {
                setData( response.data.results );  
                setInfo( response.data.info )
            }
        })
        .catch(function (error){
            console.log(error)
            setData( false );
        })
    }, [ refresh, page ] );

    const filterList = ( e, search ) => {
        e.preventDefault();
        if ( search === '' ) {
            setIsData( true )
            setIsSearch( false )
            setRefresh( refresh + 1 );
        }
        else{
            setIsData( false )
            setIsSearch( true )
            setRefresh( refresh + 1 );
            setSearch( search )
        }
    }

    const ActivePage = ( e, value ) =>{
        setPage( value )
    }

    return(
        <Grid container >
            <Grid item xs={12} md={2} className="center_center">
                <Link to="/settings" className="flex No_Decoration">
                    <ArrowBackIosIcon className="Page_title"/>
                    <Typography variant="subtitle1" gutterBottom className="Page_title">
                        Go back
                    </Typography>
                </Link>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom className="Page_title">
                    Fleet Manager
                </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
                <Box mt={1}>
                    <Link to="/manager/new" className="No_Decoration" >
                        <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >New Manager</Button>
                    </Link>
                </Box>
            </Grid>
            <Grid item xs={12} md={2} >
                <Box p={1} ml={2} className="bg_white" boxShadow={3} >
                    <Grid container className="bg_white" >
                        <Grid item xs={6} md={12}>
                            <Box className="pointer">
                                <Typography variant="body2" gutterBottom className="text_grey" align="center">
                                <b> TOTAL MANAGERS </b>
                                </Typography>
                                <Typography variant="body2" gutterBottom className="text_primary" align="center" >
                                <b> { info.count } </b>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <ListElements isManager={ true } list={ data } page_count={ info.pages } activePage={ ActivePage } filter={ filterList } /> 
            </Grid>
        </Grid>
    )
}
export default Manager
    
