import React, { useState, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
// Componentes
import ModalShow from '../Components/Global/Modal';
// IMG
import Portada from './../Assets/Img/Portada_management.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
//API
import { updateModel, getModelById, APIGO } from '../constants/API'; 

const VehicleModelDetails = () => {

    const history = useHistory();
    const match   = useRouteMatch('/model/details/:id');
    const id      = match.params.id;  
    const [ brand, setBrand]        = useState('');
    const [ model, setModel ]       = useState('');
    const [ isbrand, setIsBrand]        = useState( false );
    const [ ismodel, setIsModel ]       = useState( false );
    const [ isError, setIsErrors]   = useState([{
        brand: false,
        model: false,
    }]);
    // Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    const saveElement = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            setIsConfirm( false );
                let config = {
                    method: 'put',
                    url: `${updateModel}${id}`,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        brand: brand,
                        model: model,
                    }
                }   
                APIGO(config)
                .then(response =>{
                    if ( response.data.status === "success" ) {
                        ModalClose()
                        setModalmsg('Element added successfully');
                        setModalLink('/station');
                        setIsShow( true );
                        ModalOpen();
                    }
                    if ( response.data.status === "error" ) {
                        ModalClose()
                        setModalmsg('Sorry the name is already taken');
                        setModalLink('/model');
                        setIsShow( true );
                        ModalOpen();
                    }
                })
                .catch(function (error){
                    console.log(error)
                    ModalClose()
                    setModalmsg('Sorry We have some problems please try again in a frew minutes');
                    setModalLink('/station');
                    setIsShow( true );
                    ModalOpen();
                }) 
        }
        else{
            ModalClose()
        }
    }

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const handleForm = e => { 
        e.preventDefault();
        if( !isbrand ){
            setIsErrors({ brand: true });
        }else if( !ismodel ){
            setIsErrors({ model: true });
        }else  {
            setIsErrors('');
            setIsConfirm( true );
            setModalStaus( true );
        }
    }

    useEffect( () =>{

        var config = {
            method: 'get',
            url: `${getModelById}${id}`,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then(response =>{
            setBrand( response.data[0].model_brand )
            setModel( response.data[0].model )
        })
        .catch(function (error){
            console.log(error)
        })

    }, [ ] );

    if ( localStorage.getItem('Type') === '1' ) {
        return (
            <>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        {
                            isConfirm ?
                                <ModalShow 
                                title="Are you sure you want to save a new element? "
                                confirmOperation={ true }
                                Operation={ saveElement }
                                /> : null
                        }    
                        {
                            isShow ?
                                <ModalShow 
                                    title={ modalMsg }
                                    onlyShow={ true }
                                    showGoTo={ modalGoTo }
                                    goTo={ modalLink }
                                /> : null
                        }
                    </Grid>
                </Modal>
    
                <form onSubmit={ e => handleForm( e ) }>
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={2} className="center_center">
                            <Link to="/model" className="flex No_Decoration">
                                <ArrowBackIosIcon className="Page_title"/>
                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                    Go back
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" gutterBottom className="Page_title">
                                Vehicle Model
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Link to="/model" className="flex No_Decoration">
                                <Button variant="outlined" disableElevation fullWidth className="" >CANCEL</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >SAVE</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Box p={1} ml={2} className="bg_white" boxShadow={3} >
                                <Grid container className="bg_white" >
                                    <Grid item md={6}>
                                        <Box p={1}>
                                        <   Typography variant="body1" gutterBottom className="text_grey">
                                                BRAND
                                            </Typography>
                                            {
                                                isError.brand ?
                                                    <TextField id="brand" type="text" size="small" variant="outlined" fullWidth value={ brand } onChange={e => {setBrand(e.target.value); setIsBrand( false )}} error helperText="Enter a value for Brand please" /> 
                                                : 
                                                    <TextField id="brand" type="text" size="small" variant="outlined" fullWidth value={ brand } onChange={e => {setBrand(e.target.value); setIsBrand( false )}} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box p={1}>
                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                MODEL
                                            </Typography>
                                            {
                                                isError.model ?
                                                    <TextField id="Model" type="text" size="small" variant="outlined" fullWidth value={ model } onChange={e => {setModel(e.target.value); setIsModel( false )}} error helperText="Enter a value for Model please" /> 
                                                : 
                                                    <TextField id="Model" type="text" size="small" variant="outlined" fullWidth value={ model } onChange={e => {setModel(e.target.value); setIsModel( false )}} />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    }
    
    if ( localStorage.getItem('Type') === '2' ) {
        return (
            <>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        {
                            isConfirm ?
                                <ModalShow 
                                title="Are you sure you want to save a new element? "
                                confirmOperation={ true }
                                Operation={ saveElement }
                                /> : null
                        }    
                        {
                            isShow ?
                                <ModalShow 
                                    title={ modalMsg }
                                    onlyShow={ true }
                                    showGoTo={ modalGoTo }
                                    goTo={ modalLink }
                                /> : null
                        }
                    </Grid>
                </Modal>
    
                <form onSubmit={ e => handleForm( e ) }>
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={2} className="center_center">
                            <Link to="/model" className="flex No_Decoration">
                                <ArrowBackIosIcon className="Page_title"/>
                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                    Go back
                                </Typography>
                            </Link>
                        </Grid>
                        { 
                            localStorage.getItem('PS_U') === '1' ?
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h4" gutterBottom className="Page_title">
                                        Vehicle Model
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12} md={8}>
                                    <Typography variant="h4" gutterBottom className="Page_title">
                                        Vehicle Model
                                    </Typography>
                                </Grid>
                        }
                        <Grid item xs={6} md={2}>
                            <Link to="/model" className="flex No_Decoration">
                                <Button variant="outlined" disableElevation fullWidth className="" >CANCEL</Button>
                            </Link>
                        </Grid>
                        { 
                            localStorage.getItem('PS_U') === '1' &&
                                <Grid item xs={6} md={2}>
                                    <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >SAVE</Button>
                                </Grid>
                        }
                        <Grid item xs={12}>
                            <Box p={1} ml={2} className="bg_white" boxShadow={3} >
                                <Grid container className="bg_white" >
                                    <Grid item md={6}>
                                        <Box p={1}>
                                        <   Typography variant="body1" gutterBottom className="text_grey">
                                                BRAND
                                            </Typography>
                                            { 
                                                localStorage.getItem('PS_U') === '1' ?
                                                    isError.brand ?
                                                        <TextField id="brand" type="text" size="small" variant="outlined" fullWidth value={ brand } onChange={e => {setBrand(e.target.value); setIsBrand( false )}} error helperText="Enter a value for Brand please" /> 
                                                    : 
                                                        <TextField id="brand" type="text" size="small" variant="outlined" fullWidth value={ brand } onChange={e => {setBrand(e.target.value); setIsBrand( false )}} />
                                                :
                                                    isError.brand ?
                                                        <TextField id="brand" type="text" size="small" variant="outlined" fullWidth value={ brand } disabled error helperText="Enter a value for Brand please" /> 
                                                    : 
                                                        <TextField id="brand" type="text" size="small" variant="outlined" fullWidth value={ brand } disabled />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box p={1}>
                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                MODEL
                                            </Typography>
                                            { 
                                                localStorage.getItem('PS_U') === '1' ?
                                                    isError.model ?
                                                        <TextField id="Model" type="text" size="small" variant="outlined" fullWidth value={ model } onChange={e => {setModel(e.target.value); setIsModel( false )}} error helperText="Enter a value for Model please" /> 
                                                    : 
                                                        <TextField id="Model" type="text" size="small" variant="outlined" fullWidth value={ model } onChange={e => {setModel(e.target.value); setIsModel( false )}} />
                                                :
                                                    isError.model ?
                                                        <TextField id="Model" type="text" size="small" variant="outlined" fullWidth value={ model } disabled error helperText="Enter a value for Model please" /> 
                                                    : 
                                                        <TextField id="Model" type="text" size="small" variant="outlined" fullWidth value={ model } disabled />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    }

}
export default VehicleModelDetails

