import * as fire from 'firebase';

const config = {
    apiKey: "AIzaSyDUFGqptrJ5bJTeZ-0j4HbBz6OL6kv3JZ4",
    authDomain: "go-logistics.firebaseapp.com",
    databaseURL: "https://go-logistics.firebaseio.com",
    projectId: "go-logistics",
    storageBucket: "go-logistics.appspot.com",
    messagingSenderId: "859602243198",
    appId: "1:859602243198:web:642b3faac8b556eddad22f",
    measurementId: "G-1WJQEWXYF4"
}

fire.initializeApp(config);
export default fire;