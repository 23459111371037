import React, { useEffect, useState } from 'react'

import { getReceipts, getReceiptsReport } from '../middleware/gas_receipts/gas_receipt'
import { Box, Card, CardContent, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core';

import EyeIcon from '@material-ui/icons/Visibility'
import BaseDialog from '../Components/Global/Modals/BaseDialog';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    list: {
        width: "100%"
    }
}))

const ReceiptItem = (props) => {

    const [show, setShow] = useState(false)
    const hide = () => {
        setShow(false)
    }
    const display = () => {
        setShow(true)
    }
    return (
        <React.Fragment>
            <BaseDialog closeOnOutside maxWidth="sm" open={show} onClose={hide} >
                <Card elevation={0}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>Receipt</Typography>
                            </Grid>
                            <Grid item xs="12">
                                <Box style={{ width: "100%" }} flex justifyContent="center"  >
                                    <img alt="receipt" style={{ width: "100%" }} src={props.data.evidence} />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </BaseDialog>
            <ListItem>
                <ListItemText primary={<Typography><strong>{props.primary}</strong></Typography>} secondary={<Typography style={{ maxWidth: 300 }} noWrap >{props.secondary}</Typography>} />
                <ListItemSecondaryAction>
                    <IconButton onClick={display} >
                        <EyeIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider component="li" />
        </React.Fragment>
    )
}

const GasReceipts = () => {

    const classes = useStyles()

    const [receiptsReport, setReceiptsReport] = useState({
        GAS_TOTAL: 0,
        GAS_RECEIPTS: 0
    })
    const [receipts, setReceipts] = useState([])
    const [paging, setPaging] = useState({
        page: 1,
        pages: 0,
        count: 0
    })

    const loadReceipts = async (page) => {
        const response = await getReceipts(page, 10)
        if (response.status) {
            setPaging({
                page: response.page,
                pages: response.pages,
                count: response.count
            })
            setReceipts(response.results)
        }
    }

    useEffect(() => {

        loadReceipts(paging.page);

        (async () => {
            const response = await getReceiptsReport()
            if (response.status) {
                setReceiptsReport(response)
            }
        })();

        return () => {

        }
    }, [])



    const onPageChange = (e, p) => {
        if(p !== paging.page){
            loadReceipts(p)
        }
    }

    return (
        <Card>
            <CardContent>
                <Grid container justifyContent="center" alignItems="center" justify="center" >
                    <Grid item xs={12}>
                        <Card elevation={0} >
                            <CardContent>
                                <Typography variant="h5" >Totals:</Typography>
                                <Typography>Total: ${receiptsReport.GAS_TOTAL}</Typography>
                                <Typography>Count: {receiptsReport.GAS_RECEIPTS}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card elevation={0}>
                            <CardContent>
                                <List className={classes.list}>
                                    {
                                        receipts.map((item, index) => (
                                            <ReceiptItem key={`${index}-rp`} data={item} primary={item.user_id} secondary={`Total: $${item.total}`} />
                                        ))
                                    }
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Pagination count={paging.pages} page={paging.page} onChange={onPageChange} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default GasReceipts
