import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Alert from '@material-ui/lab/Alert';
import ImageDialog from '../../Components/Global/ImageDialog'

import FilePlaceHolder from '../../Assets/Img/file_placeholder.png'
import { UploadFile } from '../../Components/Functions/UploadFile'

import { APIGO } from './../../constants/API'

const SettingsGeneral = props => {

    const [insurance, setInsurance] = React.useState();
    const [file, setFile] = React.useState();
    const [success, setSuccess] = React.useState(false);

    React.useEffect(() => {

        let config = {
            method: 'get',
            url: 'settings/',
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`,
            },

        }

        APIGO(config)
            .then(res => {
                const {status, results} = res.data;
                if (status === "success") {
                    const insuranceUrl = results.find(setting => setting.key === "_insurance_rental").val
                    console.log(insuranceUrl);    
                    setInsurance(insuranceUrl)
                }
                
            })
            .catch(error => {
                console.log(error);

            })

        return () => {

        }
    }, [])

    const handleSelected = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let url = URL.createObjectURL(file)
        setInsurance(url)
        setFile(file);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!file) {
            return
        }

        const imageUrl = await UploadFile(file)

        let config = {
            method: 'post',
            url: 'settings/set/',
            data: {
                key: "_insurance_rental",
                value: imageUrl
            },
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`,
            },

        }

        APIGO(config)
            .then(res => {
                if (res.data.status === "success") {
                    setSuccess(true)
                }
            })
            .catch(error => {
                console.log(error);

            })

    }

    return (
        <React.Fragment>
            { success && <Alert variant="outlined" severity="success"> Settings updated </Alert> }
            <Grid container>
                <Grid item xs={12} md={2} className="center_center">
                    <Link to="/settings" className="flex No_Decoration">
                        <ArrowBackIosIcon className="Page_title"/>
                        <Typography variant="subtitle1" gutterBottom className="Page_title">
                            Go back
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h4" gutterBottom className="Page_title">
                    General
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box px={2} py={2} className="bg_white" borderRadius={5} boxShadow={3} padding={30} >
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography >
                                    Insurance Rental
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                        <ImageDialog src={insurance ? insurance : FilePlaceHolder} />
                                    </Grid>
                                </Grid>

                                <form onSubmit={handleSubmit} >
                                    <Grid container spacing={3} >
                                        <Grid item>
                                            <input required onChange={handleSelected} type="file" accept="image/*"></input>
                                        </Grid>
                                        <Grid item>
                                            <Button type="submit" className="Go_btn-primary" >Upload</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

SettingsGeneral.propTypes = {

}

export default SettingsGeneral