import BrandRounded from "./images/BrandRounded.svg"
import BrandText from "./images/BrandText.png"
import PersonBoxGo from "./images/PersonBox.png"

import ic_scan_in from "./icons/ic_scann_in.svg"
import ic_scan_out from "./icons/ic_scann_out.svg"
import ic_returns from "./icons/ic_returns.svg"
import ic_csv from "./icons/ic_csv.svg"

export const Media = {
    BrandRounded,
    BrandText,
    PersonBoxGo
}

export const Icons = {
    ic_scan_in,
    ic_scan_out,
    ic_returns,
    ic_csv
}