import moment from 'moment';

export const isEmail = email => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);

export const isZipCode = zipCode => /^[0-9]{5,7}$/.test(zipCode);

export const isText = text => /^[a-zA-Z]/.test(text);

export const isEqual = ( text1, text2 ) => text1.toString() === text2.toString();

//export const isPhone = phone => /(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/.test(phone);

export const isPhone = phone => /^[0-9.-]{10,15}$/.test(phone);

export const isNumber = num => /(^[0-9]{1,3}$|^[0-9]{1,3}\.[0-9]{1,3}$)/.test(num);

export const isDate = date => moment( date ).isValid();

export const ispass = pass => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(pass);