import React from 'react'
//MaterialUI
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
// import Pagination from '@material-ui/lab/Pagination';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

const NotificacionItem = ( { icon, title, date, body_title, body, btn_action } ) => {
    return(
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <img src={ icon } alt=""/>
                        <span className="Page_title">{ title }</span>
                    </Box>
                    <span className="labelText">{ date }</span>
                </Grid>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
                <Grid container>
                    <Grid xs={8}>
                        <span className="labelText-body"> { body_title } </span> <br/>
                        <small className="notificacion_body"> { body } </small>
                    </Grid>
                    <Grid xs={4}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button className="Go_btn-primary" disableElevation>
                                Update
                            </Button> 
                        </Box>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}
     
export default NotificacionItem