import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { getInvoices } from '../../middleware/warehouse/Warehouse'
import { Pagination } from '@material-ui/lab'
import moment from 'moment'

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

function GeneratedEdiHistory() {

    const history = useHistory()

    const [pagination, setPagination] = useState({
        page: 1,
        pages: 1,
        per_page: 10,
        count: 1
    })

    const [data, setData] = useState([])
    const [dataFiltered, setDataFiltered] = useState([])

    useEffect(() => {
        loadInvoices(1)
        return () => {

        }
    }, [])


    const handlePageChange = async (e, value) => {
        loadInvoices(value)
    }


    const loadInvoices = async (page) => {

        const response = await getInvoices(page, pagination.per_page)
        if (response.status) {
            setPagination({
                ...pagination,
                page: response.data.page,
                pages: response.data.pages,
                count: response.data.count
            })
            setData(response.data.results)
        }
    }

    return (
        <Container>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Grid container  >
                        <Grid item xs={6}>
                            <Typography variant="h5" color="primary" >CSV History</Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <Box width="100%" textAlign="right" >
                                <Button onClick={() => history.goBack()} color="secondary" variant="contained" >Back</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Card>
                        <CardContent>
                            <Grid container >
                                <Grid item xs={3}>
                                    <Typography>Date</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>File name</Typography>
                                </Grid>
                                <Grid item xs={6}>

                                </Grid>
                            </Grid>
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                            <Grid container>
                                {
                                    data.map((value, index, all) => (
                                        <Grid key={value.id} item xs={12}>
                                            <Box mt={2}  >
                                                <Grid container >
                                                    <Grid item xs={2}>
                                                        <Typography>{moment.utc(value.created).format('DD-MM-YYYY')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography>{value.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>

                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Grid container>
                                <Grid item>
                                    <Box mt={4} >
                                        <Pagination
                                            page={pagination.page}
                                            count={pagination.pages}
                                            variant='outlined'
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container >
    )
}

export default GeneratedEdiHistory