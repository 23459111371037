import * as firebase from 'firebase';

export const UploadFile = async ( file ) => {

    const cloudUrl = 'https://api.cloudinary.com/v1_1/https-golosuite-com/upload' 
    const data = new FormData()
    data.append('upload_preset', 'golo_suite')
    data.append('file', file)
    
    try {
        const resp = await fetch( cloudUrl, {
            method: 'POST',
            body: data
        })

        if ( resp.ok ) {
            const cloudResp = await resp.json();
            return cloudResp.secure_url;
        } else {
            return false;
        }
    } catch (error) {
        console.log( error );
    }
}

export const UploadStore = async ( file, path ) =>{
    
    var storage = firebase.storage().ref();
    var upload = storage.child( path );
    
    try {
        
        const resp = await upload.put( file )
        if ( resp.ok ) {
            
            const fireResp = await upload.getDownloadURL()
            console.log( fireResp );

        } else {
            return false;
        }
    } catch (error) {
        console.log( error );
    }
}

// const UploadFileStore = ( file, path ) =>{
//     let storage = firebase.storage().ref();
//     let upload = storage.child(`${path}`);
//     upload.put( e.target.files[0] ).then( snapshot => {
//         upload.getDownloadURL().then( url => {
//             const _file =  document.getElementById("avatarImg")
//             _file.src = url;
//             setTimeout(() => setValue({...value, loading: false }) , 500);
//         })
//     })
// }