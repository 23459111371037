import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, MenuItem, Select, Switch, TextareaAutosize, TextField, Typography } from '@material-ui/core'
import { isNumber } from '../../../Components/Functions/validate'
import InputFile from '../../../Components/Global/InputFile';
import DeleteIcon from '@material-ui/icons/Delete';
import { getCustomerService } from '../../../Components/Functions/LabelPdf/LabelFunctions';

const csvTemplate = 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fpackage%20batch%20%20template.csv?alt=media&token=32a8b091-f27a-482d-b1b1-c53e98c7f511'
const initialState = {
    _loading: false,
    isInsurance: false,
    totalWeight: 0,
    totalRow: 1
}
const initialMessage = {
    pickUpDate: '',
    readyTime: '',
    service: '',
    declaredValue: '',
    package: '',
    column: ''
}

export const Step4 = ({ formData, setFormData, orderType, backStep, nextStep }) => {

    const convert = 2.205;
    const u_id = localStorage.getItem('u_id')
    const [message, setMessage] = useState( initialMessage );
    const [row, setRow] = useState( formData.package );
    const [service, setService] = useState([]);
    const [csvRows, setCsvRows] = useState([])
    const [isCsv, setIsCsv] = useState(false)
    const [state, setState] = useState({
        _loading: false,
        isInsurance: false,
        totalWeight: formData.packageWeight,
        totalRow: 1,
        reload: 0
    });

    const GetServices = async () => {
        setState({ ...state, loading: true })
        const resp = await getCustomerService(u_id)
        if (resp.status) {
            setService(resp.results)
        } else {
            setService(false)
            setState({ ...state, error: resp.msg })
        }
        setState({ ...state, loading: false })
    }

    const packageWeight = ( value ) => {
        let count = 0;
        // true === KG : false === LB 
        if( !formData.weightType ){
            if( !orderType ) {
                row.forEach( _row => {
                    if( _row.act ) {
                        // count = ( parseInt( _row.weight ) / convert ) + count;
                        count = parseFloat( _row.weight ) + count;
                    }
                })
            }
            if( orderType ) {
                csvRows.forEach( _row => {
                    if( _row.act ) {
                        // count = ( parseInt( _row.weight ) / convert ) + count;
                        count = parseFloat( _row.weight ) + count;
                    }
                })
            }
            console.log( count );
            setFormData({...formData, packageWeight: count + value })
        }
        else{
            if( !orderType ) {
                row.forEach( _row => {
                    if( _row.act ) {
                        count = parseFloat( _row.weight ) + count;
                    }
                })
            }
            if( orderType ) {
                csvRows.forEach( _row => {
                    if( _row.act ) {
                        console.log(  _row.weight );
                        count = parseFloat( _row.weight ) + count;
                    }
                })
            }
            setFormData({...formData, packageWeight: parseFloat(count + value).toFixed(2) })
        }
        
    }

    const packageNum = async () => {
        let count = [];
        if( !orderType ) {
            row.forEach( _row => {
                if( _row.act ) 
                    count.push({ 
                        act: _row.act,
                        package_num: _row.package_num,
                        weight: _row.weight,
                        dimensionsL: _row.dimensionsL,
                        dimensionsW: _row.dimensionsW,
                        dimensionsH: _row.dimensionsH 
                    })
            }) 
        }
        if( orderType ) {
            csvRows.forEach( _row => {
                if( _row.act ) 
                    count.push({ 
                        act: _row.act,
                        package_num: _row.package_num,
                        weight: _row.weight,
                        dimensionsL: _row.dimensionsL,
                        dimensionsW: _row.dimensionsW,
                        dimensionsH: _row.dimensionsH 
                    })
            }) 
        }
        setRow( count )
        return true
    }

    const handleAddRow = async() => {
        let resp = await packageNum()
        setRow([...row,
            {
                act: true,
                package_num: '1',
                weight: '',
                dimensionsL: '',
                dimensionsW: '',
                dimensionsH: ''
            }
        ])
    }

    const handleJsonResult = async ( jsonArray ) => {
        let rowMap = mapRows( jsonArray )
        setCsvRows( rowMap )
        setIsCsv( true )
        setState({...state, reload: state.reload +1  })
    }

    const mapRows = ( jsonArray ) => {
        let rowMap = []
        jsonArray.map((json, index) => {
            json.act = true
            rowMap.push( json )
        })
        return rowMap;
    }
    
    const handleSubmit = ( e ) => {
        e.preventDefault();
        let isValid= true;
        if( !orderType ) {
            row.forEach( _row => {
                if( _row.act ) {
                    if ( !_row.package_num ) {
                        isValid= false;
                        return setMessage({package: 'Package number is requiered.'});
                    }
                    if ( !_row.weight ) {
                        isValid= false;
                        return setMessage({package: 'Package weight is requiered.'});
                    }
                    if ( !_row.dimensionsL ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions is requiered.'});
                    } 
                    if ( !isNumber( _row.dimensionsL ) ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions has to be a number.'})
                    } 
                    if ( !_row.dimensionsW ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions is requiered.'});
                    } 
                    if ( !isNumber( _row.dimensionsW ) ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions has to be a number.'})
                    } 
                    if ( !_row.dimensionsH ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions is requiered.'});
                    } 
                    if ( !isNumber( _row.dimensionsH ) ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions has to be a number.'})
                    } 
                    if( formData.weightType ){
                        _row.endWeight = _row.weight
                        // _row.endWeight = ( parseInt( _row.weight ) / convert ).toFixed(2)
                    }
                    else
                        _row.endWeight = _row.weight
                }
            })
        }
        if( orderType ) {
            csvRows.forEach( _row => {
                if( _row.act ) {
                    if ( !_row.package_num ) {
                        isValid= false;
                        return setMessage({package: 'Package number is requiered.'});
                    }
                    if ( !_row.weight ) {
                        isValid= false;
                        return setMessage({package: 'Package weight is requiered.'});
                    }
                    if ( !_row.dimensionsL ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions is requiered.'});
                    } 
                    if ( !isNumber( _row.dimensionsL ) ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions has to be a number.'})
                    } 
                    if ( !_row.dimensionsW ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions is requiered.'});
                    } 
                    if ( !isNumber( _row.dimensionsW ) ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions has to be a number.'})
                    } 
                    if ( !_row.dimensionsH ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions is requiered.'});
                    } 
                    if ( !isNumber( _row.dimensionsH ) ){
                        isValid= false;
                        return setMessage({package: 'Package dimensions has to be a number.'})
                    } 

                    if( formData.weightType ){
                        // _row.endWeight = ( parseInt( _row.weight ) / convert ).toFixed(2)
                        _row.endWeight = _row.weight
                    }
                    else
                        _row.endWeight = _row.weight
                }
            })
        }
        
        if ( !formData.pickUpDate )
            return setMessage({pickUpDate: 'Pick Up Date is requiered.' })
        if ( !formData.readyTime ) 
            return setMessage({readyTime: 'Ready Time is requiered.' })
        if ( !formData.service_id ){
            return setMessage({service: 'Service is requiered.' })
        } else{
            service.forEach( i => {
                if ( i.service_id === formData.service_id ) {
                    setFormData({...formData,  service: i.service })
                }
            })
        }
        
        if ( formData.isInsurance && formData.declaredValue ) 
            return setMessage({insuranceValue: 'Insurance is requiered.' })
        
        if ( isValid ) {
            if( !orderType ) {
                setFormData({ ...formData, package: row, packageNumber: row.length })
            }
            if( orderType ) {
                packageWeight( 0 );
                setFormData({ ...formData, package: csvRows, packageNumber: csvRows.length })
            }
            setState( initialState )
            return nextStep()
        }
    }

    useEffect(() => {
        packageWeight( 0 );
        GetServices();
    }, [ formData.weightType, state.reload ]);

    return (
        <form id="go_step4_form" onSubmit={ handleSubmit } >
            <Grid container className="text_grey">
                { !orderType && 
                    <>
                    <Grid item xs={12} className="py-3 customer__hr" >
                        <table style={{width: '100%', border: '1px solid #CECECE', borderCollapse: 'collapse', margin: '15px, 0'}}>
                            <thead>
                                <tr className="table__header" style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                                    <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', fontSize:'1rem'}} >
                                        Number of packages
                                    </th>
                                    <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', fontSize:'1rem'}} >
                                        Weight per package 
                                        <div className="">
                                            <label htmlFor="_weightFormat"  > LB / KG </label>
                                            <Switch checked={ formData.weightType } onChange={ e => setFormData({...formData, weightType: e.target.checked })} color="primary" id="_weightFormat"/>
                                        </div>
                                    </th>
                                    <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '60%', minWidth: '60%', fontSize:'1rem'}} >
                                        Dimensions per package (L * W * H).
                                        <div className="">
                                            <label htmlFor="_dimensionsFormat"  > IN / CM </label>
                                            <Switch checked={ formData.dimensionsType } onChange={ e => setFormData({...formData, dimensionsType: e.target.checked })} color="primary" id="_dimensionsFormat"/>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { row.map( (_row, index) => {
                                    if ( _row.act ) {
                                        return (
                                            <tr style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}} key={index}>
                                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', padding: '5px', textAlign: 'center' }} >
                                                    <select onChange={ e => { _row.package_num = e.target.value; packageWeight( 0 ); }} style={{border: 'none'}} value={ _row.package_num } >
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                    </select>
                                                </td>
                                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', padding: '5px', textAlign: 'center'}} >    
                                                    <input 
                                                        type="number"
                                                        
                                                        value={ _row.weight } 
                                                        onChange={ e => { _row.weight = e.target.value; packageWeight( 0 ); }} 
                                                        style={{border: 'none', textAlign: 'center'}} 
                                                    />
                                                </td>
                                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', padding: '5px', numberAlign: 'center', display: 'flex' }} >  
                                                    <input 
                                                        type="number" 
                                                        value={ _row.dimensionsL } 
                                                        onChange={ e => {_row.dimensionsL = e.target.value; packageWeight( 0 ); }} 
                                                        style={{border: 'none', width: '20%', textAlign: 'center'}}  
                                                    />
                                                    <p>*</p>
                                                    <input 
                                                        type="number" 
                                                        value={ _row.dimensionsW } 
                                                        onChange={ e => {_row.dimensionsW = e.target.value; packageWeight( 0 );}} 
                                                        style={{border: 'none', width: '20%', textAlign: 'center'}}  
                                                    />
                                                    <p>*</p>
                                                    <input 
                                                        type="number" 
                                                        value={ _row.dimensionsH } 
                                                        onChange={ e => {_row.dimensionsH = e.target.value; packageWeight( 0 );}} 
                                                        style={{border: 'none', width: '20%', textAlign: 'center'}}  
                                                    /> 
                                                    <span style={{width: '10%'}} > {formData.dimensionsType ? 'cm' : 'in' } </span> 
                                                    <IconButton 
                                                        style={{width: '10%', padding: '0'}} 
                                                        onClick={ async () => {
                                                            _row.act = false; 
                                                            packageWeight( 0 ); 
                                                            let resp = await packageNum();
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        )
                                    } 
                                    else return false
                                }
                                )}
                            </tbody>
                        </table>
                        <span><small>* The maximum of packages you can send is 25 and should not exceed 10 pounds each.</small></span><br/>
                        { !!message.package && <span className="text_error"><small> { message.package } </small></span> }
                        <p 
                            onClick={ () => handleAddRow() }
                            className="customer_newPackage"
                        > 
                            + Add another package 
                        </p> 
                    </Grid>
                    <Grid item xs={12} className="flex" >
                        <div className="w-20">
                            <span className="customer__list-label"> PACKAGES </span><br/>
                            <span className="customer__list-text">{ row.length }</span>
                        </div>
                        <div className="w-25 mr-3">
                            <span className="customer__list-label"> TOTAL WEIGHT </span><br/>
                            <span className="customer__list-text"> { formData.packageWeight } { formData.weightType ? 'KG' : 'LB' }  </span>
                        </div>
                    </Grid>
                    </>
                }
                { orderType && 
                    <>
                        <Grid className="header__details" container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} >
                                <Typography align="center">
                                    <a href={ csvTemplate } target="_blank" rel="noopener noreferrer" className="customer_newPackage">
                                        Download package batch template
                                    </a>
                                </Typography>
                                <Typography align="center" >Upload your csv file to parse...</Typography>
                            </Grid>
                            <Grid item xs={12} className="pointer"  >
                                <InputFile
                                    accept=".csv"
                                    label="Click to upload your csv"
                                    handleJsonResult={ handleJsonResult }
                                />
                            </Grid>
                        </Grid>
                        { !!message.package && <span className="text_error"><small> { message.package } </small></span> }
                        { isCsv && 
                            <Grid container direction="row" justify="center" alignItems="space-between" className="py-3" >
                                <table style={{width: '100%', border: '1px solid #CECECE', borderCollapse: 'collapse', margin: '15px, 0'}}>
                                    <thead>
                                        <tr className="table__header" style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                                            <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', fontSize:'1rem'}} >
                                                Number of Packages
                                            </th>
                                            <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', fontSize:'1rem'}} >
                                                Weight per Package
                                                <div className="">
                                                    <label htmlFor="_weightFormat"  > LB / KG </label>
                                                    <Switch checked={ formData.weightType } onChange={ e => setFormData({...formData, weightType: e.target.checked })} color="primary" id="_weightFormat"/>
                                                </div>
                                            </th>
                                            <th style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '60%', minWidth: '60%', fontSize:'1rem'}} >
                                                Dimensions per Package (L * W * H)
                                                <div className="">
                                                    <label htmlFor="_dimensionsFormat"  > IN / CM </label>
                                                    <Switch checked={ formData.dimensionsType } onChange={ e => setFormData({...formData, dimensionsType: e.target.checked })} color="primary" id="_dimensionsFormat"/>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { csvRows.map( row => 
                                            <tr style={{ border: '1px solid #CECECE', borderCollapse: 'collapse'}}>
                                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse',  maxWidth: '20%',  minWidth: '20%', padding: '5px', textAlign: 'center' }} >
                                                    { row.package_num }
                                                </td>
                                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '20%', minWidth: '20%', padding: '5px', textAlign: 'center' }} >
                                                    { row.weight }
                                                </td>
                                                <td style={{ border: '1px solid #CECECE', borderCollapse: 'collapse', maxWidth: '60%', minWidth: '60%', padding: '5px', textAlign: 'center' }} >   
                                                    { row.dimensionsL }&nbsp;L * { row.dimensionsW }&nbsp;W * { row.dimensionsH }&nbsp;H in
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Grid>
                        }
                        <Grid item xs={12} className="flex" >
                            <div className="w-20">
                                <span className="customer__list-label"> PACKAGES </span><br/>
                                <span className="customer__list-text">{ csvRows.length }</span>
                            </div>
                            <div className="w-25 mr-3">
                                <span className="customer__list-label"> TOTAL WEIGHT </span><br/>
                                <span className="customer__list-text"> { formData.packageWeight } { formData.weightType ? 'KG' : 'LB' }  </span>
                            </div>
                            <div className="w-20">
                                <label htmlFor="_weightFormat" className="customer__list-label" > LB / KG </label><br/>
                                <Switch checked={ formData.weightType } onChange={ e => setFormData({...formData, weightType: e.target.checked })} color="primary" id="_weightFormat"/>
                            </div>
                        </Grid>
                    </>
                }
                <Grid item xs={12} md={4} >
                    <Box mt={1} mb={2} mr={1} > 
                        <label htmlFor="_pickUpDate" > Pick up date </label>
                        <TextField id="_pickUpDate" name="pickUpDate" type="date" variant="outlined" fullWidth value={ formData.pickUpDate } onChange={e => setFormData({...formData,  pickUpDate: e.target.value})} autoComplete="off" error={ !!message.pickUpDate } helperText={ message.pickUpDate } />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} >
                    <Box mt={1} mb={2} mr={1}> 
                        <label htmlFor="_readyTime" > Ready time </label>
                        <TextField id="_readyTime" name="readyTime" type="time" variant="outlined" fullWidth value={ formData.readyTime } onChange={e => setFormData({...formData,  readyTime: e.target.value})} autoComplete="off" error={ !!message.readyTime } helperText={ message.readyTime } />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} >
                    <Box mt={1} mb={2} > 
                        <label htmlFor="_service" > Service </label>
                        <Select id="_service" name="service" variant="outlined" fullWidth value={ formData.service_id } onChange={e => setFormData({...formData,  service_id: e.target.value })} displayEmpty >
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { service 
                                ? service.map( service => <MenuItem key={ service.service_id } value={ service.service_id }><em>{ service.service }</em></MenuItem> ) 
                                : <MenuItem value="SameDay"><em>No services availity </em></MenuItem>
                            }
                        </Select>
                        { !!message.service && <span className="text_error"><small>  {message.service} </small></span> }
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Box mt={1} mb={2} > 
                        <label htmlFor="_insurance" > Insurance </label><br/>
                        <Switch checked={ formData.isInsurance } onChange={ e => setFormData({...formData, isInsurance: e.target.checked }) } color="primary" id="_insurance"/>
                    </Box>
                </Grid>
                { formData.isInsurance && 
                    <Grid item xs={12} >
                        <Box mt={1} mb={2} > 
                            <label htmlFor="_insuranceValue" > Declared value </label>
                            <TextField id="_insuranceValue" name="insuranceValue" type="number" variant="outlined" fullWidth value={ formData.insuranceValue } onChange={e => setFormData({...formData,  insuranceValue: e.target.value})} autoComplete="off" error={ !!message.insuranceValue } helperText={ message.insuranceValue } />
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} >
                    <Box mt={1} mb={2} > 
                        <label htmlFor="_comments" > Comments/Instructions </label>
                        <TextareaAutosize id="_comments" name="comments" rowsMin={5} variant="outlined" className="text_area-style" value={ formData.comments } onChange={e => setFormData({...formData,  comments: e.target.value})} autoComplete="off" />
                        { !!state.instructions && <span style={{color: 'red'}}> { state.instructions } </span> }
                    </Box>
                </Grid>
                <Grid id="go_gridContainer_stepper_buttons" container direction="row" justify="center" alignItems="center" >
                    <Button 
                        variant="outlined"
                        disableElevation 
                        className="w-150px mr-2-r mb-2-r" 
                        onClick={ () => { 
                            backStep(); 
                            setFormData({ 
                                ...formData,
                                package: row, 
                                packageNumber: row.length, 
                                packageWeight: state.totalWeight 
                            });
                        }}
                    > 
                        Back 
                    </Button>
                    <Button 
                        type="submit"  
                        variant="outlined" 
                        className="Go_btn-primary w-150px"
                    > 
                        { state._loading ? 'Loading...' : 'CONTINUE' } 
                    </Button> 
                </Grid>
            </Grid>
        </form>
    )
}
