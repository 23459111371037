import React, { useEffect, useState } from 'react'
import _go_logo from './../../../Assets/Img/go_logo.png'
import QRCode from 'qrcode.react'
import Pdf from "react-to-pdf";
import { useRouteMatch } from 'react-router-dom';
import { getOrderById } from '../../../Components/Functions/LabelPdf/LabelFunctions';
import { Button, Grid, Typography } from '@material-ui/core';
import moment from 'moment'
import { Label } from './Label';


import PdfOrder from './PdfOrder';

export const PdfView = () => {

    const u_id = localStorage.getItem('u_id')
    const match = useRouteMatch('/customer/pdf/:id');
    const order_id = match.params.id;
    const [data, setData] = useState([]);

    const handleGetLabel = async () => {
        const resp = await getOrderById(u_id, order_id)
        let count = [];
        if (resp.status) {
            for (let i = 0; i < parseInt(resp.results.total_packages); i++) {
                console.log( resp.results );
                count.push({
                    orderId: `255${resp.results.order_id}`,
                    pickupTitle: resp.results.pickup_name,
                    pickupContact: resp.results.pickup_contact,
                    pickupAddress: resp.results.pickup_address,
                    pickupApartment: resp.results.pickup_apartment ? resp.results.pickup_apartment : false,
                    pickupCity: resp.results.pickup_city,
                    pickupCountry: resp.results.pickup_country,
                    pickupPostal: resp.results.pickup_postal,
                    pickupProvince: resp.results.pickup_province,
                    deliveryTitle: resp.results.delivery_name,
                    deliveryContact: resp.results.delivery_contact,
                    deliveryAddress: resp.results.delivery_address,
                    deliveryApartmen: resp.results.delivery_apartment ? resp.results.delivery_apartment : false,
                    deliveryCity: resp.results.delivery_city,
                    deliveryCountry: resp.results.delivery_country,
                    deliveryPostal: resp.results.delivery_postal,
                    deliveryProvince: resp.results.delivery_province,
                    totalWeight: resp.results.total_weight,
                    weightUnit: resp.results.weight_unit ? resp.results.weight_unit : false,
                    orderDate: resp.results.order_date,
                    packageCount: `${i + 1} / ${resp.results.total_packages}`,
                    labelRef: React.createRef()
                })
            }
            setData(count)
        } else {
            setData(false)
        }
        console.log( data );
        // return count
    }

    useEffect(() => {
        handleGetLabel()
        // let a = await handleGetLabel()
        // console.log( a );
    }, [])


    return (
        <Grid container style={{ padding: '0 10%', marginBottom: '20px' }}>
            <Grid item xs={12} className="header__details" >
                <Typography variant='h4' > Order details </Typography>
                <Grid container direction="row" justify="space-between" alignItems="center" >
                    <Typography variant='h5' className="text_lite-white" > # 255{order_id} </Typography>
                    <Typography variant='h5' className="text_lite-white" > {moment(data.order_date).format('MMMM Do YYYY')} </Typography>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" style={{ backgroundColor: 'white' }}>
                { data && <PdfOrder labels={ data } /> }
            </Grid>
        </Grid>
    )
}
