import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Logo from './../../Assets/Img/Logo.png';

const ModalImg = ( { img } ) => {
    return (
        <Box m={2} className="bg_white" >
            <Grid container >
                <Grid item xs={12}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <img src={ Logo } alt="modal img" />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box my={2}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <img src={ img } alt="" className="modal__img" />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ModalImg;
