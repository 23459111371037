import React from 'react'
import { BrowserRouter, Switch, useRouteMatch } from 'react-router-dom'
import WarehouseDashboard from '../Pages/Warehouse/WarehouseDashboard'

//Page views


import RoutePrivate from './components/RoutePrivate'
import WarehousePrintLabel from '../Pages/Warehouse/WarehousePrintLabel';
import RegistersList from '../Pages/Warehouse/RegistersList';
import RegistersExport from '../Pages/Warehouse/RegistersExport';
import WarehouseInventoryCount from '../Pages/Warehouse/WarehouseInventoryCount';
import WarehouseInventoryList from '../Pages/Warehouse/WarehouseInventoryList';
const WarehouseRoutes = () => {

    const { url } = useRouteMatch()

    return (
        <Switch>
            <RoutePrivate exact path={`${url}`} component={WarehouseDashboard} />
            <RoutePrivate exact path={`${url}/scan/:scan`} component={RegistersList} />
            <RoutePrivate exact path={`${url}/print/:label`} component={WarehousePrintLabel} />
            <RoutePrivate exact path={`${url}/find/products`} component={RegistersList} />
            <RoutePrivate exact path={`${url}/set/:location`} component={RegistersList} />
            <RoutePrivate exact path={`${url}/export/`} component={RegistersExport} />
            <RoutePrivate exact path={`${url}/inventory/`} component={WarehouseInventoryCount} />
            <RoutePrivate exact path={`${url}/inventory/list`} component={WarehouseInventoryList} />
        </Switch>
    )
}

export default WarehouseRoutes
