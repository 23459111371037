/**
 * @author Angel
 * @email [example@mail.com]
 * @create date 2021-07-26 01:27:56
 * @modify date 2021-07-26 08:50:51
 * @desc [description]
 */
import { APIGO } from "../../constants/API"

export const getWarehouseProducts = async () =>{
    try {
        const response = await APIGO.get("/warehouse/products")
        if(response.data.status){
            return response.data.result
        }else{
            return false
        }
    } catch (error) {
        console.warn(error.response)
        return false
    }
}

export const postWarehouseProduct = async data => {
    try {
        const response = await APIGO.post("/warehouse/products",data)
        if(response.data.status){
            return response.data.result
        }else{
            return false
        }
    } catch (error) {
        console.warn(error.response)
        return false
    }
}