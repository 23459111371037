import React,  { useState, useEffect, useContext }  from 'react'
import { Link } from 'react-router-dom';
// Ma6terial UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

const ForgotPwrd = () => {

    const [ userName, setUserName ] = useState('');
    const [ isError, setIsErrors] = useState( false );

    return(
        <Grid container>
            <Grid  container direction="column" justify="center" alignItems="center">
                <Box py={3}>
                    <Typography className="text-primary" variant="h4"> Reset your password </Typography>
                </Box>
                <Box py={1} px={3}>
                    <Typography variant="body1" className="text-grey" > 
                        Enter the user to which your account is linked and we will send a new password.
                    </Typography>
                </Box>
            </Grid>
            <Grid xs={12}>
                <Box py={1}>
                    <TextField onChange={e => setUserName(e.target.value)} id="email" variant="outlined" placeholder="Username" type='email' value={userName} fullWidth required />
                </Box>
            </Grid>
            <Grid xs={12}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Box py={1}>
                        {
                            isError ?
                                <Alert severity="error">Alunos campos son incorrectos</Alert> : null
                        }
                    </Box>
                </Grid>
                <Box className="start_box" py={1}>
                    <Button type="submit" className="Go_btn-primary" variant="outlined" fullWidth>Reset your password</Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ForgotPwrd
