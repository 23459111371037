import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { deleteWarehouseRegister, getRegisters, searchInRegisters, getWarehouseList, updateRegisterData, searchWithQuery, filter } from '../../middleware/warehouse/Warehouse';
import {
    Container, Box, Button, Typography,
    Grid, Card, CardContent, FormControl,
    TextField, Select, MenuItem, Tooltip, InputAdornment, IconButton, List, Divider
} from "@material-ui/core"

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { useContext } from 'react';
import { AuthContext } from '../../Components/Functions/Auth';

import { Badge, Menu } from '@material-ui/core';
import { makeStyles, ListItem, ListItemText } from '@material-ui/core';
import WarehouseModal from './WarehouseModal';
import Pagination from '@material-ui/lab/Pagination';
import { pageMakerFilter, pagerMaker, Operators } from '../../utils/paginator';
import DialogMessage from '../../Components/Global/Modals/DialogMessage';
import WarehouseExemption from './WarehouseExemption';
import EditRegisterModal from './EditRegisterModal';
import moment from 'moment';
import Hidden from '@material-ui/core/Hidden';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import EditRegisterLocationModal from './EditRegisterLocationModal';

import BarcodeScanButton from '../../Components/Global/BarcodeScanButton';
import MenuOptions from '../../Components/Global/MenuOptions';
import { search_by, search_modes } from '../../utils/search_filter';
import RegisterItem, { trigger_types } from './RegisterItem';



const RegistersList = () => {

    const history = useHistory()

    const PER_PAGE = 10
    /**
     * id integer list id when comes from customer service lists
     * scan applies only for warehouse user, 
     * Defines which status to be applied for actions (sent out) (print label) (returns)
     */
    const { id, scan, location } = useParams()
    /**
     * User context used to make history of changes based in user
     */
    const { user: { u_id } } = useContext(AuthContext)

    // List to show on front
    const [list, setList] = React.useState()
    // Paged list
    //Page for controll which list to be shown on front
    const [page, setPage] = React.useState(1)
    const [page_count, setPageCount] = React.useState(1);

    /**
     * Defines triggers for modal options
     */
    const defaultTriggers = {
        data: false,
        trigger: -1//tirgger types exemption, found,
    }
    const [triggers, setTriggers] = React.useState(defaultTriggers)

    /**
     * Filters options to aply on listTemp
     */
    const [filterOptions, setFilterOptions] = React.useState({
        from: "",
        to: "",
        status: "all"
    })

    //Filtered text
    const [searchText, setSearchText] = React.useState("")

    React.useEffect(() => {
        handleApplyFilters()
    }, [filterOptions.status])

    // Call api to load all registers
    React.useEffect(() => {
        requestRegisters()
    }, [scan])

    const handleTriggerClose = () => {
        setTriggers(defaultTriggers)
    }

    const handleFilterOptions = ({ target }) => {
        const { name, value } = target
        setFilterOptions({
            ...filterOptions,
            [name]: value
        })
    }

    const handleApplyFilters = async () => {

        const response = await filter(id, filterOptions.status, filterOptions.from,
            filterOptions.to, page, PER_PAGE)

        if (response) {
            setList(response.result)
            setPage(parseInt(response.page))
            setPageCount(parseInt(response.pages) / PER_PAGE)
        } else {
            setList([])
        }

        /* if (filterOptions.status && filterOptions.status !== "all") {
            const from = moment(filterOptions.from).format("YYYY-MM-DD")
            const to = moment(filterOptions.to).format("YYYY-MM-DD")

            const response = await searchWithQuery(
                filterOptions.status, page, PER_PAGE,
                id ? `list_id=${id} and (created BETWEEN '${from}' AND '${to}')` : ` (created BETWEEN '${from}' AND '${to}')`
            )

            if (response.status) {
                setList(response.result)
                setPage(response.page)
                setPageCount(response.pages)
            } else {
                setList([])
                setPage(1)
                setPageCount(0)
            }

        } else {
            //const response = await getRegisters(id, u_id, page, PER_PAGE)

            const from = filterOptions.from ? moment(filterOptions.from).format("YYYY-MM-DD") : moment("2020-01-01").format("YYYY-MM-DD")
            const to = filterOptions.to ? moment(filterOptions.to).format("YYYY-MM-DD") : moment("2050-01-01").format("YYYY-MM-DD")

            const response = await searchWithQuery(
                filterOptions.status, page, PER_PAGE,
                id ? `list_id=${id} and (created BETWEEN '${from}' AND '${to}')` : ` (created BETWEEN '${from}' AND '${to}')`
            )

            if (response.status) {

                setList(response.result)
                setPage(response.page)
                setPageCount(response.pages)

            }
        } */

    }

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        if (searchText !== "") {
            handleSearchFilter(searchText)
        }
    }

    const handleSearchFilter = async () => {
        const response = await searchInRegisters(id, searchText, page, PER_PAGE, u_id)

        if (response.status) {
            setList(response.result)
            setPage(response.page)
            setPageCount(response.pages)

            if (response.result.length === 1) {
                const _data = { ...response.result[0], found: true }
                if (location) {
                    setTriggers({
                        data: _data,
                        trigger: trigger_types.edit_location
                    })
                } else {
                    setTriggers({
                        data: _data,
                        trigger: trigger_types.history
                    })
                }
            }

        } else {
            setList([])
            setPage(1)
            setPageCount(1)

            setTriggers({
                data: false,
                trigger: trigger_types.not_found
            })

        }
        setSearchText("")
        /* const _filtered = search_by({
            object_id: 'warehouse_id',
            indexed: [
                'serial_number', 'sales_order_no', 'shipment_no', 'description',
                'first_name', 'last_name', 'location_name'
            ],
            search: searchText
        }, listTemp, search_modes.AllSubStrngs)

        makePagination(_filtered)
        setSearchText("") */
    }

    const requestRegisters = async () => {

        handleApplyFilters()
        /* if (scan === "returns") {

            const response = await searchInRegisters(id, "on_route", page, PER_PAGE, u_id)
            if (response.status) {
                setList(response.result)
                setPage(response.page)
                setPageCount(response.pages)
                console.log(response);
            }
        } else {
            const response = await getRegisters(id, u_id, page, PER_PAGE)
            if (response.status) {
                setList(response.result)
                setPage(response.page)
                setPageCount(response.pages)
            }
            console.log(response);
        } */

    }

    const handlePageChange = async (value) => {

        /* if (filterOptions.status && filterOptions.status != "all") {
            const response = await searchInRegisters(id, filterOptions.status, value, PER_PAGE, u_id)

            if (response.status) {
                setList(response.result)
                setPage(response.page)
                setPageCount(response.pages)

            }
        } else {
            const response = await searchInRegisters(id, "all", value, PER_PAGE, u_id)
            if (response.status) {

                setList(response.result)
                setPage(response.page)
                setPageCount(response.pages)

            }
        } */
    }

    return (
        <Container disableGutters >

            <WarehouseModal
                data={triggers.data}
                open={triggers.trigger === trigger_types.history}
                onClose={handleTriggerClose}
                onDataChange={requestRegisters}
            />

            <DialogMessage open={triggers.trigger === trigger_types.not_found} type="error"
                positiveText="Add exemption"
                negativeAction={() => setTriggers(defaultTriggers)}
                positiveAction={() => {
                    setTriggers({
                        data: false,
                        trigger: trigger_types.exemption
                    })
                }}
                negativeText="back"
                onClose={() => setTriggers(defaultTriggers)}
                title="Product not found"
                message="The product scanned does not exists, press back to search again or add exemption" />

            <WarehouseExemption
                list_id={id}
                onDataChange={requestRegisters}
                open={triggers.trigger === trigger_types.exemption}
                onClose={handleTriggerClose}
            />

            <EditRegisterModal
                open={triggers.trigger === trigger_types.edit}
                item={triggers.data}
                onDataChange={requestRegisters}
                onClose={handleTriggerClose}
            />

            <EditRegisterLocationModal
                open={triggers.trigger === trigger_types.edit_location}
                item={triggers.data}
                onDataChange={requestRegisters}
                onClose={handleTriggerClose}
            />

            <Grid container spacing={1} >
                <Grid item xs={6} md={3} >
                    <Typography>From:</Typography>
                    <TextField fullWidth
                        name="from"
                        onChange={handleFilterOptions}
                        type="date" size="small"
                        variant="outlined" />
                </Grid>
                <Grid item xs={6} md={3} >
                    <Typography>To:</Typography>
                    <TextField
                        fullWidth
                        name="to"
                        onChange={handleFilterOptions}
                        type="date" size="small"
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}  >
                    <Typography> Status: </Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select value={scan} native name="status" onChange={handleFilterOptions}  >
                            <option value={"all"}>All</option>
                            <option value={"pending"}>Pending</option>
                            <option value={"canceled"}>Canceled</option>
                            <option value={"received"}>Received</option>
                            <option value={"exemption"}>Received-as-X</option>
                            <option value={"on_route"}>On route</option>
                            <option value={"delivered"}>Delivered</option>
                            <option value={"returned"}>Returned</option>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}  >
                    <Typography> . </Typography>
                    <Button onClick={handleApplyFilters} variant="contained" color="secondary" >Apply Filter</Button>
                </Grid>


                <Grid item xs={12} >
                    <Card >
                        <CardContent>
                            <Grid container justifyContent="center" alignItems="center" spacing={2} >
                                <Grid item xs={12} sm={12} md={8} lg={8} >
                                    <form style={{ display: "flex" }} onSubmit={handleSearchSubmit} >
                                        <TextField
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <BarcodeScanButton onSuccess={(code) => {
                                                            setSearchText(code)
                                                        }} />
                                                    </InputAdornment>,
                                            }}
                                            onChange={({ target }) => setSearchText(target.value)}
                                            value={searchText}
                                            name="search"
                                            placeholder="Search here..."
                                            autoFocus
                                            size="small"
                                            id="outlined-basic"
                                            fullWidth variant="outlined"
                                        />
                                        <Button type="submit" variant="contained" color="secondary" >Search</Button>
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Tooltip title="Export all current filtered data">
                                        <Button fullWidth variant="outlined" color="secondary" onClick={() => {
                                            //console.log( { list, u_id, id, scan, location, filterOptions })
                                            history.push("/wh/export/", { list, u_id, id, scan, location, filterOptions });
                                        }} >Export data</Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: 20, overflow: "auto" }}  >
                                <Grid item xs={12}>
                                    <Hidden only={['xs', 'sm']} >
                                        <Table style={{ width: "100%" }} >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="none" ><Typography align="center" >Order number</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >Client</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >Product</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >Serial number</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >Location</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >Status</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >Product Status</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >First Scan date</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >Aging</Typography></TableCell>
                                                    <TableCell padding="none" ><Typography align="center" >Actions</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    list && <React.Fragment>
                                                        {
                                                            list.map((item, index) => (
                                                                <RegisterItem key={index} itemData={item} handleTrigger={setTriggers} />
                                                            ))
                                                        }
                                                    </React.Fragment>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Hidden>
                                    <Hidden only={['xl', 'lg']} >
                                        <List>
                                            {
                                                list && <React.Fragment>
                                                    {
                                                        list.map((item, index) => (
                                                            <RegisterItem key={index} itemData={item} handleTrigger={setTriggers} />
                                                        ))
                                                    }
                                                </React.Fragment>
                                            }
                                        </List>
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" >
                                {list &&
                                    <Pagination page={page} count={page_count} variant="outlined" shape="rounded"
                                        onChange={(e, value) => {
                                            handlePageChange(value)
                                        }} />
                                }
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>

            </Grid>

        </Container>
    )
}

export default RegistersList