import React from 'react'
import PropTypes from 'prop-types'
import MaterialDialog from '@material-ui/core/Dialog'
import { CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, useMediaQuery, useTheme } from '@material-ui/core'

const Dialog = ({ title, message, children, type, open, setOpen }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <MaterialDialog
            fullWidth={true}
            maxWidth='sm'
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        message
                    }
                </DialogContentText>
                <Grid container justify="center" alignItems="center"  >
                    <Grid item >
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </MaterialDialog>
    )
}

Dialog.propTypes = {
    type: PropTypes.oneOf(["loader","alert","error","warning"])
}

export default Dialog
