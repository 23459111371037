import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
// Componentes
import ModalShow from '../Components/Global/Modal';
// IMG
import Portada from './../Assets/Img/Portada_management.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
//API
import { createStations, APIGO } from './../constants/API'; 

const Vendor = () => {

    const history                   = useHistory();   
    const [ id, setId ] = useState('');
    const [ address, setAddress] = useState('');
    const [ isError, setIsErrors] = useState([{
        id: false,
        address: false,
    }]);
    // Modal
    const [ isDeleted, setIsDeleted ]   = useState( false );
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]   = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };

    const ModalClose = () => {
        setModalStaus(false);
    }; 

    const saveElement = ( e, status ) => {
        e.preventDefault();
        if ( status === true ){
            setIsConfirm( false );
                
                let config = {
                    method: 'post',
                    url: createStations,
                    headers: { 
                        'Access-Control-Request-Headers': 'multipart/form-data', 
                        'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                    },
                    data: {
                        id: id,
                        address: address,
                    }
                }
                APIGO(config)
                .then(response =>{
                    if ( response.data.status === "success" ) {
                        ModalClose()
                        setModalmsg('Element added successfully');
                        setModalLink('/station');
                        setIsShow( true );
                        ModalOpen();
                    }
                    if ( response.data.status === "error" ) {
                        ModalClose()
                        setModalmsg('Sorry the name is already taken');
                        setModalLink('/station');
                        setIsShow( true );
                        ModalOpen();
                    }

                })
                .catch(function (error){
                    console.log(error)
                    ModalClose()
                    setModalmsg('Sorry We have some problems please try again in a frew minutes');
                    setModalLink('/station');
                    setIsShow( true );
                    ModalOpen();
                }) 
            
        }
        else{
            ModalClose()
        }
    }

    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };

    const handleForm = e => { 
        e.preventDefault();
        if(id === "" ){
            setIsErrors({ id: true });
        }else if( address === ""){
            setIsErrors({ address: true });
        }else  {
            setIsErrors('');
            setIsConfirm( true );
            setModalStaus( true );
        }
    }

    return (
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    {
                        isConfirm ?
                            <ModalShow 
                            title="Are you sure you want to save a new element? "
                            confirmOperation={ true }
                            Operation={ saveElement }
                            /> : null
                    }    
                    {
                        isShow ?
                            <ModalShow 
                                title={ modalMsg }
                                onlyShow={ true }
                                showGoTo={ modalGoTo }
                                goTo={ modalLink }
                            /> : null
                    }
                </Grid>
            </Modal>

            <form onSubmit={ e => handleForm( e ) }>
                <Grid container spacing={2} >
                    <Grid item xs={4} md={2} className="center_center">
                        <Link to="/station" className="flex No_Decoration">
                            <ArrowBackIosIcon className="Page_title"/>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Go back
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom className="Page_title">
                            New station
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Link to="/station" className="flex No_Decoration">
                            <Button variant="outlined" disableElevation fullWidth className="" >CANCEL</Button>
                        </Link>
                    </Grid>                    <Grid item xs={6} md={2}>
                        <Button variant="contained" disableElevation fullWidth className="Go_btn-primary" type="submit" >SAVE</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={1} ml={2} className="bg_white" boxShadow={3} >
                            <Grid container className="bg_white" >
                                <Grid item md={6}>
                                    <Box p={1}>
                                    <   Typography variant="body1" gutterBottom className="text_grey">
                                            STATION ID
                                        </Typography>
                                        {
                                            isError.id ?
                                                <TextField id="brand" type="text" size="small" variant="outlined" fullWidth value={ id } onChange={e => setId(e.target.value)} error helperText="Enter a value for id please" /> 
                                            : 
                                                <TextField id="brand" type="text" size="small" variant="outlined" fullWidth value={ id } onChange={e => setId(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item md={6}>
                                    <Box p={1}>
                                        <Typography variant="body1" gutterBottom className="text_grey">
                                            ADDRESS
                                        </Typography>
                                        {
                                            isError.address ?
                                                <TextField id="VehicleID" type="text" size="small" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} error helperText="Enter a value for Address please" /> 
                                            : 
                                                <TextField id="VehicleID" type="text" size="small" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    );

}
export default Vendor