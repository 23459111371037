import { List, ListItem, ListItemText, ListSubheader, makeStyles, Box, Typography } from '@material-ui/core'
import React from 'react'
import { getWarehouseHistory } from '../../middleware/warehouse/Warehouse'
import { dateTimeFormat } from '../../utils/formatter'



const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    content: {
        flexDirection: "column",
        alignItems: "baseline"
    },
    subheader: {
        backgroundColor: theme.palette.grey[300]
    }
}))

const RegisterHistory = ({ id, reload }) => {

    const classes = useStyles()

    const [list, setList] = React.useState()
    React.useEffect(() => {
        if (id) {
            (async () => {
                const result = await getWarehouseHistory(id)
                console.log(result)
                if (result) {
                    setList(result)
                    try {
                        const list_history = document.getElementById("h-list")
                        list_history.scrollTo(0, list_history.scrollHeight + 100)
                    } catch (error) {

                    }
                }
            })()
        }
    }, [id, reload])

    return (
        <React.Fragment>
            {list &&
                <List className={classes.root} id="h-list" subheader={<li />}>
                    {list.map((item) => (
                        <React.Fragment>
                            <li key={`section-${item.id}`} className={classes.listSection}>
                                <ul className={classes.ul}>
                                    <ListSubheader className={classes.subheader} >
                                        <Box display="flex" justifyContent="space-between" flexDirection="row" >
                                            <Typography>{
                                                item.current_status === "pending" ? "Pending receipt"
                                                    : item.current_status === "canceled" ? "Canceled"
                                                        : item.current_status === "delivered" ? "Delivered"
                                                            : item.current_status === "returned" ? "Returned"
                                                                : item.current_status === "exemption" ? "Received-as-X"
                                                                    : item.current_status === "on_route" ? "Audit/On route"
                                                                        : item.current_status === "received" ? "Received"
                                                : "Comment"
                                            }</Typography>
                                            <Typography>{dateTimeFormat(item.updated, "DD/MM/YYYY HH:MM")}</Typography>
                                        </Box>
                                    </ListSubheader>
                                    <ListItem className={classes.content} key={`item-${item.id}-${item}`}>
                                        <Typography variant="body1" >{item.comments}</Typography>
                                        <Typography variant="body1" >{item?.reason}</Typography>
                                        <Typography variant="h6" >{item.who}</Typography>
                                    </ListItem>
                                </ul>
                            </li>
                            <li id="last" ></li>
                        </React.Fragment>
                    ))}
                </List>
            }
        </React.Fragment>
    )


}

export default RegisterHistory
