import React, {useContext, useEffect} from 'react'
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from '../../Components/Functions/Auth';
import WarehouseServiceLayout from '../../Pages/Warehouse/WarehouseServiceLayout';


const PrivateWareHouseRoutes = ({ component: RouteComponent, ...rest }) => {
    const { token, user } = useContext(AuthContext)

    return (
        <Route {...rest} render={routeProps =>
            token ?
                <WarehouseServiceLayout>
                    <RouteComponent {...routeProps} />
                </WarehouseServiceLayout>
                :
                <Redirect to={"/login"} />
        } />
    );
}

export default PrivateWareHouseRoutes
