import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode'
import JsBarcode from 'jsbarcode'
import { Grid, CircularProgress, Typography, TextField, MenuItem } from '@material-ui/core';
import logo from '../../../Assets/Img/Logo.png'

// Create styles
const styles = StyleSheet.create({
    qr: {
        width: 90,
        height: 90
    },
    barcode: {
        width: 100,
        height: 50
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        justifyContent: "center",
        alignContent: "center",
        /* alignItems:"center", */
        padding: 20
    },
    row: {
        flexDirection: "row",
    },
    column: {
        width:"50%",
        flexDirection: "column",
        padding: 1,
        
    },
    section_header: {
        flexDirection: "row",
        height: 30,
        borderBottom: "solid",
        paddingBottom: 5,
        borderBottomWidth: 2,
    },
    section_col_l: {
        flexGrow: 2,
        width: 150,
        flexDirection: "column",
    },
    section_col_r: {
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    body_row: {
        flexDirection: "row",
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderBottom: "solid",
        borderBottomWidth: 2
    },
    footer: {
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderBottomColor: "#bebebe",
        borderBottomStyle: "dashed",
        borderBottomWidth: 2
    },
    logo: {
        width: 120,
        height: 20,
    },
    text_fix: {
        width: 30,
        fontSize: 10
    },
    text: {
        fontSize: 10
    }

});

const sectionStyles = StyleSheet.create({
    section: {
        flexGrow: 1,
        flexDirection: "column",
        
    },
})

const PdfOrder = ({ labels }) => {

    console.log(labels)

    const [isLoading, setisLoading] = React.useState(true)

    /* Document format */
    const [pageSize, setpageSize] = useState("A6")
    const pageSizes = [
        { label: "LETTER" },
        { label: "LEGAL" },
        { label: "A4" },
        { label: "A5" },
        { label: "A6" },
    ]
    const [orientation, setOrientation] = useState("portrait")
    const orientations = [
        { label: "portrait" },
        { label: "landscape" },
    ]
    const [labelW, setlabelW] = useState("FULL")
    const [labelH, setlabelH] = useState("FULL")
    const labelSizes = [
        { label: "FULL" },
        { label: "HALF" },
        { label: "QUARTER" },
    ]

    const handlePageChange = (e) => {
        setpageSize(e.target.value)
    }
    const handleOrientationChange = (e) => {
        setOrientation(e.target.value)
    }
    const handleLabelSize = (e, x) => {
        if (x === "W") {
            setlabelW(e.target.value)
        } else {
            setlabelH(e.target.value)
        }
    }


    let title = "";
    if (labels.length > 0) {
        const shipDate = moment(labels[0].orderDate).format('L')
        title = `label-${labels[0].orderId}-${shipDate}`
    }

    const OnRender = () => {
        setisLoading(false);
    }

    return (
        <React.Fragment>
            {isLoading && <CircularProgress />}
            {
                labels.length > 0 &&
                <PDFViewer>
                    {console.log( labels )}
                    <Document onRender={OnRender} title={title} >
                        {labels.map(label => (
                            <Page size={pageSize} style={styles.page} orientation={orientation} >
                                <Label label={label} width={labelW} height={labelH} />
                            </Page>
                        ))}
                    </Document>
                </PDFViewer>
            }
        </React.Fragment>

    )
};

const getLabelStyles = (props) => {
    const { width, height } = props

    let w = {}
    let h = {}

    switch (width) {
        case "QUARTER":
            w = { width: "27%" };
            break;
        case "HALF":
            w = { width: "53%" };
            break;
        default:
            w = { width: "100%" };
            break;
    }
    switch (height) {
        case "QUARTER":
            h = { height: 200 };
            break;
        case "HALF":
            h = { height: 412 };
            break;
        default:
            h = { height: "100%" };
            break;
    }

    return StyleSheet.flatten([sectionStyles.section, w, h])
}

const Label = (props) => {

    const { label, width, height } = props

    let canvas = document.createElement('canvas');
    QRCode.toCanvas(canvas, label.orderId);
    const qr = canvas.toDataURL()

    canvas = document.createElement('canvas');
    JsBarcode(canvas, label.orderId, { displayValue: false });
    const barcode = canvas.toDataURL();

    const {
        deliveryTitle,
        deliveryContact,
        deliveryAddress,
        deliveryApartmen,
        deliveryCity,
        deliveryCountry,
        deliveryPostal,
        deliveryProvince,
        orderDate,
        orderId,
        packageCount,
        pickupTitle,
        pickupContact,
        pickupAddress,
        pickupApartmen,
        pickupCity,
        pickupCountry,
        pickupPostal,
        pickupProvince,
        totalWeight,
        weightUnit
    } = label;

    const shipDate = moment(orderDate).format('L')

    return (
        <View style={getLabelStyles(props)}>
            {/* Section header */}
            <View style={styles.section_header}>
                <View style={styles.section_col_l} >
                    <Image src={logo} style={styles.logo} />
                </View>
                <View style={styles.section_col_r} >
                    <Text style={styles.text} >{orderId}</Text>
                </View>
            </View>

            {/* Section body */}
            <View style={styles.body}>

                {/* Section from */}
                <View style={styles.body_row}>
                    <View style={styles.section_col_l} >
                        <View style={styles.row} >
                            <View style={styles.column} >
                                <Text style={styles.text_fix}>From:</Text>
                            </View>
                            <View style={styles.column} >
                                <Text style={styles.text} >{pickupTitle}</Text>
                                <Text style={styles.text} >{pickupContact}</Text>
                                <Text style={styles.text} >{pickupAddress}</Text>
                                { pickupApartmen && <Text style={styles.text} >{pickupApartmen}</Text> }
                                <Text style={styles.text} >{pickupPostal}</Text>
                                <Text style={styles.text} >{pickupCity}</Text>
                                <Text style={styles.text} >{pickupProvince}</Text>
                                <Text style={styles.text} >{pickupCountry}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.section_col_r} >
                        <Image style={styles.qr} src={qr} />
                        <Text style={styles.text} >{orderId}</Text>
                    </View>
                </View>

                {/* Section TO */}
                <View style={styles.body_row}>
                    <View style={styles.section_col_l} >
                        <View style={styles.row} >
                            <View style={styles.column} >
                                <Text style={styles.text_fix}>To:</Text>
                            </View>
                            <View style={styles.column} >
                                <Text style={styles.text} >{deliveryTitle}</Text>
                                <Text style={styles.text} >{deliveryContact}</Text>
                                <Text style={styles.text} >{deliveryAddress}</Text>
                                { deliveryApartmen && <Text style={styles.text} >{deliveryApartmen}</Text> }
                                <Text style={styles.text} >{deliveryPostal}</Text>
                                <Text style={styles.text} >{deliveryCity}</Text>
                                <Text style={styles.text} >{deliveryProvince}</Text>
                                <Text style={styles.text} >{deliveryCountry}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.section_col_r} >
                        <Image style={styles.barcode} src={barcode} />
                        <Text style={styles.text} >{orderId}</Text>
                    </View>
                </View>

                {/* Section details */}
                <View style={styles.body_row}>
                    <View style={styles.section_col_l} >
                        <Text style={styles.text} >Package weight: {totalWeight} {weightUnit && weightUnit }</Text>
                        <Text style={styles.text} >Ship date: {shipDate}</Text>
                    </View>
                    <View style={styles.section_col_r} >
                        <Text style={styles.text} >Package: {packageCount}</Text>
                    </View>
                </View>
            </View>

            {/* Section footer */}
            <View style={styles.footer}>
                <Text style={styles.text} >GO LOGISTICS</Text>
                <Image style={styles.barcode} src={barcode} />
                <Text style={styles.text} >{orderId}</Text>
            </View>
        </View>

    )
}


export default PdfOrder