import React from 'react'
import {
    Dialog, DialogContent, useTheme,
    IconButton, makeStyles, Box, Typography, Button
} from "@material-ui/core"
import Scanner from './Scanner'

const BarcodeScanner = ({open, setOpen, onSuccess}) => {

    const theme = useTheme()

    const [code, setCode] = React.useState("")

    const [_open,_setOpen] = React.useState(true)

    React.useEffect(()=>{
        _setOpen(open)
        setCode("")
    },[open])

    const handleDetect = (result) => {
        const { codeResult } = result
        console.log(result)
        setCode(codeResult.code)
    }

    const handleCancel = () => {
        if(setOpen)setOpen(false)
        _setOpen(false)
    }

    const handleAccept = () => {
        if(onSuccess)onSuccess(code)
        if(setOpen)setOpen(false)
        _setOpen(false)
    }

    return (
        <Dialog
            fullScreen
            open={_open}>
            <Box
                p={3}
                bgcolor={theme.palette.primary.main}
                color={theme.palette.primary.contrastText}
            >
                <Typography align="center" color="inherit" variant="h5" >Scan code</Typography>
            </Box>
            <Scanner onDetected={handleDetect} />
            <Box
                p={3}>
                <Typography align="center" color="inherit" variant="h5" >{code}</Typography>
            </Box>
            <Box
                color={theme.palette.error.main}
                display="flex"
                justifyContent="center"
                p={3}>
                {
                    code && <Button
                        onClick={handleAccept}
                        color="secondary" variant="contained" >acept</Button>
                }
                {
                    !code && <Button
                        onClick={handleCancel}
                        color="primary" variant="contained" >cancel</Button>
                }
            </Box>
        </Dialog>
    )
}


export default BarcodeScanner
