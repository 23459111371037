import React from 'react'

import Pagination from '@material-ui/lab/Pagination';
import {
    FormControl, Select, MenuItem, Container,
    Card, CardContent, Grid, Box,
    Typography, List, ListItem, ListItemAvatar,
    Avatar, ListItemText, LinearProgress, Button, TextField
} from "@material-ui/core"
import { getWarehouseLocations } from '../../middleware/warehouse/WarehouseLocations';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useTheme } from '@material-ui/core';
import RegisterProduct from './RegisterProduct';
import { Link, useHistory } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { pageMakerFilter, pagerMaker, Operators } from '../../utils/paginator';

const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

const LocationItem = ({ title, subtitle, item, onClick }) => {

    return (
        <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <ListItem style={{ cursor: "pointer" }} onClick={() => { onClick(item) }} >
                <ListItemAvatar>
                    <Avatar>
                        <LocationOnIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={title} secondary={subtitle} />
            </ListItem>
        </Grid>
    )
}

const WarehouseInventoryCount = () => {

    const history = useHistory()

    const theme = useTheme()

    //Paged array of array locations
    const [pagedLocations, setPagedLocations] = React.useState([])
    const [page, setPage] = React.useState(1)
    //All locations
    const [locationsHelp, setLocationsHelp] = React.useState([])
    //current display locations
    const [locations, setLocations] = React.useState([])
    //current selected location
    const [location, setLocation] = React.useState()
    React.useEffect(() => {
        (async () => {
            const result = await getWarehouseLocations()
            if (result) {
                setLocationsHelp(result)
                const _list = pagerMaker(result, 20)
                setPagedLocations(_list)
                setLocations(_list[0])
            }
        })()
    }, [])

    React.useEffect(() => {
        if (pagedLocations.length > 0) {
            setLocations(pagedLocations[page - 1])
        }
    }, [page])

    const [products, setProducts] = React.useState([])

    const [open, setOpen] = React.useState(false)

    const handleResult = (data) => {
        console.log(data)
        setProducts([
            ...products,
            ...[data]
        ])
        setOpen(false)
    }


    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const handleSearchChange = ({ target }) => {
        const { value } = target
        if (value) {
            const _locations = locationsHelp.filter(
                item => item.code.toUpperCase().startsWith(value.toUpperCase())
            )
            const _paged = pagerMaker(_locations, 15)
            setPagedLocations(_paged)
            setLocations(_paged[0])
            setPage(1)
        } else {
            const _paged = pagerMaker(locationsHelp, 15)
            setPagedLocations(_paged)
            setLocations(_paged[0])
            setPage(1)
        }
    }

    const handlePrevLocation = () => {
        const _item = locationsHelp.find(item => {
            const item_pos = parseInt(item.code.replace(/\D/g, ""))
            const location_pos = parseInt(location.code.replace(/\D/g, ""))



            return (item.name === location.name) && location_pos - 1 === item_pos
        })

        if (_item) {
            setLocation(_item)
        }
    }

    const handleNextLocation = () => {

        const _item = locationsHelp.find(item => {
            const item_pos = parseInt(item.code.replace(/\D/g, ""))
            const location_pos = parseInt(location.code.replace(/\D/g, ""))



            return (item.name === location.name) && location_pos + 1 === item_pos
        })

        if (_item) {
            setLocation(_item)
        }
    }

    return (
        <Container>
            <Card>
                {/* Places selector */}
                <CardContent>
                    <Grid container>
                        {
                            !location ?
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography color="inherit" variant="h6" >Select location:</Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography>Search:</Typography>
                                            <form style={{ display: "flex" }} onSubmit={handleSubmit}>
                                                <TextField
                                                    fullWidth
                                                    onChange={handleSearchChange}
                                                    size="small"
                                                    name="search"
                                                    autoFocus
                                                    variant="outlined"
                                                />
                                                <Button type="sumbit" variant="contained" color="secondary" >Search</Button>
                                            </form>
                                        </Grid>

                                        {/* <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", alignItems: "center" }} >
                                        <Typography> Line: </Typography>
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Select  >
                                                <MenuItem value={"ALL"}>All</MenuItem>
                                                {
                                                    letters.map((item, index) => (
                                                        <MenuItem value={item.toUpperCase()}>Line {item.toUpperCase()}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid> */}
                                    </Grid>
                                    {
                                        locations ?
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <List title="Locations" >
                                                        <Grid container>
                                                            {
                                                                locations.map((item, index) => {
                                                                    return (
                                                                        <LocationItem
                                                                            id={item.id}
                                                                            title={item.name}
                                                                            subtitle={`${item?.code}`}
                                                                            item={item}
                                                                            onClick={(_item) => setLocation(_item)}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </List>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box p={3} display="flex" justifyContent="center" >
                                                        <Pagination page={page} count={pagedLocations.length} variant="outlined" shape="rounded"
                                                            onChange={(e, value) => {
                                                                setPage(value)
                                                            }} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            : <LinearProgress color="secondary" />
                                    }
                                </Grid>
                                :
                                <Grid item xs={12} >
                                    <Box p={3} color="#fff" style={{ background: theme.palette.primary.main }} >
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Typography color="inherit" variant="h4" >
                                                    Selected location
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="secondary" onClick={() => setLocation(false)} >Change</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="secondary" onClick={handlePrevLocation} >Prev</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="secondary" onClick={handleNextLocation} >Next</Button>
                                            </Grid>
                                        </Grid>
                                        <Typography color="inherit" variant="h6" >{location.name}</Typography>
                                        <Typography color="inherit"  >{location.code}</Typography>
                                    </Box>
                                </Grid>
                        }

                    </Grid>
                </CardContent>

                {/* Products scan */}
                {
                    location &&
                    <RegisterProduct location={location} open={open} onClose={() => setOpen(false)} onResult={handleResult} />
                }
                {
                    location &&
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between" flexDirection="row" >
                                    <Typography variant="h5" >Scan products:</Typography>
                                    <Box display="flex" justifyContent="space-between" flexDirection="row"  >
                                        <Button style={{ marginRight: theme.spacing(3) }} onClick={() => setOpen(true)} variant="outlined" color="secondary" >Add Product</Button>
                                        <Button onClick={() => setLocation(false)} variant="contained" color="secondary" >Save</Button>
                                    </Box>
                                </Box>
                            </Grid>
                            {
                                products &&
                                <Grid item xs={12}>
                                    <TableContainer>
                                        <Table style={{ width: "100%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell>Warehouse location</TableCell>
                                                    <TableCell>Order number</TableCell>
                                                    <TableCell>Serial number</TableCell>
                                                    <TableCell>Model number</TableCell>
                                                    <TableCell>Client</TableCell>
                                                    <TableCell>Created</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    products.map((item) => {
                                                        console.log(item)
                                                        return (
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Typography >{item.street_name}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography >{item.location_name}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography >{item.sales_order_no}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography >{item.serial_number}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography >{item.description}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography >{item.first_name}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography >{item.created}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                }

            </Card >

        </Container>
    )
}

export default WarehouseInventoryCount
