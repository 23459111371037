import React, { useState, useEffect, Fragment } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import moment from "moment";
// Componentes
import ModalShow from '../../Components/Global/Modal';
import HistoryItem from '../../Components/Global/HistoryItem';
// IMG
import edit from './../../Assets/Img/Icon_edit.png';
import img_add from './../../Assets/Img/Icon_add.png'
import file from './../../Assets/Img/file_placeholder.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
//API
import {
    APIGO,
    getStation,
    deleteuser,
    UpdateAvatar,
    UpdateLicense,
    getDriversById,
    EditDriverAsAdmin,
    getCicleCheckByRDGA,
    updateDriverDocument,
} from './../../constants/API';
import * as firebase from 'firebase';
import { Checkbox, Container, Dialog, FormControlLabel, FormGroup, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { findAllByDisplayValue } from '@testing-library/react';
import PlacesAutocomplete from '../../Components/Global/PlacesAutocomplete';

import { getCustomerDetails, getService, getServicesActive } from '../../Components/Functions/LabelPdf/LabelFunctions';

const CustomersDetails = () => {

    const history = useHistory();
    const match = useRouteMatch('/customer/details/:id');
    const id = match.params.id;

    const [messageErrors, setmessageErrors] = useState([]);
    const [readOnly, setreadOnly] = useState(true)

    const [formData, setFormData] = useState({
        account: "",
        username: "",
        name: "",
        email: "",
        address: "",
        apartment_number: "",
        phone: "",
        password: "",
        postal_code: "",
    });

    //Stores all available services on system
    const [serviceList, setServiceList] = useState([]);
    //Stores current user active services
    const [currentServices, setCurrentServices] = useState([])//format {id:true|false}
    //Store user services updates, its sent as put data 
    const [services, setservices] = useState([])
    
    const [servicesLoaded, setServicesLoaded] = useState(false)

    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(0);

    const [IsAdmin, setIsAdmin] = useState(false);
    const [isPass, setIsPass] = useState(false)

    // Modal
    const [isDeleted, setIsDeleted] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [modalStaus, setModalStaus] = useState(false);
    const [modalMsg, setModalmsg] = useState("");
    const [modalLink, setModalLink] = useState("");

    const [showPass, setShowPass] = useState(false)
    const [inputsprops, setInputprops] = useState({
        password: false,
        showPassword: false,
    });


    const ModalOpen = () => setModalStaus(true);
    const ModalClose = () => setModalStaus(false);


    /* Load ordered data info */
    const loadUserData = async () => {

        const resp = await getService()
        if (resp.status) {
            setServiceList(resp.results)
            setservices(resp.results)
        } else {
            setServiceList(false)
        }

        const user_details = await getCustomerDetails(id)
        const {
            address, avatar,
            email, full_name, phone,
            rdga, status,
            type, user_name } = user_details;
        let address_data = address.split("-");
        setFormData({
            account: rdga,
            username: user_name,
            province: address_data[1],
            address: address_data[0],
            postal_code: address_data[2],
            apartment_number: address_data[3],
            phone: phone,
            email: email,
            avatar: avatar,
        })

        const currentServices = await getServicesActive(id)
        if (currentServices.status === "success") {
            setServicesLoaded(true)
            setCurrentServices(currentServices.results)
        }

        setLoading(false)

    }

    useEffect(() => {
        loadUserData()
    }, [loading])


    const onServiceActive = (e, sid) => {

        try {
            let checked = e.target.checked
            let s = currentServices.findIndex(s => s.service_id == sid)

            if (s >= 0) {//index found value > 0
                let sactive = currentServices.filter(s => s.service_id != sid)
                setCurrentServices(sactive)

            } else {//not found push it
                setCurrentServices([
                    ...currentServices,
                    { service_id: sid }
                ])
            }

            //Manages services data
            let service_index = services.findIndex(s => s.id === sid )
            let service_temp = services[service_index]
            services[service_index] = {...service_temp, value:checked}
            setservices(services)
            
        } catch (error) {

        }
    }

    const updateForm = (key, value) => {
        setFormData({ ...formData, [key]: value });
    };

    const SubmitUpdate = async (e) => {

        let config = {
            method: "put",
            url: `/users/admin/update?rdga=${formData.account}`,
            data: {
                avatar: "#",
                account: formData.account,
                username: formData.username,
                full_name: formData.username,
                email: formData.email,
                address: `${formData.address}-${formData.province}-${formData.postal_code}-${formData.apartment_number}`,
                phone: formData.phone,
                password: formData.password,
                postal_code: formData.postal_code,
                apartment_number: formData.apartment_number
            },
            headers: {
                "Access-Control-Request-Headers": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
        };

        await APIGO(config)
            .then((res) => {
                const { msg, status } = res.data;
                if (status === "success") {
                    //history.push("/customers")
                } else {
                    if (Array.isArray(msg)) {
                        setmessageErrors(msg)
                    } else {
                        setmessageErrors([{ text: msg }])
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

        let conf = {
            method: "put",
            url: `customers/services?account=${id}`,
            data: {
                services: services,
            },
            headers: {
                "Access-Control-Request-Headers": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            },
        }

        const res = await APIGO(conf)

        history.push('/customers')
    };

    const toggleEdit = () => {
        if (readOnly) {
            setreadOnly(false);
        } else {
            SubmitUpdate();
        }
    }

    const saveElement = (e, status) => {
        e.preventDefault();
        if (status === true) {
            // Save element 
            setIsShow(false);
            setIsConfirm(false);
            ModalClose();
        }
        if (status === false) {
            setIsDeleted(false);
            setIsConfirm(false);
            ModalClose()
        }
    }

    const attemptDelete = (status) => {

        if (status === true) {
            setIsDeleted(true);
            ModalClose()
        }
        if (status === false) {
            setIsDeleted(false);
            setIsConfirm(false)
            ModalClose()
        }
    }

    const deleteElement = (e, status) => {
        e.preventDefault()
        if (status) {
            let config = {
                method: 'delete',
                url: `users/admin/delete/user/?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
            APIGO(config)
                .then(response => {

                    if (response.data.status == "success") {
                        setModalmsg('User was removed successfully');
                        setModalLink('/customers')
                        setIsShow(true);
                        ModalOpen()
                    } else {
                        setModalmsg(response.data.msg);
                        setIsShow(true);
                        setModalmsg('Oops! a problem happened, please try again.');
                        ModalOpen()
                    }
                    setIsDeleted(false);
                    history.push('/customers')
                })
                .catch(function (error) {
                    console.log(error)
                    setModalmsg("Oops! a problem happened, please try again.");
                    setIsShow(true);
                    ModalOpen()
                })
        } else {
            setIsDeleted(false);
        }
    }

    const modalGoTo = (e, link) => {
        e.preventDefault();
        ModalClose();
        if (link) {
            history.push(link);
        }
    };
    const chackDate = (e) => {

    }

    const onDelete = () => {
        ModalOpen()
        setIsShow(false)
        setIsDeleted(true)
        setIsConfirm(false)
    }

    return (
        <Fragment>
            <Dialog aria-labelledby="customized-dialog-title" open={isDeleted}>
                <ModalShow
                    title=" Are you sure to delete? "
                    confirmDelete={true}
                    Delete={deleteElement}
                />
            </Dialog>
            <Grid container alignItems="center" justify="center" >
                <Grid item >
                    <Box id="go_boxContainer_customerDetails" p={6} className="box_content" borderRadius={16} boxShadow={3}>
                        <Container maxWidth="sm" onSubmit={(e) => SubmitUpdate(e)}>
                            <Grid container>
                                <Grid item>
                                    <Box>
                                        <Button onClick={() => history.push("/customers")} >Back</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <TextField InputProps={{
                                            readOnly: readOnly,
                                        }}
                                            fullWidth
                                            required
                                            type="text"
                                            id="user_account"
                                            variant="outlined"
                                            placeholder="Account"
                                            label="Account"
                                            value={formData.account}
                                            autoComplete="off"
                                            onChange={(e) => updateForm("account", e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <TextField InputProps={{
                                            readOnly: readOnly,
                                        }}
                                            fullWidth
                                            required
                                            type="text"
                                            id="user_name"
                                            variant="outlined"
                                            placeholder="User name"
                                            label="User name"
                                            value={formData.username}
                                            autoComplete="off"
                                            onChange={(e) => updateForm("username", e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box py={1}>
                                        <TextField InputProps={{
                                            readOnly: readOnly,
                                        }}
                                            fullWidth
                                            required
                                            type="phone"
                                            id="user_phone"
                                            variant="outlined"
                                            placeholder="Telephone number"
                                            label="Telephone number"
                                            value={formData.phone}
                                            autoComplete="off"
                                            onChange={(e) => updateForm("phone", e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={5}>
                                    <Box py={1}>
                                        <TextField InputProps={{
                                            readOnly: readOnly,
                                        }}
                                            fullWidth
                                            required
                                            type="email"
                                            id="user_mail"
                                            variant="outlined"
                                            placeholder="E-mail"
                                            label="E-mail"
                                            value={formData.email}
                                            autoComplete="off"
                                            onChange={(e) => updateForm("email", e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <TextField InputProps={{
                                            readOnly: readOnly,
                                        }}
                                            fullWidth

                                            id="Password"
                                            type="password"
                                            variant="outlined"
                                            placeholder="Password"
                                            label="Password"
                                            value={formData.password}
                                            onChange={(e) => updateForm("password", e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <PlacesAutocomplete
                                            inValue={formData.address}
                                            handleSelected={(place, details) => {


                                                setFormData({
                                                    ...formData,
                                                    "postal_code": details ? (details.postal_code ? details.postal_code.long_name : "") : "",
                                                    "province": details ? (details.locality ? details.locality.long_name : "") : "",
                                                    "address": place?.description
                                                })

                                            }} >
                                            <TextField
                                                fullWidth
                                                required
                                                type="text"
                                                id="user_address"
                                                variant="outlined"
                                                placeholder="Address"
                                                value={formData.address}
                                                InputProps={{
                                                    readOnly: readOnly,
                                                }}
                                                autoComplete="off"
                                            />
                                        </PlacesAutocomplete>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <TextField
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            fullWidth
                                            required
                                            type="text"
                                            id="apartment_number"
                                            variant="outlined"
                                            placeholder="Apartment / Number Unit"
                                            value={formData.apartment_number}
                                            autoComplete="off"
                                            onChange={(e) => updateForm("apartment_number", e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                
                                <Grid item xs={5}>
                                    <Box py={1}>
                                        <TextField
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            fullWidth
                                            required
                                            type="text"
                                            id="postal_code"
                                            variant="outlined"
                                            placeholder="Postal code"
                                            value={formData.postal_code}
                                            autoComplete="off"
                                            onChange={(e) => updateForm("postal_code", e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <TextField
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            fullWidth
                                            required
                                            type="text"
                                            id="province"
                                            variant="outlined"
                                            placeholder="Province"
                                            value={formData.province}
                                            autoComplete="off"
                                            onChange={(e) => updateForm("province", e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <FormGroup>
                                    <Grid container>
                                        {!loading 
                                            ? serviceList.map(service => {
                                                let checked = false
                                                try {
                                                    checked = currentServices.find(ite => ite.service_id === service.id)
                                                } catch (error) { }
                                                return (
                                                    <Grid item xs={6} md={4} key={service.id} >
                                                        <FormControlLabel
                                                            label={service.service}
                                                            className="text_grey"
                                                            control={
                                                                <Checkbox
                                                                    name={service.id}
                                                                    color="default"
                                                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                                    checked={checked}
                                                                    onChange={e => { if(!readOnly) onServiceActive(e, service.id); }}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                )

                                            }

                                            )
                                            : "No services availity"
                                        }
                                    </Grid>
                                </FormGroup>


                                <Grid container justify="space-between" >
                                    <Grid item xs={4}>
                                        <Button

                                            className="Go_btn-primary"
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => attemptDelete(true)}
                                        >
                                            Delete
                                    </Button>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            onClick={toggleEdit}
                                            type="submit"
                                            className="Go_btn-primary"
                                            variant="outlined"
                                            fullWidth
                                        >
                                            {readOnly ? "Edit" : "Update"}
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}
export default CustomersDetails