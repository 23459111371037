import React, { useState, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
// Componentes
import ModalShow from '../Components/Global/Modal';
import IssueItem from '../Components/Global/IssueItem';
import AccidentItem from '../Components/Global/AccidentItem';
import AccidentNotes from '../Components/Global/AccidentNotes';
// IMG

import img_add from './../Assets/Img/Icon_add.png';
import vehicle_O from './../Assets/Img/icon-O.png';
import vehicle_R from './../Assets/Img/icon-R.png';
import vehicle_L from './../Assets/Img/icon-L.png';

// import file from './../Assets/Img/file_placeholder.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
//API
//API
import * as firebase from 'firebase';
import {
    APIGO,
    getissues,
    EditVehicle,
    updateStation,
    deleteVehicle,
    GetVehicleNote,
    getVehiclesById,
    createVehicleNote,
    getStationModelVendor,
    updateDocumentsVehicles,
} from './../constants/API';
import { Hidden, Tooltip } from '@material-ui/core';
const file = 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Ffile_placeholder.png?alt=media&token=339e21bf-9ffe-4bd5-ba81-f1db5b3cf273';
const notAvailable = 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25';
const VehiclesDetails = () => {
    
    const history = useHistory();
    const match = useRouteMatch('/vehicles/details/:id');
    const id = match.params.id;

    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataissues, setDataissues] = useState([]);
    const [stationsData, setStationsData] = useState([]);
    const [modelData, setModelData] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [notesData, setNotesData] = useState([]);
    const [notes, setNotes] = useState('');
    const [licenseChange, setlicenseChange] = useState(false);

    const [isIssues, setisIssues] = useState(false);
    const [isChanges, setIsChanges] = useState(false);
    const [isNotes, setIsNotes] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [v_ID, setV_ID] = useState('');
    const [fleet_number, setFleet_number] = useState('');
    const [v_Licens, setV_Licens] = useState('');
    const [geoTabNumber, setGeoTabNumber] = useState('');
    const [stationID, setStationID] = useState('');
    const [VIN, setVIN] = useState('');
    const [ChangeOwnership, setChangeOwnership] = useState(false);
    const [ChangeInsurance, setChangeInsurance] = useState(false);
    const [ownership, setOwnership] = useState(notAvailable);
    const [previwOwnershipDocd, setpreviwOwnershipDocd] = useState('');
    const [insurance, setInsurance] = useState(notAvailable);
    const [previwInsuranceDocd, setPreviwInsuranceDocd] = useState('');
    const [isDocumentChange, setIsDocumentChange] = useState(false)
    const [isStation, setIsStation] = useState(false)
    const [idDocumentsInsuranse, setIsDocumentsInsuranse] = useState(false);
    const [idDocumentsOwnership, setIsDocumentsOwnership] = useState(false);

    const [Year, setYear] = useState('');
    const [Make, setMake] = useState('');
    const [Model, setModel] = useState('');
    const [Series, setSeries] = useState('');
    const [statusV, setStatus] = useState('');

    const [vendor, setVendor] = useState('');
    const [Reg_Province, setReg_Province] = useState('');
    const [Reg_Exp_Date, setReg_Exp_Date] = useState('');
    const [Delivery_Date, setDelivery_Date] = useState('');
    const [Fleet_V_Status, setFleet_V_Status] = useState('');
    const [Lease_Type, setLease_Type] = useState('');
    const [Lease_Term, setLease_Term] = useState('');
    const [Garage_Address, setGarage_Address] = useState('');
    const [Garage_City, setGarage_City] = useState('');
    const [Garage_Province, setGarage_Province] = useState('');
    const [Garage_Postal_Code, setGarage_Postal_Code] = useState('');
    const [Pooled_Vehicle, setPooled_Vehicle] = useState('');
    const [_error, set_error] = useState( false )
    const [isError, setIsErrors] = useState([{
        v_ID: false,
        v_Licens: false,
        geo_tab_number: false,
        stationID: false,
        VIN: false,
        Year: false,
        Make: false,
        Registration_Province: false,
        Registration_Exp_Date: false,
        Delivery_Date: false,
        Model: false,
        Series: false,
        Cust_Num: false,
        Lease_Type: false,
        Lease_Term: false,
        Garage_Street_Address: false,
        Garage_City: false,
        Garage_Province: false,
        Garage_Postal_Code: false,
        Pooled_Vehicle: false,
        Complete: false,
        ownership: false,
        insurance: false,
        notes: false,
    }]);
    // Modal
    const [isDeleted, setIsDeleted] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [modalStaus, setModalStaus] = useState(false);
    const [modalMsg, setModalmsg] = useState("");
    const [modalLink, setModalLink] = useState("");

    const ModalOpen = () => {
        setModalStaus(true);
    };
    const ModalClose = () => {
        setModalStaus(false);
    };
    const saveElement = (e, status) => {
        e.preventDefault();
        setModalmsg('')
        if (status === true) {
            // Save element 
            setIsConfirm(false);
            ModalClose();
            if (isStation) {
                setIsStation(false)
                let config = {
                    method: 'put',
                    url: updateStation + id,
                    headers: {
                        'Access-Control-Request-Headers': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('Token')}`
                    },
                    data: {
                        station_id: stationID,
                    }
                }
                APIGO(config)
                    .then(response => {
                        if (response.data.status === "success") {
                            setModalmsg(" Information was updated ");
                            // setModalLink('/vehicles')
                            setRefresh(refresh + 1);
                            setIsStation(false);
                            setIsShow(true);
                            ModalOpen();
                        } else if (response.data.status === "error") {
                            setModalmsg(response.data.msg);
                            setIsShow(true);
                            ModalOpen();
                            setModalLink('')
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                        setModalmsg("There is no information to change.");
                        setIsShow(true);
                        ModalOpen()
                    })
            }
            if (isDocumentChange) {
                setIsDocumentChange(false)
                const docOwnership = ChangeOwnership ? document.getElementById('ownershipimg').src : ownership;
                const docInsurance = ChangeInsurance ? document.getElementById('insuranceimg').src : insurance;
                let config = {
                    method: 'put',
                    url: `${updateDocumentsVehicles}${id}`,
                    headers: {
                        'Access-Control-Request-Headers': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('Token')}`
                    },
                    data: {
                        ownership: docOwnership,
                        insurance: docInsurance,
                    }
                }
                APIGO(config)
                    .then(response => {
                        if (response.data.status === "success") {
                            setModalmsg(" Information was updated ");
                            // setModalLink('/vehicles')
                            setRefresh(refresh + 1)
                            setIsShow(true);
                            ModalOpen();
                        } else if (response.data.status === "error") {
                            setModalmsg(response.data.msg);
                            setIsShow(true);
                            ModalOpen();
                            setModalLink('')
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                        setModalmsg("There is no information to change.");
                        setIsShow(true);
                        ModalOpen()
                    })
            }
            if (isNotes) {
                setIsNotes(false)
                let config = {
                    method: 'post',
                    url: createVehicleNote,
                    headers: {
                        'Access-Control-Request-Headers': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('Token')}`
                    },
                    data: {
                        notes: notes,
                        id_vehicle: id,
                    }
                }
                APIGO(config)
                    .then(response => {
                        console.log(response);
                        if (response.data.status === "success") {
                            setModalmsg(" Note was created ");
                            setNotes('')
                            setRefresh(refresh + 1);
                            setIsStation(false);
                            setIsShow(true);
                            ModalOpen();
                        } else if (response.data.status === "error") {
                            setModalmsg(response.data.msg);
                            setIsShow(true);
                            ModalOpen();
                            setModalLink('')
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                        // setModalmsg( "There is no information to change." );
                        // setIsShow( true );
                        // ModalOpen()
                    })
            }
            if (isAdmin) {
                const docOwnership = ChangeOwnership ? document.getElementById('ownershipimg').src : ownership;
                const docInsurance = ChangeInsurance ? document.getElementById('insuranceimg').src : insurance;
                const _model = ( Model === "" || Model === "undefined" || Model === "null" ) ? 16 : Model;
                const _vendor = ( vendor === "" || vendor === "undefined" || vendor === "null" ) ? 1 : vendor;
                const _series = ( Series === "" || Series === "undefined" || Series === "null" ) ? '-' : Series;
                const _leaseType = ( Lease_Type === "" || Lease_Type === "undefined" || Lease_Type === "null" ) ? '-' : Lease_Type;
                const _leaseTerm = ( Lease_Term === "" || Lease_Term === "undefined" || Lease_Term === "null" ) ? '-' : Lease_Term;
                const _pooledVehicle = ( Pooled_Vehicle === "" || Pooled_Vehicle === "undefined" || Pooled_Vehicle === "null" ) ? '-' : Pooled_Vehicle;
                const _garageProvince = ( Garage_Province === "" || Garage_Province === "undefined" || Garage_Province === "null" ) ? '-' : Garage_Province;
                const _garageAddress = ( Garage_Address === "" || Garage_Address === "undefined" || Garage_Address === "null" ) ? '-' : Garage_Address;
                const _garageCity = ( Garage_City === "" || Garage_City === "undefined" || Garage_City === "null" ) ? '-' : Garage_City;
                const _garagePostalCode = ( Garage_Postal_Code === "" || Garage_Postal_Code === "undefined" || Garage_Postal_Code === "null" ) ? '-' : Garage_Postal_Code;
                const _deliveryDate = ( Delivery_Date === "" || Delivery_Date === "undefined" || Delivery_Date === "null" ) ? '2021-01-01' : Delivery_Date;
                const _regExpDate = ( Reg_Exp_Date === "" || Reg_Exp_Date === "undefined" || Reg_Exp_Date === "null" ) ? '2021-01-01' : Reg_Exp_Date;
                const _reg_Province = ( Reg_Province === "" || Reg_Province === "undefined" || Reg_Province === "null" ) ? '-' : Reg_Province;
                const _make = ( Make === "" || Make === "undefined" || Make === "null" ) ? '-' : Make;
                const _year = ( Year === "" || Year === "undefined" || Year === "null" ) ? '0000' : Year;
                const _geoTabNumber = ( geoTabNumber === "" || geoTabNumber === "undefined" || geoTabNumber === "null" ) ? '-' : geoTabNumber;
                
                setIsNotes(false)
                if (parseInt(statusV) === parseInt(4)) {
                    setStationID(18)
                }
                if (parseInt(stationID) === parseInt(18)) {
                    setStatus(4)
                }
                if (Model === "") {  
                    setModel( 16 ) 
                } else if (Series === "") { 
                    setSeries( '000' )
                } else if (Lease_Type === "") {  
                    setLease_Type( '000' )
                } else if (Lease_Term === "") { 
                    setLease_Term( '000' ) 
                }

                let config = {
                    method: 'put',
                    url: EditVehicle,
                    headers: {
                        'Access-Control-Request-Headers': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('Token')}`
                    },
                    data: {
                        vehicle_id: v_ID,
                        fleetnumber: fleet_number,
                        licence_plate: licenseChange ? v_Licens : '',
                        status: statusV,
                        ownership_doc: docOwnership,
                        insurance_doc: docInsurance,
                        station_id: stationID,
                        vendor: _vendor,
                        model: _model,
                        geo_tab_number: _geoTabNumber,
                        vin_number: VIN,
                        ownership: ownership,
                        insurance: insurance,
                        year: _year,
                        brand: _make,
                        delivery_date: _deliveryDate,
                        series: _series,
                        lease_type: _leaseType,
                        lease_term: _leaseTerm,
                        garage_city: _garageCity,
                        pooled_vehicle: _pooledVehicle,
                        garage_province: _garageProvince,
                        registration_province: _reg_Province,
                        registration_exp_date: _regExpDate,
                        garage_street_address: _garageAddress,
                        garage_postal_code: _garagePostalCode,
                    }
                }
                console.log( config );
                APIGO(config)
                    .then(response => {
                        if (response.data.status === "success") {
                            // console.log( response.data );
                            setModalmsg("The vehicle information was updated ");
                            setRefresh(refresh + 1);
                            setIsStation(false);
                            setIsShow(true);
                            ModalOpen();
                        } else if (response.data.status === "error") {
                            // console.log( response.data );
                            // setModalmsg(response.data.msg);
                            // setIsShow(true);
                            // setModalLink('')
                            ModalOpen();
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                        // setModalmsg( "There is no information to change." );
                        // setIsShow( true );
                        // ModalOpen()
                    })
            }
        }
        if (status === false) {
            ModalClose()
        }

    }
    const deleteElement = (e, status) => {
        e.preventDefault();
        setIsConfirm(false);
        if (status === true) {
            // deleted element 
            setIsDeleted(false);
            ModalClose()
            let config = {
                method: 'delete',
                url: deleteVehicle + id,
                headers: {
                    'Access-Control-Request-Headers': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('Token')}`
                }
            }
            APIGO(config)
                .then(response => {
                    if (response.data.status === "success") {
                        setModalmsg('Vehicles was removed successfully');
                        setModalLink('/vehicles')
                        setIsShow(true);
                        ModalOpen()
                    } else {
                        setModalmsg(response.data.msg);
                        setIsShow(true);
                        ModalOpen()
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    setModalmsg("Oops! a problem happened, please try again.");
                    setIsShow(true);
                    ModalOpen()
                })
        }
        else {
            setIsDeleted(false);
            ModalClose()
        }
    }
    const modalGoTo = (e, link) => {
        e.preventDefault();
        setIsShow(false)
        ModalClose();
        if (link) {
            history.push(link);
        }
    };
    const getData = async () => {
        var configDataDrop = {
            method: 'get',
            url: getStationModelVendor,
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        }
        try {
            const respDrop = await APIGO( configDataDrop )
            setStationsData(respDrop.data.station)
            setModelData(respDrop.data.models)
            setVendorData(respDrop.data.vendor)
        } catch (error) {
            console.log(error)
        }
        var configNotes = {
            method: 'get',
            url: `${GetVehicleNote}${id}`,
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        }
        try {
            const respNotes = await APIGO(configNotes)
            if (respNotes.data.status === "error") {
                setNotesData(null)
            }
            else {
                setNotesData(respNotes.data)
            }
        } catch (error) {
            console.log(error)
        }
        var configVehicle = {
            method: 'get',
            url: `${getVehiclesById}${id}`,
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        }
        APIGO(configVehicle)
            .then(response => {
                console.log(response);
                if (response.data.msg) {
                    setModalmsg(response.data.msg);
                    setIsShow(true);
                    ModalOpen()
                } else {
                    let data = response.data[0];
                    setData(data)
                    setStatus(`${data.status}`)
                    setV_ID(data.v_id)
                    setFleet_number((data.fleet_number) ? data.fleet_number : '')
                    setV_Licens((data.licence_plate) ? data.licence_plate : '' )
                    setGeoTabNumber((data.details.Specific_details.geo_tab_number) ? data.details.Specific_details.geo_tab_number : '' )
                    setStationID((data.station_id) ? data.station_id : '' )
                    setVIN((data.details.Specific_details.vin_number) ? data.details.Specific_details.vin_number : '')
                    setYear((data.details.Particular_Details.Year) ? data.details.Particular_Details.Year : '' )
                    setMake((data.brand) ? data.brand : '')
                    setSeries((data.details.Particular_Details.Series) ? data.details.Particular_Details.Series : '')
                    setVendor((data.details.Lease_Details.Vendor) ? data.details.Lease_Details.Vendor : 1 )
                    setReg_Province((data.details.Particular_Details.Registration_Province) ? data.details.Particular_Details.Registration_Province : '')
                    setReg_Exp_Date((data.details.Particular_Details.Registration_Exp_Date) ? data.details.Particular_Details.Registration_Exp_Date : '' )
                    setDelivery_Date((data.details.Particular_Details.Delivery_Date) ? data.details.Particular_Details.Delivery_Date : '' )
                    setFleet_V_Status((data.details.Lease_Details.Fleet_Vehicle_Status) ? data.details.Lease_Details.Fleet_Vehicle_Status : '' )
                    setLease_Type((data.details.Lease_Details.Lease_Type) ? data.details.Lease_Details.Lease_Type : '' )
                    setLease_Term((data.details.Lease_Details.Lease_Term) ? data.details.Lease_Details.Lease_Term : '')
                    setGarage_Address((data.details.Garage_Details.Garage_Street_Address) ? data.details.Garage_Details.Garage_Street_Address : '')
                    setGarage_City((data.details.Garage_Details.Garage_City) ? data.details.Garage_Details.Garage_City : '')
                    setGarage_Province((data.details.Garage_Details.Garage_Providence) ? data.details.Garage_Details.Garage_Providence : '')
                    setGarage_Postal_Code((data.details.Garage_Details.Garage_Postal_Code) ? data.details.Garage_Details.Garage_Postal_Code : '')
                    setPooled_Vehicle((data.details.Garage_Details.Pooled_Vehicle) ? data.details.Garage_Details.Pooled_Vehicle : '')
                    
                    if ( !isNaN(data.details.Particular_Details.Model) ) {
                        setModel((data.details.Particular_Details.Model) ? data.details.Particular_Details.Model : 16 )
                    } else {
                        modelData.forEach(model => {
                            if( model.model == data.details.Particular_Details.Model ) {
                                setModel( model.id )
                            }
                        })
                    }
                    if (!data.pictures.ownership_doc.includes('golosuite.com/vehicles') || !data.pictures.ownership_doc.includes('data:image/png')) {
                        setOwnership(data.pictures.ownership_doc)
                    } else {
                        setOwnership(notAvailable)
                    }
                    if (!data.pictures.insurance_doc.includes('golosuite.com/vehicles') || !data.pictures.insurance_doc.includes('data:image/png')) {
                        setInsurance(data.pictures.insurance_doc)
                    } else {
                        setInsurance(notAvailable)
                    }
                }
            })
            .catch(function (error) {
                console.log(error)
                //se quita el mensaje de no informacion
                /*setModalmsg("There is no information.");
                setIsShow(true);
                ModalOpen()*/
            })
    }    
    useEffect(() => {
        getData()
        getIssues()
    }, [refresh]);

    const getIssues = () => {
        var config = {
            method: 'get',
            url: `${getissues}${id}`,
            headers: {
                'Access-Control-Request-Headers': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('Token')}`
            }
        }
        APIGO(config)
            .then(response => {
                if (response.data.status === "error") {
                    setisIssues(false)
                    setModalmsg("Issues dosen't have information");
                    setIsShow(true);
                    ModalOpen()
                }
                else {
                    if (response.data.msg === "No issues to show") {
                        setDataissues(false)
                    }
                    else {
                        setDataissues(response.data)
                    }
                }
            })
            .catch(function (error) {
                console.log(error)
                setModalmsg("There is no information.");
                setIsShow(true);
                ModalOpen()
            })
    }
    const handleForm = e => {
        e.preventDefault();

        if (!isChanges) { setIsErrors({ stationID: true }) }
        else {
            setIsShow(false)
            setIsConfirm(true)
            setIsStation(true)
            ModalOpen()
        }

        if (!idDocumentsOwnership) { setIsErrors({ ownership: true }) }
        else if (!idDocumentsInsuranse) { setIsErrors({ insurance: true }) }
        else {
            setIsShow(false)
            setIsConfirm(true)
            setIsDocumentChange(true)
            ModalOpen()
        }

    }
    const handleFormAdmin = e => {
        e.preventDefault();
        if (v_ID === "") { 
            set_error( true )
            setIsErrors({ v_ID: true }); 
        } else if (v_Licens === "") { 
            set_error( true )
            setIsErrors({ v_Licens: true });
        // } else if (geoTabNumber === "") { 
        //     set_error( true )
        //     setIsErrors({ geo_tab_number: true }); 
        } else if (stationID === "") { 
            set_error( true )
            setIsErrors({ stationID: true }); 
        } else if (VIN === "") { 
            set_error( true )
            setIsErrors({ VIN: true }); 
        } else if (ownership === "") { 
            set_error( true )
            setIsErrors({ ownership: true });
        } else if (insurance === "") { 
            set_error( true )
            setIsErrors({ insurance: true }); 
        } 
        //else if (Year === "") { 
        //     set_error( true )
        //     setIsErrors({ Year: true }); 
        // } else if (Make === "") {
        //     set_error( true )
        //     setIsErrors({ Make: true });
        // } else if (Reg_Province === "") {
        //     set_error( true )
        //     setIsErrors({ Registration_Province: true }); 
        // } else if (Reg_Exp_Date === "") { 
        //     set_error( true )
        //     setIsErrors({ Registration_Exp_Date: true }); 
        // } else if (Delivery_Date === "") { 
        //     set_error( true )
        //     setIsErrors({ Delivery_Date: true }); 
        // } 
        // else if (Model === "") { 
            // setIsErrors({ Model: true }); 
            // setModel( 16 ) 
        // } else if (Series === "") { 
            // setIsErrors({ Series: true }); 
        //     setSeries( '000' )
        // } else if (Lease_Type === "") { 
            // setIsErrors({ Lease_Type: true }); 
        //     setLease_Type( '000' )
        // } else if (Lease_Term === "") { 
            // setIsErrors({ Lease_Term: true });
            // setLease_Term( '000' ) 
        // } 
        // else if (Garage_Address === "") { 
        //     set_error( true )
        //     setIsErrors({ Garage_Street_Address: true });
        // } else if (Garage_City === "") { 
        //     set_error( true )
        //     setIsErrors({ Garage_City: true }); 
        // } else if (Garage_Province === "") { 
        //     set_error( true )
        //     setIsErrors({ Garage_Province: true });
        // } else if (Garage_Postal_Code === "") { 
        //     set_error( true )
        //     setIsErrors({ Garage_Postal_Code: true });
        // } else if (Pooled_Vehicle === "") {
        //     set_error( true )
        //     setIsErrors({ Pooled_Vehicle: true }); 
        // } 
        // else if (vendor === "") {
        //     setVendor( 1 ); 
        // } 
        
        if( !_error ) {
            setIsErrors('');
            setIsShow(false)
            setIsConfirm(true);
            setIsAdmin(true);
            ModalOpen()
        }

        
    }
    const onDelete = () => {
        ModalOpen()
        setIsShow(false)
        setIsDeleted(true)
    }
    const uploadOwnership = (e) => {
        e.preventDefault();
        setLoading(true)
        setChangeOwnership(true)
        setOwnership(e.target.files[0]);
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/insurance/Img${Date.now()}`);
        upload.put(e.target.files[0]).then(snapshot => {
            upload.getDownloadURL().then(url => {
                const profileImg = document.getElementById("ownershipimg")
                const ownershipPreviw = document.getElementById("ownershipPreviw")
                profileImg.src = url;
                ownershipPreviw.src = "https://img.icons8.com/ios/452/file-preview.png";
                setTimeout(() => setLoading(false), 500);
            })
        })
    }
    const uploadInsurance = (e) => {
        e.preventDefault();
        setLoading(true)
        setChangeInsurance(true)
        setInsurance(e.target.files[0]);
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/insurance/Img${Date.now()}`);
        upload.put(e.target.files[0]).then(snapshot => {
            upload.getDownloadURL().then(url => {
                const profileImg = document.getElementById("insuranceimg")
                const insurancePreviw = document.getElementById("insurancePreviw")
                profileImg.src = url;
                insurancePreviw.src = "https://img.icons8.com/ios/452/file-preview.png";
                setTimeout(() => setLoading(false), 500);
            })
        })
    }
    const handleNotes = (e) => {
        e.preventDefault();
        if (notes === '') { setIsErrors({ notes: true }) }
        else {
            setIsNotes(true)
            setIsShow(false)
            setIsConfirm(true)
            setIsStation(true)
            ModalOpen()
        }
    }

    if (localStorage.getItem('Type') === '1') {
        return (
            <>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        {
                            isDeleted ?
                                <ModalShow
                                    title=" Are you sure to delete? "
                                    confirmDelete={true}
                                    Delete={deleteElement}
                                /> : null
                        }
                        {
                            isConfirm ?
                                <ModalShow
                                    title=" Are you sure you want to save changes at Vehicle? "
                                    confirmOperation={true}
                                    Operation={saveElement}
                                /> : null
                        }
                        {
                            isShow ?
                                <ModalShow
                                    title={modalMsg}
                                    onlyShow={true}
                                    showGoTo={modalGoTo}
                                    goTo={modalLink}
                                /> : null
                        }
                    </Grid>
                </Modal>
                <form onSubmit={e => handleFormAdmin(e)} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={2} className="center_center">
                            <Link to="/vehicles" className="flex No_Decoration">
                                <ArrowBackIosIcon className="Page_title" />
                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                    Go back
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" gutterBottom className="Page_title flex" >
                                Vehicle details &nbsp;
                                {data.status === 1 && <img src={vehicle_O} alt="" />}
                                {data.status === 2 && <img src={vehicle_R} alt="" />}
                                {data.status === 3 && <img src={vehicle_L} alt="" />}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Button variant="outlined" disableElevation fullWidth className="" onClick={onDelete} >DELETE</Button>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Button
                                fullWidth
                                disableElevation
                                variant="contained"
                                id={'SubmitFormVeh'}
                                className="Go_btn-primary"
                                type={loading ? "button" : "submit"}
                            >
                                {loading ? "LOADING..." : "SAVE"}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box p={2}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Specific details<br/>
                                                    <label className="labelText"><small>(All fields marked (*) are required)</small></label>
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="VehicleID" className="labelText"><small>FLEET NUMBER / VEHICLE ID *</small></label>
                                                {
                                                    isError.v_ID ?
                                                        <TextField id="VehicleID" type="text" variant="outlined" fullWidth value={fleet_number} onChange={e => setFleet_number(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="VehicleID" type="text" variant="outlined" fullWidth value={fleet_number} onChange={e => setFleet_number(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="VehicleLicens" className="labelText"><small>LICENSE PLATE *</small></label>
                                                {
                                                    isError.v_Licens ?
                                                        <TextField id="VehicleLicens" type="text" variant="outlined" fullWidth value={v_Licens} onChange={e => { setV_Licens(e.target.value); setlicenseChange(true); }} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="VehicleLicens" type="text" variant="outlined" fullWidth value={v_Licens} onChange={e => { setV_Licens(e.target.value); setlicenseChange(true); }} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Master_Cust_Num" className="labelText"><small>GEO TAB NUMBER *</small></label>
                                                {
                                                    isError.geo_tab_number ?
                                                        <TextField id="Master_Cust_Num" type="text" variant="outlined" fullWidth value={geoTabNumber} onChange={e => setGeoTabNumber(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Master_Cust_Num" type="text" variant="outlined" fullWidth value={geoTabNumber} onChange={e => setGeoTabNumber(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="stationID" className="labelText"><small>STATION ID *</small></label>
                                                <Select labelId="stationID" variant="outlined" value={stationID} onChange={e => { setStationID(e.target.value); setIsChanges(true) }} displayEmpty className="" fullWidth >
                                                    <MenuItem value=""><em>Select</em></MenuItem>
                                                    {
                                                        stationsData.map(item =>
                                                            <MenuItem value={item.st_id} key={item.st_id} >{item.station_id}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                                {
                                                    isError.stationID ?
                                                        <span className="text_error"><small>The station ID is required </small></span> : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="VIN" className="labelText"><small>VIN NUMBER *</small></label>
                                                {
                                                    isError.VIN ?
                                                        <TextField id="VIN" type="text" variant="outlined" fullWidth value={VIN} onChange={e => setVIN(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="VIN" type="text" variant="outlined" fullWidth value={VIN} onChange={e => setVIN(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2} pt={0}>
                                                <span className="labelText"><small>OWNERSHIP *</small></span>
                                                <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        <a href={ownership} target="_blank" rel="noopener noreferrer">
                                                            {
                                                                ownership &&
                                                                    idDocumentsOwnership
                                                                    ? <img src={file} alt="file" className="previw_img-real" id={'ownershipPreviw'} />
                                                                    :   <div style={{color:"grey"}}>
                                                                            <InsertDriveFileIcon src="" alt="" className="previw_img d-none" id={'ownershipimg'} />
                                                                            <p>view uploaded file</p>
                                                                        </div>
                                                                        
                                                            }
                                                        </a>
                                                    </Grid>
                                                    {previwOwnershipDocd && previwOwnershipDocd}
                                                    <img src="" alt="" className="previw_img d-none" id={'ownershipimg'} />
                                                    <input type="file" id="uploadFileOwnership" className="fileUpload" name="fileUpload" onChange={e => { setIsDocumentsOwnership(true); setpreviwOwnershipDocd(e.target.files[0].name); uploadOwnership(e); }} />
                                                    <label htmlFor="uploadFileOwnership" className="text_primary pointer">
                                                        <p className="text_primary center_center"> <img src={img_add} alt="" /> Add file</p>
                                                    </label>
                                                </Grid>
                                                {
                                                    isError.ownership ?
                                                        <span className="text_error"><small> Enter a ownership please </small></span> : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>

                                                    <Box p={2} pt={0}>
                                                        <span className="labelText"><small>INSURANCE EXTERNAL *</small></span>
                                                        <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                            <a href={insurance} target="_blank" rel="noopener noreferrer">
                                                                <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                                    {
                                                                        insurance &&
                                                                            idDocumentsInsuranse
                                                                            ? <img src={file} alt="file" className="previw_img-real" id={'insurancePreviw'} />
                                                                            :    <div style={{color:"grey"}}>
                                                                                    <InsertDriveFileIcon src="" alt="" className="previw_img d-none" id={'ownershipimg'} />
                                                                                    <p>view uploaded file</p>
                                                                                </div>
                                                                    }
                                                                </Grid>
                                                            </a>
                                                            {previwInsuranceDocd && previwInsuranceDocd}
                                                            <img src="" alt="" className="previw_img d-none" id={'insuranceimg'} />
                                                            <input type="file" id="uploadFileInsurance" className="fileUpload" name="fileUpload" onChange={e => { setIsDocumentsInsuranse(true); setPreviwInsuranceDocd(e.target.files[0].name); uploadInsurance(e); }} />
                                                            <label htmlFor="uploadFileInsurance" className="text_primary pointer">
                                                                <p className="text_primary center_center"> <img src={img_add} alt="" /> Add file</p>
                                                            </label>
                                                        </Grid>
                                                        {
                                                            isError.insurance ?
                                                                <span className="text_error"><small> Enter a insurance please </small></span> : null
                                                        }
                                                    </Box>
                                                
                                               {/* <Grid item xs={12} md={6}>
                                                    <Box p={2} pt={0}>
                                                        <span className="labelText"><small>INSURANCE RENTAL</small></span>
                                                        <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                            <a href={insurance} target="_blank" rel="noopener noreferrer">
                                                                <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                                    {
                                                                        insurance &&
                                                                            idDocumentsInsuranse
                                                                            ? <img src={file} alt="file" className="previw_img-real" id={'insurancePreviw'} />
                                                                            :    <div style={{color:"grey"}}>
                                                                                    <InsertDriveFileIcon src="" alt="" className="previw_img d-none" id={'ownershipimg'} />
                                                                                    <p>view uploaded file</p>
                                                                                 </div>
                                                                    }
                                                                </Grid>
                                                            </a>
                                                            {previwInsuranceDocd && previwInsuranceDocd}
                                                            <img src="" alt="" className="previw_img d-none" id={'insuranceimg'} />
                                                            {/* <input type="file" id="uploadFileInsurance" className="fileUpload" name="fileUpload" onChange={e => { setIsDocumentsInsuranse(true); setPreviwInsuranceDocd(e.target.files[0].name); uploadInsurance(e); }} />
                                                            <label htmlFor="uploadFileInsurance" className="text_primary pointer">
                                                                <p className="text_primary center_center"> <img src={img_add} alt="" /> Add file</p>
                                                            </label> }
                                                        </Grid>
                                                        {
                                                            isError.insurance ?
                                                                <span className="text_error"><small> Enter a insurance please </small></span> : null
                                                        }
                                                    </Box>
                                                </Grid>*/}
                                            
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Particular details
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Year" className="labelText"><small>YEAR *</small></label>
                                                {
                                                    isError.Year ?
                                                        <TextField id="Year" type="text" variant="outlined" fullWidth value={Year} onChange={e => setYear(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Year" type="text" variant="outlined" fullWidth value={Year} onChange={e => setYear(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Make" className="labelText"><small>BRAND *</small></label>
                                                <Tooltip title="Brand information are linked with model." placement="top">
                                                    {
                                                        isError.Make ?
                                                            <TextField id="Make" type="text" variant="outlined" fullWidth value={Make} onChange={e => setMake(e.target.value)} error helperText="Enter some value please" />
                                                            :
                                                            <TextField id="Make" type="text" variant="outlined" fullWidth value={Make} onChange={e => setMake(e.target.value)} />
                                                    }
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Reg_Province" className="labelText"><small>REGISTRATION PROVINCE *</small></label>
                                                {
                                                    isError.Registration_Province ?
                                                        <TextField id="Reg_Province" type="text" variant="outlined" fullWidth value={Reg_Province} onChange={e => setReg_Province(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Reg_Province" type="text" variant="outlined" fullWidth value={Reg_Province} onChange={e => setReg_Province(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Reg_Province" className="labelText"><small>REGISTRATION EXP DATE *</small></label>
                                                {
                                                    isError.Registration_Exp_Date ?
                                                        <TextField id="Reg_Province" type="date" variant="outlined" fullWidth value={Reg_Exp_Date} onChange={e => setReg_Exp_Date(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Reg_Province" type="date" variant="outlined" fullWidth value={Reg_Exp_Date} onChange={e => setReg_Exp_Date(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Delivery_Date" className="labelText"><small>DELIVERY DATE *</small></label>
                                                {
                                                    isError.Delivery_Date ?
                                                        <TextField id="Delivery_Date" type="date" variant="outlined" fullWidth value={Delivery_Date} onChange={e => setDelivery_Date(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Delivery_Date" type="date" variant="outlined" fullWidth value={Delivery_Date} onChange={e => setDelivery_Date(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Model" className="labelText"><small>MODEL *</small></label>
                                                <Select labelId="Model" variant="outlined" value={Model} onChange={e => setModel(e.target.value)} displayEmpty className="" fullWidth >
                                                    <MenuItem value=""><em>Select</em></MenuItem>
                                                    {
                                                        modelData.map(item => (
                                                            <MenuItem key={item.id} value={item.id} >{item.model}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Series" className="labelText"><small>SERIES *</small></label>
                                                {
                                                    isError.Series ?
                                                        <TextField id="Series" type="text" variant="outlined" fullWidth value={Series} onChange={e => setSeries(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Series" type="text" variant="outlined" fullWidth value={Series} onChange={e => setSeries(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Ownership Details
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Fleet_V_Status" className="labelText"><small>OWNERSHIP</small></label>
                                                <RadioGroup row value={statusV} onChange={e => setStatus(e.target.value)}>
                                                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Owned" />
                                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Rented" />
                                                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Leased" />
                                                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Return" />
                                                </RadioGroup>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="vendor" className="labelText"><small>VENDOR</small></label>
                                                <Select labelId="vendor" variant="outlined" value={vendor} onChange={e => setVendor(e.target.value)} displayEmpty className="" fullWidth >
                                                    <MenuItem value=""><em>Select</em></MenuItem>
                                                    {
                                                        vendorData.map(item => (
                                                            <MenuItem key={item.v_id} value={item.v_id} >{item.v_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Lease_Type" className="labelText"><small>LEASE TYPE *</small></label>
                                                {
                                                    isError.Lease_Type ?
                                                        <TextField id="Lease_Type" type="text" variant="outlined" fullWidth value={Lease_Type} onChange={e => setLease_Type(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Lease_Type" type="text" variant="outlined" fullWidth value={Lease_Type} onChange={e => setLease_Type(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Lease_Term" className="labelText"><small>LEASE TERM *</small></label>
                                                {
                                                    isError.Lease_Term ?
                                                        <TextField id="Lease_Term" type="text" variant="outlined" fullWidth value={Lease_Term} onChange={e => setLease_Term(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Lease_Term" type="text" variant="outlined" fullWidth value={Lease_Term} onChange={e => setLease_Term(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Garage
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Garage_Address" className="labelText"><small>GARAGE STREET ADDRESS *</small></label>
                                                {
                                                    isError.Garage_Street_Address ?
                                                        <TextField id="Garage_Address" type="text" variant="outlined" fullWidth value={Garage_Address} onChange={e => setGarage_Address(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Garage_Address" type="text" variant="outlined" fullWidth value={Garage_Address} onChange={e => setGarage_Address(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Garage_City" className="labelText"><small>GARAGE CITY *</small></label>
                                                {
                                                    isError.Garage_City ?
                                                        <TextField id="Garage_City" type="text" variant="outlined" fullWidth value={Garage_City} onChange={e => setGarage_City(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Garage_City" type="text" variant="outlined" fullWidth value={Garage_City} onChange={e => setGarage_City(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Garage_Province" className="labelText"><small>GARAGE PROVIDENCE *</small></label>
                                                {
                                                    isError.Garage_Province ?
                                                        <TextField id="Garage_Province" type="text" variant="outlined" fullWidth value={Garage_Province} onChange={e => setGarage_Province(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Garage_Province" type="text" variant="outlined" fullWidth value={Garage_Province} onChange={e => setGarage_Province(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Garage_Postal_Code" className="labelText"><small>GARAGE POSTAL CODE *</small></label>
                                                {
                                                    isError.Garage_Postal_Code ?
                                                        <TextField id="Garage_Postal_Code" type="text" variant="outlined" fullWidth value={Garage_Postal_Code} onChange={e => setGarage_Postal_Code(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Garage_Postal_Code" type="text" variant="outlined" fullWidth value={Garage_Postal_Code} onChange={e => setGarage_Postal_Code(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Pooled_Vehicle" className="labelText"><small>POOLED VEHICLE *</small></label>
                                                {
                                                    isError.Pooled_Vehicle ?
                                                        <TextField id="Pooled_Vehicle" type="text" variant="outlined" fullWidth value={Pooled_Vehicle} onChange={e => setPooled_Vehicle(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Pooled_Vehicle" type="text" variant="outlined" fullWidth value={Pooled_Vehicle} onChange={e => setPooled_Vehicle(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Notes
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {
                                            notesData &&
                                            notesData.map(item =>
                                                <Grid item xs={12}>
                                                    <Box px={2} py={0}>
                                                        <AccidentNotes
                                                            key={item.id}
                                                            text={item.note}
                                                            user={item.created_by}
                                                            date={item.created}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0} >
                                                <Typography variant="body1" className="text_grey"><b> ADD NOTE </b></Typography>
                                                <TextareaAutosize aria-label="minimum height" rowsMin={4} className="text_area-style" value={notes} onChange={e => setNotes(e.target.value)} />
                                                {isError.notes && <> <span className="text_error"><small> Enter a note please </small></span> <br /> </>}
                                                <Button variant="contained" disableElevation className="Go_btn-primary" onClick={e => handleNotes(e)} >
                                                    Submit
                                            </Button>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Station Transfer
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Grid container>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <Grid item xs={1}>
                                                            <Typography variant="body1" pl={2} gutterBottom className="text_grey">
                                                                ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Type
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                User
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Previous
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                New
                                                            </Typography>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} >
                                                        {
                                                            dataissues ?
                                                                dataissues.map(item => {
                                                                    if (item.type === 0 && item.details.type === "Station Transfer") {
                                                                        return (
                                                                            <IssueItem
                                                                                key={item.id}
                                                                                id={item.id}
                                                                                date={item.details.date}
                                                                                changeType={item.details.type}
                                                                                status={item.type}
                                                                                user={item.details.user}
                                                                                Previous={item.details.previous_inf}
                                                                                New={item.details.actual_inf}
                                                                                stationData={stationsData}
                                                                                link="Link"
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                <Typography variant="body2" gutterBottom className="Page_title">
                                                                    No information
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Documents changed
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Grid container>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <Grid item xs={1}>
                                                            <Typography variant="body1" pl={2} gutterBottom className="text_grey">
                                                                ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Type
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                User
                                                            </Typography>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} >
                                                        {
                                                            dataissues ?
                                                                dataissues.map(item => {
                                                                    if (item.type === 0 && (item.details.type === "Documents change" || item.details.type === "Change Information")) {
                                                                        return (
                                                                            <IssueItem
                                                                                key={item.id}
                                                                                id={item.id}
                                                                                date={item.details.date}
                                                                                changeType={item.details.type}
                                                                                status={item.type}
                                                                                user={item.details.user}
                                                                                Previous={item.details.previous_inf}
                                                                                New={item.details.actual_inf}
                                                                                stationData={stationsData}
                                                                                link="Link"
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                <Typography variant="body2" gutterBottom className="Page_title">
                                                                    No information
                                                                </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Accidents / Claims
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Grid container>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <Grid item xs={1}>
                                                            <Typography variant="body1" pl={2} gutterBottom className="text_grey">
                                                                Accident ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Driver
                                                            </Typography>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} >
                                                        {
                                                            dataissues ?
                                                                dataissues.map(item => {
                                                                    if (item.type === 1) {
                                                                        return (
                                                                            <AccidentItem
                                                                                key={item.id}
                                                                                id={item.id}
                                                                                date={item.details.date}
                                                                                changeType={item.details.type}
                                                                                status={item.type}
                                                                                user={item.details.user}
                                                                                Previous={item.details.previous_inf}
                                                                                New={item.details.actual_inf}
                                                                                stationData={stationsData}
                                                                                link="Link"
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                <Typography variant="body2" gutterBottom className="Page_title">
                                                                    No information
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }

    if (localStorage.getItem('Type') === '2') {
        return (
            <>
                <Modal
                    open={modalStaus}
                    onClose={ModalClose}
                >
                    <Grid className="modal__Posotion modal__Global">
                        {
                            isDeleted ?
                                <ModalShow
                                    title=" Are you sure to delete? "
                                    confirmDelete={true}
                                    Delete={deleteElement}
                                /> : null
                        }
                        {
                            isConfirm ?
                                <ModalShow
                                    title="Are you sure you want to save changes at Vehicle? "
                                    confirmOperation={true}
                                    Operation={saveElement}
                                /> : null
                        }
                        {
                            isShow ?
                                <ModalShow
                                    title={modalMsg}
                                    onlyShow={true}
                                    showGoTo={modalGoTo}
                                    goTo={modalLink}
                                /> : null
                        }
                    </Grid>
                </Modal>
                <form onSubmit={e => handleForm(e)} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={2} className="center_center">
                            <Link to="/vehicles" className="flex No_Decoration">
                                <ArrowBackIosIcon className="Page_title" />
                                <Typography variant="subtitle1" gutterBottom className="Page_title">
                                    Go back
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" gutterBottom className="Page_title flex" >
                                Vehicle details &nbsp;
                                {data.status === 1 && <img src={vehicle_O} alt="" />}
                                {data.status === 2 && <img src={vehicle_R} alt="" />}
                                {data.status === 3 && <img src={vehicle_L} alt="" />}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            {
                                localStorage.getItem('PV_D') === '1' &&
                                <Button variant="outlined" disableElevation fullWidth className="" onClick={onDelete} >DELETE</Button>
                            }
                        </Grid>
                        <Grid item xs={6} md={2}>
                            {localStorage.getItem('PV_U') === '1' &&
                                <Button
                                    fullWidth
                                    disableElevation
                                    variant="contained"
                                    id={'SubmitFormVeh'}
                                    className="Go_btn-primary"
                                    type={loading ? "button" : "submit"}
                                >
                                    {loading ? "LOADING..." : "SAVE"}
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box p={2}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Specific details<br/>
                                                    <label className="labelText"><small>(All fields marked (*) are required)</small></label>
                                                    <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="VehicleID" className="labelText"><small>Fleet Number / Vehicle ID *</small></label>
                                                {
                                                    isError.v_ID ?
                                                        <TextField id="VehicleID" disabled type="text" variant="outlined" fullWidth value={fleet_number} onChange={e => setFleet_number(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="VehicleID" disabled type="text" variant="outlined" fullWidth value={fleet_number} onChange={e => setFleet_number(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="VehicleLicens" className="labelText"><small>LICENSE PLATE *</small></label>
                                                {
                                                    isError.v_Licens ?
                                                        <TextField id="VehicleLicens" disabled type="text" variant="outlined" fullWidth value={v_Licens} onChange={e => setV_Licens(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="VehicleLicens" disabled type="text" variant="outlined" fullWidth value={v_Licens} onChange={e => setV_Licens(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Master_Cust_Num" className="labelText"><small>GEO TAB NUMBER *</small></label>
                                                {
                                                    isError.geo_tab_number ?
                                                        <TextField id="Master_Cust_Num" disabled type="text" variant="outlined" fullWidth value={geoTabNumber} onChange={e => setGeoTabNumber(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Master_Cust_Num" disabled type="text" variant="outlined" fullWidth value={geoTabNumber} onChange={e => setGeoTabNumber(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="stationID" className="labelText"><small>STATION ID *</small></label>
                                                {
                                                    localStorage.getItem('PV_U') === '1' ?
                                                        <>
                                                            <Select labelId="stationID" variant="outlined" value={stationID} onChange={e => { setStationID(e.target.value); setIsChanges(true) }} displayEmpty className="" fullWidth >
                                                                <MenuItem value=""><em>Select</em></MenuItem>
                                                                {
                                                                    stationsData.map(item =>
                                                                        <MenuItem value={item.st_id} key={item.st_id} >{item.station_id}</MenuItem>
                                                                    )
                                                                }
                                                            </Select>
                                                            {
                                                                isError.stationID ?
                                                                    <span className="text_error"><small>The station ID is required </small></span> : null
                                                            }
                                                        </>
                                                        :
                                                        <Select labelId="stationID" variant="outlined" value={stationID} disabled displayEmpty className="" fullWidth >
                                                            <MenuItem value=""><em>Select</em></MenuItem>
                                                            {
                                                                stationsData.map(item =>
                                                                    <MenuItem value={item.st_id} key={item.st_id} >{item.station_id}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="VIN" className="labelText"><small>VIN NUMBER *</small></label>
                                                {
                                                    isError.VIN ?
                                                        <TextField id="VIN" disabled type="text" variant="outlined" fullWidth value={VIN} onChange={e => setVIN(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="VIN" disabled type="text" variant="outlined" fullWidth value={VIN} onChange={e => setVIN(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2} pt={0}>
                                                <span className="labelText"><small>OWNERSHIP *</small></span>
                                                <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                    <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                        <a href={ownership} target="_blank" rel="noopener noreferrer">
                                                            {
                                                                ownership ?
                                                                    idDocumentsOwnership ?
                                                                        <img src={file} alt="file" className="previw_img" id={'ownershipPreviw'} />
                                                                        :
                                                                        <img src={ownership} alt="file-chek" className="previw_img" />
                                                                    : null
                                                            }
                                                        </a>
                                                    </Grid>
                                                    {previwOwnershipDocd && previwOwnershipDocd}
                                                    <img src="" alt="" className="previw_img d-none" id={'ownershipimg'} />
                                                    {
                                                        localStorage.getItem('PV_U') === '1' ?
                                                            <input type="file" id="uploadFileOwnership" className="fileUpload" name="fileUpload" onChange={e => { setIsDocumentsOwnership(true); setpreviwOwnershipDocd(e.target.files[0].name); uploadOwnership(e); }} />
                                                            :
                                                            <input type="file" id="uploadFileOwnership" className="fileUpload" name="fileUpload" disabled />
                                                    }
                                                    <label htmlFor="uploadFileOwnership" className="text_primary pointer">
                                                        <p className="text_primary center_center"> <img src={img_add} alt="" /> Add file</p>
                                                    </label>
                                                </Grid>
                                                {
                                                    isError.ownership ?
                                                        <span className="text_error"><small> Enter a ownership please </small></span> : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box p={2} pt={0}>
                                                <span className="labelText"><small>INSURANCE *</small></span>
                                                <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                    <a href={insurance} target="_blank" rel="noopener noreferrer">
                                                        <Grid container className="" direction="column" justify="center" alignItems="center" >
                                                            {
                                                                insurance ?
                                                                    idDocumentsInsuranse ?
                                                                        <img src={file} alt="file" className="previw_img-real" id={'insurancePreviw'} />
                                                                        :
                                                                        <img src={insurance} alt="file-chek" className="previw_img-real" />
                                                                    : null
                                                            }
                                                        </Grid>
                                                    </a>
                                                    {previwInsuranceDocd && previwInsuranceDocd}
                                                    <img src="" alt="" className="previw_img d-none" id={'insuranceimg'} />
                                                    {
                                                        localStorage.getItem('PV_U') === '1' ?
                                                            <input type="file" id="uploadFileInsurance" className="fileUpload" name="fileUpload" onChange={e => { setIsDocumentsInsuranse(true); setPreviwInsuranceDocd(e.target.files[0].name); uploadInsurance(e); }} />
                                                            :
                                                            <input type="file" id="uploadFileInsurance" className="fileUpload" name="fileUpload" disabled />
                                                    }
                                                    <label htmlFor="uploadFileInsurance" className="text_primary pointer">
                                                        <p className="text_primary center_center"> <img src={img_add} alt="" /> Add file</p>
                                                    </label>
                                                </Grid>
                                                {
                                                    isError.insurance ?
                                                        <span className="text_error"><small> Enter a insurance please </small></span> : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Particular details
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Year" className="labelText"><small>YEAR *</small></label>
                                                {
                                                    isError.Year ?
                                                        <TextField id="Year" disabled type="text" variant="outlined" fullWidth value={Year} onChange={e => setYear(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Year" disabled type="text" variant="outlined" fullWidth value={Year} onChange={e => setYear(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Make" className="labelText"><small>BRAND *</small></label>
                                                <Tooltip title="Brand information are linked with model." placement="top">
                                                    {
                                                        isError.Make ?
                                                            <TextField id="Make" disabled type="text" variant="outlined" fullWidth value={Make} onChange={e => setMake(e.target.value)} error helperText="Enter some value please" />
                                                            :
                                                            <TextField id="Make" disabled type="text" variant="outlined" fullWidth value={Make} onChange={e => setMake(e.target.value)} />
                                                    }
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Reg_Province" className="labelText"><small>REGISTRATION PROVINCE *</small></label>
                                                {
                                                    isError.Registration_Province ?
                                                        <TextField id="Reg_Province" disabled type="text" variant="outlined" fullWidth value={Reg_Province} onChange={e => setReg_Province(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Reg_Province" disabled type="text" variant="outlined" fullWidth value={Reg_Province} onChange={e => setReg_Province(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Reg_Province" className="labelText"><small>REGISTRATION EXP DATE *</small></label>
                                                {
                                                    isError.Registration_Exp_Date ?
                                                        <TextField id="Reg_Province" disabled type="date" variant="outlined" fullWidth value={Reg_Exp_Date} onChange={e => setReg_Exp_Date(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Reg_Province" disabled type="date" variant="outlined" fullWidth value={Reg_Exp_Date} onChange={e => setReg_Exp_Date(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Delivery_Date" className="labelText"><small>DELIVERY DATE *</small></label>
                                                {
                                                    isError.Delivery_Date ?
                                                        <TextField id="Delivery_Date" disabled type="date" variant="outlined" fullWidth value={Delivery_Date} onChange={e => setDelivery_Date(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Delivery_Date" disabled type="date" variant="outlined" fullWidth value={Delivery_Date} onChange={e => setDelivery_Date(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Model" className="labelText"><small>MODEL *</small></label>
                                                <Select labelId="Model" disabled variant="outlined" value={Model} onChange={e => setModel(e.target.value)} displayEmpty className="" fullWidth >
                                                    <MenuItem value=""><em>Select</em></MenuItem>
                                                    {
                                                        modelData.map(item => (
                                                            <MenuItem key={item.id} value={item.id} >{item.model}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Series" className="labelText"><small>SERIES *</small></label>
                                                {
                                                    isError.Series ?
                                                        <TextField id="Series" type="text" disabled variant="outlined" fullWidth value={Series} onChange={e => setSeries(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Series" type="text" disabled variant="outlined" fullWidth value={Series} onChange={e => setSeries(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Ownership Details
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Fleet_V_Status" className="labelText"><small>OWNERSHIP</small></label>
                                                <RadioGroup row value={statusV} onChange={e => setStatus(e.target.value)} >
                                                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Owned" />
                                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Rented" />
                                                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Leased" />
                                                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Return" />
                                                </RadioGroup>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="vendor" className="labelText"><small>VENDOR</small></label>
                                                <Select labelId="vendor" disabled variant="outlined" value={vendor} onChange={e => setVendor(e.target.value)} displayEmpty className="" fullWidth >
                                                    <MenuItem value=""><em>Select</em></MenuItem>
                                                    {
                                                        vendorData.map(item => (
                                                            <MenuItem key={item.v_id} value={item.v_id} >{item.v_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Lease_Type" className="labelText"><small>LEASE TYPE *</small></label>
                                                {
                                                    isError.Lease_Type ?
                                                        <TextField id="Lease_Type" disabled type="text" variant="outlined" fullWidth value={Lease_Type} onChange={e => setLease_Type(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Lease_Type" disabled type="text" variant="outlined" fullWidth value={Lease_Type} onChange={e => setLease_Type(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Lease_Term" className="labelText"><small>LEASE TERM *</small></label>
                                                {
                                                    isError.Lease_Term ?
                                                        <TextField id="Lease_Term" disabled type="text" variant="outlined" fullWidth value={Lease_Term} onChange={e => setLease_Term(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Lease_Term" disabled type="text" variant="outlined" fullWidth value={Lease_Term} onChange={e => setLease_Term(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Garage
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Garage_Address" className="labelText"><small>GARAGE STREET ADDRESS *</small></label>
                                                {
                                                    isError.Garage_Street_Address ?
                                                        <TextField id="Garage_Address" disabled type="text" variant="outlined" fullWidth value={Garage_Address} onChange={e => setGarage_Address(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Garage_Address" disabled type="text" variant="outlined" fullWidth value={Garage_Address} onChange={e => setGarage_Address(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Garage_City" className="labelText"><small>GARAGE CITY *</small></label>
                                                {
                                                    isError.Garage_City ?
                                                        <TextField id="Garage_City" disabled type="text" variant="outlined" fullWidth value={Garage_City} onChange={e => setGarage_City(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Garage_City" disabled type="text" variant="outlined" fullWidth value={Garage_City} onChange={e => setGarage_City(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Garage_Province" className="labelText"><small>GARAGE PROVIDENCE *</small></label>
                                                {
                                                    isError.Garage_Province ?
                                                        <TextField id="Garage_Province" disabled type="text" variant="outlined" fullWidth value={Garage_Province} onChange={e => setGarage_Province(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Garage_Province" disabled type="text" variant="outlined" fullWidth value={Garage_Province} onChange={e => setGarage_Province(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Garage_Postal_Code" className="labelText"><small>GARAGE POSTAL CODE *</small></label>
                                                {
                                                    isError.Garage_Postal_Code ?
                                                        <TextField id="Garage_Postal_Code" disabled type="text" variant="outlined" fullWidth value={Garage_Postal_Code} onChange={e => setGarage_Postal_Code(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Garage_Postal_Code" disabled type="text" variant="outlined" fullWidth value={Garage_Postal_Code} onChange={e => setGarage_Postal_Code(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} md={2}>
                                            <Box p={2} pt={0}>
                                                <label htmlFor="Pooled_Vehicle" className="labelText"><small>POOLED VEHICLE *</small></label>
                                                {
                                                    isError.Pooled_Vehicle ?
                                                        <TextField id="Pooled_Vehicle" disabled type="text" variant="outlined" fullWidth value={Pooled_Vehicle} onChange={e => setPooled_Vehicle(e.target.value)} error helperText="Enter some value please" />
                                                        :
                                                        <TextField id="Pooled_Vehicle" disabled type="text" variant="outlined" fullWidth value={Pooled_Vehicle} onChange={e => setPooled_Vehicle(e.target.value)} />
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Notes
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {
                                            notesData &&
                                            notesData.map(item =>
                                                <Grid item xs={12}>
                                                    <Box px={2} py={0}>
                                                        <AccidentNotes
                                                            key={item.id}
                                                            text={item.note}
                                                            user={item.created_by}
                                                            date={item.created}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={12}>
                                            <Box px={3} >
                                                <Typography variant="body1" className="text_grey"><b> ADD NOTE </b></Typography>
                                                <TextareaAutosize aria-label="minimum height" rowsMin={4} className="text_area-style" value={notes} onChange={e => setNotes(e.target.value)} />
                                                {
                                                    isError.notes &&
                                                    <> <span className="text_error"><small> Enter a note please </small></span> <br /> </>
                                                }
                                                <Button variant="contained" disableElevation className="Go_btn-primary" onClick={e => handleNotes(e)} >
                                                    Submit
                                            </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={1}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Station Transfer
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box px={3} >
                                                <Grid container>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <Grid item xs={1}>
                                                            <Typography variant="body1" pl={2} gutterBottom className="text_grey">
                                                                ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Type
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                User
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Previous
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                New
                                                            </Typography>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} >
                                                        {
                                                            dataissues ?
                                                                dataissues.map(item => {
                                                                    if (item.type === 0 && item.details.type === "Station Transfer") {
                                                                        return (
                                                                            <IssueItem
                                                                                key={item.id}
                                                                                id={item.id}
                                                                                date={item.details.date}
                                                                                changeType={item.details.type}
                                                                                status={item.type}
                                                                                user={item.details.user}
                                                                                Previous={item.details.previous_inf}
                                                                                New={item.details.actual_inf}
                                                                                stationData={stationsData}
                                                                                link="Link"
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                <Typography variant="body2" gutterBottom className="Page_title">
                                                                    No information
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Documents changed
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box px={3} >
                                                <Grid container>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <Grid item xs={1}>
                                                            <Typography variant="body1" pl={2} gutterBottom className="text_grey">
                                                                ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Type
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                User
                                                            </Typography>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} >
                                                        {
                                                            dataissues ?
                                                                dataissues.map(item => {
                                                                    if (item.type === 0 && (item.details.type === "Documents change" || item.details.type === "Change Information")) {
                                                                        return (
                                                                            <IssueItem
                                                                                key={item.id}
                                                                                id={item.id}
                                                                                date={item.details.date}
                                                                                changeType={item.details.type}
                                                                                status={item.type}
                                                                                user={item.details.user}
                                                                                Previous={item.details.previous_inf}
                                                                                New={item.details.actual_inf}
                                                                                stationData={stationsData}
                                                                                link="Link"
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                <Typography variant="body2" gutterBottom className="Page_title">
                                                                    No information
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                <Typography variant="h6" gutterBottom className="Page_title">
                                                    Accidents / Claims
                                                <hr className="divider_blue" />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box px={3} >
                                                <Grid container>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <Grid item xs={1}>
                                                            <Typography variant="body1" pl={2} gutterBottom className="text_grey">
                                                                Accident ID
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Date
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body1" gutterBottom className="text_grey">
                                                                Driver
                                                            </Typography>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} >
                                                        {
                                                            dataissues ?
                                                                dataissues.map(item => {
                                                                    if (item.type === 1) {
                                                                        return (
                                                                            <AccidentItem
                                                                                key={item.id}
                                                                                id={item.id}
                                                                                date={item.details.date}
                                                                                changeType={item.details.type}
                                                                                status={item.type}
                                                                                user={item.details.user}
                                                                                Previous={item.details.previous_inf}
                                                                                New={item.details.actual_inf}
                                                                                stationData={stationsData}
                                                                                link="Link"
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                <Typography variant="body2" gutterBottom className="Page_title">
                                                                    No information
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }

}
export default VehiclesDetails
