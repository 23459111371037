import React,  { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
// Componentes
import ListElements from '../Components/Global/ListElements';
// IMG
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
//API
import { getAccidents, getAllEstimates, getStation, APIGO } from './../constants/API'; 
import { Button, Paper } from '@material-ui/core';

const Accidents = () => {

    const [ data, setData ] = useState([]);
    const [ info, setInfo ] = useState('');
    
    const [ refresh, setRefresh ]           = useState(0);
    const [ page, setPage ]                 = useState(1);
    const [ filter, setfilter ]             = useState(1);
    const [ estimationGo, setEstimationGo ] = useState(0);
    const [ estimationAssurance, setEstimationAssurance ] = useState(0);
    
    const [ stationsData, setStationsData ] = useState([]);
    const [ stations, setStations ] = useState('');
    
    const [ dateFrom, setDateFrom ] = useState('');
    const [ dateTo, setDateTo ]     = useState('');
    const [ dateMsg, setDateMsg ]   = useState('');
    const [ search, setSearch ]     = useState('');
    const [ covered, setCovered ]   = useState('');

    const [ isData, setIsData ]         = useState( true );
    const [ isCovered, setIsCovered ]   = useState( false );
    const [ isStation, setIsStation ]   = useState( false );
    const [ isSearch, setIsSearch ]     = useState( false );
    const [ isDate, setIsDate ]         = useState( false );

    
    useEffect( () =>{
        
        var config_accident = {
            method: 'get',
            url: getAccidents,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config_accident )
        .then(response => {
            if ( response.data.status === 'error' ) {
                // List for all vehicles
                setData( false );
            }
            if ( response.data.status === 'success' ){
                // Get all data 
                console.log( response.data.results );
                setData( response.data.results );
                setInfo( response.data.info )
            }
        })
        .catch(function (error){
            console.log(error)
        })

        var config = {
            method: 'get',
            url: getAllEstimates,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(config)
        .then(response =>{
            let count_Go = 0;
            let count_Assuer = 0;
            response.data.forEach( index => {
                if ( index.active === 1 && index.covered_by === 0 ) {
                    count_Go = count_Go + index.amount;
                }
                if ( index.active === 1 && index.covered_by === 1 ) {
                    count_Assuer = count_Assuer + index.amount;
                }
            })
            setEstimationGo( count_Go )
            setEstimationAssurance( count_Assuer )
        })
        .catch(function (error){
            console.log(error)
        })

        var configStation = {
            method: 'get',
            url: getStation,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configStation)
        .then(response =>{
            setStationsData( response.data )
        })
        .catch(function (error){
            console.log(error)
        })

    }, [ refresh ] );

    useEffect( () => {
        let config;
        if ( isData ) {
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20`,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        else if ( isCovered ) {
            // station and status
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20&isFilter=true&byCovered=${covered} `,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        else if ( isDate ) {
            // Search and status
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20&isFilter=true&byStrarDate=${dateFrom}&byEndDate=${dateTo}`,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        else if ( isStation ) {
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20&isFilter=true&byStation=${stations}`,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        else if ( isSearch ) {
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20&isFilter=true&search=${search}`,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        else if ( isSearch && isCovered ) {
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20&isFilter=true&byCovered=${covered}&search=${search} `,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        else if ( isSearch && isStation ) {
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20&isFilter=true&byStation=${stations}&search=${search} `,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        else if ( isStation && isCovered ) {
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20&isFilter=true&byCovered=${covered}&byStation=${stations} `,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        else if ( isSearch && isStation && isCovered ) {
            config = {
                method: 'get',
                url: `/accident?page=${page}&limit=20&isFilter=true&byCovered=${covered}&byStation=${stations}&search=${search} `,
                headers: { 
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                }
            }
        }
        APIGO(config)
        .then( response => {
            console.log( response );
            if ( response.data.status === 'error' ) {
                // List for all vehicles
                setData( false );
            }
            if ( response.data.status === 'success' ) {
                // List for all vehicles
                setData( response.data.results );
                setInfo( response.data.info )
            }
            console.log( data );
        })
        .catch(function (error){
            console.log(error)
            setData( false );
        })    
    }, [ page, filter ] );

    const getAllData = () => {
        setIsData  ( true );
        setIsDate  ( false );
        setIsStation( false );
        setIsCovered( false );
        setIsSearch( false );
        // Reset Data for filters
        setStations('');
        setDateFrom('');
        setDateTo('');
        // Load data
        setfilter( filter + 1 );
    }

    const filterbyInsurance = () => {
        if ( isSearch ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( false );
            setIsCovered( true );
            setIsSearch( true );
        }
        else if ( isStation ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( true );
            setIsCovered( true );
            setIsSearch( false );
        }
        else if ( isSearch && isStation ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( true );
            setIsCovered( true );
            setIsSearch( true );
        }
        else {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( false );
            setIsCovered( true );
            setIsSearch( false );
        }
        setCovered( 1 )
        // Reset Data for filters
        setStations('');
        setDateFrom('');
        setDateTo('');
        // Load data
        setfilter( filter + 1 );
    }

    const filterbyGo = () => {
        if ( isSearch ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( false );
            setIsCovered( true );
            setIsSearch( true );
        }
        else if ( isStation ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( true );
            setIsCovered( true );
            setIsSearch( false );
        }
        else if ( isSearch && isStation ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( true );
            setIsCovered( true );
            setIsSearch( true );
        }
        else {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( false );
            setIsCovered( true );
            setIsSearch( false );
        }
        setCovered( 0 )
        // Reset Data for filters
        setStations('');
        setDateFrom('');
        setDateTo('');
        // Load data
        setfilter( filter + 1 );
    }

    const filterByStation = (e) => {
        if ( isSearch ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( true );
            setIsCovered( false );
            setIsSearch( true );
        }
        else if ( isCovered ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( true );
            setIsCovered( true );
            setIsSearch( false );
        }
        else if ( isSearch && isCovered ) {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( true );
            setIsCovered( true );
            setIsSearch( true );
        }
        else {
            setIsData  ( false );
            setIsDate  ( false );
            setIsStation( true );
            setIsCovered( false );
            setIsSearch( false );
        }
        // Station selected
        setStations( e );
        // Reset Data for filters
        setDateFrom('');
        setDateTo('');
        // Load data
        setfilter( filter + 1 );
    }
    const filterByDate = (e) => {
        setIsDate  ( true );
        setIsData  ( false );

        // Reset Data for filters
        if ( dateFrom && dateTo ) {
            setDateMsg('')
            if ( data.length > 0 ) {
                const newList = data.filter( item => { 
                    const dateToCheck = item.details.form.A.fields[0].value;
                    if ( dateFrom <= dateToCheck && dateToCheck <= dateTo ) {
                        return item
                    }
                })
                if ( newList.length <= 0 ) {
                    setData( false )
                }
                else{
                    setData( newList )
                }
            }
        }
        else {
            setDateMsg('*The both date are required')
        }
    }

    const filterList = ( e, search ) => {
        e.preventDefault();
        if ( search === '' ) {
            setIsSearch( false );
            setStations('')
            setfilter( filter + 1 );
        }
        else{
            if ( isStation ) {
                setIsData  ( false );
                setIsDate  ( false );
                setIsStation( true );
                setIsCovered( false );
                setIsSearch( true );
            }
            else if ( isCovered ) {
                setIsData  ( false );
                setIsDate  ( false );
                setIsStation( false );
                setIsCovered( true );
                setIsSearch( true );
            }
            else if ( isStation && isCovered ) {
                setIsData  ( false );
                setIsDate  ( false );
                setIsStation( true );
                setIsCovered( true );
                setIsSearch( true );
            }
            else {
                setIsData  ( false );
                setIsDate  ( false );
                setIsStation( false );
                setIsCovered( false );
                setIsSearch( true );
            }
            setSearch( search )
            setfilter( filter + 1 );
        }
    }

    const ActivePage = ( e, value ) =>{
        setPage( value )
    }

    const NoData = () =>{
        return (
            <Paper elevation={3}> 
                <Box p={5} >
                    <Typography variant="h6" className="text_primary" >
                        There is no Accidents &nbsp;
                        <span
                            className="try_again"
                            onClick={ () => setRefresh( refresh + 1 ) } 
                        >
                            Try again.
                        </span>
                    </Typography>
                </Box>
            </Paper>
        )
    }

    return(
        <Grid container >
            <Grid item xs={12} md={2}>
                <Typography variant="h4" gutterBottom className="Page_title">
                    Accidents
                </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
                <Grid container className="" >
                    <Grid item xs={4} md={6}>
                        <Box pl={1}>
                            Browse from:
                            { dateMsg } 
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Box pl={1}>
                            All stations:
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <Box pl={1}>
                            Drafts:
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box p={1}>
                            <TextField id="to" type="date" variant="outlined" fullWidth value={ dateFrom } onChange={e => { setDateFrom(e.target.value); filterByDate(e); }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box p={1}>
                            <TextField id="from" type="date" variant="outlined" fullWidth value={ dateTo } onChange={e => { setDateTo(e.target.value); filterByDate(e);  } } />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box p={1} >
                            <Select labelId="stationID" variant="outlined" displayEmpty className="" fullWidth value={stations} onChange={e =>{ filterByStation(e.target.value); }} >
                                <MenuItem value=""><em>All stations</em></MenuItem>
                                {
                                    stationsData.map( item => 
                                        <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                    )
                                }
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box p={1} >
                            <Button variant="contained" disableElevation className="Go_btn-primary" >Show drafts</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Box p={1} >
                            <Button variant="contained" disableElevation className="Go_btn-primary" onClick={ getAllData } >Remove filters</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} className="bg_white shadow" boxShadow={3} >
                <Box p={1} className="bg_white"  >
                    <Grid container className="bg_white" >
                        <Grid item xs={4} md={2} onClick={ getAllData }>
                            <Box className="pointer">
                                <Typography variant="caption" display="block" gutterBottom className="text_grey" align="center">
                                    TOTAL ACCIDENTS<br/><br/>
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom className="text_primary" align="center" >
                                <b> 
                                    { info.count } 
                                </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} md={2} onClick={ filterbyInsurance }>
                            <Box className="pointer">
                                <Typography variant="caption" display="block" gutterBottom className="text_grey" align="center">
                                    COVERED BY<br/>INSURANCE
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom className="text_primary" align="center" >
                                <b> 
                                    { info.count_insurance }  
                                </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} md={2} onClick={ filterbyInsurance }>
                            <Box className="pointer">
                                <Typography variant="caption" display="block" gutterBottom className="text_grey" align="center">
                                    TOTAL COVERED BY<br/>INSURANCE
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom className="text_primary" align="center" >
                                    <b> ${ estimationAssurance } </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Box className="pointer" onClick={ filterbyGo }>
                                <Typography variant="caption" display="block" gutterBottom className="text_grey" align="center" >
                                    COVERED BY<br/>GO LOGISTICS
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom className="text_primary" align="center" >
                                    <b> 
                                        { info.count_go }
                                    </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Box className="pointer" onClick={ filterbyGo }>
                                <Typography variant="caption" display="block" gutterBottom className="text_grey" align="center" >
                                    TOTAL COVERED BY <br/>GO LOGISTICS
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom className="text_primary" align="center" >
                                    <b> ${ estimationGo } </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} md={2} onClick={ getAllData } >
                            <Typography variant="caption" display="block" gutterBottom className="text_grey" align="center" >
                                TOTAL <br/> VALUE 
                            </Typography>
                            <Typography variant="body2" display="block" gutterBottom className="text_primary" align="center" >
                                <b> ${ estimationGo + estimationAssurance } </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                { 
                    data 
                    ? 
                        data.length > 0 
                        ? <ListElements isAccident={ true } page_count={ 1 } activePage={ ActivePage } list={ data } filter={ filterList } /> 
                        : NoData()
                    : NoData()
                }
            </Grid>
        </Grid>
    )
}
export default Accidents
    