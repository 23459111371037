import { PDFDocument, StandardFonts } from "pdf-lib";
import pdf from "html-pdf";
import download from "downloadjs";
import { APIGO } from "../../../constants/API";

export const saveOrder = async (data) => {

  const u_id = localStorage.getItem("u_id");
  const url = "https://golosuite.com:8443/label";
  const _weightUnit = data.weightUnit ? 'kg' : 'lb';
  const _packageUnit = data.packageUnit ? 'cm' : 'in';
  let formData = new FormData();
  formData.append("account", u_id);
  formData.append("order_date", data.pickUpDate);
  formData.append("ready_time", data.readyTime);
  formData.append("service", data.service);
  formData.append("pickup_id", data.pickUpId);
  if (data.deliverySaved) {
    formData.append("delivery_id", data.deliveryId);
  } else {
    formData.append("consignee_name", data.deliveryName);
    formData.append("delivery_address", data.deliveryLocation);
    formData.append("delivery_city", data.deliveryCity);
    formData.append("delivery_province", data.deliveryProvince);
    formData.append("delivery_postal", data.deliveryPostal);
    formData.append("delivery_country", data.deliveryCountry);
    formData.append("delivery_contact", data.deliveryName);
    formData.append("delivery_phone", data.deliveryPhone);
    formData.append("delivery_apartment", data.deliveryApartment);
  }
  formData.append("packages", JSON.stringify(data.package));
  formData.append("total_packages", data.packageNumber);
  formData.append("packages_unit", _packageUnit);
  formData.append("total_weight", data.packageWeight);
  formData.append("insurance_value", data.insuranceValue);
  formData.append("weight_unit", _weightUnit);
  formData.append("instructions", data.comments);

  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (resp.ok) {
      const res = await resp.json();
      console.log(res);
      return {
        status: true,
        msg: res.message,
        response: {
          order_id: res.response.order_id,
          golo_id: res.response.golo_id,
        },
      };
    } else {
      const res = await resp.json();
      console.log(res);
      return {
        status: false,
        msg: res.errors,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveOrderBulk = async (data) => {
  const u_id = localStorage.getItem("u_id");
  const url = "https://golosuite.com:8443/label/bulk";
  let formData = new FormData();
  formData.append("account", u_id);
  formData.append("order_date", data.pickUpDate);
  formData.append("service", data.service);
  formData.append("pickup_address", data.pickupAddress);
  formData.append("pickup_apartment", data.pickupApartment);
  formData.append("pickup_city", data.pickupCity);
  formData.append("pickup_province", data.pickupProvince);
  formData.append("pickup_postal", data.pickupPostal);
  formData.append("pickup_country", data.pickupCountry);
  formData.append("pickup_contact", data.pickupContact);
  formData.append("pickup_phone", data.pickupPhone);
  formData.append("delivery_address", data.deliveryAddress);
  formData.append("delivery_apartment", data.deliveryApartment);
  formData.append("delivery_city", data.deliveryCity);
  formData.append("delivery_province", data.deliveryProvince);
  formData.append("delivery_postal", data.deliveryPostal);
  formData.append("delivery_country", data.deliveryCountry);
  formData.append("delivery_contact", data.deliveryContact);
  formData.append("delivery_phone", data.deliveryPhone);
  formData.append("total_packages", data.totalPackages);
  formData.append("total_weight", data.totalWeight);
  formData.append("package_type", data.packageType);
  formData.append("instructions", data.instructions);

  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
    });
    if (resp.ok) {
      const res = await resp.json();
      return {
        status: true,
        msg: res.message,
        response: {
          order_id: res.response.order_id,
          golo_id: res.response.golo_id,
        },
      };
    } else {
      const res = await resp.json();
      return {
        status: false,
        msg: res.errors,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveNewAddress = async (data) => {
  const url = "https://golosuite.com:8443/label/address";
  let formData = new FormData();
  formData.append("account", data.account);
  formData.append("address", data.address);
  formData.append("city", data.city);
  formData.append("province", data.province);
  formData.append("country", data.country);
  formData.append("postal", data.postal);
  formData.append("phone", data.phone);
  formData.append("contact", data.contact);
  formData.append("name", data.name);
  formData.append("aprt_num", data.numInt);
  formData.append("type", data.type);

  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (resp.ok) {
      const res = await resp.json();
      return {
        status: true,
        msg: res.message,
        address_id: res.address_id,
      };
    } else {
      const res = await resp.json();
      return {
        status: false,
        msg: res.errors,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
export const deleteAddress = async (account, address_id) => {
  const url = `https://golosuite.com:8443/label/address?account=${account}&address_id=${address_id}`;

  try {
    const resp = await fetch(url, {
      method: "DELETE",
    });
    if (resp.ok) {
      const res = await resp.json();
      if (res.status === "success") {
        return {
          status: true,
          results: res.results,
        };
      } else {
        return {
          status: false,
          msg: "Account is not valid.",
        };
      }
    } else {
      const res = await resp.json();
      return {
        status: true,
        msg: "Network error, please try again in a few moments.",
        error: res,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAddress = async (account, type) => {

  let config = {
    url:`/label/address/?account=${account}&type=${type}`,
    method:'GET',
  }

 


  const response = await APIGO(config);

  if(response.data.status === "success"){
    return {
      status: true,
      results: response.data.results,
    };
  }else{
    return {
      status: false,
      msg: "Account is not valid.",
    };
  }


/*

  const url = `http://localhost.com:8443/label/address?account=${account}&type=${type}`;

  try {
    const resp = await fetch(url, {
      method: "GET",
    });

    if (resp.ok) {
      const res = await resp.json();
      if (res.status === "success") {
        return {
          status: true,
          results: res.results,
        };
      } else {
        return {
          status: false,
          msg: "Account is not valid.",
        };
      }
    } else {
      const res = await resp.json();
      return {
        status: true,
        msg: "Network error, please try again in a few moments.",
        error: res,
      };
    }
  } catch (error) {
    console.log(error);
  }*/
};
export const getOrderAccount = async (account, limit, page) => {
  const _page = page || 1;
  const _limit = limit || 100;

  const url = `https://golosuite.com:8443/label/account?account=${account}&limit=${_limit}&page=${_page}`;

  try {
    const resp = await fetch(url, {
      method: "GET",
    });

    if (resp.ok) {
      const res = await resp.json();
      if (res.status === "success") {
        return {
          status: true,
          info: res.info,
          results: res.results,
        };
      } else {
        return {
          status: false,
          msg: "Account is not valid.",
        };
      }
    } else {
      const res = await resp.json();
      return {
        status: true,
        msg: "Network error, please try again in a few moments.",
        error: res,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
export const getOrderById = async (account, order_id) => {
  const url = `https://golosuite.com:8443/label/id?account=${account}&order_id=${order_id}`;

  try {
    const resp = await fetch(url, {
      method: "GET",
    });

    if (resp.ok) {
      const res = await resp.json();
      if (res.status === "success") {
        return {
          status: true,
          results: res.results,
        };
      } else {
        return {
          status: false,
          msg: "Account is not valid.",
        };
      }
    } else {
      const res = await resp.json();
      return {
        status: true,
        msg: "Network error, please try again in a few moments.",
        error: res,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
export const getCustomerService = async (account) => {
  const url = `https://golosuite.com:8443/customers/services?account=${account}`;

  try {
    const resp = await fetch(url, {
      method: "GET",
    });

    if (resp.ok) {
      const res = await resp.json();
      if (res.status === "success") {
        return {
          status: true,
          results: res.results,
        };
      } else {
        return {
          status: false,
          msg: "Account is not valid.",
        };
      }
    } else {
      const res = await resp.json();
      return {
        status: true,
        msg: "Network error, please try again in a few moments.",
        error: res,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerDetails = async (id) => {
  let config = {
    method: "get",
    url: `/users/?type=5&id=${id}`,
    headers: {
      "Access-Control-Request-Headers": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("Token")}`,
    },
  };

  const response = await APIGO(config);

  if(response.data){
    return response.data;
  }

  return null
  
};

export const getServicesActive = async (id) => {
    let config = {
        method: 'get',
        url: `customers/services/?account=${id}`,
        headers: {
            "Access-Control-Request-Headers": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
    }
    const response = await APIGO(config)

    if(response.data){
        return response.data
    }

    return null
}

export const getService = async () => {
  const url = `https://golosuite.com:8443/customers/allservices`;

  try {
    const resp = await fetch(url, {
      method: "GET",
    });

    if (resp.ok) {
      const res = await resp.json();
      if (res.status === "success") {
        return {
          status: true,
          results: res.results,
        };
      } else {
        return {
          status: false,
          msg: "Account is not valid.",
        };
      }
    } else {
      const res = await resp.json();
      return {
        status: true,
        msg: "Network error, please try again in a few moments.",
        error: res,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
