import React from 'react'
// Components
import DashboardElement from './../Components/Global/DashboardElement'
// IMG
import Vehicles from './../Assets/Img/Icon_vehicles.png';
import Drivers from './../Assets/Img/Icon_drivers.png';
import History from './../Assets/Img/Icon_history.png';
import Reports from './../Assets/Img/Icon_reports.png';
import Accident from './../Assets/Img/Icon_accident.png';
import Recipt from '../Assets/Img/Icon_recipt.png'

//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const Dashboard = () => {
    console.log(localStorage.getItem('Type'))
    return (
        <Grid container spacing={2} >
            {
                localStorage.getItem('Type') === '1' &&
                <>
                    <Grid item xs={12} md={6}>
                        <DashboardElement icon={Vehicles} title="Vehicles" link="/vehicles" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardElement icon={Drivers} title="Drivers" link="/drivers" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardElement icon={Recipt} title="Gas receipts" link="/drivers/gas" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardElement icon={Reports} title="Reports" link="/reports" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardElement icon={History} title="History" link="/history" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardElement icon={Accident} title="Accidents/Claims" link="/accidents" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardElement icon={Accident} title="Customers" link="/customers" />
                    </Grid>
                    
                </>
            }
            {
                localStorage.getItem('Type') === '2' &&
                <>
                    {
                        localStorage.getItem('PV_R') === '1' &&
                        <Grid item xs={12} md={6}>
                            <DashboardElement icon={Vehicles} title="Vehicles" link="/vehicles" />
                        </Grid>
                    }
                    {
                        localStorage.getItem('PD_R') === '1' &&
                        <Grid item xs={12} md={6}>
                            <DashboardElement icon={Drivers} title="Drivers" link="/drivers" />
                        </Grid>
                    }
                    {
                        localStorage.getItem('PR_R') === '1' &&
                        <Grid item xs={12} md={6}>
                            <DashboardElement icon={Reports} title="Reports" link="/reports" />
                        </Grid>
                    }
                    {
                        localStorage.getItem('PH_R') === '1' &&
                        <Grid item xs={12} md={6}>
                            <DashboardElement icon={History} title="History" link="/history" />
                        </Grid>
                    }
                    {
                        localStorage.getItem('PA_R') === '1' &&
                        <Grid item xs={12} md={6}>
                            <DashboardElement icon={Accident} title="Accidents/Claims" link="/accidents" />
                        </Grid>
                    }
                </>
            }
            {
                localStorage.getItem('Type') === '5' &&
                <>
                   
                </>
            }
        </Grid>
    )
}
export default Dashboard