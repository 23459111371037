import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from './../../Pages/Layout'
//
import {AuthContext} from '../Functions/Auth';

const PrivateRoute = ( { component: RouteComponent, ...rest } ) => {

    // let Token  = sessionStorage.Token;
    const {token} = useContext(AuthContext)
    useEffect(() => {
        
    })

    return (
        <Route { ...rest } render = { routeProps =>
            token? 
                <Layout>
                    <RouteComponent {...routeProps} />
                </Layout> 
            : 
                <Redirect to={"/login"} />
        }/>
    );
};

export default PrivateRoute