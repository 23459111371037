import React from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Logo from './../../Assets/Img/Logo.png';

const Modal = ({ title, onlyShow, showGoTo, goTo, confirmDelete, Delete, confirmOperation, Operation }) =>{
    return(
        <Grid container className="modal__style"> 
            <Grid item >
                <Box>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <img src={ Logo } alt="modal img" />
                    </Grid>
                </Box >
                <Box pb={2} pt={2} textAlign="center">
                    <Typography variant="h6" gutterBottom className="Page_title">
                        { title }
                    </Typography>
                </Box>
                <Box m={4}>
                    {   onlyShow ? 
                            <Grid container>
                                <Grid item xs={12} >
                                    <form onSubmit={ e => showGoTo(e, goTo) }>
                                        <Button variant="outlined" className="Go_btn-primary" fullWidth type="submit" >Confirm</Button>
                                    </form>
                                </Grid>
                            </Grid> : <></>
                    }
                    {
                        confirmDelete ?
                            <Grid container> 
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form onSubmit={ e => Delete(e, false) }>
                                            <Button className="" variant="outlined" type="submit" fullWidth>Cancel</Button>
                                        </form>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form onSubmit={ e => Delete(e, true) }>
                                            <Button variant="outlined" className="Go_btn-primary" fullWidth type="submit" >Delete</Button>
                                        </form>
                                    </Box>
                                </Grid> 
                            </Grid> : <></>
                    }
                    {
                        confirmOperation ?
                            <Grid container> 
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form onSubmit={ e => Operation(e, false) }>
                                            <Button className="" variant="outlined" type="submit" fullWidth>Cancel</Button>
                                        </form>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box px={2}>
                                        <form onSubmit={ e => Operation(e, true) }>
                                            <Button variant="outlined" className="Go_btn-primary" fullWidth type="submit" >Confirm</Button>
                                        </form>
                                    </Box>
                                </Grid> 
                            </Grid> : <></>
                    }
                </Box>
            </Grid>
        </Grid>
    );
}

export default Modal