import React,  { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from "moment";
// Componentes
import ModalShow from '../Components/Global/Modal';
// IMG
import user from './../Assets/Img/Profile_avatar.png';
import img_add from './../Assets/Img/Icon_add.png'
import edit from './../Assets/Img/Icon_edit.png';
import file_placeholder from './../Assets/Img/file_placeholder.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
//API
import { postRegisterDriver, getStation, APIGO } from './../constants/API'; 
import * as firebase from 'firebase';
import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// import 'firebase/storage';
// import firebasesConfig from './../constants/credentials'; 

const Drivers = () => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ fullname, setFullname] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    
    const [ address, setAddress ] = useState('');
    
    const [ goid, setGoid ] = useState('');
    const [ licenseExp, setLicenseExp ] = useState('');
    const [ pswrd, setPswrd ] = useState('');
    const [ stationsData, setStationsData ] = useState([]);
    const [ stations, setStations ] = useState('');
    
    const [ docProfile, setDocProfile] = useState('');
    const [ docDeduction, setDocDeduction ] = useState('');
    const [ previwDeduction, setPreviwDeduction ] = useState('');
    const [ docLicenseFront, setDocLicenseFront ] = useState('');
    const [ previwLicenseFront, setPreviwLicenseFront ] = useState('');
    const [ docProLicenseBack, setDocProLicenseBack ] = useState('');
    const [ previwLicenseBack, setPreviwLicenseBack ] = useState('');
    const [ docDriversAbstract, setDocDriversAbstract ] = useState('');
    const [ previwDriversAbstract, setPreviwDriversAbstract ] = useState('');
    
    const [ data, setData ] = useState( null );
    const [ isError, setIsErrors] = useState([{
        iserror: false,
        fullname: false,
        phone: false,
        email: false,
        address: false,
        goid: false,
        license_exp: false,
        pswrd: false,
        deduction: false,
        license_front: false,
        license_back: false,
        avatar: false,
        Abstract: false,
        station: false,
    }]);
    const [ msg, setMsg] = useState('')
    // Modal
    const [ isConfirm, setIsConfirm ]   = useState( false );
    const [ isShow, setIsShow ]         = useState( false );
    const [ modalStaus, setModalStaus ] = useState( false );
    const [ modalMsg, setModalmsg ]     = useState("");
    const [ modalLink, setModalLink ]     = useState("");
    const [inputsprops, setInputprops] = useState({
        amount: '',
        password: false,
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const [showPass, setShowPass] = useState(false)
    
    const ModalOpen = () =>  setModalStaus(true);
    const ModalClose = () => setModalStaus(false);
    const saveElement = ( e, status ) => { 
        const profileImg = docProfile ? document.getElementById('ImgProfile').src : 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2FProfile_avatar.png?alt=media&token=0c02f76e-981b-42a2-9395-74e979f02520';
        const licenceFront = docLicenseFront ? document.getElementById('licenceFront').src : 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25';
        const licenceBack = docProLicenseBack ? document.getElementById('licenceBack').src : 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25';
        const deductionimg = docDeduction ? document.getElementById('deductionimg').src : 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25';
        const abstract = docDriversAbstract ? document.getElementById('Abstractimg').src : 'https://firebasestorage.googleapis.com/v0/b/go-logistics.appspot.com/o/public%2Fnot-available.png?alt=media&token=14ba0fe5-1d78-4984-9004-1187dcaacb25';
        const licenseExp_ = licenseExp ? moment( licenseExp ).format("YYYYMMDD") : moment().format("YYYYMMDD")

        if ( status === true ){
            setIsConfirm( false );
            
            let config = {
                method: 'post',
                url: postRegisterDriver,
                headers: { 
                    'Access-Control-Request-Headers': 'multipart/form-data', 
                    'Authorization': `Bearer ${ localStorage.getItem('Token') }`
                },
                data: {
                    rdga: goid,
                    user_name: email,
                    email: email,
                    licence_exp: licenseExp_,
                    password: pswrd,
                    phone: phone,
                    full_name: fullname,
                    address: address,
                    deduction: deductionimg,
                    licence_front: licenceFront,
                    licence_back: licenceBack,
                    avatar: profileImg,
                    drivers_abstract: abstract,
                    station: stations,
                    regType: '1'
                }
            }
            APIGO(config)
            .then(response =>{
                console.log( response );
                if ( response.data.status === "error" ) {
                    if( response.data.msg === `The RDGA ${goid} is already use` ){
                        ModalClose()
                        setIsErrors({ userName: true})
                        setMsg( `The RDGA ${goid} is already use` )
                        setModalmsg( `The RDGA ${goid} is already use` );
                        setIsShow( true );
                        ModalOpen();
                    }else if( response.data.msg === `The email ${email} is already registered` ){
                        ModalClose()
                        setIsErrors({ email: true})
                        setMsg( `The email ${email} is already registered` )
                        setModalmsg( `The email ${email} is already registered` );
                        setIsShow( true );
                        ModalOpen();
                    }else if( response.data?.errors[0]['user_name'] ){
                        ModalClose()
                        setIsErrors({ userName: true})
                        setMsg(response.data.errors[0]['user_name'])
                        setModalmsg(response.data.errors[0]['user_name']);
                        setIsShow( true );
                        ModalOpen();
                    }else if( response.data.errors[0]['password'] ){
                        ModalClose()
                        setIsErrors({ pswrd: true });
                        setMsg( " The password must have uppercase, lowercase and numbers " )
                        setModalmsg( " The password must have uppercase, lowercase and numbers " );
                        setIsShow( true );
                        ModalOpen();
                    }else if( response.data.errors[0]['deduction'] ){
                        ModalClose()
                        setIsErrors({ deduction: true });
                        setMsg("The Deduction from must not be null")
                        setModalmsg("The Deduction from must not be null");
                        setIsShow( true );
                        ModalOpen();
                    }else if( response.data.errors[0]['drivers_abstract'] ){
                        ModalClose()
                        setIsErrors({ Abstract: true });
                        setMsg("The drivers abstract must not be null")
                        setModalmsg("The drivers abstract must not be null");
                        setIsShow( true );
                        ModalOpen();
                    }else if( JSON.stringify(response.data.errors[0].password) ){
                        ModalClose()
                        setIsErrors({ pswrd: true });
                        setMsg("The password must have uppercase, lowercase and numbers")
                        setModalmsg("The password must have uppercase, lowercase and numbers")
                        setIsShow( true );
                        ModalOpen();
                    }else if( JSON.stringify(response.data.errors[0].phone) ){
                        ModalClose()
                        setIsErrors({ phone: true });
                        setMsg(response.data.errors[0].phone)
                        setModalmsg(response.data.errors[0].phone)
                        setIsShow( true );
                        ModalOpen();
                    }                    
                }
                if( response.data.status === "error_username" ){
                    ModalClose()
                    setIsErrors({ userName: true})
                    setIsShow( true );
                    setMsg( 'The username is already taken, try to type something different.' )
                    setModalmsg( 'The username is already taken, try to type something different.' )
                    ModalOpen();
                }
                if(response.data.message){
                    setGoid('')
                    setFullname('')
                    setAddress('')
                    setPhone('')
                    setEmail('')
                    setLicenseExp('')
                    setPswrd('')
                    ModalClose()
                    setModalmsg('Driver added successfully');
                    setModalLink('/drivers');
                    setIsShow( true );
                    ModalOpen();
                }
            })
            // .catch(function (error){
            //     // console.log(error)
            //     ModalClose()
            //     setModalmsg('Sorry We have some problems to save a new driver');
            //     setModalLink('/drivers');
            //     setIsShow( true );
            //     ModalOpen();
            // }) 
        }
        else{
            ModalClose()
        }
        setIsConfirm( false );
    }
    const modalGoTo = ( e, link ) => {
        e.preventDefault();
        ModalClose();
        if ( link ) {
            history.push(link);
        }
    };
    const previwImg =  ( e, img ) =>{
        e.preventDefault();
        setLoading( true )
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/Profile/Img${Date.now()}`);
        upload.put(img).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("ImgProfile")
                profileImg.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwDocAbstract = ( e, img ) =>{
        e.preventDefault();
        setLoading( true )
        setPreviwDriversAbstract( e.target.files[0].name )
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/abstract/Img${Date.now()}`);
        upload.put(img).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("Abstractimg")
                profileImg.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwDocDeduction = ( e, img ) =>{
        e.preventDefault();
        setLoading( true )
        setPreviwDeduction( e.target.files[0].name )
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/deduction/Img${Date.now()}`);
        upload.put(img).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("deductionimg")
                profileImg.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwLicenseFrontDoc =  ( e, img ) =>{
        e.preventDefault();
        setLoading( true )
        setPreviwLicenseFront( e.target.files[0].name )
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/licence/Front${Date.now()}`);
        upload.put(img).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("licenceFront")
                profileImg.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const previwsetLicenseBackDoc =  ( e, img ) =>{
        e.preventDefault();
        setLoading( true )
        setPreviwLicenseBack( e.target.files[0].name )
        var storage = firebase.storage().ref();
        var upload = storage.child(`drivers/licence/Back${Date.now()}`);
        upload.put(img).then(snapshot=>{
            upload.getDownloadURL().then(url=>{
                const profileImg =  document.getElementById("licenceBack")
                profileImg.src = url;
                setTimeout(() => setLoading( false ) , 500);
            })
        })
    }
    const handleForm = async e => { 
        e.preventDefault();
        if(fullname === ""){
            setIsErrors({fullname: true, iserror: true });
        }
        else if(phone === ""){
            setIsErrors({phone: true, iserror: true });
            setMsg('Enter a Phone please')
        }
        else if(email === ""){
            setIsErrors({email: true, iserror: true });
            setMsg('Enter a email please')
        }
        else if(address === ""){
            setIsErrors({address: true, iserror: true });
        }
        else if(stations === ""){
            setIsErrors({station: true, iserror: true });
            setMsg('Enter a station please')
        }
        else if(goid === "" ){
            setIsErrors({goid: true, iserror: true });
        }
        else if(pswrd === ""){
            setIsErrors({pswrd: true, iserror: true });
            setMsg('Enter a Password please')
        }
        
        else {
            setIsErrors({ iserror: true });
            setMsg('')
            setIsConfirm( true );
            setIsShow( false )
            setModalStaus( true );
        }
    }
    const handleClickShowPassword = () => {
        setInputprops({ ...inputsprops, showPassword: !inputsprops.showPassword });
        setInputprops({...inputsprops, password: !inputsprops.password })
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        var configDrop = {
            method: 'get',
            url: getStation,
            headers: { 
                'Access-Control-Request-Headers': 'multipart/form-data', 
                'Authorization': `Bearer ${ localStorage.getItem('Token') }`
            }
        }
        APIGO(configDrop)
        .then(response =>{
            setStationsData( response.data )
        })
        .catch( error => { console.log(error) } )
    }, [])

    return(
        <>
            <Modal
                open={modalStaus}
                onClose={ModalClose}
            >
                <Grid className="modal__Posotion modal__Global">
                    { isConfirm ?
                        <ModalShow 
                        title="Are you sure you want to save a new driver? "
                        confirmOperation={ true }
                        Operation={ saveElement }
                        /> : null
                    }    
                    { isShow ?
                        <ModalShow 
                            title={ modalMsg }
                            onlyShow={ true }
                            showGoTo={ modalGoTo }
                            goTo={ modalLink }
                        /> : null
                    }
                </Grid>
            </Modal>

            <form id='myForm' onSubmit={e=> handleForm(e)} >
                <Grid container spacing={2} >
                    <Grid item xs={4} md={2} className="center_center">
                        <Link to="/drivers" className="flex No_Decoration">
                            <ArrowBackIosIcon className="Page_title"/>
                            <Typography variant="subtitle1" gutterBottom className="Page_title">
                                Go back
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={6}  >
                        <Typography variant="h4" gutterBottom className="Page_title">
                            New Driver
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Link to="/drivers" className="No_Decoration" >
                            <Button variant="outlined" disableElevation fullWidth className="" type="button" >CANCEL</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button 
                            fullWidth 
                            disableElevation 
                            variant="contained" 
                            className="Go_btn-primary" 
                            type={ loading ? "button" : "submit" } 
                        >
                            { loading ? "LOADING..." : "SAVE" }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={5} className="box_content" borderRadius={16} boxShadow={3} >
                            <Grid container>
                                { isError.iserror && 
                                    <Grid item xs={12}>
                                        <Box p={2}> 
                                            <Alert severity="error"> Please complete all the files required. </Alert> 
                                        </Box>
                                    </Grid>
                                }   
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Box py={3}>
                                            <input type="file" id="ProfileImage" className="fileUpload" onChange={ e => {setDocProfile( ( e.target.files[0] ) ); previwImg(e, e.target.files[0] );  } }  />
                                            <label htmlFor="ProfileImage" className="labelFileImg">
                                            { docProfile ? 
                                                <img alt="" className="previw_img rounded" id={'ImgProfile'} />
                                                :
                                                <img src={user} alt="" className="profileImg rounded" />
                                            }
                                                <img src={ edit } alt="" className="" />
                                            </label><br/>
                                            { isError.avatar ?
                                                <span className="text_error"><small> Enter an avatar please </small></span> : null
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="DriveLastName" className="labelText"><small>NAME</small></label>
                                        { isError.fullname ?
                                            <TextField id="DriveLastName" type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} error helperText="Enter a valid name please" /> 
                                            : 
                                            <TextField id="DriveLastName" type="text" variant="outlined" fullWidth value={ fullname } onChange={e => setFullname(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="DriverPhone" className="labelText" ><small>PHONE NUMBER</small></label>
                                        { isError.phone ?
                                                <TextField id="DriverPhone" type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} error helperText={msg}/> 
                                            : 
                                                <TextField id="DriverPhone" type="text" variant="outlined" fullWidth value={ phone } onChange={e => setPhone(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="email" className="labelText"><small>EMAIL</small></label>
                                        { isError.email ?
                                                <TextField id="email" type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} error helperText={msg} /> 
                                            : 
                                                <TextField id="email" type="email" variant="outlined" fullWidth value={ email } onChange={e => setEmail(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Box p={1}> 
                                        <label htmlFor="DriveAddress" className="labelText"><small> ADDRESS </small></label>
                                        { isError.address ?
                                                <TextField id="DriveAddress" type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} error helperText="Enter a valid Address please" /> 
                                            : 
                                                <TextField id="DriveAddress" type="text" variant="outlined" fullWidth value={ address } onChange={e => setAddress(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="Station" className="labelText"><small>Station</small></label>
                                        <Select labelId="Station" variant="outlined" value={stations} onChange={e => setStations(e.target.value)} displayEmpty className={ isError.station && 'file-error' } fullWidth >
                                            <MenuItem value=""><em>Select</em></MenuItem>
                                            {  stationsData?.map( item => (
                                                    <MenuItem key={ item.st_id } value={ item.st_id } >{ item.station_id }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        { isError.station ?
                                            <span className="text_error"><small> Enter a Station please </small></span> : null
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="RDGA" className="labelText" ><small>RDGA</small></label>
                                        {
                                            isError.goid ?
                                                <TextField id="RDGA" type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} error helperText="Enter a valid id please" /> 
                                            : 
                                                <TextField id="RDGA" type="text" variant="outlined" fullWidth value={ goid } onChange={e => setGoid(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="licenseExp" className="labelText" ><small>DRIVER LICENSE EXPIRATE DATE</small></label>
                                        {
                                            isError.license_exp ?
                                                <TextField id="licenseExp" type="date" variant="outlined" fullWidth value={ licenseExp } onChange={e => setLicenseExp(e.target.value)} error helperText="Enter a valid id please" /> 
                                            : 
                                                <TextField id="licenseExp" type="date" variant="outlined" fullWidth value={ licenseExp } onChange={e => setLicenseExp(e.target.value)} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box p={1}> 
                                        <label htmlFor="DrivePswrd" className="labelText"><small>PASSWORD</small></label>
                                        {
                                            isError.pswrd ?
                                            <OutlinedInput
                                                id="DrivePswrd"
                                                type={showPass ? 'text' : 'password'}
                                                fullWidth
                                                value={ pswrd }
                                                onChange={e => setPswrd(e.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                endAdornment={
                                                    <InputAdornment 
                                                        position="end"
                                                        onClick={ () => setShowPass( !showPass )  }
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPass ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                labelWidth={0}
                                                helperText={msg}
                                                error 
                                            />
                                            :
                                            <OutlinedInput
                                                id="DrivePswrd"
                                                type={showPass ? 'text' : 'password'}
                                                fullWidth
                                                value={ pswrd }
                                                onChange={e => setPswrd(e.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                endAdornment={
                                                    <InputAdornment 
                                                        position="end"
                                                        onClick={ () => setShowPass( !showPass )  }
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPass ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                labelWidth={0}
                                            />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box p={1} pt={0}>  
                                        <input type="file" id="uploadFileAbstract" className="fileUpload" name="fileUpload" onChange={ e => { setDocDriversAbstract(e.target.files[0]); previwDocAbstract(e, e.target.files[0]); } }  />
                                        <label htmlFor="uploadFileAbstract" className="text_primary pointer">
                                            <span className="labelText"><small><b>DRIVERS ABSTRACT</b></small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                {
                                                    docDriversAbstract && 
                                                    <>
                                                        <img src={ docDriversAbstract } alt="" className="previw_img" id='Abstractimg' />
                                                        {/* <img src={ file_placeholder } alt="" className="previw_img"  /> */}
                                                        <span>{ previwDriversAbstract }</span>
                                                    </>
                                                }
                                                <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                            </Grid>
                                            {
                                                isError.Abstract ?
                                                    <span className="text_error"><small> Enter a drivers abstract from please </small></span> : null
                                            }
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box p={1} pt={0}>  
                                        <input type="file" id="uploadFileDeduction" className="fileUpload" name="fileUpload" onChange={ e => { setDocDeduction(e.target.files[0]); previwDocDeduction(e, e.target.files[0]); } }  />
                                        <label htmlFor="uploadFileDeduction" className="text_primary pointer">
                                            <span className="labelText"><small><b> DEDUCTION FORM </b></small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                {
                                                    docDeduction && 
                                                    <>
                                                        <img src={ docDeduction } alt="" className="previw_img" id='deductionimg' />
                                                        {/* <img src={ file_placeholder } alt="" className="previw_img"  /> */}
                                                        <span>{ previwDeduction }</span>
                                                    </>
                                                }
                                                <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                            </Grid>
                                            {
                                                isError.deduction ?
                                                    <span className="text_error"><small> Enter a deduction from please </small></span> : null
                                            }
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box p={1} pt={0}>  
                                        <input type="file" id="uploadFileLicenseFront" className="fileUpload" name="fileUpload" onChange={ e => { setDocLicenseFront(e.target.files[0]); previwLicenseFrontDoc(e, e.target.files[0]); } }  />
                                        <label htmlFor="uploadFileLicenseFront" className="text_primary pointer">
                                            <span className="labelText"><small> DRIVER LICENSE FRONT </small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                {
                                                    docLicenseFront && 
                                                    <>
                                                        <img src={ docLicenseFront } alt="" className="previw_img" id={'licenceFront'} />
                                                        {/* <img src={ file_placeholder } alt="" className="previw_img"  /> */}
                                                        <span>{ previwLicenseFront }</span>
                                                    </>
                                                }
                                                <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                            </Grid>
                                            {
                                                isError.license_front ?
                                                    <span className="text_error"><small> Enter a driver license please </small></span> : null
                                            }
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box p={1} pt={0}>  
                                        <input type="file" id="uploadFileLicenseBack" className="fileUpload" name="fileUpload" onChange={ e => { setDocProLicenseBack(e.target.files[0]); previwsetLicenseBackDoc(e, e.target.files[0]); } }  />
                                        <label htmlFor="uploadFileLicenseBack" className="text_primary pointer">
                                            <span className="labelText"><small> DRIVER LICENSE BACK </small></span>
                                            <Grid container className="upload_file" direction="column" justify="center" alignItems="center" >
                                                {
                                                    docProLicenseBack && 
                                                    <>
                                                        <img src={ docProLicenseBack } alt="" className="previw_img" id={'licenceBack'} />
                                                        {/* <img src={ file_placeholder } alt="" className="previw_img"  /> */}
                                                        <span>{ previwLicenseBack }</span>
                                                    </>
                                                }
                                                <p className="text_primary center_center"> <img src={img_add}  alt=""/> Add file</p>
                                            </Grid>
                                            {
                                                isError.license_back ?
                                                    <span className="text_error"><small> Enter a driver license please </small></span> : null
                                            }
                                        </label>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
export default Drivers
    

