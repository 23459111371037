import React, {useContext, useEffect, useState} from 'react'
import { Link, useHistory  } from 'react-router-dom';
//styles
import './../Assets/Styles/Layout.css';
// IMG
import Logo from './../Assets/Img/Logo.png';
import Notification from './../Assets/Img/Icon_notification.png';
//MaterialUI
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
//
import {AuthContext} from '../Components/Functions/Auth';
import { Badge, Hidden, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { CHATS_REF } from './../Pages/NewChat/Functions/Contants'
import { APIGO } from '../constants/API';
import MoreIcon from '@material-ui/icons/MoreVert';

const Layout = ( { children } ) => {

    const history = useHistory();
    const userType = localStorage.getItem('Type');
    const { handlelogout, isChatActive, setIsChatActive, type, token } = useContext(AuthContext);
    const [chats, setChats] = useState('');
    const [movil, setMovil] = useState(null);

    const handleClick = (event) => setMovil(event.currentTarget);
    const handleClose = () => setMovil(null);

    useEffect(() => {
        let config = {
            method: 'GET',
            url: '/users/profile',
            headers: {
                'Access-Control':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
        APIGO(config)
        .then( res => {
            getNotifications( res.data.station )
        })
        .catch(err => console.log(err))
    }, [])

    const getNotifications = async ( stationID ) => {
        let chatsActive = [];
        CHATS_REF.orderByChild("status").equalTo("attend").on("value", snap => {
            if ( type == 1 ) {
                snap.forEach( chat => {
                    chatsActive.push( chat.val() )
                })
                setChats( chatsActive )
            }
            else if ( type == 2 ) {
                snap.forEach( chat => {
                    if ( chat.val().station == stationID ) {
                        chatsActive.push( chat.val() )
                    }
                })
                setChats( chatsActive )
            }
            if ( chatsActive.length > 0 ) {
                setIsChatActive( true )
            }
        })
    }

    const LogOut = ( e ) => {
        e.preventDefault();
        history.push("/login");
        window.location.replace('');
    }

    return(
        <Grid  container spacing={3}>
            <Grid item xs={12}  >
                <AppBar position="static" className="navBar" >
                    <Toolbar>
                        <Grid container direction="row" justify="space-between" alignItems="center" className="navBar_movil" >
                            <Box px={3} className="bar_logo">
                                <Link to="/home">
                                    <img src={ Logo } alt="Logo Go Logistics" className="navBar_logo" />
                                </Link>
                            </Box>
                            <Box px={3} className="navBar_menu" >
                                <Hidden only={['xs', 'sm']}>
                                    { userType === '1' && <>
                                        <Link to="/vehicles">
                                            <Button >Vehicles</Button>
                                        </Link>
                                        <Link to="/drivers">
                                            <Button >Drivers</Button>
                                        </Link>
                                        <Link to="/customers">
                                            <Button >Customers</Button>
                                        </Link>
                                        <Link to="/accidents">
                                            <Button >Accidents</Button>
                                        </Link>
                                        <Link to="/reports">
                                            <Button >Reports</Button>
                                        </Link>
                                        <Link to="/history">
                                            <Button >History</Button>
                                        </Link>
                                        <Link to="/settings">
                                            <Button >Settings</Button>
                                        </Link>
                                        <Link to="/chat">
                                            <Button >
                                                { isChatActive 
                                                    ? <Badge color="secondary" variant="dot" badgeContent={chats.length} > <Typography variant="body2">CHAT</Typography> </Badge>
                                                    : <Typography>CHAT</Typography>
                                                }
                                            </Button>
                                        </Link>
                                    </>}
                                    { userType === '2' && <>
                                        {localStorage.getItem('PV_R') === '1' &&
                                            <Link to="/vehicles">
                                                <Button >Vehicles</Button>
                                            </Link>
                                        }
                                        {localStorage.getItem('PD_R') === '1' &&
                                            <Link to="/drivers">
                                                <Button >Drivers</Button>
                                            </Link>
                                        }
                                        {localStorage.getItem('PA_R') === '1' &&
                                            <Link to="/accidents">
                                                <Button >Accidents</Button>
                                            </Link>
                                        }
                                        {localStorage.getItem('PH_R') === '1' &&
                                            <Link to="/history">
                                                <Button >History</Button>
                                            </Link>
                                        }
                                        {localStorage.getItem('PR_R') === '1' &&
                                            <Link to="/reports">
                                                <Button >Reports</Button>
                                            </Link>
                                        }
                                        {localStorage.getItem('PS_R') === '1' &&
                                            <Link to="/settings">
                                                <Button >Settings</Button>
                                            </Link>
                                        }
                                        <Link to="/chat">
                                            <Button >
                                                { isChatActive 
                                                    ? <Badge color="secondary" variant="dot" badgeContent={chats.length} > <Typography variant="body2">CHAT</Typography> </Badge>
                                                    : <Typography>CHAT</Typography>
                                                }
                                            </Button>
                                        </Link>
                                    </>}
                                    { userType === '4' && 
                                        <Link to="/drivers">
                                            <Button >Drivers</Button>
                                        </Link>
                                    }
                                    { userType === '5' &&  <>
                                        <Link to="/customer/order">
                                            <Button >New Order</Button>
                                        </Link>
                                        <Link to="/customer/history">
                                            <Button >Order History</Button>
                                        </Link>
                                        <Link to="#">
                                            <Button >Billing</Button>
                                        </Link>
                                    </>}
                                    <Link to="/login">
                                        <Button onClick={e => { handlelogout(); LogOut(e); }} >Logout</Button>
                                    </Link>
                                    <Button aria-controls="notificationsMenu"  >
                                        <Link to="/notifications">
                                            <img src={ Notification } alt="Notification" />
                                        </Link>
                                    </Button>
                                </Hidden>
                                {/* Menu movil */}
                                <Hidden mdUp>
                                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                        <MoreIcon />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={ movil }
                                        keepMounted
                                        open={ Boolean( movil ) }
                                        onClose={ handleClose }
                                    >
                                        { userType === '1' && <>
                                            <MenuItem>
                                                <Link to="/vehicles" className="navBar_menu-text"> Vehicles </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/drivers" className="navBar_menu-text"> Drivers </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/customers" className="navBar_menu-text"> Customers </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/accidents" className="navBar_menu-text"> Accidents </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/reports" className="navBar_menu-text"> Reports </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/history" className="navBar_menu-text"> History </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/settings" className="navBar_menu-text"> Settings </Link>
                                            </MenuItem>
                                        </>}
                                        { userType === '2' && <>
                                            {localStorage.getItem('PV_R') === '1' &&
                                                <MenuItem>
                                                    <Link to="/vehicles" className="navBar_menu-text"> Vehicles </Link>
                                                </MenuItem>
                                            }
                                            {localStorage.getItem('PD_R') === '1' &&
                                                <MenuItem>
                                                    <Link to="/drivers" className="navBar_menu-text"> Drivers </Link>
                                                </MenuItem>
                                            }
                                            {localStorage.getItem('PA_R') === '1' &&
                                                <MenuItem>
                                                    <Link to="/accidents" className="navBar_menu-text"> Accidents </Link>
                                                </MenuItem>
                                            }
                                            {localStorage.getItem('PH_R') === '1' &&
                                                <MenuItem>
                                                    <Link to="/history" className="navBar_menu-text"> History </Link>
                                                </MenuItem>
                                            }
                                            {localStorage.getItem('PR_R') === '1' &&
                                                <MenuItem>
                                                    <Link to="/reports" className="navBar_menu-text"> Reports </Link>
                                                </MenuItem>
                                            }
                                            {localStorage.getItem('PS_R') === '1' &&
                                                <MenuItem>
                                                    <Link to="/settings" className="navBar_menu-text"> Settings </Link>
                                                </MenuItem>
                                            }
                                        </>}
                                        { ( userType === '2' || userType === '1' ) && 
                                            <MenuItem>
                                                <Link to="/chat" className="navBar_menu-text"> 
                                                    { isChatActive 
                                                        ? <Badge color="secondary" variant="dot" badgeContent={chats.length} > <Typography variant="body2">CHAT</Typography> </Badge>
                                                        : <Typography>CHAT</Typography>
                                                    } 
                                                </Link>
                                            </MenuItem>
                                        }
                                        { userType === '4' && 
                                            <MenuItem>
                                                <Link to="/drivers" className="navBar_menu-text"> Drivers </Link>
                                            </MenuItem>
                                        }
                                        { userType === '5' && <>
                                            <MenuItem>
                                                <Link to="/customer/order" className="navBar_menu-text"> New Order </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/customer/history" className="navBar_menu-text"> Order History </Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="#" className="navBar_menu-text"> Billing </Link>
                                            </MenuItem>
                                        </>}
                                        <MenuItem >
                                            <Link to="/notifications" className="navBar_menu-text"> Notifications </Link> 
                                        </MenuItem>
                                        <MenuItem onClick={e => { handlelogout(); LogOut(e); }} className="navBar_menu-text"> Logout </MenuItem>
                                    </Menu>
                                </Hidden>
                            </Box>                                   
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Container fixed >
                    <Box mt={3} className="App_Content">
                        { children }
                    </Box>
                </Container>
            </Grid>
        </Grid>
    )
}
export default Layout