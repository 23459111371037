import React, {useContext, useEffect} from 'react'
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from '../../Components/Functions/Auth';

const RoutePrivate = ({ component: RouteComponent, ...rest }) => {
    // let Token  = sessionStorage.Token;
    const { token } = useContext(AuthContext)
    useEffect(() => {
        
    },[])

    return (
        <Route {...rest} render={routeProps =>
            token ?
                <React.Fragment>
                    <RouteComponent {...routeProps} />
                </React.Fragment>
                :
                <Redirect to={"/login"} />
        } />
    );
}

export default RoutePrivate
