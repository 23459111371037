import React from 'react'

import { Dialog, DialogContent, IconButton, makeStyles,  useTheme } from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        padding: 0,
        minWidth:theme.spacing(40)
    },
    
}))

const BaseDialog = ({ children,onClose,closeOnOutside,open,maxWidth, fullWidth}) => {

    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.only('xs'));

    const handleClose = () => {
        if(closeOnOutside){
            onClose()
        }
    }

    return (
        <Dialog
            fullScreen={matches}
            fullWidth={fullWidth}
            open={open}
            maxWidth={maxWidth}
            onClose={handleClose}
        >
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>

            <DialogContent className={classes.content} >
                {children}
            </DialogContent>

        </Dialog>
    )
}

BaseDialog.defaultProps = {
    fullWidth:false,
    maxWidth:"xs",
    type:"info",
    open:false,
    closeOnOutside:false
};

export default BaseDialog
