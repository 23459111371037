import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import BarcodeScanner from './BarcodeScanner'
import CropFreeIcon from '@material-ui/icons/CropFree';
const BarcodeScanButton = ({ onSuccess }) => {

    const [open, setOpen] = useState(false)
    const theme = useTheme()

    const matches = useMediaQuery(theme.breakpoints.only("xs"))

    return (
        <React.Fragment>
            {
                matches && <BarcodeScanner open={open} setOpen={setOpen} onSuccess={onSuccess} />
            }
            {matches &&
                <IconButton
                    onClick={() => setOpen(true)}
                    edge="end"
                >
                    <CropFreeIcon />
                </IconButton>}
        </React.Fragment>
    )
}

export default BarcodeScanButton
